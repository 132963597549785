import React, { useState, useEffect } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import Activity from './../../assets/img/ic_Activity.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import Cluster from './../../assets/img/Cluster.svg';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import {
  Container,
  Header,
  Logo,
  Icon,
  Title,
  Description,
  ContainerTitle,
  DescriptionData,
  Row,
  Group,
  DescriptionGroup,
  SubLabel,
  Line
} from './styles';

const Career = () => {

  const [width, setWidth] = useState(window.screen.width);
  const history = useHistory();
  const location = useLocation();

  useEffect(async () => {
    setWidth(window.screen.width)
  }, [width]);


  const onHome = () => {
    history.push({
      pathname: '/',
    });
  }

  const onTest = () => {
    history.push({
      pathname: '/progressoTesteCarreira',
      state: {
        vacancy: location.state.vacancy
      }
    });
  }

  return (
    <Container>
      <ResponsiveAppBar />
      {/* <HeaderUser width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <Group>
        <Icon src={Cluster} alt="Next" />
        <Title>Teste de âncora de carreira</Title>
        <DescriptionGroup>
          <Description>
            <SubLabel color={colors.lightBlue}>Objetivo:</SubLabel> Aumentar o seu autoconhecimento apresentando os pontos mais relevantes para você quando falamos de carreira, aqueles com baixo valor e, se estiver trabalhando, a comparação entre o que você espera e o que a sua empresa está entregando</Description>
        </DescriptionGroup>
        <DescriptionGroup>
          <SubLabel color={colors.lightBlue}>Instruções:</SubLabel>
          <Description>Instruções:
            Serão apresentados dois conjuntos de atributos para a sua avaliação:
            • O primeiro sobre a importância de cada item para você,
            • e o segundo sobre como você percebe esse item na empresa atual, caso esteja trabalhando.
          </Description>
        </DescriptionGroup>
        <DescriptionGroup>
          <Description>
            Dê uma nota de 1 a 10 de acordo com a importância de cada item, sendo 1 nada importante e 10 muito importante.</Description>
        </DescriptionGroup>
        <Row>
          <Icon width='30px' right='8px' src={Activity} alt="Next" />
          <DescriptionData> Tempo estimado 7 minutos</DescriptionData>
        </Row>
        <Line />
        <ContainerTitle>
          <Button
            type="button"
            width="80px"
            fontSize="13px"
            text="Sair"
            color={colors.lightBlue}
            bgColor={colors.transparent}
            onClick={onHome}
          />
          <Button
            type="button"
            width="120px"
            fontSize="13px"
            hoverColor={colors.white}
            bgColor={colors.white}
            color={colors.darkBlue}
            border
            text="Iniciar teste"
            onClick={onTest}
          />
        </ContainerTitle>
      </Group>
    </Container>
  )
}

export default Career;
