import React, { useState, useEffect } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import logo from './../../assets/img/logo.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import HardskillModal from './../../components/HardskillModal';
import { validEmail } from '../../utils/validEmail';
import { useLocation } from 'react-router';
import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";

import {
  Container,
  Header,
  Logo,
  Card,
  SubTitle,
  Title,
  GpButton,
  Group,
  GpForm,
  SubLabel,
  Description,
  Line,
  Step,
  ContainerButton,
  InputSmaller,
  ErrorMessage,
  DescriptionData,
  DescriptionGroup,
  GpNote,
  Tag,
  Note,
  Column
} from './styles';

const StartHardskill = () => {

  const defaultTheme = createMuiTheme();

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: colors.lightBlue,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: colors.lightBlue,
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const [width, setWidth] = useState(window.screen.width);
  const [value, setValue] = useState('');
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [msg, setMsg] = useState("");
  const [step, setStep] = useState(1);
  const [time, setTime] = useState(0);
  const [note1, setNote1] = useState(false);
  const [note2, setNote2] = useState(false);
  const [note3, setNote3] = useState(false);
  const [note4, setNote4] = useState(false);
  const [note5, setNote5] = useState(false);
  const [note6, setNote6] = useState(false);
  const [note7, setNote7] = useState(false);
  const [note8, setNote8] = useState(false);
  const [note9, setNote9] = useState(false);
  const [note10, setNote10] = useState(false);
  const [valueNote, setValueNote] = useState(0);
  const history = useHistory();

  useEffect(async () => {
    setWidth(window.screen.width);

  }, [width]);

  const onBack = () => {
    step == 1 ?
      history.push({
        pathname: '/vaga',
      })
      :
      setStep(step - 1);
  }


  const onNext = async (event) => {
    const value = event.target.value;
    await setStep(step + 1);
  }

  const onFinish = async () => {
    await setTime(4);
    await setModal(true);
  }

  const onHome = async () => {
    history.push({
      pathname: '/',
    });
  }

  const onCloseModal = async () => {
    await setModal(false);
  }

  const handleChange = async (value) => {
    setValueNote(value);
    if (value == 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 2) {
      setNote1(true);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 3) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 4) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 5) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 6) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 7) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 8) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value == 9) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(true);
      setNote10(false);
    } else if (value == 10) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(true);
      setNote10(true);
    }
    onFinish();
  };


  const step1 = <Group>
    <SubLabel color={colors.darkBlue}>Hardskill da vaga - Passo 1 de 4</SubLabel>
    <DescriptionGroup>
      <Step active><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
    </DescriptionGroup>
    <Title>Auto-avaliação de competências</Title>
    <DescriptionGroup>
      <DescriptionData font="14px" weight="400">{'Utilize o formulário abaixo para registrar a sua auto avaliação sobre as suas competências técnicas.'}</DescriptionData>
      <DescriptionData font="14px" weight="400">{'Responda conforme o seu grau de conhecimento sobre cada assunto, considerando a seguinte escala de referência:'}</DescriptionData>
      <DescriptionData font="14px" weight="400">{'0: Nunca atuei na área e não possuo conhecimento teórico.'}</DescriptionData>
      <DescriptionData font="14px" weight="400">{'3: Nunca atuei na área, mas possuo conhecimento teórico.'}</DescriptionData>
      <DescriptionData font="14px" weight="400">{'5: Tenho alguma experiência, mas estou aprendendo.'}</DescriptionData>
      <DescriptionData font="14px" weight="400">{'7: Tenho experiência e consigo auxiliar outras pessoas.'}</DescriptionData>
      <DescriptionData font="14px" weight="400">{'10: Tenho muita experiência e sou visto(a) como referência.'}</DescriptionData>
    </DescriptionGroup>
    <Line />
    < ContainerButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
      <Button
        type="button"
        width="140px"
        fontSize="13px"
        text="Próximo"
        hoverColor={colors.lightBlue}
        color={colors.white}
        bgColor={colors.lightBlue}
        onClick={onNext}
      />
    </ ContainerButton>
  </Group>


  const step2 = <Group>
    <Tag>
      <DescriptionData font="8px" color={colors.white} align="center">
        Desejável
      </DescriptionData>
    </Tag>
    <SubLabel color={colors.darkBlue}>Hardskill da vaga - Passo 2 de 4</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step active><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
    </DescriptionGroup>
    <Title>Inglês</Title>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Básico" control={<BpRadio />} onChange={onNext} label="Básico" />
          </Card>
          <Card>
            <FormControlLabel value="Intermediário" control={<BpRadio />} onChange={onNext} label="Intermediário" />
          </Card>
          <Card>
            <FormControlLabel value="Avançado" control={<BpRadio />} onChange={onNext} label="Avançado" />
          </Card>
          <Card>
            <FormControlLabel value="Fluente" control={<BpRadio />} onChange={onNext} label="Fluente" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step3 = <Group>
    <Tag>
      <DescriptionData font="8px" color={colors.white} align="center">
        Desejável
      </DescriptionData>
    </Tag>
    <SubLabel color={colors.darkBlue}>Hardskill da vaga - Passo 3 de 4</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step active><p>3</p></Step>
      <Step ><p>4</p></Step>
    </DescriptionGroup>
    <Title>CRECI</Title>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Sim" control={<BpRadio />} onChange={onNext} label="Sim" />
          </Card>
          <Card>
            <FormControlLabel value="Não" control={<BpRadio />} onChange={onNext} label="Não" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step4 = <Group>
    <Tag>
      <DescriptionData font="8px" color={colors.white} align="center">
        Desejável
      </DescriptionData>
    </Tag>
    <SubLabel color={colors.darkBlue}>Hardskill da vaga - Passo 4 de 4</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step active><p>4</p></Step>
    </DescriptionGroup>
    <Title>Conhecimento em Python</Title>
    <DescriptionGroup>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
            <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
            <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
            <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
            <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
            <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
            <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
            <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
            <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
          </GpNote>
        </DescriptionGroup>
      )
        :
        (
          <DescriptionGroup>
            <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
            <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
            <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
            <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
            <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
            <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
            <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
            <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
            <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
            <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
          </DescriptionGroup>
        )}
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Container>
        <ResponsiveAppBar />
        {/* <HeaderUser width={width} /> */}
        {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
        <HardskillModal isOpen={modal} onRequestClose={onCloseModal} onHome={onHome} />
        {step == 1 ?
          step1
          : step == 2 ?
            step2
            : step == 3 ?
              step3
              : step4
        }
      </Container>
    </MuiThemeProvider>
  )
}

export default StartHardskill;
