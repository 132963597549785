import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { lightTheme } from './../../styles/theme.js';
import colors from './../../styles/colors';
import Button from "./../../components/Button";
import { ThemeProvider } from "styled-components";
import logo from './../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import PrivacyPolicyModal from './../../components/PrivacyPolicyModal';
import TermsModal from './../../components/TermsModal';
import {
  createMuiTheme
} from "@material-ui/core/styles";


//UTILS
import ScrollToTop from './../../utils/ScrollToTop';

//COMPONENTS
import StoreContext from '../../components/Store/Context';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Description,
  Container,
  Card,
  Header,
  Logo,
  Group,
  FormCheckbox
} from './styles';



const Register = () => {
  const { token, setToken, setLoginHash, loginHash, uuidSession, setUuidSession } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [checkbox, setCheckbox] = useState(false);
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [type, seType] = useState("");
  const [width, setWidth] = useState(window.screen.width);

  const history = useHistory();

  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
          backgroundColor: colors.darkBlue
        }
      }
    }
  });


  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: colors.lightBlue,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: colors.lightBlue,
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }


  useEffect(() => {
    setWidth(window.screen.width)
  }, [width, type])


  const onChangeModal = async (policy, terms) => {
    setPolicy(policy);
    setTerms(terms);
  }

  const onNext = async (e) => {
    e.preventDefault();
    if(type === 'PJ'){
      history.push({
        pathname: '/pessoaJuridica',
      });
    }else{
      history.push({
        pathname: '/pessoaFisica',
      });
    }
  }

  const handleChange = (event) => {
    seType(event.target.value)
  };

  const onCheck = () => {
    setCheckbox(true);
    onChangeModal(false, false);
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <PrivacyPolicyModal width={width} isOpen={policy} onRequestClose={() => onChangeModal(false, false)} onCheck={onCheck} />
            <TermsModal width={width} isOpen={terms} onRequestClose={() => onChangeModal(false, false)} onCheck={onCheck} />
            <Container>
              <Header>
              <a href="/login"><Logo src={logo} alt="iGrown" /></a>
              </Header>
              <Label>Faça seu cadastro</Label>
              <Card>
                <Group top="30px">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormCheckbox border={type === 'PF' ? colors.lightBlue : false}>
                      <FormControlLabel
                        value="PF"
                        control={<BpRadio />}
                        label="Sou pessoa física"
                        labelPlacement="start"
                        onChange={handleChange}
                      />
                    </FormCheckbox>
                    <FormCheckbox border={type === 'PJ' ? colors.lightBlue : false}>
                      <FormControlLabel
                        value="PJ"
                        control={<BpRadio />}
                        label="Sou empresa"
                        onChange={handleChange}
                        labelPlacement="start"
                      />
                    </FormCheckbox>
                  </RadioGroup>
                </Group>
                <Checkbox
                  checked={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Description color={colors.gray3} weight="400" font="12px">Li e estou de acordo com a&nbsp;</Description>
                <Description onClick={() => setPolicy(true)} color={colors.lightBlue} cursor="pointer" weight="400" font="12px">Política de privacidade&nbsp;</Description>
                <Description color={colors.gray3} weight="400" font="12px">e os&nbsp;</Description>
                <Description onClick={() => setTerms(true)} color={colors.lightBlue} cursor="pointer" weight="400" font="12px">Termos de uso.</Description>
                <Group>
                  <Button
                    type="button"
                    width={width > 530 ? "600px" : "320px"}
                    hoverColor={colors.lightBlue}
                    text="Avançar"
                    disabled={checkbox && type.length > 0 ? false : true}
                    onClick={onNext}
                  />
                </Group>
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  )
}

export default Register;
