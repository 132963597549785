import React, { useEffect } from 'react';
import { analytics } from '../../firebaseConfig';
import HeaderLogo from './../../components/Headers/HeaderLogo';
import { logEvent } from "firebase/analytics";
import { lightTheme } from './../../styles/theme.js';
import { ThemeProvider } from "styled-components";
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';

//UTILS
import ScrollToTop from './../../utils/ScrollToTop';


import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Title,
  Container,
  Card,
  Label
} from './styles';
import colors from '../../styles/colors';



const Login = () => {
  const location = useLocation();
  const locationFilter = location.state;
  const email = locationFilter.email;

  const history = useHistory();

  useEffect(() => {
    logEvent(analytics, "SendEmail");
  }, [])


  const onLogin = async (e) => {
    e.preventDefault();
    history.push({
      pathname: '/',
      state: {
        email: email
      }
    });
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogo />
            <Container>
              <Title>Te enviamos um e-mail</Title>
              <Card>
                <Label>
                  Para continuar acesse o link que enviamos para o
                  <p>{email}</p>
                </Label>
                <Button
                  type="button"
                  width="300px"
                  fontSize="13px"
                  text="Voltar"
                  color={colors.lightBlue}
                  bgColor={colors.transparent}
                  onClick={onLogin}
                />
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  )
}

export default Login;
