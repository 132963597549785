import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from './pages/Login';
import LoginAdmin from './pages/LoginAdmin';
import SendEmail from './pages/SendEmail';
import Register from "./pages/Register";
import PrivateRoutes from './components/Routes/PrivateRoutes';
import StoreProvider from './components/Store/Provider';
import Vacancy from "./pages/Vacancy";
import LegalPerson from "./pages/LegalPerson";
import NaturalPerson from "./pages/NaturalPerson";
import Layout from "./Layout";
import CookieConsent from "react-cookie-consent";
import colors from "./styles/colors";
import './styles/global.css';

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      <Switch>
          <Route path="/login" component={Login} />
          <Route path="/loginAdmin" component={LoginAdmin} />
          <Route path="/enviarEmail" component={SendEmail} />
          <Route path="/novoCadastro" component={Register} />
          <Route path="/pessoaFisica" component={NaturalPerson} />
          <Route path="/pessoaJuridica" component={LegalPerson} />
          <Route path="/vaga" component={Vacancy} />
          <PrivateRoutes path="/" component={Layout} />
      </Switch>
       <CookieConsent
        location="bottom"
        buttonText="Entendi"
        cookieName="myAwesomeCookieName2"
        style={{ background: colors.offWhite }}
        buttonStyle={{ background: colors.lightBlue, color: colors.white, fontSize: "13px" }}
        expires={150}
      >
        <span style={{ fontSize: "12px", color: colors.black }}>Este site usa cookies e dados pessoais de acordo com os nossos Termos de Uso e Política de Privacidade e, ao continuar navegando neste site, você declara estar ciente dessas condições.</span>
      </CookieConsent>
    </StoreProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
