import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from './../../components/Table';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { Select, TextField, MenuItem, FormControl } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import api from '../../api';
import StoreContext from '../../components/Store/Context';
import ErrorModal from './../../components/ErrorModal';
import { ThreeDots } from 'react-loader-spinner';
import { useHistory, Link } from "react-router-dom";
import Visualization from './../../components/Visualizations/Time';

import {
  Container,
  Header,
  Label,
  Logo,
  Card,
  GroupText,
  LabelData,
  Group,
  GroupFilter
} from './styles';
import { OneKPlusOutlined } from '@mui/icons-material';


const TeamDashboard = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [error, setError] = useState(false);
  const [companieSelected, setCompanieSelected] = useState(0);
  const [areaSelected, setAreaSelected] = useState(0);
  const [potentialSelected, setPotentialSelected] = useState(0);
  const [leadershipSelected, setLeadershipSelected] = useState(0);
  const [timeSelected, setTimeSelected] = useState(0);
  const [user, setUser] = useState("");
  const [allData, setAllData] = useState([])
  const [dataSelected, setDataSelected] = useState([])
  const [arrayUser, setArrayUser] = useState([]);
  const [msgError, setMsgError] = useState("");
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(async () => {
    getCompanies();
    getUsers();
    setWidth(window.screen.width);
  }, [width]);

  const getUsers = async () => {
    await api.get('/users?status=S', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.users;
      setArrayUser(data);

    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const getInfo = async (value) => {
    const user = arrayUser.filter((item) => {
      return item.name == value || item.email == value
    })


    history.push({
      pathname: '/perfil',
      state: {
        activeUser: user[0].email
      }
    });

  }

  const getData = async () => {

    if (companieSelected > 0) {
      setShow(false);
      setIsVisible(false);

      let data;



      if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected > 0 && areaSelected > 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
          potencial: potentialSelected,
          area_departamento: areaSelected,
          tempo_empresa: timeSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected > 0 && areaSelected > 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
          potencial: potentialSelected,
          area_departamento: areaSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected > 0 && areaSelected == 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
          potencial: potentialSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected == 0 && areaSelected == 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected == 0 && areaSelected == 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected > 0 && areaSelected > 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          potencial: potentialSelected,
          area_departamento: areaSelected,
          tempo_empresa: timeSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected == 0 && areaSelected > 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          area_departamento: areaSelected,
          tempo_empresa: timeSelected,
        };

      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected == 0 && areaSelected == 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          tempo_empresa: timeSelected,
        };
      }

      await api.post('/reports/igrowntime', data, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
        const data = response.data.data;
        let aux = [];
        await data.map((item) => {
          aux.push({ "id": 1, "name": item.nome, "profile": item.perfil_comportamental || "-", "culture": item.cultura || '-', "potential": item.potencial, "values": item.valores_carreira || "-" })
        })
        await setData(aux);
        await setIsVisible(true);
        await setShow(true);
        await setAllData(data);

      }).catch((error) => {
        console.log('erro', error);
        setError(true);
      });
    } else {
      setMsgError("É obrigatória a seleção da empresa!");
    }
  }

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.profile}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.culture}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.values}</th>
      <th key={index}>{item.button}</th>
    </>
  )

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.profile}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.culture}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.values + "%"}</td>
      <td>
        {/* <Link
          target="_blank"
          to={{
            pathname: "/perfil?activeUser=" + arrayUser.filter((i) => {
              return i.name == item.name || i.email == item.name
            })[0].email,
            search: "",
            hash: "",
            state: {
              activeUser: arrayUser.filter((i) => {
                return i.name == item.name || i.email == item.name
              })[0].email
            }
          }}
        > */}
        <Button
          type="button"
          width="120px"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          fontSize={'12px'}
          height={'30px'}
          margin={'0px'}
          text="Ver detalhes"
          onClick={() => getDataSelected(true, item)}
        />
        {/* </Link> */}
      </td>
    </tr >
  )

  const getDataSelected = async (value, json) => {
    await setDataSelected(allData.filter((item) => {
      return item.nome == json.name
    }));
    await setDetails(value);
  }

  const onCloseError = () => {
    setError(false);
  }

  const handleCompanie = (e) => {
    const newValue = e.target.value;
    setCompanieSelected(newValue);
    setMsgError("");
  };

  const handleArea = (e) => {
    const newValue = e.target.value;
    setAreaSelected(newValue);
    setMsgError("");
  };

  const handlePotential = (e) => {
    const newValue = e.target.value;
    setPotentialSelected(newValue);
    setMsgError("");
  };

  const handleLeadership = (e) => {
    const newValue = e.target.value;
    setLeadershipSelected(newValue);
    setMsgError("");
  };

  const handleTime = (e) => {
    const newValue = e.target.value;
    setTimeSelected(newValue);
    setMsgError("");
  };

  const handleUser = (e) => {
    const newValue = e.target.value;
    setUser(newValue);
    setMsgError("");
  };

  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setCompanies(data);

    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setCompanies(data);
  }

  const onClose = () => {
    setDetails(false)
  }

  const users =
  {
    head: [
      { name: 'Nome', profile: 'Perfil comportamental', culture: 'Cultura', values: 'Âncora de carreira', button: ' ' }
    ],
    body: data
  }

  let fieldRow0 = width <= 570 ? '80%' : 1045;
  let fieldRow1 = width <= 570 ? '80%' : 515;
  let fieldRow2A = width <= 570 ? '80%' : 339;
  let fieldRow2B = width <= 570 ? '80%' : 200;

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <Visualization arrayUser={arrayUser} width={width} data={dataSelected} isOpen={details} onClose={onClose} />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <Card>
        <Label>Filtrar sua busca</Label>
        <GroupText>
          <LabelData font="14px" weight="400">Para começar a visualizar os dados, há algumas opções de filtros que podem ser aplicados, como cargo, área ou departamento, tempo de empresa, potencial etc. </LabelData>
        </GroupText>
        <Group>
          {/* <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
            <TextField autoWidth size="small" color="info" disabled={false} placeholder="Procurar por nome de usuário"
              value={user}
              onChange={handleUser}
              InputProps={{
                sx: {
                  'input': {
                    padding: 0.7,
                    '&::placeholder': {
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal'
                    }
                  },
                },
              }}
            />
          </FormControl> */}
          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow0 }}>
            <Select
              value={companieSelected}
              onChange={handleCompanie}
              autoWidth
              size="small"
              color="info"
              sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              InputProps={{
                sx: {
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                },
              }}
            >
              <MenuItem value={0}>
                <em>Procurar por empresa</em>
              </MenuItem>
              {companies.length > 0 ? companies.map((item) => {
                return <MenuItem value={item.id}>{item.nome_fantasia}</MenuItem>
              }) : false
              }
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 1, width: fieldRow1 }}>
            <Select
              value={areaSelected}
              onChange={handleArea}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Área ou departamento</em>
              </MenuItem>
              <MenuItem value={"Todos"}>Todos</MenuItem>
              <MenuItem value={"Diretoria"}>Diretoria</MenuItem>
              <MenuItem value={"Suprimentos"}>Suprimentos</MenuItem>
              <MenuItem value={"Administração de vendas"}>Administração de vendas</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: fieldRow1 }}>
            <Select
              value={leadershipSelected}
              onChange={handleLeadership}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Cargo de liderança</em>
              </MenuItem>
              <MenuItem value={"Todos"}>Todos</MenuItem>
              <MenuItem value={"Sim"}>Sim</MenuItem>
              <MenuItem value={"Não"}>Não</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 1, width: fieldRow1 }}>
            <Select
              value={potentialSelected}
              onChange={handlePotential}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Potencial</em>
              </MenuItem>
              <MenuItem value={"Todos"}>Todos</MenuItem>
              <MenuItem value={"Todos"}>Todos</MenuItem>
              <MenuItem value={"Não"}>Não</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl sx={{ m: 1, width: fieldRow1 }}>
            <Select
              value={0}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Diretoria</em>
              </MenuItem>
              <MenuItem value={1}>Todos</MenuItem>
              <MenuItem value={3}>Marketing</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl sx={{ m: 1, width: fieldRow1 }}>
            <Select
              value={timeSelected}
              onChange={handleTime}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Tempo de empresa</em>
              </MenuItem>
              <MenuItem value={"7 meses a 1 ano"}>7 meses a 1 ano</MenuItem>
              <MenuItem value={"1 a 2 anos"}>1 a 2 anos</MenuItem>
              <MenuItem value={"2 a 4 anos"}>2 a 4 anos</MenuItem>
              <MenuItem value={"4 a 6 anos"}>4 a 6 anos</MenuItem>
              <MenuItem value={"mais de 6 anos"}>mais de 6 anos</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <LabelData align="center" color={colors.alertRed} weight="500">{msgError}</LabelData>
        </Group>
        <GroupFilter align={width > 530 ? "right" : "center"}>
          <Button
            type="button"
            width="120px"
            hoverColor={colors.lightBlue}
            text="Aplicar"
            fontSize="12px"
            onClick={() => getData()}
          />
        </GroupFilter>
      </Card>
      {!show ? <></>
        :
        isVisible ?
          <Card>
            <Label>Time da Empresa</Label>
            <Table
              limit='5'
              headData={users.head}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={users.body}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </Card >
          :
          <Card>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={colors.gray3}
              ariaLabel="three-dots-loading"
              wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
              wrapperClassName=""
              visible={true}
            />
          </Card>
      }
    </Container >
  )
}

export default TeamDashboard;
