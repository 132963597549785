import { createContext } from 'react';

const StoreContext = createContext({
  token: {},
  setToken: () => {},
  userGlobal: {},
  setUserGlobal: () => {},
  loginHash: {},
  setLoginHash: () => {},
  uuidSession: {},
  setUuidSession: () => {},
});

export default StoreContext;
