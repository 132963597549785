import React, { useState, useEffect, useContext } from 'react';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { Select, MenuItem, FormControl } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import ErrorModal from './../../components/ErrorModal';
import { ThreeDots } from 'react-loader-spinner'
import api from '../../api';
import AlertModal from './../../components/AlertModal';
import dataCandidate from './../../assets/data/dataCandidates.json';
import CardColor from './../../components/Card';
import { useLocation } from 'react-router';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts';

import {
  ContainerCard,
  Container,
  Label,
  Card,
  LabelData,
  Group,
  GroupFilter,
  Row,
  ErrorMessage,
  GroupItems,
  SubTitle,
  SubCard,
  DescriptionData,
  CardFilter,
  GroupSearch,
  GpItems,
  Title
} from './styles';


const FitCollaborators = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [del, setDel] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [idSelected, setIdSelected] = useState("");
  const [companieSelected, setCompanieSelected] = useState(0);
  const [msg, setMsg] = useState("");
  const [display, setDisplay] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [userSelected, setUserSelected] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [job, setJob] = useState([]);
  const [error, setError] = useState(false);
  const [modalCandidate, setModalCandidate] = useState(false);
  const [disabledCandidate, setDisabledCandidate] = useState(true);
  const [disabledJob, setDisabledJob] = useState(true);
  const [modalPersona, setModalPersona] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [jobSelected, setJobSelected] = useState(0);
  const [arrayholder, setArrayholder] = useState([]);
  const [selected, setSelected] = useState([]);
  const [line, setLine] = useState([]);
  const [bar, setBar] = useState([]);
  const [column, setColumn] = useState([]);
  const [radar, setRadar] = useState([]);
  const [emails, setEmails] = useState([]);
  const [dataFit, setDataFit] = useState([]);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const handleUser = (e) => {
    e.preventDefault();
    const {
      target: { value },
    } = e;
    if (userSelected.includes(0)) {
      userSelected.splice(0, 1)
    }
    setAlertError(false);
    setUserSelected( // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,);
  }

  const handleCompanie = (e) => {
    const newValue = e.target.value;
    if (newValue > 0) {
      // getNewData(newValue);
      setDisabledJob(false);
    }
    setCompanieSelected(newValue);
  };

  const handleJob = (e) => {
    const newValue = e.target.value;
    if (newValue > 0) {
      getCandidate(companieSelected)
      setDisabledCandidate(false);
    }
    setJobSelected(newValue);
  };

  const getNewData = async (companieSelected) => {
    const newData = await arrayholder.filter((item) => {
      return item.company_id == companieSelected
    })
    setJob(newData);
  }

  const getCandidate = async (id) => {
    let emails = [];
    await api.get('/companies/users/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      await data.map((item) => {
        emails.push(item.email);
      });
      await setEmails(emails);
      await setSelectedCandidate(data);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const getUsers = async () => {
    await api.get('/users?status=S', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.users;
      setUsers(data);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setCompanies(data);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setCompanies(data);
  }

  const getName = (email) => {
    return selectedCandidate.map((item) => {
      if (email == item.email) {
        return item.name;
      }
    })
  }

  const getReport = async () => {
    if (userSelected.length > 0 && jobSelected > 0 && companieSelected > 0) {

      const body = {
        "emails": location.state ? location.state.activeUser : location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : emails,
        "vaga_id": jobSelected
      }

      await api.post('/persona/fit', body, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
        const data = response.data;
        await setDataFit(data);
        let chatSeries = [];
        let barSeries = [];
        let columnSeries = [];
        let radarSeries = [];
        await data.map(async (item) => {
          const nameUser = getName(item.EMAIL);
          const values = item.FITS.VALORES;
          const fits = item.FITS;
          const result = item;
          await chatSeries.push({
            name: nameUser,
            data: [values.ABERTURA_PARA_MUDANCAS_E_INOVACAO, values.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR, values.CELEBRACAO_DE_CONQUISTAS, values.CLIMA_INTERNO, values.CONFIANCA_NA_LIDERANCA_IMEDIATA, values.CONFIANCA_NO_TIME_DE_TRABALHO,
            values.CULTURA_E_VALORES_DA_EMPRESA, values.DESAFIOS_CONSTANTES, values.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS, values.HORARIO_DE_TRABALHO, values.LOCAL_DE_TRABALHO, values.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO,
            values.PACOTE_DE_BENEFICIOS, values.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO, values.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO, values.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS, values.RECONHECIMENTO_E_VALORIZACAO, values.SALARIO,
            values.TRATAMENTO_HUMANIZADO, values.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS, values.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO]
          });
          await barSeries.push({
            name: nameUser,
            data: [fits.VALORES_CARREIRA, fits.COMPORTAMENTAL, fits.LEARN_AGILITIES, fits.CULTURAL, fits.HARD_SKILLS, fits.FIT_PERSONA_IDEAL]
          })
          await columnSeries.push({
            name: nameUser,
            data: [result.AGILIDADE_MENTAL, result.AGILIDADE_COM_PESSOAS, result.AGILIDADE_COM_MUDANCAS, result.AGILIDADE_COM_RESULTADOS, result.POTENCIAL]
          })
          await radarSeries.push({
            name: nameUser,
            data: [result.DESBRAVADOR, result.EMBAIXADOR, result.MANTENEDOR, result.INVESTIGADOR, result.ACOLHIMENTO, result.PROPOSITO, result.APRENDIZADO, result.PRAZER, result.RESULTADOS, result.AUTORIDADE, result.SEGURANÇA, result.ORDEM]
          })
        })
        await setLine({
          series: chatSeries,
          options: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'number',
              categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"]
            },
          }
        })
        await setColumn({
          series: columnSeries,
          options: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ["AGILIDADE MENTAL", "AGILIDADE COM PESSOAS", "AGILIDADE COM MUDANCAS", "AGILIDADE COM RESULTADOS", "POTENCIAL"],
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "$ " + val + " thousands"
                }
              }
            }
          }
        })
        await setBar({
          series: barSeries,
          options: {
            chart: {
              type: 'bar',
              height: 430
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#fff']
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['#fff']
            },
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              categories: ["VALORES DE CARREIRA", "COMPORTAMENTAL", "LEARN AGILITIES", "CULTURAL", "HARDSKILLS", "FIT PERSONA IDEAL"],
            },
          },
        })
        await setRadar({
          series: radarSeries,
          options: {
            chart: {
              height: 350,
              type: 'radar',
              dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1
              }
            },
            title: {
              text: ''
            },
            stroke: {
              width: 2
            },
            fill: {
              opacity: 0.1
            },
            markers: {
              size: 0
            },
            xaxis: {
              categories: ["DESBRAVADOR", "EMBAIXADOR", "MANTENEDOR", "INVESTIGADOR", "ACOLHIMENTO", "PROPOSITO", "APRENDIZADO", "PRAZER", "RESULTADOS", "AUTORIDADE", "SEGURANÇA", "ORDEM"]
            }
          }
        })

        await setIsVisible(true);
        await setDisplay(true);
      }).catch((error) => {
        console.log('erro', error);
        setError(true);
      });
    } else {
      setAlertError(true)
    }
  }

  const getJob = async () => {
    // await api.get('/carreiras', {
    await api.get('/companies/carreiras/' + userGlobal[0].id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      setJob(data);
      setArrayholder(data);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  useEffect(async () => {
    getCompanies();
    getJob();
    getUsers();
    setWidth(window.screen.width)
  }, [width, idSelected, selected]);



  const onCloseError = () => {
    setError(false);
  }

  const onCloseCandidate = () => {
    setModalCandidate(false);
  }

  const onClosePersona = () => {
    setModalPersona(false);
  }

  const onCloseAlert = () => {
    setAlert(false);
  }

  let fieldRow1 = width <= 570 ? '80%' : "54%";
  let fieldRow2 = width <= 570 ? '80%' : "34%";
  let fieldRow3 = width <= 570 ? '80%' : "89.5%";

  return (
    <Container>
      <ResponsiveAppBar />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <Title>Comparativo Fit Colaboradores</Title>
      <CardFilter>
        <Label>Filtrar sua busca</Label>
        <GroupSearch>
          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow2 }}>
            <Select
              value={companieSelected}
              onChange={handleCompanie}
              autoWidth
              size="small"
              color="info"
              sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              InputProps={{
                sx: {
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                },
              }}
            >
              <MenuItem value={0}>
                <em>Procurar por empresa</em>
              </MenuItem>
              {companies.length > 0 ? companies.map((item) => {
                return <MenuItem value={item.id}>{item.nome_fantasia}</MenuItem>
              }) : false
              }
            </Select>
          </FormControl>

          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
            <Select
              value={jobSelected}
              onChange={handleJob}
              disabled={disabledJob}
              autoWidth
              size="small"
              color="info"
              sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              InputProps={{
                sx: {
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                },
              }}
            >
              <MenuItem value={0}>
                <em>Procurar por carreira</em>
              </MenuItem>
              {job.length > 0 ? job.map((item) => {
                return <MenuItem value={item.dados_carreira.id}>{item.dados_carreira.nome}</MenuItem>
              }) : false
              }
            </Select>
          </FormControl>
          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow3 }}>
            <Select
              value={userSelected}
              onChange={handleUser}
              disabled={disabledCandidate}
              autoWidth
              size="small"
              color="info"
              multiple
              sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              InputProps={{
                sx: {
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                },
              }}
            >
              <MenuItem value={0}>
                <em>Procurar por colaborador</em>
              </MenuItem>
              {selectedCandidate.length > 0 ? selectedCandidate.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>
              }) : false
              }
            </Select>
          </FormControl>
        </GroupSearch>
        <GroupSearch>
          <GroupFilter align={width > 530 ? "right" : "center"}>
            <Button
              type="button"
              width="120px"
              hoverColor={colors.lightBlue}
              text="Aplicar"
              fontSize="12px"
              onClick={() => getReport()}
            />
          </GroupFilter>

          <ErrorMessage color={colors.alertRed}>{alertError ? "É necessário selecionar todos os campos!" : ""}</ErrorMessage>
        </GroupSearch>
      </CardFilter>
      {display && dataFit.length > 0 ?
        <>
          <ContainerCard top="45%" bottom="0%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Fit Persona ideal</SubTitle>
                {dataFit.length > 0 ?
                  <Chart options={bar.options} series={bar.series} type="bar" height={400} />
                  : false}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="45%" bottom="0%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Cultura</SubTitle>
                {dataFit.length > 0 ?
                  <Chart options={radar.options} series={radar.series} type="radar" height={600} />
                  : false}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="45%" bottom="0%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Learn Agilities</SubTitle>
                {dataFit.length > 0 ?
                  <Chart options={column.options} series={column.series} type="bar" height={400} />
                  : false}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="45%" bottom="1%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Âncora de carreira</SubTitle>
                {dataFit.length > 0 ?
                  <Chart options={line.options} series={line.series} type="area" height={400} />
                  : false}
                <Row top="2%" align="start" bottom="2px">
                  <GpItems padding="10px" width="50%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                    <SubTitle font={"12px"}>1 - Abertura para mudanças e inovação</SubTitle>
                    <SubTitle font={"12px"}>2 - Autonomia e abertura para se expressar</SubTitle>
                    <SubTitle font={"12px"}>3 - Celebração de conquistas</SubTitle>
                    <SubTitle font={"12px"}>4 - Clima interno</SubTitle>
                    <SubTitle font={"12px"}>5 - Confiança na liderança imediata</SubTitle>
                    <SubTitle font={"12px"}>6 - Confiança no time de trabalho</SubTitle>
                    <SubTitle font={"12px"}>7 - Cultura e valores da empresa</SubTitle>
                    <SubTitle font={"12px"}>8 - Desafios constantes</SubTitle>
                    <SubTitle font={"12px"}>9 - Gestão clara e acompanhamento dos objetivos</SubTitle>
                    <SubTitle font={"12px"}>10 - Horário de Trabalho</SubTitle>
                    <SubTitle font={"12px"}>11 - Local de Trabalho</SubTitle>
                  </GpItems>
                  <GpItems padding="15px" width="50%" radius="0px 12px 12px 0px" background={colors.white} borderColor={colors.white}>
                    <SubTitle font={"12px"}>12 - Oportunidades de desenvolvimento e aprendizado</SubTitle>
                    <SubTitle font={"12px"}>13 - Pacote de benefícios</SubTitle>
                    <SubTitle font={"12px"}>14 - Plano de carreira claro e estruturado</SubTitle>
                    <SubTitle font={"12px"}>15 - Possibilidades de crescimento e promoção</SubTitle>
                    <SubTitle font={"12px"}>16 - Processos simples e desburocratizados</SubTitle>
                    <SubTitle font={"12px"}>17 - Salário</SubTitle>
                    <SubTitle font={"12px"}>18 - Reconhecimento e valorização</SubTitle>
                    <SubTitle font={"12px"}>19 - Tratamento humanizado</SubTitle>
                    <SubTitle font={"12px"}>20 - Tratamento justo e igualitário com todos</SubTitle>
                    <SubTitle font={"12px"}>21 - Visão de futuro da empresa ou negócio</SubTitle>
                  </GpItems>
                </Row>
              </GroupItems>
            </Card>
          </ContainerCard>
        </>
        :
        <></>
      }
    </Container>
  )
}

export default FitCollaborators;
