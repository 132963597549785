import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import logo from './../../assets/img/logo.svg';
import Arrow from './../../assets/img/Arrow.svg';
import Table from './../../components/Table';
import MenuData from '../../components/Menu/MenuCompany';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { Select, TextField, MenuItem, FormControl } from '@mui/material';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import { removeAccentuation } from '../../utils/removeAccentuation.js';
import ErrorModal from './../../components/ErrorModal';
import { ThreeDots } from 'react-loader-spinner';

import {
  Container,
  Header,
  Label,
  Logo,
  Card,
  ContainerCard,
  Icon,
  Value,
  GroupItems,
  Group,
  GroupFilter
} from './styles';


const Dashboard = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [error, setError] = useState(false);
  const [jsonDetails, setJsonDetails] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [data, setData] = useState([]);
  const [active, setActive] = useState('--');
  const [users, setUsers] = useState('--');
  const [plans, setPlans] = useState('--');
  const [object, setObject] = useState([]);
  const [arrayholder, setArrayholder] = useState([]);
  const [companyName, setCompanyName] = useState([]);

  useEffect(async () => {
    console.log(userGlobal)
    getData();
    getUsers();
    setWidth(window.screen.width);
  }, [width]);

  const changeDetails = async (data) => {
    await setJsonDetails(data);
    await setDetails(!details);
  }

  const onClose = async () => {
    await setDetails(!details);
  }

  const onFilter = async (e) => {
    await handleName(e);
    await setIsVisible(false);

    if (companyName.length > 0) {
      const textData = removeAccentuation(companyName.toUpperCase().trim());

      const newData = object.filter((item) => {
        const itemData = removeAccentuation(item.nome_fantasia.toUpperCase().trim());

        return itemData.indexOf(textData) > -1;
      });
      let json = [];
      newData.map((item) => {
        json.push({ "id": item.id, "name": item.nome_fantasia, "plan": item.nome_plano, "status": item.status == 'S' ? 'Ativo' : 'Inativo', details: item })
      });
      setData(json);
    } else {
      setData(arrayholder)
    }
    await setIsVisible(true);
  }

  const getData = async () => {

    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setObject(data);
    //   let json = [];
    //   data.map((item) => {
    //     json.push({ "id": item.id, "name": item.nome_fantasia, "plan": item.nome_plano, "status": item.status == 'S' ? 'Ativo' : 'Inativo', details: item })
    //   });
    //   let num = data.filter((item) => {
    //     return item.status == 'S';
    //   });
    //   // let numPlan = _.keys(_.countBy(data, function(data) { return data.nome_plano; }));

    //   let plans = data.map((item) => {
    //     return item.nome_plano
    //   })
    //   let numPlan = [...new Set(plans)];
    //   setPlans(numPlan.length);
    //   setActive(num.length);
    //   setData(json);
    //   setArrayholder(json);
    //   setIsVisible(true);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

      const data = userGlobal;
      setObject(data);
      let json = [];
      data.map((item) => {
        json.push({ "id": item.id, "name": item.nome_fantasia, "plan": item.nome_plano, "status": item.status == 'S' ? 'Ativo' : 'Inativo', details: item })
      });
      let num = data.filter((item) => {
        return item.status == 'S';
      });
      // let numPlan = _.keys(_.countBy(data, function(data) { return data.nome_plano; }));

      let plans = data.map((item) => {
        return item.nome_plano
      })
      let numPlan = [...new Set(plans)];
      setPlans(numPlan.length);
      setActive(num.length);
      setData(json);
      setArrayholder(json);
      setIsVisible(true);
  }

  const getUsers = async () => {
    await api.get('/users?status=S', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.users;
      setUsers(data.length);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const onCloseError = () => {
    setError(false);
  }

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.plan}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.status}</th>
      <th key={index}>{item.button}</th>
    </>
  )

  const handleName = async (e) => {
    e.preventDefault();
    setCompanyName(e.target.value);
  }


  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.plan}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.status}</td>
      <td>
        <Button
          type="button"
          width="80px"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          fontSize={'12px'}
          height={'30px'}
          margin={'0px'}
          text="Detalhes"
          onClick={() => changeDetails(item.details)}
        /></td>
    </tr>
  )

  let companies =
  {
    head: [
      { name: 'Nome da empresa', plan: 'Plano', status: 'Status', button: ' ' }
    ],
    body: data
  }

  let fieldRow = width <= 570 ? '80%' : 1030;
  let fieldRow1 = width <= 570 ? '80%' : 515;
  let fieldRow2A = width <= 570 ? '80%' : 339;
  let fieldRow2B = width <= 570 ? '80%' : 200;

  return (
    <Container>
      {details ?
        <MenuData isOpen={details} onClose={onClose} title="Detalhes da Empresa" width={width} data={jsonDetails}/>
        : <></>}
        <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <ResponsiveAppBar />
      {/* <HeaderUser width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <ContainerCard>
        <Card width="32%">
          <GroupItems>
            <Label>Empresas ativas</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>{active}</Value>
          </GroupItems>
        </Card>
        <Card width="32%">
          <GroupItems>
            <Label>Usuários ativos</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>{users}</Value>
          </GroupItems>
        </Card>
        <Card width="32%">
          <GroupItems>
            <Label>Planos Contratados</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>{plans}</Value>
          </GroupItems>
        </Card>
      </ContainerCard>
      {/* <ContainerCard>
        <Card width="32%">
          <GroupItems>
            <Label>NPS Teste comportamental</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>--</Value>
          </GroupItems>
        </Card>
        <Card width="32%">
          <GroupItems>
            <Label>NPS Teste cultura</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>--</Value>
          </GroupItems>
        </Card>
        <Card width="32%">
          <GroupItems>
            <Label>NPS Teste Âncora de carreira</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>--</Value>
          </GroupItems>
        </Card>
      </ContainerCard> */}
      <Card>
        <Label>Filtrar sua busca</Label>
        <Group>
          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
            <TextField autoWidth size="small" color="info" disabled={false} placeholder="Procurar por nome de empresa" value={companyName} onChange={onFilter}
              InputProps={{
                sx: {
                  'input': {
                    padding: 0.7,
                    '&::placeholder': {
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal'
                    }
                  },
                },
              }}
            />
          </FormControl>
          {/* <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
            <Select
              value={0}
              autoWidth
              size="small"
              color="info"
              sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              InputProps={{
                sx: {
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                },
              }}
            >
              <MenuItem value={0}>
                <em>Procurar por empresa</em>
              </MenuItem>
              <MenuItem value={1}>iGrown</MenuItem>
            </Select>
          </FormControl> */}
        </Group>
        {/* <Group>
          <FormControl sx={{ m: 1, width: fieldRow2A }}>
            <Select
              value={0}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Área ou departamento</em>
              </MenuItem>
              <MenuItem value={1}>Todos</MenuItem>
              <MenuItem value={2}>Diretoria</MenuItem>
              <MenuItem value={2}>Suprimentos</MenuItem>
              <MenuItem value={4}>Administração de vendas</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: fieldRow2A }}>
            <Select
              value={0}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Cargo de liderança</em>
              </MenuItem>
              <MenuItem value={1}>Todos</MenuItem>
              <MenuItem value={2}>Sim</MenuItem>
              <MenuItem value={3}>Não</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: fieldRow2A }}>
            <Select
              value={0}
              autoWidth
              size="small"
              color="info"
              sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
            >
              <MenuItem value={0}>
                <em>Potencial</em>
              </MenuItem>
              <MenuItem value={1}>Todos</MenuItem>
              <MenuItem value={2}>Sim</MenuItem>
              <MenuItem value={3}>Não</MenuItem>
            </Select>
          </FormControl>
        </Group> */}
        {/* <GroupFilter align={width > 530 ? "right" : "center"}>
          <Button
            type="button"
            width="120px"
            hoverColor={colors.lightBlue}
            text="Aplicar"
            fontSize="12px"
            onClick={onFilter}
          />
        </GroupFilter> */}
      </Card>
      {isVisible ?
        <Card>
          <Label>Últimas empresas</Label>
          <Table
            limit='5'
            headData={companies.head}
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={companies.body}
            renderBody={(item, index) => renderBody(item, index)}
          />
        </Card>
        :
        <Card>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color={colors.gray3}
          ariaLabel="three-dots-loading"
          wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
          wrapperClassName=""
          visible={true}
        />
      </Card>
      }
    </Container>
  )
}

export default Dashboard;
