import { color } from '@mui/system';
import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
    /* Shape */
    position: relative;
    width: 100%;
    height: 100vh;
    `;

export const Step = styled.div`
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    display: inline-grid;
    background: ${(props) => props.active ? colors.lightBlue : colors.transparent};
    color:  ${(props) => props.active ? colors.white : colors.lightBlue};
    margin: 0.16%;
    border: 1px solid ${colors.lightBlue};
    `;

export const Note = styled.div`
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 10px;
    display: inline-grid;
    background: ${(props) => props.active ? colors.lightBlue : colors.transparent};
    color:  ${(props) => props.active ? colors.white : colors.lightBlue};
    margin: 0.2%;
    border: 1px solid ${colors.lightBlue};
    `;


export const Card = styled.div`
    position: relative;
    margin-bottom: 10px;
    width: 428px;
    height: 56px;
    display: inherit;
    justify-content: space-between;    
    padding-left: 20px;
    padding-right: 20px;
    /* FFFFFF */
    
    background: #FFFFFF;
    /* E2E2EA */
    
    border: 1px solid #E2E2EA;
    border-radius: 8px;

    @media (max-width: 870px) {
      width: 400px;
      margin-bottom: 5%;
    }

    @media (max-width: 570px) {  
      width: 340px;
      margin-bottom: 5%;
    }
    `;

export const Header = styled.div`
    position: relative;
    background-color: ${colors.white};
    left: 0%;
    heigth: 100px;
    right: 0%;
    top: 0%;
    bottom: 0%;
    box-shadow: inset 0px -1px 0px #E2E2EA;
  `;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 140px;
  }

  @media (max-width: 570px) {  
    width: 100px;
  }
`;

export const Icon = styled.img`
  position: relative;
  text-align: center;
  padding-right : ${(props) => props.right || "0px"};
  width: ${(props) => props.width || "40px"};
  @media (max-width: 870px) {
    width: ${(props) => props.width || "40px"};
  }

  @media (max-width: 570px) {  
    width: ${(props) => props.width || "40px"};
  }
`;

export const SubTitle = styled.h2`
        position: relative;
        height: 52px;
        font-size: 22px;
        padding-top: 0.5%;
        padding-bottom: 4%;
        padding-left: 4%;
        padding-right: 4%;

        /* H1 - Web */

        font-family: 'Poppins';
        font-weight: 700;

        line-height: 130%;
        /* identical to box height, or 52px */

        @media (max-width: 1440px) {
          font-size: 20px;
        }

        @media (max-width: 870px) {
          font-size: 20px;
        }

        @media (max-width: 570px) {  
          font-size: 18px;
          padding-left: 1%;
          padding-right: 1%;
        }

        text-align: center;
        letter-spacing: 0.116667px;

        color: ${colors.darkBlue};
`;

export const Group = styled.div`
    text-align: center;
    padding-top: 80px;
`;

export const Title = styled.h1`
      position: relative;
      height: 52px;
      font-size: 40px;
      padding-bottom: 4%;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 37px;
      }

      @media (max-width: 570px) {  
        font-size: 27px;
      }

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${colors.darkBlue};
 `;

export const DescriptionGroup = styled.div`
      padding-bottom: 2%;
      padding-top: ${(props) => props.top || "0%"};
      justify-content: space-between;
      @media (max-width: 1500px) {
        padding-bottom: 6%;
        padding-top: 3%;
      }
      @media (max-width: 870px) {
        padding-bottom: 6%;
        padding-top: 5%;
      }

      @media (max-width: 570px) {  
        padding-bottom: 6%;
        padding-top: 5%;
      }

      @media (max-width: 425px) {  
        padding-bottom: 8%;
        padding-top: 5%;
      }

      @media (max-width: 320px) {  
        padding-bottom: 8%;
        padding-top: 5%;
      }
 `;

 export const GpForm = styled.div`
      padding-bottom: 2%;
      width: 470px;
      padding-top: ${(props) => props.top || "0%"};
      justify-content: center;
      display: inline-grid;
 `;

 export const ErrorMessage = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  padding-top: 10px
  margin-bottom: ${(props) => props.maginBottom || "45px"};
  color: ${(props) => props.color || colors.gray2};
  text-align: center;
  @media (max-width: 870px) {
    margin-bottom: 10px;
  }
`;

 export const InputField = styled.div`
  max-width: 320px;
  width: 100%;
  background-color: ${colors.white};
  // margin: 10px 0;
  justify-content: center;
  border: 1px solid ${colors.gray5};
  height: 55px;
  border-radius: 10px;
  padding: 0 0.4rem;
  position: relative;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  /* identical to box height, or 24px */

  letter-spacing: 0.1px;

  input {
  background: none;
  font-weight: 600;
  font-size: 1rem;
  color: ${colors.gray1};
  }

  input::placeholder {
  color: ${colors.gray5};
  font-size: 0.8rem;
  font-weight: 500;
  }
    .eye-toggle{
      position: absolute;
      right: 20px;
      top: 10px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      opacity: 0.6;
    }
`;

export const InputSmaller = styled(InputField)`
  max-width: 320px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 45px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
`;

 export const Description = styled.text`
      position: relative;
      text-align: center !important;
      height: 52px;
      font-size: 13px;
      padding-top: 1.5%;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;

      line-height: 130%;
      /* identical to box height, or 52px */

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${(props) => props.color || colors.white};
`;

export const SubLabel = styled.label`
      position: relative;
      text-align: center !important;
      height: 52px;
      font-size: 15px;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 15px;
      }

      @media (max-width: 570px) {  
        font-size: 14px;
      }

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${(props) => props.color || colors.white};
`;

export const GpButton = styled.div`
      position: relative;
      justify-content: center;
      display: flex;
`;

export const ContainerButton = styled.div`
      position: relative;
      display: flex;
      justify-content: space-between !important;
      padding-right: 41%;
      padding-left: 41%;

      @media (max-width: 1400px) {
        padding-right: 25%;
        padding-left: 25%;
      }

      @media (max-width: 870px) {
        padding-right: 20%;
        padding-left: 20%;
      }

      @media (max-width: 570px) {  
        padding-right: 10%;
        padding-left: 10%;
      }

      @media (max-width: 320px) {  
        padding-bottom: 5%;
        padding-top: 5%;
      }
`;

export const Line = styled.div`
      opacity: 0.2;
      border-bottom: 1px solid ${colors.gray4};
      padding-top: ${(props) => props.top || "5%"};

    @media (max-width: 870px) {
      padding-top: ${(props) => props.top || "5%"};
    }

    @media (max-width: 570px) {  
      padding-top: ${(props) => props.top || "6%"};
    }

    @media (max-width: 425px) {  
      padding-top: ${(props) => props.top || "6%"};
    }

    @media (max-width: 320px) {  
      padding-top: ${(props) => props.top || "8%"};
    }
`;
