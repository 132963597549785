import React, { useContext, useState, useEffect } from 'react';
import { Item, Group, Title, GroupLine, Container, Shape, SubTitle, Row, GpItems, DescriptionData, LabelData, Icon } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import './../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from './../../ErrorModal';
import StatesBrazil from './../../../assets/data/states';
import Chart from 'react-apexcharts';
import vector from './../../../assets/img/Vector.svg';
import { Link } from "react-router-dom";
import CardColor from './../../Card';

const MenuAbout = (prop) => {
    const { token, setToken } = useContext(StoreContext);
    const data = prop.data;

    let icon = token ? false : prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;
    let result = data.length > 0 ? data[0] : [];
    let width = prop.width;
    let user = prop.user;
    let vacancy = prop.vacancy;
    let slide = width <= 570 ? "420px" : "700px";


    return (
        data.length > 0 ?
        <Slide width={slide} right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <GroupLine>
                <Title>Detalhes do candidato</Title>
            </GroupLine>
            <Container>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Avaliado: <b>{ user.users != undefined ? user.users[0].name : '--'}</b></SubTitle>
                    </GpItems>
                    <GpItems padding="15px" width="20%" radius="0px 12px 12px 0px" background={colors.white} borderColor={colors.white}>
                        {data.length > 0 && user.users != undefined ?
                            <Link
                                target="_blank"
                                to={{
                                    pathname: "/perfil?activeUser=" +  user.users != undefined ? user.users[0].email : '--',
                                    search: "",
                                    hash: "",
                                    state: {
                                        activeUser: user.users != undefined ? user.users[0].email : '--',
                                    }
                                }}
                            >
                                <Button
                                    type="button"
                                    width="80px"
                                    hoverColor={colors.lightBlue}
                                    fontSize={'12px'}
                                    height={'30px'}
                                    margin={'0px'}
                                    text="Ver perfil"
                                />
                            </Link>
                            : false}
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Cargo: <b>{vacancy.nome}</b></SubTitle>
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Fit Comportamental: <b>{result.FITS ? result.FITS.COMPORTAMENTAL : "--"}</b></SubTitle>
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Fit Learning Agilities: <b>{result.FITS ? result.FITS.LEARN_AGILITIES : "--"}</b></SubTitle>
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Fit Cultural: <b>{result.FITS ? result.FITS.CULTURAL : "--"}</b></SubTitle>
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Fit com a Persona ideal: <b>{result.FITS ? result.FITS.FIT_PERSONA_IDEAL : "--"}</b></SubTitle>
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Fit Valores: <b>{result.FITS ? result.FITS.VALORES_CARREIRA : "--"}</b></SubTitle>
                    </GpItems>
                </Row>
                <GroupLine>
                    <Title>Perfil Comportamental</Title>
                </GroupLine>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <CardColor
                            width="400px"
                            bgColor={colors.yellow}
                            title='Desbravador'
                            value={result.DESBRAVADOR + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.yellow}
                            title='Embaixador'
                            value={result.EMBAIXADOR + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.yellow}
                            title='Mantenedor'
                            value={result.MANTENEDOR + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.yellow}
                            title='Investigador'
                            value={result.INVESTIGADOR + '%'}
                            font="14px"
                        />
                    </GpItems>
                </Row>
                <GroupLine>
                    <Title>Learning Agilities</Title>
                </GroupLine>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade Mental'
                            value={result.AGILIDADE_MENTAL + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade com Pessoas'
                            value={result.AGILIDADE_COM_PESSOAS + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade com Mudanças'
                            value={result.AGILIDADE_COM_MUDANCAS + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade com Resultados'
                            value={result.AGILIDADE_COM_RESULTADOS + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Potencial Fit'
                            value={result.POTENCIAL + '%'}
                            font="14px"
                        />
                    </GpItems>
                </Row>
                <GroupLine>
                    <Title>Cultura</Title>
                </GroupLine>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Acolhimento'
                            value={result.ACOLHIMENTO + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Propósito'
                            value={result.PROPOSITO + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Aprendizado'
                            value={result.APRENDIZADO + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Prazer'
                            value={result.PRAZER + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Resultados'
                            value={result.RESULTADOS + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Autoridade'
                            value={result.AUTORIDADE + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Segurança'
                            value={result.SEGURANÇA + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.alertRed}
                            title='Ordem'
                            value={result.ORDEM + '%'}
                            font="14px"
                        />
                    </GpItems>
                </Row>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            
            </Container>
        </Slide >
        :
        <Slide width={slide} right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
        <GroupLine>
            <Title>Detalhes do candidato</Title>
        </GroupLine>
        <Container>
            <Row top="0%" align="start" bottom="0px">
                <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                    <SubTitle>Avaliado: <b>{ user.users != undefined ? user.users[0].name : '--'}</b></SubTitle>
                </GpItems>
                <GpItems padding="15px" width="20%" radius="0px 12px 12px 0px" background={colors.white} borderColor={colors.white}>
                    {data.length > 0 && user.users != undefined ?
                        <Link
                            target="_blank"
                            to={{
                                pathname: "/perfil?activeUser=" +  user.users != undefined ? user.users[0].email : '--',
                                search: "",
                                hash: "",
                                state: {
                                    activeUser: user.users != undefined ? user.users[0].email : '--',
                                }
                            }}
                        >
                            <Button
                                type="button"
                                width="80px"
                                hoverColor={colors.lightBlue}
                                fontSize={'12px'}
                                height={'30px'}
                                margin={'0px'}
                                text="Ver perfil"
                            />
                        </Link>
                        : false}
                </GpItems>
            </Row>
            <SubTitle align="center" font="16px" color={colors.alertRed}><b>Não há dados para esse candidado!</b></SubTitle>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>
    </Slide >
    )
}

export default MenuAbout;
