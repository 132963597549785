import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
    /* Shape */
    position: relative;
    width: 100%;
    height: 400px;
    z-index: 1;
    `;


export const Card = styled.div`
    position: relative;
    text-align: center;
    align-item: center;
    width: ${(props) => props.width || "1200px"};
    height: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.5%;
    margin-bottom: 1%;
    top: 20%;
    background: ${colors.white};
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 12px;
    align-items: center;

    @media (max-width: 870px) {
      width: 700px;
      height: 500px;
      margin-bottom: 5%;
    }

    @media (max-width: 570px) {  
      width: 340px;
      height: 480px;
      margin-bottom: 5%;
    }
    `;

export const Header = styled.div`
    position: relative;
    background-color: ${colors.white};
    left: 0%;
    heigth: 100px;
    right: 0%;
    top: 0%;
    bottom: 0%;
    box-shadow: inset 0px -1px 0px #E2E2EA;
  `;

export const Tag = styled.div`
  position: relative;
  background-color: ${(props) => props.background || colors.darkBlue};
  width: 150px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  border-radius: 20px;
`;

export const Icon = styled.img`
  position: relative;
  width: ${(props) => props.width || "50px"};
  float: right;
  padding-right: 10px;

  @media (max-width: 870px) {
    width: ${(props) => props.width || "50px"};
  }

  @media (max-width: 570px) {  
    width: ${(props) => props.width || "50px"};
  }
`;


export const SubTitle = styled.h2`
    /* H2 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: start;
    padding-top: 1.5%;
    padding-right: 30%;
    padding-bottom: ${(props) => props.bottom || "1.8%"};
    letter-spacing: 0.116667px;
    color: ${colors.darkBlue};
    @media (max-width: 870px) {
      padding-top: 5%;
      padding-bottom: 6%;
    }
  
    @media (max-width: 570px) {  
      padding-top: 5%;
      padding-bottom: 6%;
    }
`;



export const ContainerCard = styled.div`
      position: relative;
      text-align: center;
      display: flex;
      align-item: center;
      width: ${(props) => props.width || "1400px"};
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5%;
      justify-content: start;
      top: 30%;
      border-radius: 12px;
      align-items: center;

      @media (max-width: 870px) {
        width: 700px;
        display: block;
      }

      @media (max-width: 570px) {  
        width: 340px;
        display: block;
      }
`;

export const GroupItems = styled.div`
      padding: 5%
`;

export const GroupProgress = styled.div`
      padding-top: 10%;
      padding-bottom: 5%;
`;

export const Progress = styled.div`
      background: ${(props) => props.colors || colors.gray5};
      height: 5px !important;
`;

export const Title = styled.h1`
      position: relative;
      height: 52px;
      top: 80px;
      font-size: 40px;
      padding-bottom: 4%;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 37px;
      }

      @media (max-width: 570px) {  
        font-size: 27px;
      }

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${colors.darkBlue};
 `;

export const Description = styled.h3`
      position: relative;
      height: 52px;
      top: 85px;
      font-size: 20px;
      padding-bottom: 1%;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 18px;
        padding: 8%;
      }

      @media (max-width: 570px) {  
        font-size: 15px;
        padding: 8%;
      }

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${colors.darkBlue};
`;

export const ContainerTitle = styled.div`
      position: relative;
      justify-content: space-between;
      display: flex;
      padding-bottom: ${(props) => props.bottom || "0%"};
`;


export const Row = styled.div`
      position: relative;
      display: flex;
      padding-top: ${(props) => props.top || "10%"};
`;

export const Column = styled.div`
      position: relative;
      justify-content: flex-start;
      display: flex;
      padding-top: ${(props) => props.top || "5%"};
`;

export const DescriptionData = styled.p`
          font-size: 12px;
          padding-top: 1.5%;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: ${(props) => props.weight || "600"};;

          line-height: 130%;
          /* identical to box height, or 52px */

          @media (max-width: 870px) {
            font-size: 12px;
            padding-top: 0.5%;
          }

          @media (max-width: 570px) {  
            font-size: 12px;
            padding-top: 1%;
          }

          text-align: center;
          letter-spacing: 0.116667px;

          color: ${(props) => props.color || colors.darkBlue};
`;

export const DescriptionCard = styled.p`
            text-align: left;

            /* 14px - Regular */
             padding-bottom: ${(props) => props.bottom || "0%"};

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            /* or 21px */

            letter-spacing: 0.1px;

            /* 92929D */

            color: ${(props) => props.color || "#92929D"};
`;