import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from './../../components/Table';
import MenuData from '../../components/Menu/MenuCompany';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import ErrorModal from './../../components/ErrorModal';
import { ThreeDots } from 'react-loader-spinner'

import {
  Container,
  Header,
  Logo,
  Title,
  Card
} from './styles';


const ActiveCompanies = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [jsonDetails, setJsonDetails] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [object, setObject] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);


  useEffect(async () => {
    setWidth(window.screen.width);
    getData();
  }, [width]);

  const changeDetails = async (item) => {
    await setJsonDetails(item);
    await setDetails(!details);
  }

  const getData = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setObject(data);
    //   let json = [];
    //   data.map((item) => {
    //     json.push({ "id": item.id, "name": item.nome_fantasia, "plan": item.nome_plano, "status": item.status == 'S' ? 'Ativo' : 'Inativo', details: item })
    //   });
    //   let num = data.filter((item) => {
    //     return item.status == 'S';
    //   });
    //   setData(json);
    //   setIsVisible(true);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setObject(data);
    let json = [];
    data.map((item) => {
      json.push({ "id": item.id, "name": item.nome_fantasia, "plan": item.nome_plano, "status": item.status == 'S' ? 'Ativo' : 'Inativo', details: item })
    });
    let num = data.filter((item) => {
      return item.status == 'S';
    });
    setData(json);
    setIsVisible(true);


  }

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.plan}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.status}</th>
      <th key={index}>{item.button}</th>
    </>
  )

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.plan}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.status}</td>
      <td>
        <Button
          type="button"
          width="80px"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          fontSize={'12px'}
          height={'30px'}
          margin={'0px'}
          text="Detalhes"
          onClick={() => changeDetails(item.details)}
        /></td>
    </tr>
  )

  const onCloseDetails = async () => {
    await setDetails(!details);
  }

  const companies =
  {
    head: [
      { name: 'Nome da empresa', plan: 'Plano', status: 'Status', button: ' ' }
    ],
    body: data
  }

  const onCloseError = () => {
    setError(false);
  }

  return (
    <Container>
      {details ?
        <MenuData isOpen={details} onClose={onCloseDetails} title="Detalhes da Empresa" width={width} data={jsonDetails} />
        : <></>}
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <ResponsiveAppBar />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <Title>Empresas ativas</Title>
      <Card>
        {isVisible ?
          <Table
            limit='10'
            headData={companies.head}
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={companies.body}
            renderBody={(item, index) => renderBody(item, index)}
          />
          :
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={colors.gray3}
            ariaLabel="three-dots-loading"
            wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
            wrapperClassName=""
            visible={true}
          />}
      </Card>
    </Container>
  )
}

export default ActiveCompanies;
