import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from '../../components/Table';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { Select, TextField, MenuItem, FormControl } from '@mui/material';
import * as Fi from "react-icons/fi";
import * as Bi from "react-icons/bi";
import Tooltip from '@material-ui/core/Tooltip';
import NewCareerModal from '../../components/newCareerModal'; 
import CopyModal from '../../components/CopyModal';
import ItemDeleteModal from '../../components/ItemDeleteModal';
import { useHistory, Link } from 'react-router-dom';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import ErrorModal from './../../components/ErrorModal';
import { ThreeDots } from 'react-loader-spinner'
import { styled } from '@mui/material/styles';
import api from '../../api';
import AlertModal from './../../components/AlertModal';
import { removeAccentuation } from '../../utils/removeAccentuation.js';
import NewPersona from '../../components/NewPersona';

//DATA
import Users from '../../assets/data/career.json'

import {
  ButtonLink,
  Container,
  Header,
  Label,
  Logo,
  Card,
  GroupText,
  LabelData,
  Group,
  GroupFilter,
  Row,
  ErrorMessage,
  Title
} from './styles';


const CareerPanel = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [link, setLink] = useState(false);
  const [del, setDel] = useState(false);
  const [persona, setPersona] = useState(false);
  const [alert, setAlert] = useState(false);
  const [idSelected, setIdSelected] = useState("");
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [arrayholder, setArrayholder] = useState([]);

  const history = useHistory();


  const getData = async () => {
    // await api.get('/carreiras', {
    await api.get('/companies/carreiras/'+userGlobal[0].id, {
    headers: {
      'authorization': 'Bearer ' + token.refreshToken
    }
  }).then(async (response) => {
    const data = response.data;
    setData(data);
    setArrayholder(data);
    setDisplay(true);
    setIsVisible(true);
  }).catch((error) => {
    console.log('erro', error);
    setError(true);
  });
}


useEffect(async () => {
  getData();
  setWidth(window.screen.width)
}, [width, idSelected, selected]);

const renderHead = (item, index) => (
  <>
    <th key={index}>{item.name}</th>
    <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.description}</th>
    <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.code}</th>
    <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.persona}</th>
    <th key={index}>{item.actions}</th>
  </>
)

const renderBody = (item, index) => {
  return <tr key={index}>
    <td>{item.dados_carreira.nome}</td>
    <td class={width <= 570 ? 'hidden' : ''}>{item.dados_carreira.descricao}</td>
    <td class={width <= 570 ? 'hidden' : ''}>{item.dados_carreira.codigo}</td>
    <td class={width <= 570 ? 'hidden' : ''}>{item.persona_ideal_id ? "Sim" : "Não"}</td>
    <td width={"120px"}>
      <Group>
        <Tooltip title="Calibrar Persona">
          <a onClick={() => openPersona(item)}><Fi.FiUsers size={'1rem'} /></a>
        </Tooltip>
        <Tooltip title="Editar carreira">
          <a onClick={() => openEdit(item)}><Bi.BiEditAlt size={'1rem'} /></a>
        </Tooltip>
        {/* <Tooltip title="Excluir carreira">
            <a onClick={() => openDel(item.id)}><Bi.BiTrash size={'1rem'} /></a>
          </Tooltip> */}
      </Group>
    </td>
  </tr>

}

const openEdit = async (item) => {
  await setSelected(item);
  await setIsOpen(true);
}

const openPersona = async (item) => {
  await setSelected(item);
  await setPersona(true);
}



const users =
{
  head: [
    { name: 'Nome', description: 'Descrição', code: "Código", persona: "Persona", actions: 'Ações' }
  ],
  body: data
}

const openDel = async (id) => {
  await setIdSelected(id);
  await setDel(true);
}

const deletePersona = async (id) => {

  await api.delete('/persona/delete/' + id, {
    headers: {
      'authorization': 'Bearer ' + token.refreshToken
    }
  }).then(async (response) => {
    await getData();
    await setMsg("Persona excluída com sucesso!");
    await setDel(false);
    await setAlert(true);
  }).catch((error) => {
    console.log('erro', error);
  });
}

const handleName = async (e) => {
  e.preventDefault();
  setName(e.target.value);
}

const onFilter = async (e) => {
  await handleName(e);
  await setIsVisible(false);

  if (name.length > 0) {
    const textData = removeAccentuation(name.toUpperCase().trim());

    const newData = data.filter((item) => {
      const itemData = removeAccentuation(item.nome.toUpperCase().trim());

      return itemData.indexOf(textData) > -1;
    });
    let json = [];
    newData.map((item) => {
      json.push(item)
    });
    setData(json);
  } else {
    setData(arrayholder)
  }
  await setIsVisible(true);
}

const onCloseError = () => {
  setError(false);
}


const onCloseAlert = () => {
  setAlert(false);
}

const onRequestClose = async () => {
  await setIsOpen(false);
  await setIsVisible(false);
  await getData();
  await setIsVisible(true);
}

const onRequestClosePersona = async () => {
  await setPersona(false);
  await setIsVisible(false);
  await getData();
  await setIsVisible(true);
}

let fieldRow = width <= 570 ? '80%' : 1030;
let fieldRow1 = width <= 570 ? '80%' : "90%";

return (
  <Container>
    <ResponsiveAppBar />
    {/* <HeaderAdmin width={width} /> */}
    {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
    <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
    <ErrorModal isOpen={error} onRequestClose={onCloseError} />
    <NewPersona selected={selected} getData={() => getData()} isOpen={persona} width={width} onRequestClose={() => onRequestClosePersona()} />
    <NewCareerModal selected={selected} getData={() => getData()} isOpen={isOpen} width={width} onRequestClose={() => onRequestClose()} />
    <CopyModal isOpen={link} width={width} onRequestClose={() => setLink(false)} />
    <ItemDeleteModal isOpen={del} width={width} onRequestClose={() => setDel(false)} onDelete={() => deletePersona(idSelected)} />
    <Title>Painel de Carreiras</Title>
    <Card>
      <Label>Filtrar sua busca</Label>
      <Group>
        <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
          <TextField autoWidth size="small" color="info" disabled={false} placeholder="Procurar por nome de carreira" value={name} onChange={onFilter}
            InputProps={{
              sx: {
                'input': {
                  padding: 0.7,
                  '&::placeholder': {
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal'
                  }
                },
              },
            }}
          />
        </FormControl>
      </Group>
      <Group>
        <GroupFilter align={width > 530 ? "right" : "center"}>
          <Button
            type="button"
            width="120px"
            color={colors.lightBlue}
            bgColor={colors.transparent}
            text="Nova carreira"
            fontSize="12px"
            onClick={() => setIsOpen(true)}
          />
        </GroupFilter>
      </Group>
    </Card>
    {display ?
      <Card>
        {isVisible ?
          <Table
            limit='10'
            headData={users.head}
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={users.body}
            renderBody={(item, index) => renderBody(item, index)}
          />
          :
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={colors.gray3}
            ariaLabel="three-dots-loading"
            wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
            wrapperClassName=""
            visible={true}
          />
        }
      </Card>
      : <></>
      // <Card>
      //   <ThreeDots
      //     height="80"
      //     width="80"
      //     radius="9"
      //     color={colors.gray3}
      //     ariaLabel="three-dots-loading"
      //     wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
      //     wrapperClassName=""
      //     visible={true}
      //   />
      // </Card>
    }
  </Container>
)
}

export default CareerPanel;
