import React, { useState } from 'react';
import Modal from "react-modal";

//ASSETS
import Finish from './../../assets/img/Finish.svg';
import Activity from './../../assets/img/ic_Activity.svg';

//COMPONENTS
import Button from '../Button';

//STYLES
import colors from '../../styles/colors'
import { ModalContainer, ModalContent, Image, Title, SubLabel, Row, Icon, DescriptionData, Group } from './styles';

Modal.setAppElement("#root");

function PrivacyPolicyModal({
  isOpen,
  onRequestClose,
  width,
  onCheck,
}) {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 570 ? '800px' : '380px',
      minWidth: '450px',
      height: '450px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 9999
    },
  };


  return (
    <ModalContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Title>Política de Privacidade</Title>
          <Group>
            <DescriptionData weight="400" color={colors.darkBlue}>É de suma importância que você leia atentamente a presente POLÍTICA DE PRIVACIDADE (também chamada somente de “Política”) antes de utilizar os serviços da iGROWN.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN tem como princípio a preservação e a proteção da sua privacidade e seus dados. Por meio desta Política de Privacidade, nós asseguramos esse compromisso.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Ao utilizar os nossos serviços, você está de acordo e ciente acerca da presente Política de Privacidade. Lembrando que você pode acessá-la no momento em que desejar, mas é essencial que você a leia antes de realizar o seu cadastro na plataforma.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Esta Política de Privacidade pode sofrer alterações com o tempo. Sempre que ela for alterada, será indicado, no texto, a data da última modificação. Além disso, você também receberá um e-mail comunicando quais foram as alterações realizadas, com o link para acesso ao texto completo.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Caso ocorra a alteração dos dados coletados e/ou a finalidade da coleta, será requerido um novo consentimento em relação à coleta desses dados.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Caso você tenha alguma dúvida em relação a esta Política de Privacidade, entre em contato conosco através do e-mail <b>ouvidoria@igrown.com.br</b>.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Importante dizer que essa Política de Privacidade e os serviços prestados pela iGROWN estão de acordo com a Lei Geral de Proteção de Dados (Lei nº 13.709). Assim como esta Lei brasileira, a iGROWN também quer garantir os direitos das pessoas em relação a seus dados pessoais.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Então, vamos ao que interessa!</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Os seus dados são coletados, armazenados e utilizados pela iGROWN com o intuito de proporcionar o melhor serviço possível para você.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Assim, nessa Política de Privacidade nós explicamos, de forma simples e direta:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Quais são os dados coletados;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Por que os dados são coletados e para que são utilizados;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Para quem e por que os seus dados são compartilhados; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Como os seus dados são armazenados e protegidos.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Coletar um dado nada mais é do que conseguir ter acesso a uma informação pessoal fornecida por você. Essa informação permite com que você seja identificado(a) por nós.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>1 - QUAIS DADOS COLETAMOS DOS USUÁRIOS COLABORADORES?</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Nós, da iGROWN, temos acesso às informações dos USUÁRIOS através do preenchimento de nosso FORMULÁRIO DE CADASTRO (“Formulário”).</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para se cadastrar são solicitados os seguintes dados pessoais:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Seu e-mail;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Nome completo</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Como devemos te chamar (apelido);</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Celular; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Ocupação Principal</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para o cadastro de pessoas jurídicas em nossa plataforma, são solicitados os dados cadastrais básicos das empresas, como o CNPJ e endereço completo.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Após o preenchimento do formulário de cadastro, o usuário terá criado um login e uma senha para acesso aos serviços da iGROWN através da plataforma. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Na eventual perda de senha, o e-mail será utilizado para recuperar e criar uma nova senha de acesso à plataforma da iGROWN.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Após o preenchimento do formulário de cadastro, é possível completar seu perfil profissional com as seguintes informações:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	dados sobre a carreira (ocupação principal, resumo profissional, cursos e certificações);</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	data de nascimento;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	cidade, estado e país;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	identidade de gênero (podendo selecionar gênero feminino, masculino, não binário ou não citar nenhum gênero);</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	indicar se é PCD (pessoa com deficiência);</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	idioma principal;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	número de celular;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	endereço;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	foto para perfil.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para além disso, há a possibilidade de anexar os comprovantes de cursos realizados, certificados, dentre outros, bem como indicar suas redes sociais, como Facebook, LinkedIn e Instagram.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Há também, na plataforma, espaço livre para contar sobre si mesmo, indicando informações relevantes para a composição de um perfil profissional. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para além disso, são coletadas informações a partir da interação com a plataforma, visando compreender e compor o seu perfil profissional. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Existem 3 (três) testes que podem ser realizados através da plataforma: </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	teste sobre o perfil comportamental;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	cultura;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	âncora de carreira.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Você pode melhor entender quais perguntas serão realizadas, quais respostas podem ser selecionadas dentre as previstas através do tutorial em vídeo, acessível através do presente link: <b>https://igrown.com.br/tutorial-igrown/</b> </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A partir da presente Política de Privacidade, a iGROWN deixa claro que coleta dados pessoais sensíveis. Segundo a LGPD, dado pessoal sensível é todo dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Assim, sempre que houver a coleta desse tipo de dado pessoal, a iGROWN irá indicar precisamente a finalidade da coleta do dado, bem como irá requerer o consentimento específico para o tratamento do referido dado. O consentimento para a coleta e tratamento desses dados será coletado quando do preenchimento desses dados na plataforma. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Eventualmente, será possível realizar o cadastro através do Facebook e/ou Google. Fica desde já estipulado que, caso o USUÁRIO deseje realizar o cadastro através desses sites, deverá ler a Política de Privacidade dos mesmos, pois a presente Política de Privacidade não engloba ou envolve o tratamento de dados realizados pelo Facebook e pelo Google.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Há também a coleta de dados referentes ao dispositivo utilizado para acessar a plataforma, como por exemplo:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	navegador utilizado;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	protocolo de internet (IP, com data e hora);</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	origem do IP;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	páginas acessadas; </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	fuso horário;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	termos de busca digitados na plataforma;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	totalidade do clickstream; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	histórico de navegação.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Através dos aplicativos da iGrown, podemos também coletar as seguintes informações:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	localização; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	identificação sobre seu dispositivo móvel.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Lembrando que os dados acerca da localização podem ser desativados manualmente através das configurações do dispositivo móvel. Se você não consente em fornecer dados sobre sua localização, você poderá desativar essa funcionalidade através de seu próprio aparelho.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>2 - QUAL A FINALIDADE DA COLETA DESSES DADOS?</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Essas informações e dados são coletados com as seguintes finalidades:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	prestar serviço de suporte ao USUÁRIO;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	realizar contato personalizado e direto com o USUÁRIO;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	criar o cadastro mínimo exigido para prestação do serviço;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	realizar o envio de mensagens de marketing para manutenção de USUÁRIOS COLABORADORES ativos;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	melhoramento constante dos serviços prestados por meio da plataforma;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	indicar as melhores trilhas de desenvolvimento profissional do USUÁRIO;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	indicar as melhores áreas para a atuação profissional do USUÁRIO;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	indicar as afinidades do USUÁRIO;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	garantir o acesso do USUÁRIO ao programa de desenvolvimento da empresa, às trilhas de aprendizado específicas para as funções/cargos desejados;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	ter o perfil visualizado e avaliado por potenciais empresas contratantes.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Vale lembrar que essas finalidades para a coleta de dados pessoais estão de acordo com as bases legais previstas no art. 7º da LGPD.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Com isso, conseguiremos cada vez mais melhorar e personalizar o serviço para os USUÁRIOS, de acordo com suas preferências e necessidades, além de criar novos serviços e funcionalidades para a iGROWN.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>3 - DA ATUALIZAÇÃO DA FINALIDADE DA COLETA DOS DADOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Importante dizer que, quando formos solicitar algum dado pessoal, mesmo que não sejam sensíveis, sempre vamos perguntar se você consente em fornecê-los e nós também vamos indicar, no momento da coleta, para qual finalidade esta informação será usada, de maneira bem clara e destacada.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Caso ocorra a alteração da finalidade do uso do dado, nós vamos entrar em contato com você para requerer um novo consentimento.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Vale pontuar que o site pode identificar e armazenar informações relativas aos USUÁRIOS COLABORADORES e visitantes através de Cookies.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Você tem total liberdade para optar por desabilitar os Cookies. Para isso, basta desativar a opção de salvamento e leitura de dados por Cookies, presente nas configurações do navegador utilizado. É possível também impedir os Cookies pelo sistema de Antivírus, caso você tenha instalado em seu computador ou celular.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Os endereços destes links podem coletar informações distintas sobre você para outros fins e de outras formas que diferem da nossa política, por isso, pedimos que verifique com atenção as Políticas de Privacidade e Termos de Uso destes respectivos serviços.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>4 - INFORMAÇÕES E CORREÇÕES DE DADOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Conforme previsto na Lei Geral de Proteção de Dados, você poderá, quando desejar, solicitar que a iGROWN lhe informe quais dos seus dados nós possuímos.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Você também pode corrigir algum dado, alterá-lo ou atualizá-lo e tem o direito de solicitar que os seus dados sejam excluídos a qualquer tempo, desde que não utilize ou não queira mais utilizar a iGROWN, tendo em vista que os dados solicitados são essenciais para a prestação do serviço.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para além disso, você também pode entrar em contato conosco para obter informações acerca do tratamento dos seus dados. Nós vamos disponibilizar essas informações de maneira gratuita e clara.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para ter qualquer informação sobre seus dados, basta entrar em contato conosco através do email <b>ouvidoria@igrown.com.br</b> .</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>É através deste mesmo e-mail que você pode solicitar a portabilidade dos dados e revogar o seu consentimento para o tratamento de dados.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>5 - O PERÍODO DE ARMAZENAMENTO DE DADOS E INCIDENTE QUE ENVOLVA OS DADOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Após o encerramento da relação com a iGROWN, os dados coletados pela iGROWN ficam armazenados pelo prazo de 5 (cinco) anos. Durante esse prazo, a iGROWN poderá comunicar e notificar os USUÁRIOS quanto à novos processos seletivos, novas vagas disponibilizadas por empresas, bem como e-mail marketing relativo ao desenvolvimento de carreiras. Caso você deseje que a iGROWN não envie mais comunicações acerca de tais temas, poderá solicitar a exclusão de seus dados.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Outros dados serão armazenados quando necessários para cumprimento de obrigações legais, incluindo questões tributárias, fiscais e regulatórias.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Caso tenhamos algum incidente ou acidente que envolva os dados (vazamento de dados, invasão da plataforma, dentre outros), temos um procedimento de atuação que consiste em:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>i) no envio de uma notificação a todas as pessoas que chegaram a nos fornecer dados através do preenchimento do formulário de cadastro, de modo a alertar acerca do incidente e de possíveis contatos e abordagens realizados em nome da iGROWN; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>ii) para além disso, indicaremos as medidas de segurança que utilizaremos para mitigar os danos e, além disso, medidas de segurança que serão utilizadas para a proteção dos dados.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para além disso, é válido dizer que a iGROWN emprega todos os esforços para garantir a segurança dos dados coletados, podendo-se citar:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Utilização de métodos padrões e de mercado para criptografar e anonimizar os dados coletados;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Utilização de software de proteção contra acesso não autorizado aos nossos sistemas;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Autorização de acesso somente a pessoas previamente estabelecidas aos locais onde armazenamos as informações;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Aqueles que entrarem em contato com as informações deverão se comprometer a manter sigilo absoluto. A quebra do sigilo acarretará responsabilidade civil e o responsável será processado nos moldes da legislação brasileira.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Vale pontuar que a iGROWN se utiliza do software Secure Sockets Layer (SSL) para a criptografia dos dados coletados. </DescriptionData>
            <DescriptionData color={colors.darkBlue}>6 - COMO OS DADOS SÃO ARMAZENADOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN tem o objetivo de estabelecer uma relação de confiança com o titular dos dados, através de uma atuação transparente que visa assegurar os mecanismos de participação do titular.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Nesse sentido, é importante mencionar que a iGROWN armazena os dados coletados em um servidor na nuvem, denominado CloudJiffy:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 https://cloudjiffy.com/. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para ter mais informações sobre a Política de Privacidade desse servidor, acesse:</DescriptionData>
            <DescriptionData color={colors.darkBlue}>7 - DO COMPARTILHAMENTO DE DADOS COM AS EMPRESAS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para que a iGROWN realize o compartilhamento de seus dados pessoais com outras empresas, será requerida uma autorização expressa para tal compartilhamento.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Para autorizar o compartilhamento de dados será necessário realizar o login na plataforma. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Após o login, o USUÁRIO deverá acessar a aba denominada “Perfil & Configurações” e após “Completar meu perfil”.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Nessa seção, o USUÁRIO poderá selecionar especificamente para quem (quais empresas) deseja que seus dados sejam compartilhados.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Após a declaração expressa de consentimento e a autorização para o compartilhamento dos dados, a iGROWN poderá realizar o compartilhamento das seguintes informações:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>1. Dados do currículo do USUÁRIO;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>2. Dados de performance; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>3. Dados de avaliação e feedback.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>O compartilhamento dos referidos dados, após autorização por parte do USUÁRIO, é o que garante que a iGROWN possa cumprir as finalidades estipuladas na presente Política de Privacidade. </DescriptionData>
            <DescriptionData color={colors.darkBlue}>8 - OS SEUS DIREITOS COMO TITULAR DOS DADOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Finalmente, é importante mencionar que você, titular dos dados, tem seus direitos garantidos pela iGROWN, como previsto na Lei Geral de Proteção de Dados, como por exemplo:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Confirmação de que existe um ou mais tratamento de dados sendo realizado</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Acesso aos dados pessoais conservados que lhe digam respeito</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Correção de dados pessoais incompletos, inexatos ou desatualizados</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Eliminação de dados pessoais desnecessários, excessivos ou caso o seu tratamento seja ilícito</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Portabilidade de dados a outro fornecedor de serviço ou produto, observados os segredos comercial e industrial</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Eliminação de dados (exceto quando o tratamento é legal, mesmo que sem o consentimento do titular)</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Informação sobre compartilhamento de seus dados com entes públicos e privados, caso exista</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Informação sobre o não consentimento, ou seja, sobre a opção de não autorizar o tratamento e as consequências da negativa</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Revogação do consentimento, nos termos da lei</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Reclamação contra o controlador dos dados junto à autoridade nacional</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	 Oposição, caso discorde de um tratamento feito sem o seu consentimento e o considere irregular</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Por fim, caso você esteja com qualquer dúvida com relação à presente Política de Privacidade, não deixe de entrar em contato conosco, por meio do e-mail <b>ouvidoria@igrown.com.br .</b></DescriptionData>
          </Group>
          {width > 570 ?
            <Row top="0%">
              <Button
                type="button"
                width="300px"
                fontSize="13px"
                text="Ler depois"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="400px"
                fontSize="13px"
                text={"Aceito as Políticas de Privacidade"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </Row>
            :
            <>
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text="Ler depois"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text={"Aceito as Políticas de Privacidade"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </>
          }
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default PrivacyPolicyModal;


