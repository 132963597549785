
// eslint-disable-next-line import/no-anonymous-default-export
export default {

    // Brand
    pureGreen: '#95D45A',
    darkestGreen: '#135436',
    cream: '#f5fffa',

    // Primary
    darkBlue: '#062847',
    saturatedDarkGreen: '#0C5D36',
    mediumGreen: '#39684D',
    lowGreen: '#0F8D48',
    cleanGreen: '#1DB954',
    saturatedGreen: '#94bda9',
    cleanGreen2: '#3DD969',
    cleanGreen3: '#D1FFBF',
    lightBlue: '#07A5D4',
    blue: '#40caff',
    blueClean: '#c8e7fe',

    // Support
    neutralGray: '#333333',
    yellow: '#fad761',
    lightRed: '#FB9283',
    lightGreen: '#06bc84',
    alertRed: '#FF5967',
    white: '#FFFFFF',
    offWhite: '#F2F2F2',

    // Neutral
    black: '#262626',
    gray1: '#333333',
    gray2: '#4F4F4F',
    gray3: '#828282',
    gray4: '#A3A3A3',
    gray5: '#E2E2EA',
    gray6: '#f1f1f3',
    lightSmock: '#D5D7DE',
    gray6: '#F2F2F2',
    snow: '#f7f7f7',
    smock: '#F5F5F5',
    carmine: '#F5FFFB',

    // Background
    lightCream: 'rgba(199, 255, 177, 0.12)',
    transparent: 'transparent',
};