import React, { useContext, useState } from 'react';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import './../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from './../../ErrorModal';

const MenuSocial = (prop) => {

    const { token, setToken } = useContext(StoreContext);
    const [facebook, setFacebook] = useState(prop.user[0].dados_adicionais.facebook);
    const [twitter, setTwitter] = useState(prop.user[0].dados_adicionais.twitter);
    const [linkedin, setLinkedin] = useState(prop.user[0].dados_adicionais.linkedin);
    const [site_pessoal, setSite_pessoal] = useState(prop.user[0].dados_adicionais.site_pessoal);
    const [error, setError] = useState(false);

    const handleSite = async (e) => {
        const value = e.target.value;
        setSite_pessoal(value)
    }

    const handleFacebook = async (e) => {
        const value = e.target.value;
        setFacebook(value)
    }

    const handleTwitter = async (e) => {
        const value = e.target.value;
        setTwitter(value)
    }

    const handleLinkedin = async (e) => {
        const value = e.target.value;
        setLinkedin(value)
    }

    const onCloseError = () => {
        setError(false);
    }

    const putData = async () => {
        const json = {
            "user_id": prop.user[0].id,
            "site_pessoal": site_pessoal,
            "linkedin": linkedin,
            "facebook": facebook,
            "twitter": twitter,
        }

        await api.put('/dados-candidatos/update', json, {
            headers: {
                'authorization': 'Bearer ' + token.refreshToken
            }
        }).then(async (response) => {
            prop.onClose();
        }).catch((error) => {
            console.log('erro', error);
            setError(true);
        });
    }

    let icon = prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;

    return (
        <Slide right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <ErrorModal isOpen={error} onRequestClose={onCloseError} />
            <GroupLine>
                <Title>Redes Sociais</Title>
            </GroupLine>
            <Container>
                <Group>
                    <TextField label="Site pessoal" onChange={handleSite} InputLabelProps={{ shrink: true, required: false }} size="small" value={site_pessoal} color="info" disabled={false} />
                </Group>
                <Group>
                    <TextField label="LinkedIn" onChange={handleLinkedin} InputLabelProps={{ shrink: true, required: false }} size="small" value={linkedin} color="info" disabled={false} />
                </Group>
                <Group>
                    <TextField label="Facebook" onChange={handleFacebook} InputLabelProps={{ shrink: true, required: false }} size="small" value={facebook} color="info" disabled={false} />
                </Group>
                <Group>
                    <TextField label="Twitter" onChange={handleTwitter} InputLabelProps={{ shrink: true, required: false }} size="small" value={twitter} color="info" disabled={false} />
                </Group>
                <Button
                    type="button"
                    width="230px"
                    hoverColor={colors.lightBlue}
                    text="Salvar alterações"
                    fontSize="12px"
                    onClick={putData}
                />
            </Container>
        </Slide>
    )
}

export default MenuSocial;
