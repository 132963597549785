import styled from 'styled-components';
import colors from './../styles/colors';

export const LayoutContainer = styled.div `
  background-color: ${({ theme }) => theme.background};
  /* transition: 0.8s ease-in-out */
`; 

export const LayoutContent = styled.div `
  min-height: 100vh;
  @media (max-width: 870px) {
    padding-left: 0;
  }
`;

export const LayoutContentMain = styled.div `
  margin: 0 auto;
  margim-bottom: 10%;
`; 

