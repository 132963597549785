import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth, analytics } from '../../firebaseConfig';
import { signInWithEmailAndPassword } from "firebase/auth";
import { logEvent } from "firebase/analytics";
import { lightTheme } from './../../styles/theme.js';
import colors from './../../styles/colors';
import Button from "./../../components/Button";
import { validEmail } from './../../utils/validEmail';
import { ThemeProvider } from "styled-components";
import logo from './../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
//ASSETS
import Eye from '../../assets/img/Eye_Icon.png';
import EyeClose from '../../assets/img/Eye_Close_Icon.png';

//UTILS
import ScrollToTop from './../../utils/ScrollToTop';

//COMPONENTS
import StoreContext from '../../components/Store/Context';
import api from '../../api';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Container,
  Card,
  InputSmaller,
  ErrorMessage,
  Header,
  Logo,
  Group
} from './styles';



const Login = () => {
  const { token, setToken, setLoginHash, loginHash, uuidSession, setUuidSession } = useContext(StoreContext);
  const [error, setError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [msgLogin, setMsgLogin] = useState("");
  const [width, setWidth] = useState(window.screen.width);


  const history = useHistory();

  useEffect(() => {
    logEvent(analytics, "Login");
    setWidth(window.screen.width)
    if (email.length > 0 && password.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password, width])


  const handleSignIn = async (e) => {
    e.preventDefault();
    logEvent(analytics, "Login_TAP_SignIn");
    setIsLoading(true);
    if (!validEmail(email)) {
      setError(true);
      setIsLoading(false);
      setMsgLogin("E-mail inválido! Digite um e-mail válido!");
    } else {
      firebaseAutentication();
    }
  }


  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const firebaseAutentication = async () => { 
      logEvent(analytics, "Login_TAP_SignIn");

      const data = {
        'email': email,
        'password': password,
      }
  
      if (email && password) {
        try {
          const { user } = await signInWithEmailAndPassword(auth, email.trim(), password.trim());
          await api.post('/login', data, {
            headers: {
              'Content-Type': 'aplication/json',
            }
          }).then(async (response) => {
            const data = response.data
            await setToken(data);
            history.push({
              pathname: '/',
              state: {
                email: email
              }
            });
          }).catch((error) => {
            console.log('error', error);
          });
        } catch (error) {
          console.log('error', error.message);
          setIsLoading(false);
          setMsgLogin('Usuário ou senha inválidos!');
          setError(true);
        }
      } else {
        setMsgLogin('Usuário ou senha inválidos!');
        setError(true);
        setIsLoading(false);
      }
  }

  const handleEmail = async (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setError(false);
    setMsgLogin("");
  }

  const handlePassword = async (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    setError(false);
    setMsgLogin("");
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <Container>
              <Header>
              <a href="/login"><Logo src={logo} alt="iGrown" /></a>
              </Header>
              <Label>Acesso Admin</Label>
              <Card>
                <Group>
                  <InputSmaller autoFocus>
                    <input type="text" value={email} name="email" placeholder="Digite seu e-mail" onChange={handleEmail} />
                  </InputSmaller>
                  <InputSmaller autoFocus>
                    <input type={passwordShown ? "text" : "password"} value={password} name="password" placeholder="Digite sua senha" onChange={handlePassword} />
                    <img className="eye-toggle" src={passwordShown ? Eye : EyeClose} alt="password toggle" onClick={togglePassword} />
                  </InputSmaller>
                </Group>
                <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>{msgLogin}</ErrorMessage>
                <Button
                  type="button"
                  width="320px"
                  hoverColor={colors.lightBlue}
                  text="Avançar"
                  isLoading={isLoading}
                  disabled={disabled}
                  onClick={handleSignIn}
                />
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  )
}

export default Login;
