import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from './../../components/Table';
import Ellipse1 from './../../assets/img/Ellipse1.svg';
import Ellipse2 from './../../assets/img/Ellipse2.svg';
import MenuData from '../../components/Menu/MenuData';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import ErrorModal from './../../components/ErrorModal';

import { ThreeDots } from 'react-loader-spinner'

//DATA
import data from './../../assets/data/plans.json';
import api from '../../api';

import {
  Container,
  Title,
  Header,
  Label,
  Logo,
  Card,
  ContainerCard,
  Icon,
  Value,
  GroupItems,
  ContainerTitle
} from './styles';



const Plans = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [jsonPlans, setJsonPlans] = useState([]);
  const [data, setData] = useState([]);
  const [aux, setAux] = useState([]);
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [active, setActive] = useState("Todos os planos");


  useEffect(async () => {
    setWidth(window.screen.width);
    getData();
  }, [width]);


  const getData = async () => {
    await api.get('/planos', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      setJsonPlans(data);
      getCompanies();
    }).catch((error) => {
      console.log('erro', error);
    });
  }


  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;

    //   let json = [];
    //   data.map((item) => {
    //     json.push({ "name": item.nome_fantasia, "plan": item.nome_plano, "status": item.status == 'S' ? 'Ativo' : 'Inativo' })
    //   });
    //   setData(json);
    //   setAux(json);
    //   setIsVisible(true);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;

    let json = [];
    data.map((item) => {
      json.push({ "name": item.nome_fantasia, "plan": item.nome_plano, "status": item.status == 'S' ? 'Ativo' : 'Inativo' })
    });
    setData(json);
    setAux(json);
    setIsVisible(true);
  }

  const onCloseError = () => {
    setError(false);
  }

  const changeDetails = (id) => {
    setDetails(!details);
  }

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.plan}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.status}</th>
    </>
  )

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.plan}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.status}</td>
    </tr>
  )

  const plans =
  {
    head: [
      { name: 'Nome da empresa', plan: 'Plano', status: 'Status' }
    ],
    body: data
  }

  const onFilter = async (plan) => {
    setActive(plan);
    setIsVisible(false);
    if (plan != "Todos os planos") {
      await setData(aux.filter((item) => {
        return item.plan == plan
      })
      );
      await setIsVisible(true);
    } else {
      await setData(aux);
      await setIsVisible(true);
    }
  }


  return (
    <Container>
      {/* {details ? */}
      {/* <MenuData isOpen={details} onClose={changeDetails} title="Detalhes do Plano" width={width} />
        :  */}
      <ResponsiveAppBar />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      {/* } */}
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <Title>Planos Contratados</Title>
      <ContainerCard>
        <Card width="19%" cursor="pointer" background={active == "Todos os planos" ? colors.lightBlue : false} onClick={() => onFilter("Todos os planos")}>
          <GroupItems>
            <ContainerTitle>
              <Label color={active == "Todos os planos" ? colors.white : false}>Todos os planos</Label>
              <Icon src={active == "Todos os planos" ? Ellipse2 : Ellipse1} alt="Next" />
            </ContainerTitle>
            <Value color={active == "Todos os planos" ? colors.white : false}>{aux.length}</Value>
          </GroupItems>
        </Card>
        <Card width="19%" cursor="pointer" background={active == "Sem plano" ? colors.lightBlue : false} onClick={() => onFilter("Sem plano")}>
          <GroupItems>
            <ContainerTitle>
              <Label color={active == "Sem plano" ? colors.white : false}>Free</Label>
              <Icon src={active == "Sem plano" ? Ellipse2 : Ellipse1} alt="Next" />
            </ContainerTitle>
            <Value color={active == "Sem plano" ? colors.white : false}>{aux.filter((item) => {
              return item.plan == "Sem plano"
            }
            ).length}</Value>
          </GroupItems>
        </Card>
        {jsonPlans.map((plan) => {
          return <Card width="19%" cursor="pointer" background={active == plan.nome ? colors.lightBlue : false} onClick={() => onFilter(plan.nome)}>
            <GroupItems>
              <ContainerTitle>
                <Label color={active == plan.nome ? colors.white : false}>{plan.nome}</Label>
                <Icon src={active == plan.nome ? Ellipse2 : Ellipse1} alt="Next" />
              </ContainerTitle>
              <Value color={active == plan.nome ? colors.white : false}>{aux.filter((item) => {
                return item.plan == plan.nome
              }
              ).length}</Value>
            </GroupItems>
          </Card>
        })}
      </ContainerCard>
      {isVisible ?
        <Card>
          <Table
            limit='10'
            headData={plans.head}
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={plans.body}
            renderBody={(item, index) => renderBody(item, index)}
          />
        </Card>
        :
        <Card>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={colors.gray3}
            ariaLabel="three-dots-loading"
            wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
            wrapperClassName=""
            visible={true}
          />
        </Card>
      }
    </Container>
  )
}

export default Plans;
