import React, { useState } from 'react';
import Modal from "react-modal";

//COMPONENTS
import Button from '../Button';

//STYLES
import colors from '../../styles/colors'
import { ModalContainer, ModalContent, Image, Title, SubLabel, Row, Icon, DescriptionData } from './styles';

Modal.setAppElement("#root");

function ItemDeleteModal({
  isOpen,
  onRequestClose,
  onDelete,
  width,
}) {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: `${width}`,
      minWidth: '450px',
      height: '300px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 9999
    },
  };


  return (
    <ModalContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Title>Deseja mesmo excluir esse item?</Title>
          {/* <SubLabel color={colors.darkBlue}>Melhore as análises do seu perfil e inicie o próximo teste</SubLabel> */}
          {/* <Row bottom="8%">
            <Icon width='30px' right='8px' src={Activity} alt="Next" />
            <DescriptionData color={colors.darkBlue}> Tempo estimado {minutes == 1 ? minutes + 'minuto' : minutes + 'minutos'}</DescriptionData>
          </Row> */}
          <Row top="0%"> 
             <Button
              type="button"
              width="150px"
              fontSize="13px"
              text="Cancelar"
              color={colors.lightBlue}
              bgColor={colors.transparent}
              onClick={onRequestClose}
            /> 
              <Button
                type="button"
                width="150px"
                fontSize="13px"
                text={"Excluir"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onDelete}
              />
            </Row>
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default ItemDeleteModal;


