import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import vector from './../../assets/img/Vector.svg';
import plus from './../../assets/img/Plus.svg';
import total from './../../assets/img/Total.svg';
import minus from './../../assets/img/Minus.svg';
import colors from '../../styles/colors';
import { useHistory } from 'react-router-dom';
import CardColor from './../../components/Card';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Graph from './../../assets/img/Graph.svg';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts';
import Button from './../../components/Button';
import { Select, TextField, MenuItem, FormControl } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import api from '../../api';
import StoreContext from '../../components/Store/Context';
import ErrorModal from './../../components/ErrorModal';

import {
  Container,
  Header,
  Logo,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  GpItems,
  GroupItems,
  GroupFilter,
  DescriptionData,
  Row,
  Label,
  LabelData,
  SubCard,
  GroupText,
  Group
} from './styles';



const CompanyDashboard = () => {
  const [details, setDetails] = useState(false);
  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [radar, setRadar] = useState([]);
  const [send, setSend] = useState(false);
  const [companieSelected, setCompanieSelected] = useState(0);
  const [areaSelected, setAreaSelected] = useState(0);
  const [potentialSelected, setPotentialSelected] = useState(0);
  const [leadershipSelected, setLeadershipSelected] = useState(0);
  const [timeSelected, setTimeSelected] = useState(0);
  const [msgError, setMsgError] = useState("");
  const [object, setObject] = useState([]);
  const [show, setShow] = useState("");
  const [user, setUser] = useState("");
  const [companies, setCompanies] = useState("");
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(async () => {
    getCompanies();
    setWidth(window.screen.width)
  }, [width]);

  const handleCompanie = (e) => {
    const newValue = e.target.value;
    setCompanieSelected(newValue);
    setMsgError("");
  };

  const handleArea = (e) => {
    const newValue = e.target.value;
    setAreaSelected(newValue);
    setMsgError("");
  };

  const handlePotential = (e) => {
    const newValue = e.target.value;
    setPotentialSelected(newValue);
    setMsgError("");
  };

  const handleLeadership = (e) => {
    const newValue = e.target.value;
    setLeadershipSelected(newValue);
    setMsgError("");
  };

  const handleTime = (e) => {
    const newValue = e.target.value;
    setTimeSelected(newValue);
    setMsgError("");
  };

  const handleUser = (e) => {
    const newValue = e.target.value;
    setUser(newValue);
    setMsgError("");
  };

  const getCompanies = async () => {
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setCompanies(data);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setCompanies(data);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onCloseError = () => {
    setError(false);
  }

  const getData = async () => {

    if (companieSelected > 0) {
      setShow(false);

      let data;


      if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected > 0 && areaSelected > 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
          potencial: potentialSelected,
          area_departamento: areaSelected,
          tempo_empresa: timeSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected > 0 && areaSelected > 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
          potencial: potentialSelected,
          area_departamento: areaSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected > 0 && areaSelected == 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
          potencial: potentialSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected > 0 && potentialSelected == 0 && areaSelected == 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
          lideranca: leadershipSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected == 0 && areaSelected == 0 && timeSelected == 0) {
        data = {
          company_id: companieSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected > 0 && areaSelected > 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          potencial: potentialSelected,
          area_departamento: areaSelected,
          tempo_empresa: timeSelected,
        };
      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected == 0 && areaSelected > 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          area_departamento: areaSelected,
          tempo_empresa: timeSelected,
        };

      } else if (companieSelected > 0 && leadershipSelected == 0 && potentialSelected == 0 && areaSelected == 0 && timeSelected > 0) {
        data = {
          company_id: companieSelected,
          tempo_empresa: timeSelected,
        };
      }

      await api.post('/reports/igrownx', data, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
        const data = response.data.data;
        await setRadar({
          series: [{
            name: 'Cultura',
            data: [data.cultura.dados.ACOLHIMENTO, data.cultura.dados.PROPOSITO, data.cultura.dados.APRENDIZADO, data.cultura.dados.PRAZER, data.cultura.dados.RESULTADOS, data.cultura.dados.AUTORIDADE, data.cultura.dados.SEGURANCA, data.cultura.dados.ORDEM],
          }],
          options: {
            colors: ['#95D45A'],
            chart: {
              height: 350,
              type: 'radar',
              fontFamily: 'Poppins',
            },
            title: {
              text: ''
            },
            xaxis: {
              color: [colors.darkBlue],
              categories: ['Acolhimento', 'Propósito', 'Aprendizado', 'Prazer', 'Resultados', 'Autoridade', 'Segurança', 'Ordem']
            }
          },
        });
        await setObject(data)
        await setShow(true);
      }).catch((error) => {
        console.log('erro', error);
        setError(true);
      });
    } else {
      setMsgError("É obrigatória a seleção da empresa!");
    }
  }

  let fieldRow0 = width <= 570 ? '80%' : 1045;
  let fieldRow1 = width <= 570 ? '80%' : 515;
  let fieldRow2A = width <= 570 ? '80%' : 339;
  let fieldRow2B = width <= 570 ? '80%' : 200;

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      {/* <HeaderUser width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <ContainerCard top="20%">
        <Card width="100%" heigth="650px">
          <GroupItems padding={width > 530 ? "2%" : "4%"}>
            <SubTitle>Olá! Bem-vindo(a) ao Painel iGrown-X!</SubTitle>
            <LabelData font="14px" weight="400">Essa área é voltada, exclusivamente, para as empresas que utilizam a plataforma iGrown. No <b>Painel iGrown-X</b>, é possível visualizar um cruzamento das informações de todos os colaboradores da empresa ou de candidatos de um processo seletivo. Ou seja: aqui, está <b>a visão consolidada do time da sua organização</b>.</LabelData>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="5%" width="800px">
        <SubTitle font="17px" align="center">Abaixo, de acordo com os filtros aplicados, está apresentada uma análise geral, baseada na resposta de todos os usuários.</SubTitle>
      </ContainerCard>

      <ContainerCard top="5%">
        <Card>
          <Label>Filtrar sua busca</Label>
          {/* <GroupText>
          <LabelData font="14px" weight="400">Para começar a visualizar os dados, há algumas opções de filtros que podem ser aplicados, como cargo, área ou departamento, tempo de empresa, potencial etc. </LabelData>
        </GroupText> */}
          <GroupFilter>
            {/* <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
              <TextField autoWidth size="small" color="info" disabled={false} placeholder="Procurar por nome de usuário"
              value={user}
              onChange(handleUser)
                InputProps={{
                  sx: {
                    'input': {
                      padding: 0.7,
                      '&::placeholder': {
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal'
                      }
                    },
                  },
                }}
              />
            </FormControl> */}
            <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow0 }}>
              <Select
                value={companieSelected}
                onChange={handleCompanie}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                <MenuItem value={0}>
                  <em>Procurar por empresa</em>
                </MenuItem>
                {companies.length > 0 ? companies.map((item) => {
                  return <MenuItem value={item.id}>{item.nome_fantasia}</MenuItem>
                }) : false
                }
              </Select>
            </FormControl>
          </GroupFilter>
          <GroupFilter>
            <FormControl sx={{ m: 1, width: fieldRow1 }}>
              <Select
                value={areaSelected}
                onChange={handleArea}
                autoWidth
                size="small"
                color="info"
                sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              >
                <MenuItem value={0}>
                  <em>Área ou departamento</em>
                </MenuItem>
                <MenuItem value={"Todos"}>Todos</MenuItem>
                <MenuItem value={"Diretoria"}>Diretoria</MenuItem>
                <MenuItem value={"Suprimentos"}>Suprimentos</MenuItem>
                <MenuItem value={"Administração de vendas"}>Administração de vendas</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: fieldRow1 }}>
              <Select
                value={leadershipSelected}
                onChange={handleLeadership}
                autoWidth
                size="small"
                color="info"
                sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              >
                <MenuItem value={0}>
                  <em>Cargo de liderança</em>
                </MenuItem>
                <MenuItem value={"Todos"}>Todos</MenuItem>
                <MenuItem value={"Sim"}>Sim</MenuItem>
                <MenuItem value={"Não"}>Não</MenuItem>
              </Select>
            </FormControl>
          </GroupFilter>
          <GroupFilter>
            {/* <FormControl sx={{ m: 1, width: fieldRow1 }}>
              <Select
                value={0}
                autoWidth
                size="small"
                color="info"
                sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              >
                <MenuItem value={0}>
                  <em>Diretoria</em>
                </MenuItem>
                <MenuItem value={1}>Todos</MenuItem>
                <MenuItem value={3}>Marketing</MenuItem>
              </Select>
              
            </FormControl> */}
            <FormControl sx={{ m: 1, width: fieldRow1 }}>
              <Select
                value={potentialSelected}
                onChange={handlePotential}
                autoWidth
                size="small"
                color="info"
                sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              >
                <MenuItem value={0}>
                  <em>Potencial</em>
                </MenuItem>
                <MenuItem value={"Todos"}>Todos</MenuItem>
                <MenuItem value={"Sim"}>Sim</MenuItem>
                <MenuItem value={"Não"}>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: fieldRow1 }}>
              <Select
                value={timeSelected}
                onChange={handleTime}
                autoWidth
                size="small"
                color="info"
                sx={{ fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              >
                <MenuItem value={0}>
                  <em>Tempo de empresa</em>
                </MenuItem>
                <MenuItem value={"7 meses a 1 ano"}>7 meses a 1 ano</MenuItem>
                <MenuItem value={"1 a 2 anos"}>1 a 2 anos</MenuItem>
                <MenuItem value={"2 a 4 anos"}>2 a 4 anos</MenuItem>
                <MenuItem value={"4 a 6 anos"}>4 a 6 anos</MenuItem>
                <MenuItem value={"mais de 6 anos"}>mais de 6 anos</MenuItem>
              </Select>
            </FormControl>
          </GroupFilter>
          <GroupFilter>
            <LabelData align="center" color={colors.alertRed} weight="500">{msgError}</LabelData>
          </GroupFilter>
          <GroupFilter align={width > 530 ? "right" : "center"}>
            <Button
              type="button"
              width="120px"
              hoverColor={colors.lightBlue}
              text="Aplicar"
              fontSize="12px"
              onClick={() => getData()}
            />
          </GroupFilter>
        </Card>
      </ContainerCard>


      {show ?
        <>
          <ContainerCard top="-5%">
            <Card width="100%" heigth="650px">
              <GroupItems padding="2%">
                <GroupItems padding="1%">
                  <SubTitle>Adesão do time</SubTitle>
                  <LabelData font="14px" weight="400">Por fim, é possível ver um consolidado entre o que os colaboradores valorizam e <b>o que eles acreditam que é valorizado pela empresa</b>. Por meio dessas informações, é possível analisar:</LabelData>
                </GroupItems>
                {width > 530 ?
                  <Row top="0%">
                    <Group width="25%">
                      <SubCard top="2%" width="100%" heigth="100px" background={colors.gray5} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.transparent}
                            title='Usuários cadastrados'
                            font="11px"
                            height="100%"
                            num={object.adesao.users || "-"}
                            fontNum="20px"
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="25%">
                      <SubCard top="2%" width="100%" heigth="100px" background={colors.white} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title='Teste de perfil comportamental'
                            value={object.adesao.comportamental || "-"}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="25%">
                      <SubCard top="2%" width="100%" heigth="100px" background={colors.white} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title='Teste de cultura'
                            topProgress="24px"
                            value={object.adesao.cultural || "-"}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="25%">
                      <SubCard top="2%" width="100%" heigth="100px" background={colors.white} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title='Teste de âncora de carreira'
                            value={object.adesao.valores || "-"}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                  </Row>
                  :
                  <>
                    <Group width="100%">
                      <SubCard top="2%" width="100%" heigth="auto" background={colors.gray5} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.transparent}
                            title='Usuários cadastrados'
                            font="11px"
                            height="100%"
                            num={object.adesao.users || "-"}
                            fontNum="20px"
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="100%">
                      <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title='Teste de perfil comportamental'
                            value={object.adesao.comportamental || "-"}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="100%">
                      <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title='Teste de cultura'
                            value={object.adesao.cultural || "-"}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="100%">
                      <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title='Teste de âncora de carreira'
                            value={object.adesao.valores || "-"}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                  </>
                }
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="-10%">
            {width > 530 ?
              <Row top="-10px">
                <Group width="40%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório comportamental</SubTitle>
                      <LabelData font="14px" weight="400">Abaixo, é possível ver quais são os perfis comportamentais dominantes quando fazemos uma média de todos os candidatos. Caso queira ver a descrição detalhada de algum perfil, basta clicar no ícone</LabelData>
                      <Icon float="none" src={vector} alt="Icon" />
                      <LabelData font="14px" weight="400">, ao lado dos nomes. E, para ver os números exatos, passe o mouse por cima de cada barra.</LabelData>
                      <br /><br />
                      <DescriptionData font="14px">Como você pode ver, é possível que o perfil predominante seja apenas um ou uma combinação de dois perfis distintos, como “Embaixador – Mantenedor”, por exemplo.</DescriptionData>
                      <br />
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR || object.valores.DESBRAVADOR >= 0 ? object.valores.DESBRAVADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador - Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR_EMBAIXADOR || object.valores.DESBRAVADOR_EMBAIXADOR >= 0 ? object.valores.DESBRAVADOR_EMBAIXADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador - Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR_MANTENEDOR || object.valores.DESBRAVADOR_MANTENEDOR >= 0 ? object.valores.DESBRAVADOR_MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador - Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR_INVESTIGADOR || object.valores.DESBRAVADOR_INVESTIGADOR >= 0 ? object.valores.DESBRAVADOR_INVESTIGADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.EMBAIXADOR || object.valores.EMBAIXADOR >= 0 ? object.valores.EMBAIXADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador - Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.EMBAIXADOR - MANTENEDOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador - Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.EMBAIXADOR_MANTENEDOR || object.valores.EMBAIXADOR_MANTENEDOR >= 0 ? object.valores.EMBAIXADOR_MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador - Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR_DESBRAVADOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR || object.valores.INVESTIGADOR >= 0 ? object.valores.INVESTIGADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador - Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR_DESBRAVADOR || object.valores.INVESTIGADOR_DESBRAVADOR >= 0 ? object.valores.INVESTIGADOR_DESBRAVADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador - Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.MANTENEDOR_DESBRAVADOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador - Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR_MANTENEDOR || object.valores.INVESTIGADOR_MANTENEDOR >= 0 ? object.valores.INVESTIGADOR_MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.MANTENEDOR || object.valores.MANTENEDOR >= 0 ? object.valores.MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor - Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.MANTENEDOR_DESBRAVADOR || object.valores.MANTENEDOR_DESBRAVADOR >= 0 ? object.valores.MANTENEDOR_DESBRAVADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor - Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.MANTENEDOR_EMBAIXADOR || object.valores.MANTENEDOR_EMBAIXADOR >= 0 ? object.valores.MANTENEDOR_EMBAIXADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor - Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}
                    </GroupItems>
                  </Card>
                </Group>
                <Group width="60%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório cultural</SubTitle>
                      <LabelData font="14px" weight="400">Na parte de cultura, está demonstrada a cultura predominante da empresa, baseada nos relatórios de todos os colaboradores. Para entender melhor sobre os diferentes estilos culturais, clique no botão.
                      </LabelData>
                      <Icon float="none" src={vector} alt="Icon" />
                      <Chart options={radar.options} series={radar.series} type="radar" height={600} />
                      <GroupItems padding="15px">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography sx={{ width: '6%', flexShrink: 0 }}>
                              <Icon width="40px" src={Graph} alt="Item" />
                            </Typography>
                            <Typography sx={{ color: colors.darkBlue }}>
                              <b>{object.cultura.cultura}</b>
                              <LabelData font="16px" weight="400" color={colors.lightBlue}> &nbsp;<b>Perfil predominante</b></LabelData>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GroupText>
                              <Typography>
                                {object.cultura.texto}
                              </Typography>
                            </GroupText>
                          </AccordionDetails>
                        </Accordion>
                      </GroupItems>
                    </GroupItems>
                  </Card>
                </Group>
              </Row>
              :
              <>
                <Group width="100%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório comportamental</SubTitle>
                      <LabelData font="14px" weight="400">Abaixo, é possível ver quais são os perfis comportamentais dominantes quando fazemos uma média de todos os candidatos. Caso queira ver a descrição detalhada de algum perfil, basta clicar no ícone</LabelData>
                      <Icon float="none" src={vector} alt="Icon" />
                      <LabelData font="14px" weight="400">, ao lado dos nomes. E, para ver os números exatos, passe o mouse por cima de cada barra.</LabelData>
                      <br /><br />
                      <DescriptionData font="14px">Como você pode ver, é possível que o perfil predominante seja apenas um ou uma combinação de dois perfis distintos, como “Embaixador – Mantenedor”, por exemplo.</DescriptionData>
                      <br />
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR || object.valores.DESBRAVADOR >= 0 ? object.valores.DESBRAVADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador - Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR_EMBAIXADOR || object.valores.DESBRAVADOR_EMBAIXADOR >= 0 ? object.valores.DESBRAVADOR_EMBAIXADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador - Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR_MANTENEDOR || object.valores.DESBRAVADOR_MANTENEDOR >= 0 ? object.valores.DESBRAVADOR_MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador - Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR_INVESTIGADOR || object.valores.DESBRAVADOR_INVESTIGADOR >= 0 ? object.valores.DESBRAVADOR_INVESTIGADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.EMBAIXADOR || object.valores.EMBAIXADOR >= 0 ? object.valores.EMBAIXADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador - Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador - Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.EMBAIXADOR_MANTENEDOR || object.valores.EMBAIXADOR_MANTENEDOR >= 0 ? object.valores.EMBAIXADOR_MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador - Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR || object.valores.INVESTIGADOR >= 0 ? object.valores.INVESTIGADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador - Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR_DESBRAVADOR || object.valores.INVESTIGADOR_DESBRAVADOR >= 0 ? object.valores.INVESTIGADOR_DESBRAVADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador - Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR_MANTENEDOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Investigador - Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.INVESTIGADOR_MANTENEDOR || object.valores.INVESTIGADOR_MANTENEDOR >= 0 ? object.valores.INVESTIGADOR_MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.MANTENEDOR || object.valores.MANTENEDOR >= 0 ? object.valores.MANTENEDOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor - Desbravador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.MANTENEDOR_DESBRAVADOR || object.valores.MANTENEDOR_DESBRAVADOR >= 0 ? object.valores.MANTENEDOR_DESBRAVADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor - Embaixador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.MANTENEDOR_EMBAIXADOR || object.valores.MANTENEDOR_EMBAIXADOR >= 0 ? object.valores.MANTENEDOR_EMBAIXADOR + "%" : "-"}</DescriptionData>
                        </GpItems>
                      </Row>
                      {/* <Row top="2%" align="start" bottom="2px">
                        <GpItems padding="14px" width="85%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.gray5}>
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Mantenedor - Investigador</LabelData>
                        </GpItems>
                        <GpItems padding="16px" width="15%" radius="0px 12px 12px 0px" background={colors.lightBlue} borderColor={colors.lightBlue}>
                          <DescriptionData font="14px" color={colors.white}>{object.valores.DESBRAVADOR+"%" || "-"}</DescriptionData>
                        </GpItems>
                      </Row> */}
                    </GroupItems>
                  </Card>
                </Group>
                <Group width="100%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório cultural</SubTitle>
                      <LabelData font="14px" weight="400">Na parte de cultura, está demonstrada a cultura predominante da empresa, baseada nos relatórios de todos os colaboradores. Para entender melhor sobre os diferentes estilos culturais, clique no botão.
                      </LabelData>
                      <Icon float="none" src={vector} alt="Icon" />
                      {isVisible ?
                        <Chart options={radar.options} series={radar.series} type="radar" height={800} />
                        :
                        false}
                      <GroupItems padding="15px">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography sx={{ width: '6%', flexShrink: 0 }}>
                              <Icon width="40px" src={Graph} alt="Item" />
                            </Typography>
                            <Typography sx={{ color: colors.darkBlue }}>
                              <b>Acolhimento</b>
                              <LabelData font="16px" weight="400" color={colors.lightBlue}> &nbsp;<b>Perfil predominante</b></LabelData>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GroupText>
                              <Typography>
                                A cultura de acolhimento está relacionada aos relacionamentos e à confiança mútua. Os ambientes são locais calorosos, onde as pessoas ajudam e apoiam umas às outras. As pessoas são unidas por lealdade e os líderes enfatizam a sinceridade, o trabalho em equipe e os relacionamentos positivos..
                              </Typography>
                            </GroupText>
                          </AccordionDetails>
                        </Accordion>
                      </GroupItems>
                    </GroupItems>
                  </Card>
                </Group>
              </>
            }
          </ContainerCard>

          <ContainerCard top="0%" bottom='1%'>
            <Card width="100%" heigth="650px">
              <GroupItems padding="2%">
                <GroupItems padding="1%">
                  <SubTitle>Âncora de carreira</SubTitle>
                  <LabelData font="14px" weight="400">Nessa parte, o gráfico demonstra como foi a participação dos colaboradores e/ou candidatos, deixando claro quantos colaboradores estão cadastrados na plataforma e, desses, <b>quantos realizaram os testes comportamental, de cultura e de valores</b>. Para ver os números exatos, passe o mouse por cima de cada barra.</LabelData>
                </GroupItems>
                {width > 530 ?
                  <>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems padding="14px" width="10%" background={colors.transparent} borderColor={colors.transparent}>
                        <Icon float="left" width="120px" src={plus} alt="Icon" />
                      </GpItems>
                      <GpItems padding="16px" width="90%" radius="0px 12px 12px 0px" background={colors.transparent} borderColor={colors.transparent}>
                        <DescriptionData font="14px" top="0px">O que demanda investimento</DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">Quais critérios são importantes para o colaborador e que não vêm sendo atendidos pela empresa? No gráfico, isso é representado quando, em um critério específico, a linha azul está mais alta do que a cinza.</DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems padding="14px" width="10%" background={colors.transparent} borderColor={colors.transparent}>
                        <Icon float="left" width="120px" src={total} alt="Icon" />
                      </GpItems>
                      <GpItems padding="16px" width="90%" radius="0px 12px 12px 0px" background={colors.transparent} borderColor={colors.transparent}>
                        <DescriptionData font="14px" top="0px">O que já está bom</DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">Quais critérios são importantes para o colaborador e a empresa está atendendo essas expectativas? No gráfico, isso é representado quando, em um critério específico, as linhas azul e o cinza estão na mesma altura.</DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems padding="14px" width="10%" background={colors.transparent} borderColor={colors.transparent}>
                        <Icon float="left" width="120px" src={minus} alt="Icon" />
                      </GpItems>
                      <GpItems padding="16px" width="90%" radius="0px 12px 12px 0px" background={colors.transparent} borderColor={colors.transparent}>
                        <DescriptionData font="14px" top="0px">O que não demanda tanto investimento</DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">Quais critérios não são importantes para o colaborador e a empresa já está superando as expectativas. No gráfico, isso é representado quando, em um critério específico, a linha azul está mais baixa do que a cinza.</DescriptionData>
                      </GpItems>
                    </Row>
                  </>
                  :
                  <>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems padding="14px" width="35%" background={colors.transparent} borderColor={colors.transparent}>
                        <Icon float="left" width="120px" src={plus} alt="Icon" />
                      </GpItems>
                      <GpItems padding="16px" width="65%" radius="0px 12px 12px 0px" background={colors.transparent} borderColor={colors.transparent}>
                        <DescriptionData font="14px" top="0px">O que demanda investimento</DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">Quais critérios são importantes para o colaborador e que não vêm sendo atendidos pela empresa? No gráfico, isso é representado quando, em um critério específico, a linha azul está mais alta do que a cinza.</DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems padding="14px" width="35%" background={colors.transparent} borderColor={colors.transparent}>
                        <Icon float="left" width="120px" src={total} alt="Icon" />
                      </GpItems>
                      <GpItems padding="16px" width="65%" radius="0px 12px 12px 0px" background={colors.transparent} borderColor={colors.transparent}>
                        <DescriptionData font="14px" top="0px">O que já está bom</DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">Quais critérios são importantes para o colaborador e a empresa está atendendo essas expectativas? No gráfico, isso é representado quando, em um critério específico, as linhas azul e o cinza estão na mesma altura.</DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems padding="14px" width="35%" background={colors.transparent} borderColor={colors.transparent}>
                        <Icon float="left" width="120px" src={minus} alt="Icon" />
                      </GpItems>
                      <GpItems padding="16px" width="65%" radius="0px 12px 12px 0px" background={colors.transparent} borderColor={colors.transparent}>
                        <DescriptionData font="14px" top="0px">O que não demanda tanto investimento</DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">Quais critérios não são importantes para o colaborador e a empresa já está superando as expectativas. No gráfico, isso é representado quando, em um critério específico, a linha azul está mais baixa do que a cinza.</DescriptionData>
                      </GpItems>
                    </Row>
                  </>
                }
                <GroupItems padding="2%">
                  {width > 530 ?
                    <Row top="0%" items="start">
                      <Group width="35%">
                        <Grid xs="3">
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue} border={colors.darkBlue}>
                            <GroupItems padding="1%">
                              <CardColor
                                right
                                left
                                iconWhite
                                width="100%"
                                bgColor={colors.darkBlue}
                                title='Estrutura e benefícios'
                                value=''
                                font="11px"
                                height="100%"
                                fontColor={colors.white}
                                valueColor={colors.white}
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Pacote de Benefícios'
                                value={object.valores_carreira.PACOTE_DE_BENEFICIOS_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Plano de carreira claro e estruturado'
                                value={object.valores_carreira.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Possibilidade de crescimento e promoção'
                                value={object.valores_carreira.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Processos simples e desburocratizados'
                                value={object.valores_carreira.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Reconhecimento e valorização'
                                value={object.valores_carreira.RECONHECIMENTO_E_VALORIZACAO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Salário'
                                value={object.valores_carreira.SALARIO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.SALARIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.SALARIO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.SALARIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.SALARIO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Visão de futuro da empresa ou negócio'
                                value={object.valores_carreira.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <Grid xs="3">
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue} border={colors.darkBlue}>
                            <GroupItems padding="1%">
                              <CardColor
                                right
                                left
                                iconWhite
                                width="100%"
                                bgColor={colors.darkBlue}
                                title='Cultura e valores'
                                value=''
                                font="11px"
                                height="100%"
                                fontColor={colors.white}
                                valueColor={colors.white}
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Cultura e valores da empresa'
                                value={object.valores_carreira.CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Desafio constantes'
                                value={object.valores_carreira.DESAFIOS_CONSTANTES_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Gestão clara e acompanhamento dos objetivos'
                                value={object.valores_carreira.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Horário de trabalho'
                                value={object.valores_carreira.HORARIO_DE_TRABALHO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Local de trabalho'
                                value={object.valores_carreira.LOCAL_DE_TRABALHO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Oportunidades de desenvolvimento e aprendizado'
                                value={object.valores_carreira.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Tratamento humanizado'
                                value={object.valores_carreira.TRATAMENTO_HUMANIZADO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Tratamento justo e igualitário com todos'
                                value={object.valores_carreira.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <Grid xs="3">
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue} border={colors.darkBlue}>
                            <GroupItems padding="1%">
                              <CardColor
                                right
                                left
                                iconWhite
                                width="100%"
                                bgColor={colors.darkBlue}
                                title='Clima e confiança'
                                value=''
                                font="11px"
                                height="100%"
                                fontColor={colors.white}
                                valueColor={colors.white}
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Abertura para mudanças e inovação'
                                value={object.valores_carreira.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Autonomia e abertura para se expressar'
                                value={object.valores_carreira.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Celebração de conquistas'
                                value={object.valores_carreira.CELEBRACAO_DE_CONQUISTAS_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Clima interno'
                                value={object.valores_carreira.CLIMA_INTERNO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.CLIMA_INTERNO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CLIMA_INTERNO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.CLIMA_INTERNO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CLIMA_INTERNO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Confiança na liderança imediata'
                                value={object.valores_carreira.CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title='Confiança no time de trabalho'
                                value={object.valores_carreira.CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA}
                                colorProgress={object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                                valueColor={object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                        </Grid>
                      </Group>
                    </Row>
                    :
                    <>
                      <Grid xs="3">
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue} border={colors.darkBlue}>
                          <GroupItems padding="1%">
                            <CardColor
                              right
                              left
                              iconWhite
                              width="100%"
                              bgColor={colors.darkBlue}
                              title='Estrutura e benefícios'
                              value=''
                              font="11px"
                              height="100%"
                              fontColor={colors.white}
                              valueColor={colors.white}
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Pacote de Benefícios'
                              value={object.valores_carreira.PACOTE_DE_BENEFICIOS_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PACOTE_DE_BENEFICIOS == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Plano de carreira claro e estruturado'
                              value={object.valores_carreira.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Possibilidade de crescimento e promoção'
                              value={object.valores_carreira.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Processos simples e desburocratizados'
                              value={object.valores_carreira.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Reconhecimento e valorização'
                              value={object.valores_carreira.RECONHECIMENTO_E_VALORIZACAO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.RECONHECIMENTO_E_VALORIZACAO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Salário'
                              value={object.valores_carreira.SALARIO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.SALARIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.SALARIO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.SALARIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.SALARIO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Visão de futuro da empresa ou negócio'
                              value={object.valores_carreira.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                      </Grid>

                      <Grid xs="3">
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue} border={colors.darkBlue}>
                          <GroupItems padding="1%">
                            <CardColor
                              right
                              left
                              iconWhite
                              width="100%"
                              bgColor={colors.darkBlue}
                              title='Cultura e valores'
                              value=''
                              font="11px"
                              height="100%"
                              fontColor={colors.white}
                              valueColor={colors.white}
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Cultura e valores da empresa'
                              value={object.valores_carreira.CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CULTURA_E_VALORES_DA_EMPRESA == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Desafio constantes'
                              value={object.valores_carreira.DESAFIOS_CONSTANTES_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.DESAFIOS_CONSTANTES == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Gestão clara e acompanhamento dos objetivos'
                              value={object.valores_carreira.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Horário de trabalho'
                              value={object.valores_carreira.HORARIO_DE_TRABALHO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.HORARIO_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Local de trabalho'
                              value={object.valores_carreira.LOCAL_DE_TRABALHO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.LOCAL_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Oportunidades de desenvolvimento e aprendizado'
                              value={object.valores_carreira.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Tratamento humanizado'
                              value={object.valores_carreira.TRATAMENTO_HUMANIZADO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_HUMANIZADO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Tratamento justo e igualitário com todos'
                              value={object.valores_carreira.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                      </Grid>

                      <Grid xs="3">
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue} border={colors.darkBlue}>
                          <GroupItems padding="1%">
                            <CardColor
                              right
                              left
                              iconWhite
                              width="100%"
                              bgColor={colors.darkBlue}
                              title='Clima e confiança'
                              value=''
                              font="11px"
                              height="100%"
                              fontColor={colors.white}
                              valueColor={colors.white}
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Abertura para mudanças e inovação'
                              value={object.valores_carreira.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.ABERTURA_PARA_MUDANCAS_E_INOVACAO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Autonomia e abertura para se expressar'
                              value={object.valores_carreira.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Celebração de conquistas'
                              value={object.valores_carreira.CELEBRACAO_DE_CONQUISTAS_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CELEBRACAO_DE_CONQUISTAS == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Clima interno'
                              value={object.valores_carreira.CLIMA_INTERNO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.CLIMA_INTERNO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CLIMA_INTERNO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.CLIMA_INTERNO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CLIMA_INTERNO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Confiança na liderança imediata'
                              value={object.valores_carreira.CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NA_LIDERANCA_IMEDIATA == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title='Confiança no time de trabalho'
                              value={object.valores_carreira.CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA}
                              colorProgress={object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                              valueColor={object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "verde" ? colors.lightGreen : object.valores_carreira_semaforo.CONFIANCA_NO_TIME_DE_TRABALHO == "amarelo" ? colors.yellow : colors.alertRed}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                      </Grid>
                    </>
                  }
                </GroupItems>
              </GroupItems>
            </Card>
          </ContainerCard>
        </>
        :
        <></>
      }
    </Container >

  )
}

export default CompanyDashboard;
