import styled from 'styled-components';

import colors from '../../styles/colors';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;

  @media(max-width: 900px) {
    width: 50% !important;
  }

  @media(max-width: 1200px) {
    width: 50% !important;
  }
`;

export const ModalContent = styled.div`
  padding: 8px;
  overflow: hidden;
`;

export const SubLabel = styled.label`
  position: relative;
  text-align: start !important;
  height: 52px;
  font-size: 13px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 12px;
  }

  @media (max-width: 570px) {  
    font-size: 11px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${(props) => props.color || colors.white};
`;

export const Image = styled.img`
  position: relative;
  width: 80px;
  text-align: center;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 80px;
  }

  @media (max-width: 570px) {  
    width: 60px;
  }
`;

export const Title = styled.h2`
  position: relative;
  font-size: 18px;
  padding-top: 0.5%;
  padding-bottom: 5px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 870px) {
    font-size: 16px;
  }

  @media (max-width: 570px) {  
    font-size: 14px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.darkBlue};
`;

export const Row = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  padding-top: ${(props) => props.top || "8%"};
  padding-bottom: ${(props) => props.bottom || "0%"};
  justify-content: center;
`;

export const DescriptionData = styled.p`
  font-size: 13px;
  padding-top: 2%;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${(props) => props.weight || "700"};;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    padding-top: 2%;
  }

  @media (max-width: 570px) {  
    padding-top: 2%;
  }

  text-align: start;
  letter-spacing: 0.116667px;

  color: ${(props) => props.color || colors.white};
`;

export const Group = styled.div`
    height: 300px;
    overflow: auto;
`;

export const Icon = styled.img`
  position: relative;
  text-align: center;
  padding-right : ${(props) => props.right || "0px"};
  width: ${(props) => props.width || "40px"};
  @media (max-width: 870px) {
    width: ${(props) => props.width || "40px"};
  }

  @media (max-width: 570px) {  
    width: ${(props) => props.width || "40px"};
  }
`;