import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from '../../components/Table';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { Select, MenuItem, FormControl } from '@mui/material';
import * as Fi from "react-icons/fi";
import * as Bi from "react-icons/bi";
import Tooltip from '@material-ui/core/Tooltip';
import NewJobModal from '../../components/NewJobModal';
import CopyModal from '../../components/CopyModal';
import ItemDeleteModal from '../../components/ItemDeleteModal';
import { useHistory, Link } from 'react-router-dom';
import StoreContext from '../../components/Store/Context';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Details from './../../assets/data/details.json'
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import ErrorModal from './../../components/ErrorModal';
import { phoneMask } from '../../utils/phoneMask';
import ListIcon from '@mui/icons-material/List';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThreeDots } from 'react-loader-spinner'
import { styled } from '@mui/material/styles';
import api from '../../api';
import AlertModal from './../../components/AlertModal';
import VisualizationCandidate from './../../components/Visualizations/Candidate';
import VisualizationPersona from './../../components/Visualizations/Persona';
import dataCandidate from './../../assets/data/dataCandidate.json';

//DATA
import Users from '../../assets/data/career.json'

import {
  ButtonLink,
  Container,
  Header,
  Label,
  Logo,
  Card,
  GroupText,
  LabelData,
  Group,
  GroupFilter,
  Row,
  ErrorMessage,
  Title
} from './styles';


const JobPanel = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [link, setLink] = useState(false);
  const [del, setDel] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [idSelected, setIdSelected] = useState("");
  const [msg, setMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [details, setDetails] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [companieSelected, setCompanieSelected] = useState(0);
  const [value, setValue] = useState('1');
  const [status, setStatus] = useState(0);
  const [checkbox, setCheckbox] = useState(false);
  const [error, setError] = useState(false);
  const [modalCandidate, setModalCandidate] = useState(false);
  const [modalPersona, setModalPersona] = useState(false);
  const [data, setData] = useState([]);
  const [arrayholder, setArrayholder] = useState([]);
  const [dataFit, setDataFit] = useState(dataCandidate);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [selected, setSelected] = useState([]);
  const [emails, setEmails] = useState([]);
  const [reports, setReports] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [arrayholderCollaborators, setArrayholderCollaborators] = useState([]);
  const [companies, setCompanies] = useState([]);
  const history = useHistory();

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  // Inspired by blueprintjs
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }

  const getCandidate = async (id, value) => {
    let emails = [];
    await api.get('/users/vaga/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      await data.map((item) => {
        emails.push(item.users[0].email);
      });
      await getReports(emails, data.vaga_id);
      await setCandidate(data);
      await setDetails(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }



  const handleCompanie = (e) => {
    const newValue = e.target.value;
    setAlertError(false);
    setCompanieSelected(newValue);
  };

  const handleStatus = (e) => {
    const newValue = e.target.value;
    setAlertError(false);
    setStatus(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setCompanies(data);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setCompanies(data);
  }


  const copyLink = (id) => {
    navigator.clipboard.writeText(process.env.REACT_APP_URL + '/vaga?id=' + id);
    setLink(true);
  }

  const getData = async () => {
    await api.get('/vagas', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      setData(data);
      setArrayholder(data);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const getCollaborators = async (id) => {
    await api.get('/companies/users/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      setCollaborators(data);
      setArrayholderCollaborators(data);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  useEffect(async () => {
    getData();
    getCompanies();
    setWidth(window.screen.width)
  }, [width, idSelected, selected]);

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.career}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.status}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.vacancy}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.persona}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.company}</th>
      <th key={index}>{item.actions}</th>
    </>
  )

  const renderBody = (item, index) => {
    let json = companies.filter((i) => {
      return item.company_id == i.id
    })
    return <tr key={index}>
      <td>{item.nome}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.status == 0 ? "Inativa" : "Ativa"}</td>
      <td class={width <= 570 ? 'hidden' : ''}>
        <Link
          target="_blank"
          to={{
            pathname: "/vaga?id=" + item.id,
            search: "",
            hash: "",
            state: {
              id: item.id,
            }
          }}
        >
          Link
        </Link></td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.persona_ideal_id ? 'Sim' : 'Não'}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{json.length > 0 ? json[0].nome_fantasia : "--"}</td>
      <td width={"120px"}>
        <Group>
          {/* <Tooltip title="Persona">
            <a onClick={() => onPersona()}><Fi.FiUsers size={'1rem'} /></a>
          </Tooltip>
          <Tooltip title="Calibrar Hard Skills da vaga">
            <a href="/calibrarHardsKill"><Fi.FiLink size={'1rem'} /></a>
          </Tooltip> */}
          <Tooltip title="Copiar link">
            <a onClick={() => copyLink(item.id)}><Fi.FiCopy size={'1rem'} /></a>
          </Tooltip>
          <Tooltip title="Ver candidatos da vaga">
            <a onClick={() => onChangeDetails(true, item)}><Bi.BiSearch size={'1rem'} /></a>
          </Tooltip>
          <Tooltip title="Editar vaga">
            <a onClick={() => openEdit(item)}><Bi.BiEditAlt size={'1rem'} /></a>
          </Tooltip>
          <Tooltip title="Excluir vaga">
            <a onClick={() => openDel(item.id)}><Bi.BiTrash size={'1rem'} /></a>
          </Tooltip>
        </Group>
      </td>
    </tr>

  }

  const openEdit = async (item) => {
    await setSelected(item);
    await setIsOpen(true);
  }

  const onChangeDetails = async (value, item) => {
    await getCandidate(item.id, value)
    await setSelected(item);
  }

  const renderHeadCollaborators = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th key={index}>{item.actions}</th>
    </>
  )

  const renderBodyCollaborators = (item, index) => {
    return <tr key={index}>
      <td>{item.name}</td>
      <td>
        <Group align="start">
          <Tooltip title="Desvincular colaborador da empresa">
            <a onClick={() => unbindCompanie(item)}><Bi.BiTrash size={'1rem'} /></a>
          </Tooltip>
        </Group>
      </td>
    </tr>
  }


  const getReports = async (emails, id) => {

    const body = {
      "emails": emails,
      "vaga_id": id
    }

    await api.post('/persona/fit', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      await setReports(data);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const renderHeadCandidate = (item, index) => {

    return <>
      {/* <th></th> */}
      {/* <th>{item.ranking}</th> */}
      <th>{item.candidate}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.email}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.status}</th>
      {/* <th class={width <= 570 ? 'hidden' : ''}>{item.fit}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.behavioral}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.learningAgilities}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.cultural}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.hardSkills}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.potential}</th>
      <th class={width <= 570 ? 'hidden' : ''}>{item.phone}</th> */}
      <th key={index}>{"Ações"}</th>
    </>
  }

  const renderBodyCandidate = (item, index) => {
    return <tr key={index}>
      {/* <td>
        <FormGroup>
          <FormControlLabel control={
            <BpCheckbox
              checked={checkbox}
              inputProps={{ 'aria-label': 'controlled' }}
            />} />
        </FormGroup>
      </td> */}
      {/* <td>{item.ranking}</td> */}
      <td>{item.users[0].name}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.users[0].email}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.users[0].status == 'S' ? "Ativo" : "Inativo"}</td>
      {/* <td class={width <= 570 ? 'hidden' : ''}>{item.fit + "%"}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.behavioral + "%"}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.learningAgilities + "%"}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.cultural + "%"}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.hardSkills + "%"}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.potential}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{phoneMask(item.phone)}</td> */}
      <td>
        <Group>
          <Tooltip title="Detalhes do candidato">
            <a onClick={() => openDetailsCandidate(item)}><Bi.BiDetail size={'1rem'} /></a>
          </Tooltip>
          <Tooltip title="Fit Persona Ideal">
            <a onClick={() => openDetailsPersona(item)}><Bi.BiGridAlt size={'1rem'} /></a>
          </Tooltip>
          <Tooltip title="Desvincular usuário da vaga">
            <a onClick={() => unbind(item)}><Bi.BiTrash size={'1rem'} /></a>
          </Tooltip>
          {/* <Tooltip title="Exportar">
            <a ><Bi.BiExport size={'1rem'} /></a>
          </Tooltip> */}
        </Group>
      </td>
    </tr>

  }

  const openDetailsCandidate = async (item) => {
    const body = {
      "emails": [
        item.users[0].email
      ],
      "vaga_id": selected.id
    }



    await api.post('/persona/fit', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      setModalCandidate(true);
      setDataFit(data);
      setSelectedCandidate(item);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const openDetailsPersona = async (item) => {
    const body = {
      "emails": [
        item.users[0].email
      ],
      "vaga_id": selected.id
    }



    await api.post('/persona/fit', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      setModalPersona(true);
      setDataFit(data);
      setSelectedCandidate(item);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }


  const unbind = async (item) => {
    const json = {
      "user_id": item.id,
      "vaga_id": selected.id
    }
    await api.delete('/user-vagas', json, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      await setMsg("Desvinculo concluído com sucesso!")
      await setAlert(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const unbindCompanie = async (item) => {
    const json = {
      "user_id": item.id,
      "company_id": companieSelected
    }
    await api.delete('/users/empresa', json, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      await setMsg("Desvinculo concluído com sucesso!")
      await setAlert(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }


  const getNewData = async () => {
    setIsVisible(false);
    if (companieSelected > 0 && status > 0) {
      const newData = await arrayholder.filter((item) => {
        return item.company_id == companieSelected && item.status + 1 == status
      });
      setData(newData);
      getCollaborators(companieSelected);
      setIsVisible(true);
      setDisplay(true);
    } else if (companieSelected > 0 && status == 0) {
      const newData = await arrayholder.filter((item) => {
        return item.company_id == companieSelected
      });
      setData(newData);
      getCollaborators(companieSelected);
      setIsVisible(true);
      setDisplay(true);
    } else {
      setAlertError(true)
    }

  }

  const users =
  {
    head: [
      { career: 'Vagas', status: 'Status', vacancy: 'Link', persona: 'Persona', company: 'Empresa', actions: 'Ações' }
    ],
    body: data
  }

  const jsonCollaborators =
  {
    head: [
      { name: 'Nome', actions: 'Ações' }
    ],
    body: collaborators || []
  }

  const jsonCandidates =
  {
    head: [
      { candidate: "Candidato", email: "E-mail", status: "Status" }
    ],
    body: candidate || []
    // body: Details || []
  }


  const openDel = async (id) => {
    await setIdSelected(id);
    await setDel(true);
  }

  const deleteVacancy = async (id) => {
    await api.delete('/vagas/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      await setMsg("Vaga excluída com sucesso!")
      await setDel(false);
      await setAlert(true);
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const onCloseError = () => {
    setError(false);
  }

  const onCloseCandidate = () => {
    setModalCandidate(false);
  }

  const onClosePersona = () => {
    setModalPersona(false);
  }

  const onCloseAlert = () => {
    setAlert(false);
  }

  let fieldRow1 = width <= 570 ? '80%' : 700;
  let fieldRow2 = width <= 570 ? '80%' : 330;

  return (
    <Container>
      <ResponsiveAppBar />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <VisualizationCandidate vacancy={selected} user={selectedCandidate} data={dataFit} isOpen={modalCandidate} onClose={onCloseCandidate} />
      <VisualizationPersona vacancy={selected} user={selectedCandidate} data={dataFit} isOpen={modalPersona} onClose={onClosePersona} />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <NewJobModal selected={selected} getData={() => getData()} isOpen={isOpen} width={width} onRequestClose={() => setIsOpen(false)} />
      <CopyModal isOpen={link} width={width} onRequestClose={() => setLink(false)} />
      <ItemDeleteModal isOpen={del} width={width} onRequestClose={() => setDel(false)} onDelete={() => deleteVacancy(idSelected)} />
      <Title>Painel de Vagas</Title>
      <Card>
        <Label>Filtrar sua busca</Label>
        <Group>
          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
            <Select
              value={companieSelected}
              onChange={handleCompanie}
              autoWidth
              size="small"
              color="info"
              sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              InputProps={{
                sx: {
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                },
              }}
            >
              <MenuItem value={0}>
                <em>Procurar por empresa</em>
              </MenuItem>
              {companies.length > 0 ? companies.map((item) => {
                return <MenuItem value={item.id}>{item.nome_fantasia}</MenuItem>
              }) : false
              }
            </Select>
          </FormControl>
          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow2 }}>
            <Select
              value={status}
              onChange={handleStatus}
              autoWidth
              size="small"
              color="info"
              sx={{ backgroundColor: colors.gray6, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
              InputProps={{
                sx: {
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                },
              }}
            >
              <MenuItem value={0}>
                <em>Status</em>
              </MenuItem>
              <MenuItem value={1}>Inativo</MenuItem>
              <MenuItem value={2}>Ativo</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <GroupFilter align={width > 530 ? "right" : "center"}>
            <Button
              type="button"
              width="120px"
              color={colors.lightBlue}
              bgColor={colors.transparent}
              text="Nova vaga"
              fontSize="12px"
              onClick={() => setIsOpen(true)}
            />
            <Button
              type="button"
              width="120px"
              hoverColor={colors.lightBlue}
              text="Aplicar"
              fontSize="12px"
              onClick={() => getNewData()}
            />
          </GroupFilter>

          <ErrorMessage color={colors.alertRed}>{alertError ? "É obrigatória a seleção da empresa" : ""}</ErrorMessage>
        </Group>
      </Card>
      {display ?
        <Card>
          {details ?
            <>
              <Row>
                <Fi.FiChevronLeft color={colors.lightBlue} />
                <ButtonLink onClick={() => setDetails(false)} font="12px" color={colors.lightBlue}>Voltar</ButtonLink>
              </Row>
              <Table
                limit='10'
                headData={jsonCandidates.head}
                renderHead={(item, index) => renderHeadCandidate(item, index)}
                bodyData={jsonCandidates.body}
                renderBody={(item, index) => renderBodyCandidate(item, index)}
              />

            </>
            :
            <TabContext value={value}>
              <TabList onChange={handleChange} >
                <Tab label={<Label>Vagas</Label>} value="1" />
                <Tab label={<Label>Colaboradores</Label>} value="2" />
              </TabList>
              <TabPanel value="1">
                {isVisible ?
                  <Table
                    limit='10'
                    headData={users.head}
                    renderHead={(item, index) => renderHead(item, index)}
                    bodyData={users.body}
                    renderBody={(item, index) => renderBody(item, index)}
                  />
                  :
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color={colors.gray3}
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
                    wrapperClassName=""
                    visible={true}
                  />}
              </TabPanel>
              <TabPanel value="2">
                {isVisible ?
                  <Table
                    limit='10'
                    headData={jsonCollaborators.head}
                    renderHead={(item, index) => renderHeadCollaborators(item, index)}
                    bodyData={jsonCollaborators.body}
                    renderBody={(item, index) => renderBodyCollaborators(item, index)}
                  />
                  :
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color={colors.gray3}
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
                    wrapperClassName=""
                    visible={true}
                  />}
              </TabPanel>
            </TabContext>
          }
        </Card>
        :
        <></>
      }
    </Container>
  )
}

export default JobPanel;
