import React, { useContext, useState } from 'react';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from './../../ErrorModal';
import { styled } from '@mui/material/styles';
import './../../../styles/SidebarConfig.css';
import moment from 'moment';

const MenuFormation = (prop) => {

    const data = prop.active;
    const { token, setToken } = useContext(StoreContext);
    const [name, setName] = useState(data.length > 0 ? data[0].instituicao : "");
    const [course, setCourse] = useState(data.length > 0 ? data[0].nome_formacao : "");
    const [type, setType] = useState("");
    const [startDate, setStartDate] = useState(data.length > 0 ? data[0].periodo_de : "");
    const [finalDate, setFinalDate] = useState(data.length > 0 ? data[0].periodo_ate : "");
    const [description, setDescription] = useState("");
    const [checkbox, setCheckbox] = useState(false);
    const [disabledCheckbox, setdisabledCheckbox] = useState(false);
    const [disabledDate, setdisabledDate] = useState(false);
    const [error, setError] = useState(false);


    let icon = prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    // Inspired by blueprintjs
    function BpCheckbox(props) {
        return (
            <Checkbox
                sx={{
                    '&:hover': { bgcolor: 'transparent' },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                {...props}
            />
        );
    }


    const handleName = (e) => {
        const value = e.target.value;
        setName(value);
    }

    const handleCourse = (e) => {
        const value = e.target.value;
        setCourse(value);
    }

    const handleType = (e) => {
        const value = e.target.value;
        setType(value);
    }

    const handleStartDate = (e) => {
        const value = e.target.value;
        setStartDate(value);
    }

    const handleFinalDate = (e) => {
        const value = e.target.value;
        setFinalDate(value);
        if (value) {
            setdisabledCheckbox(true);
        } else {
            setdisabledCheckbox(false);
        }
    }

    const handleDescription = (e) => {
        const value = e.target.value;
        setDescription(value);
    }

    const putCollege = async () => {

        const last = data.length > 0 ?
            {
                // "candidato_id":  prop.user[0].dados_adicionais.id,
                // "id": prop.user[0].dados_adicionais.id,
                "instituicao": name,
                "nome_formacao": course,
                // "descricao": description,
                "periodo_de": startDate,
                "periodo_ate": moment(Date.now()).format("YYYY-MM-DD"),
            }
            :
            checkbox ?
                {
                    "id": prop.user[0].dados_adicionais.id,
                    "instituicao": name,
                    "nome_formacao": course,
                    // "descricao": description,
                    "periodo_de": startDate,
                    "periodo_ate": moment(Date.now()).format("YYYY-MM-DD"),
                }
                :
                {
                    "id": prop.user[0].dados_adicionais.id,
                    "instituicao": name,
                    "nome_formacao": course,
                    // "descricao": description,
                    "periodo_de": startDate,
                    "periodo_ate": finalDate,
                }

        const json = {
            "user_id": prop.user[0].id,
            "formacoes": [
                last
            ]
        }

        data.length > 0 ?
            await api.put('/dados-candidatos/update', json, {
                headers: {
                    'authorization': 'Bearer ' + token.refreshToken
                }
            }).then(async (response) => {

                prop.onClose();
            }).catch((error) => {
                console.log('erro', error);
                setError(true);
            })
            :
            await api.post('/dados-candidatos/save', json, {
                headers: {
                    'authorization': 'Bearer ' + token.refreshToken
                }
            }).then(async (response) => {

                prop.onClose();
            }).catch((error) => {
                console.log('erro', error);
                setError(true);
            });
    }

    const onCloseError = (value) => {
        setError(false);
    }

    const changeDisabledDate = (value) => {
        if (value) {
            setdisabledDate(true);
        } else {
            setdisabledDate(false);
        }
        setCheckbox(value);
    }





    return (
        <Slide right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <ErrorModal isOpen={error} onRequestClose={onCloseError} />
            <GroupLine>
                <Title>Formação acadêmica</Title>
            </GroupLine>
            <Container>
                <Group>
                    <TextField label="Nome da escola" InputLabelProps={{ shrink: true, required: true }} size="small" value={name} onChange={handleName} color="info" disabled={false} />
                </Group>
                {/* <Group>
                    <FormControl sx={{ m: 0, width: 220 }}>
                        <InputLabel id="typeOfCourse">Tipo de Curso</InputLabel>
                        <Select
                            labelId="typeOfCourse"
                            id="typeOfCourse"
                            InputLabelProps={{ shrink: true, required: true }}
                            value={type}
                            size="small"
                            onChange={handleType}
                            color="info"
                            disabled={false}
                            input={<OutlinedInput label="Tipo de Curso" />}
                        >
                            <MenuItem value={1}>Licenciatura</MenuItem>
                            <MenuItem value={2}>Bacharel</MenuItem>
                            <MenuItem value={3}>Tecnólogo</MenuItem>
                        </Select>
                    </FormControl>
                </Group> */}
                <Group>
                    <TextField label="Nome do curso" onChange={handleCourse} InputLabelProps={{ shrink: true, required: true }} size="small" value={course} color="info" disabled={false} />
                </Group>
                <Group>
                    <TextField label="Data inicial" type="date" onChange={handleStartDate} InputLabelProps={{ shrink: true, required: true }} size="small" value={startDate} color="info" disabled={false} />
                </Group>
                <Group>
                    <TextField label="Data Final" type="date" onChange={handleFinalDate} InputLabelProps={{ shrink: true, required: false }} size="small" value={finalDate} color="info" disabled={disabledDate} />
                </Group>
                <Group>
                    <FormGroup>
                        <FormControlLabel control={
                            <BpCheckbox
                                checked={checkbox}
                                disabled={disabledCheckbox}
                                onChange={() => changeDisabledDate(!checkbox)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Atualmente" />
                    </FormGroup>
                </Group>
                {/* <Group>
                    <TextField label="Descrição do curso"
                        InputLabelProps={{ shrink: true, required: false }}
                        size="small"
                        multiline
                        rows={10}
                        maxRows={15}
                        onChange={handleDescription}
                        value={description}
                        color="info"
                        disabled={false} />
                </Group> */}
                <Button
                    type="button"
                    width="230px"
                    hoverColor={colors.lightBlue}
                    text="Salvar alterações"
                    fontSize="12px"
                    onClick={putCollege}
                />
            </Container>
        </Slide>
    )
}

export default MenuFormation;
