import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
    /* Shape */
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 0% 100% 0% 100% / 82% 0% 100% 18%;
    border-bottom-right-radius: 50px;
    z-index: 1;
    background: ${colors.darkBlue};
    `;

export const Card = styled.div`
    position: relative;
    text-align: start;
    align-item: center;
    width: ${(props) => props.width || "1200px"};
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.5%;
    margin-bottom: 1%;
    top: 20%;
    background: ${colors.white};
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 12px;
    align-items: center;

    @media (max-width: 870px) {
      width: 700px;
      margin-bottom: 5%;
    }

    @media (max-width: 570px) {  
      width: 340px;
      margin-bottom: 5%;
    }
    `;

    export const GroupText = styled.div`
      width: 100%;
      alin-items: center;
      padding-top: ${(props) => props.left || "2%"};
      padding-bottom: ${(props) => props.left || "2%"};
      padding-left: ${(props) => props.left || "5%"};
      padding-right: ${(props) => props.right || "5%"};
`;

    export const LabelData = styled.label`
          font-size: ${(props) => props.font || "12px"};
          text-align: ${(props) => props.align || "start"};

          font-family: 'Poppins';
          font-style: normal;
          font-weight: ${(props) => props.weight || "600"};

          line-height: 130%;
          /* identical to box height, or 52px */

          @media (max-width: 870px) {
            font-size: 12px;
          }

          @media (max-width: 570px) {  
            font-size: 12px;
          }

          text-align: start;
          letter-spacing: 0.116667px;

          color: ${(props) => props.color || colors.darkBlue};
`;

export const Header = styled.div`
    position: relative;
    background-color: ${colors.white};
    left: 0%;
    heigth: 100px;
    right: 0%;
    top: 0%;
    bottom: 0%;
    box-shadow: inset 0px -1px 0px #E2E2EA;
  `;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 120px;
  }

  @media (max-width: 570px) {  
    width: 100px;
  }
`;

export const GroupFilter = styled.div`
    padding-bottom: 0.2%;
    padding-left: 5.6%;
    padding-right: 5.6%;
    text-align: ${(props) => props.align || "center"};;
`;

export const Label = styled.h2`
    /* H2 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: start;
    padding-top: 1.5%;
    padding-bottom: 0.5%;
    padding-left: 3%;
    letter-spacing: 0.116667px;
    color: ${colors.darkBlue};
    @media (max-width: 870px) {
      padding-top: 5%;
      padding-bottom: 6%;
    }
  
    @media (max-width: 570px) {  
      padding-top: 5%;
      padding-bottom: 6%;
    }
`;

export const Group = styled.div`
    padding-bottom: 0.2%;
    text-align: center;
`;


