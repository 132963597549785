import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from './../../components/Table';
import MenuData from '../../components/Menu/MenuCompany';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import ErrorModal from './../../components/ErrorModal';
import { ThreeDots } from 'react-loader-spinner';
import * as Io from "react-icons/io5";
import PlusBlue from './../../assets/img/PlusBlue.svg';
import MenuHardskill from '../../components/Menu/MenuHardskill';
import BtnPlus from './../../assets/img/BtnPlus.png';
import DeleteModal from './../../components/DeleteModal'
import HardskillCalibration from '../../components/hardskillCalibration';

import {
  Container,
  Header,
  Logo,
  Title,
  Card,
  Label,
  ContainerCard,
  GroupItems,
  SubTitle,
  Wrap,
  Row,
  Icon,
  Group,
  GroupFilter
} from './styles';


const JobsApplied = () => {

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [isVisible, setIsVisible] = useState(false);
  const [hardskill, setHardskill] = useState([]);
  const [object, setObject] = useState([]);
  const [user, setUser] = useState([]);
  const [dataCompanies, setDataCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [cardHardsKill, setCardHardskill] = useState(false);
  const [calibration, setCalibration] = useState(false);
  const [del, setDel] = useState(false);
  const [selectedHS, setSelectedHS] = useState("");
  const [career, setCareer] = useState("");


  useEffect(async () => {
    setWidth(window.screen.width);
    getCompanies();
    getHardskill();
    getUsers();
  }, [width]);

  const onCardHardsKills = () => {
    setCardHardskill(true);
  }


  const onSaveHS = async (id) => {
    setSelectedHS(id);
    setDel(true);
  }

  const getUsers = async () => {
    await api.get('/users?status=S', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.users;
      let user = data.filter((item) => {
        return item.email == token.email
      })
      setUser(user);
      console.log('user', user)
      setCareer(user[0].carreira_id)
      getData(user[0].id);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }


  const getData = async (id) => {
    await api.get('/vagas/user/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;

      setObject(data);
      setIsVisible(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const deleteHS = async (id) => {
    await api.delete('user-hardskill/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      setDel(false);
      getUsers();
    }).catch((error) => {
      setDel(false);
      console.log('erro', error);
      setError(true);
    });
  }

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.vacancy}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.description}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.salary}</th>
      <th key={index}>{item.status}</th>
    </>
  )

  const renderBody = (item, index) => {
    let json = dataCompanies.filter((i) => {
      return item.vagas[0].company_id == i.id
    })
    return <tr key={index}>
      <td>{json.length > 0 ? json[0].nome_fantasia : "--"}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.vagas[0].nome}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.vagas[0].descricao}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.vagas[0].faixa_salarial_inicial + " - " + item.vagas[0].faixa_salarial_final}</td>
      <td >{item.vagas[0].status == 0 ? "Inativa" : "Ativa"}</td>
    </tr>
  }

  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setDataCompanies(data);

    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setDataCompanies(data);
  }


  const companies =
  {
    head: [
      { name: 'Nome da empresa', vacancy: 'Vaga', description: "Descrição", salary: "Faixa salarial", status: 'Status' }
    ],
    body: object
  }

  const onCloseError = () => {
    setError(false);
  }

  const onClose = () => {
    setCardHardskill(false);
    getUsers();
  }

  const getHardskill = async () => {
    await api.get('/hardskill', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      setHardskill(data);

    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  return (
    <Container>
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <MenuHardskill user={user} isOpen={cardHardsKill} onClose={onClose} />
      <HardskillCalibration career={career} width={width} onRequestClose={() => setCalibration(false)} isOpen={calibration} />
      <DeleteModal isOpen={del} onRequestClose={() => setDel(false)} title={"Tem certeza que deseja desvincular a hardskill?"} onNext={() => deleteHS(selectedHS)} />
      <ResponsiveAppBar />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <Title>Meu painel de carreiras</Title>
      {career != null ?
      <ContainerCard top="10%" bottom="1%">
        <GroupFilter top="100px" bottom="1px">
          <Button
            type="button"
            width="260px"
            hoverColor={colors.lightBlue}
            text="Calibrar Hardskills da carreira"
            fontSize="12px"
            onClick={() => setCalibration(true)}
            icon={<Icon width="40px" float="left" left="21px" right="-21px" src={BtnPlus} alt="Item" />}
          />
        </GroupFilter>
      </ContainerCard> : <></>}
      {user.length > 0 && user[0].hard_skills.length > 0 ?
        <ContainerCard top="10%" bottom="1%">
          <Card width="100%" heigth="300px">
            <GroupItems>
              <Row top="0%" content="space-between">
                <SubTitle>Hardskills</SubTitle>
                <Button
                  type="button"
                  width="230px"
                  fontSize="13px"
                  text="Adicionar hardskill"
                  color={colors.lightBlue}
                  bgColor={colors.transparent}
                  onClick={onCardHardsKills}
                  icon={<Icon width="40px" float="left" left="20px" right="-20px" src={PlusBlue} alt="Item" />}
                />
              </Row>

              {user.length > 0 && user[0].hard_skills.length > 0 ?
                <Wrap top="0%">
                  {user[0].hard_skills.map((i) => {
                    let json = hardskill.filter((item) => {
                      return i.hard_skill_id == item.id
                    })


                    return <Group width="35%" bottom="1.5%">
                      <Row cursor={"pointer"}>
                        <Io.IoRemoveCircle color={colors.alertRed} onClick={() => onSaveHS(i.hard_skill_id)} />
                        <SubTitle font="14px">&nbsp;{json.length > 0 ? json[0].nome : "--"}</SubTitle>
                        <SubTitle font="14px" weight="400">&nbsp;{'(' + i.valor + ')'}</SubTitle>
                      </Row>
                    </Group>
                  })}
                </Wrap>
                : <></>
              }

            </GroupItems>
          </Card>
        </ContainerCard>
        :
        <></>}
      <ContainerCard top="10%" bottom="10%">
        {isVisible ?
          <Card >
            <Label>Minhas vagas</Label>
            <Table
              limit='10'
              headData={companies.head}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={companies.body}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </Card>
          :
          <Card>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={colors.gray3}
              ariaLabel="three-dots-loading"
              wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
              wrapperClassName=""
              visible={true}
            />
          </Card>}
      </ContainerCard>
    </Container>
  )
}

export default JobsApplied;
