import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from './../../components/Table';
import MenuData from '../../components/Menu/MenuData';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import ErrorModal from './../../components/ErrorModal';
import { Select, TextField, MenuItem, FormControl } from '@mui/material';
import { removeAccentuation } from '../../utils/removeAccentuation.js';
import { ThreeDots } from 'react-loader-spinner'
import api from './../../api';

import {
  Container,
  Header,
  Logo,
  Title,
  Card,
  Label, 
  Group
} from './styles';


const NPS = () => {

  const { token, setToken } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [isvisible, setIsVisible] = useState(false);
  const [object, setObject] = useState([]);
  const [arrayholder, setArrayholder] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const [test, setTest] = useState("");
  const [error, setError] = useState(false);

  useEffect(async () => {
    getNPS();
    setWidth(window.screen.width)
  }, [width]);

  const changeDetails = (id) => {
    setDetails(!details);
  }

  const onCloseError = () => {
    setError(false);
  }

  const onFilter = async (e) => {
    await handleName(e);
    await setIsVisible(false);
    let type = e.target.value;

    if (type.length > 0) {
      const textData = removeAccentuation(type.toUpperCase().trim());



      const newData = object.filter((item) => {

        const itemData = removeAccentuation(item.teste_tipo.toUpperCase().trim());

        return itemData.indexOf(textData) > -1;
      });

       setObject(newData);
    } else {
      setObject(arrayholder)
    }
    await setIsVisible(true);
  }

  const handleName = async (e) => {
    e.preventDefault();
    setTest(e.target.value);
  }

  const getNPS = async () => {
    await api.get('/nps', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const resp = response.data;

      setObject(resp);
      setArrayholder(resp);
      setIsVisible(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.nps}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.commentary}</th>
      {/* <th key={index}>{item.button}</th> */}
    </>
  )

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.teste_tipo}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.nps}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.comentario}</td>
      {/* <td>
        <Button
          type="button"
          width="80px"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          fontSize={'12px'}
          height={'30px'}
          margin={'0px'}
          text="Detalhes"
          onClick={() => changeDetails(item.id)}
        /></td> */}
    </tr>
  )

  const nps =
  {
    head: [
      { name: 'Teste', nps: 'Nota', commentary: 'Comentário', button: ' ' }
    ],
    body: object
  }

  let fieldRow = width <= 570 ? '80%' : 1100;

  return (
    <Container>
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      {details ?
        <MenuData isOpen={details} onClose={changeDetails} title="NPS" width={width} />
        : <></>}

      <ResponsiveAppBar />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <Title>NPS</Title>
      <Card>
        <Label>Filtrar sua busca</Label>
        <Group>
          <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
            <TextField autoWidth size="small" color="info" disabled={false} placeholder="Procurar por teste" value={test} onChange={onFilter}
              InputProps={{
                sx: {
                  'input': {
                    padding: 0.7,
                    '&::placeholder': {
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal'
                    }
                  },
                },
              }}
            />
          </FormControl>
          </Group>
      </Card>
      {isvisible ? 
      <Card>
        <Table
          limit='5'
          headData={nps.head}
          renderHead={(item, index) => renderHead(item, index)}
          bodyData={nps.body}
          renderBody={(item, index) => renderBody(item, index)}
        />
      </Card>
      : 
      <Card>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color={colors.gray3}
        ariaLabel="three-dots-loading"
        wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
        wrapperClassName=""
        visible={true}
      />
    </Card>
}
    </Container>
  )
}

export default NPS;
