import React from 'react';

import { Container, Image, Text, Value, Icon, Label, GpItems, Progress, Step, Row } from './styles';
import vector from './../../assets/img/Vector.svg'
import exclamation from './../../assets/img/Exclamation.svg'
import colors from '../../styles/colors';
import Tooltip from '@material-ui/core/Tooltip';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";

const Card = ({
    bgColor,
    color,
    title,
    height,
    width,
    description,
    fontColor,
    value,
    font,
    progress,
    colorProgress,
    step,
    percent,
    paddingLabel,
    topProgress,
    valueColor,
    left,
    right,
    num,
    fontNum,
    iconWhite,
    tooltip
}) => {

    const defaultTheme = createMuiTheme();
    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: "1em",
                    backgroundColor: colors.darkBlue
                }
            }
        }
    });


    return (
        <MuiThemeProvider theme={defaultTheme}>
            <Container
                bgColor={bgColor}
                color={color}
                width={width}
                height={height}
                right={right ? right : description ? "0%" : "30px"}
                left={left ? left : progress || step ? "30px" : "0px"}
            >
                <GpItems>
                    <Image>

                        <MuiThemeProvider theme={theme}>
                            <Tooltip title={tooltip}>
                                {iconWhite ?
                                    <Icon width={step ? "20px" : "15px"} top={description ? "30%" : "22%"} src={exclamation} alt="Icon" />
                                    :
                                    <Icon width={step ? "20px" : "15px"} top={description ? "30%" : "22%"} src={vector} alt="Icon" />
                                }
                            </Tooltip></MuiThemeProvider>
                    </Image>
                    {description ?
                        <Text>
                            <Label padding={paddingLabel ? paddingLabel : "3%"} color={fontColor ? fontColor : colors.darkBlue} font={"12px"}>{title}</Label>
                            <Label padding={paddingLabel ? paddingLabel : "3%"} color={fontColor ? fontColor : colors.darkBlue} font={"10px"}>{description}</Label>
                        </Text>
                        :
                        <Text width={step ? "50%" : "70%"}>
                            <Label padding={paddingLabel ? paddingLabel : "3%"} color={fontColor ? fontColor : colors.darkBlue} font={font} top="8%">{title}</Label>
                        </Text>
                    }
                    <Value width={paddingLabel ? "0%" : description || step ? "25%" : "10%"}>
                        <Label color={valueColor ? valueColor : colors.darkBlue} font={"100%"}>{value}</Label>
                    </Value>
                </GpItems>
                <GpItems>
                    {progress ? colorProgress ? <Progress color={colorProgress} top={topProgress ? topProgress : "10px"} /> : <Progress topProgress={topProgress ? topProgress : "10px"} /> : false}
                    {step ?
                        <Row>
                            {step == 1 ?
                                <>
                                    < Step color={colors.lightBlue} />
                                    <Step />
                                    <Step />
                                    <Step />
                                    <Step />
                                </>
                                :
                                step == 2 ?
                                    <>
                                        < Step color={colors.lightBlue} />
                                        <Step color={colors.lightBlue} />
                                        <Step />
                                        <Step />
                                        <Step />
                                    </>
                                    :
                                    step == 3 ?
                                        <>
                                            < Step color={colors.lightBlue} />
                                            <Step color={colors.lightBlue} />
                                            <Step color={colors.lightBlue} />
                                            <Step />
                                            <Step />
                                        </>
                                        :
                                        step == 4 ?
                                            <>
                                                < Step color={colors.lightBlue} />
                                                <Step color={colors.lightBlue} />
                                                <Step color={colors.lightBlue} />
                                                <Step color={colors.lightBlue} />
                                                <Step />
                                            </>
                                            :
                                            <>
                                                < Step color={colors.lightBlue} />
                                                <Step color={colors.lightBlue} />
                                                <Step color={colors.lightBlue} />
                                                <Step color={colors.lightBlue} />
                                                <Step color={colors.lightBlue} />
                                            </>
                            }
                        </Row>

                        : false}
                    {num ?
                        <Text float="right" width="80%">
                            <Label font={fontNum} top="8%">{num}</Label>
                        </Text>
                        :
                        false}
                </GpItems>
            </Container>
        </MuiThemeProvider>
    )
}

export default Card;
