import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
    /* Shape */
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 0% 100% 0% 100% / 82% 0% 100% 18%;
    border-bottom-right-radius: 50px;
    z-index: 1;
    background: ${colors.darkBlue};
    `;

export const GroupItems = styled.div`
      padding: ${(props) => props.padding || "5%"};
`;

export const Title = styled.h1`
position: relative;
height: 52px;
top: 110px;

/* H1 - Web */

font-family: 'Poppins';
font-style: normal;
font-weight: 700;

line-height: 130%;
/* identical to box height, or 52px */

@media (max-width: 870px) {
  font-size: 37px;
}

@media (max-width: 570px) {  
  font-size: 27px;
}

text-align: center;
letter-spacing: 0.116667px;

color: ${colors.white};
`;

export const DescriptionData = styled.p`
          font-size: ${(props) => props.font || "12px"};
          padding-bottom: ${(props) => props.bottom || "0%"};
          padding-top: 1.5%;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: ${(props) => props.weight || "600"};

          /* identical to box height, or 52px */

          @media (max-width: 870px) {
            font-size: 12px;
            padding-top: 0.5%;
          }

          @media (max-width: 570px) {  
            font-size: 12px;
            padding-top: 1%;
          }

          text-align: start;
          letter-spacing: 0.116667px;

          color: ${(props) => props.color || colors.darkBlue};
`;

export const GpItems = styled.div`
  position: relative;
  margin-top: 0;
  padding: ${(props) => props.padding || "20px"};
  border: 1px solid ${(props) => props.borderColor || colors.transparent};;
  border-radius: ${(props) => props.radius ? props.radius : "12px 12px 12px 12px"};
  width: ${(props) => props.width || "500px"};
  background: ${(props) => props.background ? props.background : colors.transparent};
`;

export const SubCard = styled.div`
    position: relative;
    text-align: start;
    align-item: center;
    width: ${(props) => props.width || "1200px"};
    height:${(props) => props.heigth || "500px"};
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.5%;
    margin-bottom: 1%;
    margin-top: 2%;
    top: ${(props) => props.top || "10%"};
    background: ${(props) => props.background || colors.white};
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 12px;
    border: 1px solid ${(props) => props.border || colors.darkBlue};
    align-items: center;

    @media (max-width: 870px) {
      width: 600px;
      height: auto;
      margin-bottom: 5%;
    }

    @media (max-width: 570px) {  
      width: 300px;
      height: auto;
      margin-bottom: 5%;
    }
`;

export const SubTitle = styled.h2`
    /* H2 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: ${(props) => props.font || "20px"};
    line-height: 150%;
    text-align: start;
    padding-top:  ${(props) => props.top || "1.5%"};
    padding-bottom: ${(props) => props.bottom || "1.8%"};
    letter-spacing: 0.116667px;
    color:  ${(props) => props.color || colors.darkBlue};
    @media (max-width: 870px) {
      padding-top: 5%;
      padding-bottom: 6%;
    }
  
    @media (max-width: 570px) {  
      padding-top: 5%;
      padding-bottom: 6%;
    }
`;

export const ContainerCard = styled.div`
      position: relative;
      text-align: start;
      align-item: start;
      width: ${(props) => props.width || "1200px"};
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${(props) => props.bottom || "0.5%"};
      padding-bottom: ${(props) => props.bottom || "0%"};
      justify-content: start;
      top: ${(props) => props.top || '30%'};
      border-radius: 12px;
      align-items: start;

      @media (max-width: 870px) {
        width: 700px;
        display: block;
      }

      @media (max-width: 570px) {  
        width: 340px;
        display: block;
      }
`;

export const CardFilter = styled.div`
    position: relative;
    text-align: start;
    align-item: center;
    width: ${(props) => props.width || "1200px"};
    height: auto;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.5%;
    margin-bottom: 1%;
    top: 30%;
    background: ${colors.white};
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 12px;
    align-items: center;

    @media (max-width: 870px) {
      width: 700px;
      margin-bottom: 5%;
    }

    @media (max-width: 570px) {  
      width: 340px;
      margin-bottom: 5%;
    }
    `;


    export const Card = styled.div`
    position: relative;
    text-align: start;
    align-item: center;
    width: ${(props) => props.width || "1200px"};
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.5%;
    margin-bottom: 8%;
    top: ${(props) => props.top || "10%"};
    background: ${(props) => props.background || colors.white};
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 12px;
    align-items: center;

    @media (max-width: 870px) {
      width: 700px;
      height: auto;
      margin-bottom: 25%;
    }

    @media (max-width: 570px) {  
      width: 340px;
      height: auto;
      margin-bottom: 25%;
    }
    `;

export const GroupText = styled.div`
      width: 100%;
      alin-items: center;
      padding-top: ${(props) => props.left || "2%"};
      padding-bottom: ${(props) => props.left || "2%"};
      padding-left: ${(props) => props.left || "5%"};
      padding-right: ${(props) => props.right || "5%"};
`;

export const ButtonLink = styled.a`
      font-size: ${(props) => props.font || "12px"};
      text-align: start;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: ${(props) => props.weight || "600"};

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 12px;
      }

      @media (max-width: 570px) {  
        font-size: 12px;
      }

      text-align: start;
      letter-spacing: 0.116667px;

      color: ${(props) => props.color || colors.darkBlue};

`;

export const ErrorMessage = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  padding-top: 10px
  margin-bottom: ${(props) => props.maginBottom || "45px"};
  color: ${(props) => props.color || colors.gray2};
  text-align: center;
  @media (max-width: 870px) {
    margin-bottom: 10px;
  }
`;

export const Row = styled.div`
      position: relative;
      width: 100% important;
      text-align: ${(props) => props.align || "center"};
      align-item: center;
      display: flex;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${(props) => props.bottom || "20px"};
      justify-content: start;
      border-radius: 12px;
      top: ${(props) => props.top || "0%"};
      align-items: ${(props) => props.items || "center"};;
`;

export const LabelData = styled.label`
          font-size: ${(props) => props.font || "12px"};
          text-align: start;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: ${(props) => props.weight || "600"};

          line-height: 130%;
          /* identical to box height, or 52px */

          @media (max-width: 870px) {
            font-size: 12px;
          }

          @media (max-width: 570px) {  
            font-size: 12px;
          }

          text-align: start;
          letter-spacing: 0.116667px;

          color: ${(props) => props.color || colors.darkBlue};
`;

export const Header = styled.div`
    position: relative;
    background-color: ${colors.white};
    left: 0%;
    heigth: 100px;
    right: 0%;
    top: 0%;
    bottom: 0%;
    box-shadow: inset 0px -1px 0px #E2E2EA;
  `;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 120px;
  }

  @media (max-width: 570px) {  
    width: 100px;
  }
`;

export const GroupFilter = styled.div`
    padding-bottom: 0.2%;
    padding-left: 5.6%;
    padding-right: 5.6%;
    text-align: ${(props) => props.align || "center"};
`;

export const Label = styled.h2`
    /* H2 */
    text-transform: initial;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: start;
    padding-top: 1.5%;
    padding-bottom: 0.5%;
    padding-left: 5%;
    letter-spacing: 0.116667px;
    color: ${colors.darkBlue};
    @media (max-width: 870px) {
      padding-top: 5%;
      padding-bottom: 6%;
    }
  
    @media (max-width: 570px) {  
      padding-top: 5%;
      padding-bottom: 6%;
    }
`;

export const Group = styled.div`
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  width: ${(props) => props.width || "500px"};
`;

export const GroupSearch = styled.div`
    padding-bottom: 0.2%;
    text-align: ${(props) => props.align || "center"};
`;


