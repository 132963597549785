import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import logo from './../../assets/img/logo.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import Info from './../../assets/img/Info.svg';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import TestsModal from './../../components/TestsModal';
import ErrorModal from './../../components/ErrorModal';
import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation } from 'react-router';

import {
  Container,
  Header,
  Logo,
  Card,
  Icon,
  SubTitle,
  Title,
  GpButton,
  Group,
  DescriptionGroup,
  SubLabel,
  Line,
  Step,
  Note,
  ContainerButton,
} from './styles';


const StartBehavioral = () => {

  const defaultTheme = createMuiTheme();
  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
          backgroundColor: colors.darkBlue
        }
      }
    }
  });


  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: colors.lightBlue,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: colors.lightBlue,
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const { token, setToken } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [value, setValue] = useState('');
  const [note1, setNote1] = useState(false)
  const [note2, setNote2] = useState(false)
  const [note3, setNote3] = useState(false)
  const [note4, setNote4] = useState(false)
  const [note5, setNote5] = useState(false)
  const [note6, setNote6] = useState(false)
  const [note7, setNote7] = useState(false)
  const [note8, setNote8] = useState(false)
  const [note9, setNote9] = useState(false)
  const [note10, setNote10] = useState(false)
  const [modal, setModal] = useState(false)
  const [error, setError] = useState(false);
  const [valueNote, setValueNote] = useState(0)
  const [step, setStep] = useState(1);
  const [time, setTime] = useState(0);
  const [resp, setResp] = useState([]);
  const [data, setData] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(async () => {
    getTests();

    setWidth(window.screen.width)
  }, [width]);

  const onBack = () => {
    step == 1 ?
      history.push({
        pathname: '/',
      })
      :
      resp.length = resp.length - 1;
    setStep(step - 1);
  }

  const getTests = async () => {
    const body = {
      "email": token.email
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      await setData(data)
    }).catch((error) => {
      console.log('erro', error);
    });
  }



  const handleChange = (value) => {
    setValueNote(value);
    if (value == 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 2) {
      setNote1(false);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 3) {
      setNote1(false);
      setNote2(false);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 4) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 5) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 6) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 7) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 8) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value == 9) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(true);
      setNote10(false);
    } else if (value == 10) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(true);
    }
  };

  const onNext = async (event) => {
    const value = event.target.value;
    resp.push(value)
    if (step != 40) {
      await setStep(step + 1);
    }

  }

  const onFinish = async () => {
    await setTime(4);
    await setModal(true);
  }

  const onCloseError = () => {
    setError(false);
  }

  const sendTest = async () => {
    const body = {
      "lang": "pt-br",
      "email": token.email,
      "name": token.displayName || "undefined",
      "datahora": new Date(),
      "perfil": "A mim mesmo (autoteste)",
      "email_amigo": "",
      "empresa": "",
      "palavra1": resp[0],
      "palavra2": resp[1],
      "palavra3": resp[2],
      "palavra4": resp[3],
      "palavra5": resp[4],
      "palavra6": resp[5],
      "palavra7": resp[6],
      "palavra8": resp[7],
      "palavra9": resp[8],
      "palavra10": resp[9],
      "palavra11": resp[10],
      "palavra12": resp[11],
      "palavra13": resp[12],
      "palavra14": resp[13],
      "palavra15": resp[14],
      "palavra16": resp[15],
      "palavra17": resp[16],
      "palavra18": resp[17],
      "palavra19": resp[18],
      "palavra20": resp[19],
      "palavra21": resp[20],
      "palavra22": resp[21],
      "palavra23": resp[22],
      "palavra24": resp[23],
      "palavra25": resp[24],
      "palavra26": resp[25],
      "palavra27": resp[26],
      "palavra28": resp[27],
      "palavra29": resp[28],
      "palavra30": resp[29],
      "palavra31": resp[30],
      "palavra32": resp[31],
      "palavra33": resp[32],
      "palavra34": resp[33],
      "palavra35": resp[34],
      "palavra36": resp[35],
      "palavra37": resp[36],
      "palavra38": resp[37],
      "palavra39": resp[38],
      "palavra40": resp[39]
    }


    await api.post('/behiavor-test', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      onFinish();
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const startTest = async () => {
    const route = !data.comportamental && !data.cultural && !data.valores ? '/testeComportamental' 
    : !data.comportamental && !data.cultural && data.valores ? '/testeComportamental' 
    : data.comportamental && data.cultural && !data.valores ? '/testeCarreira' 
    : !data.comportamental && data.cultural && data.valores ? '/testeComportamental'
    : data.comportamental && !data.cultural && data.valores ? '/testeCultura'
    : !data.comportamental && data.cultural && !data.valores ? '/testeComportamental' : '/testeCultura'

    history.push({
      pathname: route,
      state: {
        vacancy: location.state.vacancy
      }
    });
  }

  const startReport = async () => {
    history.push({
      pathname: '/relatorioComportamental',
    });
  }

  const onCloseModal = async () => {
    await setModal(false);
  }

  const step1 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 1 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step active><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Realizadora" control={<BpRadio />} onChange={onNext} label="Realizadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem capacidade de realizar, empreendedora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Comprometida" control={<BpRadio />} onChange={onNext} label="Comprometida" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Envolvida, engajada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Livre" control={<BpRadio />} onChange={onNext} label="Livre" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Independente, que não se submete aos outros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Pontual" control={<BpRadio />} onChange={onNext} label="Pontual" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que cumpre prazos e horários)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step2 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 2 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step active><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Interessada" control={<BpRadio />} onChange={onNext} label="Interessada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Curiosa, empenhada, motivada, que expressa interesse)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Curiosa" control={<BpRadio />} onChange={onNext} label="Curiosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="Que tem grande vontade de saber, entender as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Desconfiada" control={<BpRadio />} onChange={onNext} label="Desconfiada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que receia ser enganada, que duvida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Autodidata" control={<BpRadio />} onChange={onNext} label="Autodidata" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que aprende sozinha)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step3 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 3 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step active><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Generelista" control={<BpRadio />} onChange={onNext} label="Generelista" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que não possui uma única especialização; que atua em diversas áreas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Informal" control={<BpRadio />} onChange={onNext} label="Informal" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Sem formalidades ou regras)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Investigadora" control={<BpRadio />} onChange={onNext} label="Investigadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que examina com cuidado, que pesquisa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Morosa" control={<BpRadio />} onChange={onNext} label="Morosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Demorada, atrasada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step4 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 4 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step active><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Direta" control={<BpRadio />} onChange={onNext} label="Direta" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Sincera, clara, que vai direto ao ponto)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Comunicativa" control={<BpRadio />} onChange={onNext} label="Comunicativa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que se comunica facilmente, franca, expansiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Receptiva" control={<BpRadio />} onChange={onNext} label="Receptiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Acolhedora, aberta, compreensiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Quieta" control={<BpRadio />} onChange={onNext} label="Quieta" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que prefere não falar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step5 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 5 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step active><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Dominante" control={<BpRadio />} onChange={onNext} label="Dominante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que domina, que prevalece, influente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Relacional" control={<BpRadio />} onChange={onNext} label="Relacional" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de se relacionar com as pessoas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Colaborativa" control={<BpRadio />} onChange={onNext} label="Colaborativa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que ajuda, coopera)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Individualista" control={<BpRadio />} onChange={onNext} label="Individualista" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que se coloca em primeiro lugar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step6 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 6 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step active><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Desafiadora" control={<BpRadio />} onChange={onNext} label="Desafiadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que desafia, que sugere uma disputa, um duelo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Desenvolvedora" control={<BpRadio />} onChange={onNext} label="Desenvolvedora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de desenvolver as pessoas, mentora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Treinadora" control={<BpRadio />} onChange={onNext} label="Treinadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que treina ou instrui os outros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Demonstradora" control={<BpRadio />} onChange={onNext} label="Demonstradora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que ensina de forma prática, demonstrando)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step7 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 7 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step active><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Motivada_por_desafios" control={<BpRadio />} onChange={onNext} label="Motivada por desafios" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gera ação quando recebe desafios)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Confiante" control={<BpRadio />} onChange={onNext} label="Confiante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem confiança em si, segura)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Comedida" control={<BpRadio />} onChange={onNext} label="Comedida" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Pacífica, serena, delicada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Conforme" control={<BpRadio />} onChange={onNext} label="Conforme" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que realiza conforme as normas e regras)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step8 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 8 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step active><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Determinada" control={<BpRadio />} onChange={onNext} label="Determinada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que expressa decisão, convicção, decidida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Distraida" control={<BpRadio />} onChange={onNext} label="Distraída" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Desatenta, com baixa concentração)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Ouvinte" control={<BpRadio />} onChange={onNext} label="Ouvinte" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que prefere escutar ao invés de falar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Critica" control={<BpRadio />} onChange={onNext} label="Crítica" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que avalia, que analisa e dá o seu parecer)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step9 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 9 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step active><p>9</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Agilizada" control={<BpRadio />} onChange={onNext} label="Agilizada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Estimulada, desembaraçada, que realiza com rapidez)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Aberta" control={<BpRadio />} onChange={onNext} label="Aberta" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Receptiva, sem pré-julgamentos, sem resistência)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Conservadora" control={<BpRadio />} onChange={onNext} label="Conservadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que prefere manter as coisas do mesmo jeito)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Sistematica" control={<BpRadio />} onChange={onNext} label="Sistemática" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Metódica, organizada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step10 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 10 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step active><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step><p>12</p></Step>
      <Step><p>13</p></Step>
      <Step><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Persistente" control={<BpRadio />} onChange={onNext} label="Persistente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que não desiste facilmente, obstinada, que tem continuidade)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Animada" control={<BpRadio />} onChange={onNext} label="Animada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Entusiasmada, calorosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Paciente" control={<BpRadio />} onChange={onNext} label="Paciente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem paciência, que não se importa em esperar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Criteriosa" control={<BpRadio />} onChange={onNext} label="Criteriosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que possui critérios para a tomada de decisão, cuidadosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step11 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 11 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step active><p>11</p></Step>
      <Step><p>12</p></Step>
      <Step><p>13</p></Step>
      <Step><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Decidida" control={<BpRadio />} onChange={onNext} label="Decidida" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Determinada, deliberada, disposta, resolvida, com objetivos claros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Encorajadora" control={<BpRadio />} onChange={onNext} label="Encorajadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title=" (Incentivadora, que dá ânimo aos outros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Receosa" control={<BpRadio />} onChange={onNext} label="Receosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title=" (Que tem medo, preocupada, apreensiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Prudente" control={<BpRadio />} onChange={onNext} label="Prudente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Cautelosa, que age com cuidado)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step12 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 12 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step active><p>12</p></Step>
      <Step><p>13</p></Step>
      <Step><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Exigente" control={<BpRadio />} onChange={onNext} label="Exigente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Rigorosa, que não se satisfaz facilmente; que sempre quer o melhor)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Avaliadora" control={<BpRadio />} onChange={onNext} label="Avaliadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Crítica, analítica)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Responsavel" control={<BpRadio />} onChange={onNext} label="Responsável" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que responde pelos seus próprios ato)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Detalhista" control={<BpRadio />} onChange={onNext} label="Detalhista" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que se apega aos detalhes, aos pormenores)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step13 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 13 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step ><p>12</p></Step>
      <Step active><p>13</p></Step>
      <Step><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Energica" control={<BpRadio />} onChange={onNext} label="Enérgica" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Ativa, energética, segura, disposta)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Bem_humorada" control={<BpRadio />} onChange={onNext} label="Bem humorada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Disposta, com bom humor)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Calorosa" control={<BpRadio />} onChange={onNext} label="Calorosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Afetuosa, simpática, enérgica)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Introvertida" control={<BpRadio />} onChange={onNext} label="Introvertida" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Concentrada, voltada para si própria)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step14 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 14 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step active><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Comandante" control={<BpRadio />} onChange={onNext} label="Comandante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title=" (Que comanda, lidera)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Motivada" control={<BpRadio />} onChange={onNext} label="Motivada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem um objetivo, uma razão para agir)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Focada" control={<BpRadio />} onChange={onNext} label="Focada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que possui foco, atenta ao seu objetivo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Planejadora" control={<BpRadio />} onChange={onNext} label="Planejadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que é programada, que gosta de planejar as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step15 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 15 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step active><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Originadora" control={<BpRadio />} onChange={onNext} label="Originadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que origina ou é causadora de algo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Mentora" control={<BpRadio />} onChange={onNext} label="Mentora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Conselheira, que orienta pessoas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Aprovadora" control={<BpRadio />} onChange={onNext} label="Aprovadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que aprova ou autoriza, que dá seu consentimento)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Centralizadora" control={<BpRadio />} onChange={onNext} label="Centralizadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de ter o controle de tudo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step16 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 16 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step active><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Acelerada" control={<BpRadio />} onChange={onNext} label="Acelerada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Apressada, rápida, vertiginosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Desorganizada" control={<BpRadio />} onChange={onNext} label="Desorganizada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Sem organização, sem método)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Metodica" control={<BpRadio />} onChange={onNext} label="Metódica" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que segue métodos, normas e regras)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Demorada" control={<BpRadio />} onChange={onNext} label="Demorada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Devagar, morosa, lenta)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step17 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 17 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step active><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Imediatista" control={<BpRadio />} onChange={onNext} label="Imediatista" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que age de maneira imediata, que deseja as coisas na hora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Apaixonada" control={<BpRadio />} onChange={onNext} label="Apaixonada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que faz as coisas com paixão, entusiasmada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Conformada" control={<BpRadio />} onChange={onNext} label="Conformada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que aceita a adversidade sem se opor)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Racional" control={<BpRadio />} onChange={onNext} label="Racional" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que toma decisões baseada no raciocínio, lógica)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step18 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 18 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step ><p>17</p></Step>
      <Step active><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Criativa" control={<BpRadio />} onChange={onNext} label="Criativa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Inventiva, inovadora, que cria ou transforma as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Promotora" control={<BpRadio />} onChange={onNext} label="Promotora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que promove, que dá impulso a alguma coisa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Rotineira" control={<BpRadio />} onChange={onNext} label="Rotineira" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que realiza sempre da mesma forma, que prefere a rotina)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Teorica" control={<BpRadio />} onChange={onNext} label="Teórica" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Com conhecimento não prático, vindo de suposição)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step19 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 19 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step ><p>17</p></Step>
      <Step ><p>18</p></Step>
      <Step active><p>19</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Pioneira" control={<BpRadio />} onChange={onNext} label="Pioneira" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que faz por primeiro, antecessora, descobridora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Seguidora" control={<BpRadio />} onChange={onNext} label="Seguidora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que segue outros, partidária)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Pratica" control={<BpRadio />} onChange={onNext} label="Prática" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que consegue realizar, não é teórica)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Conhecedora" control={<BpRadio />} onChange={onNext} label="Conhecedora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que conhece, que possui vivência)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step20 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 20 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step active><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Ansiosa" control={<BpRadio />} onChange={onNext} label="Ansiosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que deseja excessivamente algo. que expressa inquietação)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Alegre" control={<BpRadio />} onChange={onNext} label="Alegre" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que causa alegria contente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Tranquila" control={<BpRadio />} onChange={onNext} label="Tranquila" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(De personalidade calma, sossegada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Controladora" control={<BpRadio />} onChange={onNext} label="Controladora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que se controla, serena)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step21 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 21 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step active><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Competitiva" control={<BpRadio />} onChange={onNext} label="Competitiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de competição, disputa pela vitória)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Persuasiva" control={<BpRadio />} onChange={onNext} label="Persuasiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que convence os outros com facilidade)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Respeitada" control={<BpRadio />} onChange={onNext} label="Respeitada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que conseguiu o respeito de outras pessoas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Questionadora" control={<BpRadio />} onChange={onNext} label="Questionadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que questiona, que não aceita facilmente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step22 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 22 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step active><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Bem_sucedida" control={<BpRadio />} onChange={onNext} label="Bem sucedida" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Exploradora, pioneira, que faz por primeiro)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Energizante" control={<BpRadio />} onChange={onNext} label="Energizante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem argumentos convincentes, que faz o outro mudar de opinião)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Auto_desenvolvedora" control={<BpRadio />} onChange={onNext} label="Auto-desenvolvedora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que pensa de forma lógica, avaliando causas e consequências)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Estavel" control={<BpRadio />} onChange={onNext} label="Estável" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem força e influência, poder)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step23 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 23 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step active><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Vitoriosa" control={<BpRadio />} onChange={onNext} label="Vitoriosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Com sucesso, êxito)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Entuasiasmada" control={<BpRadio />} onChange={onNext} label="Entuasiasmada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que fornece energia aos outros, reanima)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Cautelosa" control={<BpRadio />} onChange={onNext} label="Cautelosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que se desenvolve sozinha, a si mesma)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Previsível" control={<BpRadio />} onChange={onNext} label="Previsível" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que não possui variações, constante)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step24 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 24 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step active><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Vitoriosa" control={<BpRadio />} onChange={onNext} label="Vitoriosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Vencedora, gloriosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Entusiasmada" control={<BpRadio />} onChange={onNext} label="Entusiasmada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Animada, empolgada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Cautelosa" control={<BpRadio />} onChange={onNext} label="Cautelosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que age com cuidado, evitando riscos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Previsivel" control={<BpRadio />} onChange={onNext} label="Previsível" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que age da forma esperada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step25 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 25 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step active><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Insensivel" control={<BpRadio />} onChange={onNext} label="Insensível" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Sem sensibilidade, indiferente ao sentimento alheio)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Amiga" control={<BpRadio />} onChange={onNext} label="Amiga" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que preza pelas relações de amizade, de afeição)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Isolada" control={<BpRadio />} onChange={onNext} label="Isolada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que prefere ficar só)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Inclusiva" control={<BpRadio />} onChange={onNext} label="Inclusiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que inclui os diferentes, valoriza a diversidade)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step26 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 26 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step active><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Franca" control={<BpRadio />} onChange={onNext} label="Franca" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Sincera, verdadeira)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Magnética" control={<BpRadio />} onChange={onNext} label="Magnética" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title='(Que atrai a atenção dos outros como um "ímã", que seduz)'>
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Concreta" control={<BpRadio />} onChange={onNext} label="Concreta" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que prefere o que é real, verdadeiro)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Diplomática" control={<BpRadio />} onChange={onNext} label="Diplomática" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que trata as pessoas com delicadeza e gentileza, que preza pelos bons relacionamentos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step27 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 27 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step active><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Negociadora" control={<BpRadio />} onChange={onNext} label="Negociadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de negociar, discutir para chegar a um acordo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Cativante" control={<BpRadio />} onChange={onNext} label="Cativante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Simpática, que inspira)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Atenta" control={<BpRadio />} onChange={onNext} label="Atenta" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que presta atenção, que nota as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Confiavel" control={<BpRadio />} onChange={onNext} label="Confiável" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Em quem se pode confiar, honesta, sincera)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step28 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 28 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step active><p>28</p></Step>
      <Step ><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Visionaria" control={<BpRadio />} onChange={onNext} label="Visionária" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que enxerga além do óbvio, voltada ao futuro, idealista)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Experiente" control={<BpRadio />} onChange={onNext} label="Experiente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que possui vivência, conhecimento prático)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Processual" control={<BpRadio />} onChange={onNext} label="Processual" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que age de forma ordenada, por processos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Senso_de_dever" control={<BpRadio />} onChange={onNext} label="Senso de dever" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que possui clareza de suas obrigações, responsável)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step29 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 29 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step ><p>22</p></Step>
      <Step ><p>23</p></Step>
      <Step ><p>24</p></Step>
      <Step ><p>25</p></Step>
      <Step ><p>26</p></Step>
      <Step ><p>27</p></Step>
      <Step ><p>28</p></Step>
      <Step active><p>29</p></Step>
      <Step><p>...</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Concretizadora" control={<BpRadio />} onChange={onNext} label="Concretizadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title=" (Que torna as coisas reais, que atinge os seus objetivos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Influenciadora" control={<BpRadio />} onChange={onNext} label="Influenciadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que exerce influência, poder sobre o outro)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Executora" control={<BpRadio />} onChange={onNext} label="Executora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title='(Que gosta de executar as coisas, "mão na massa")'>
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Administradora" control={<BpRadio />} onChange={onNext} label="Administradora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gerencia as situações, que administra)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step30 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 30 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step active><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Agil" control={<BpRadio />} onChange={onNext} label="Ágil" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que é rápida, veloz)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Impulsiva" control={<BpRadio />} onChange={onNext} label="Impulsiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que age por impulso, de forma impensada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Analitica" control={<BpRadio />} onChange={onNext} label="Analítica" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Examinadora, que analisa detalhadamente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Calma" control={<BpRadio />} onChange={onNext} label="Calma" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Tranquila, sem agitação)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step31 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 31 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step active><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Objetiva" control={<BpRadio />} onChange={onNext} label="Objetiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que vai direto ao ponto, sem rodeios)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Estudiosa" control={<BpRadio />} onChange={onNext} label="Estudiosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de estudar, aprender)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Perfeccionista" control={<BpRadio />} onChange={onNext} label="Perfeccionista" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que busca a perfeição)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Especialista" control={<BpRadio />} onChange={onNext} label="Especialista" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Com profundo conhecimento de um determinado tema)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step32 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 32 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step active><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Apressada" control={<BpRadio />} onChange={onNext} label="Apressada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem pressa, não sabe esperar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Experimentadora" control={<BpRadio />} onChange={onNext} label="Experimentadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de experimentar coisas novas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Minuciosa" control={<BpRadio />} onChange={onNext} label="Minuciosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Detalhista, ligada aos pormenores)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Segura" control={<BpRadio />} onChange={onNext} label="Segura" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que possui autoconfiança, que não hesita)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step33 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 33 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step active><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Inspiradora" control={<BpRadio />} onChange={onNext} label="Inspiradora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que é capaz de inspirar outras pessoas, influenciar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Falante" control={<BpRadio />} onChange={onNext} label="Falante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que fala muito, extrovertida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Orientadora" control={<BpRadio />} onChange={onNext} label="Orientadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de orientar, ensinar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Reflexiva" control={<BpRadio />} onChange={onNext} label="Reflexiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que reflete, ponderada, prudente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step34 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 34 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step active><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Independente" control={<BpRadio />} onChange={onNext} label="Independente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que não depende de ninguém, livre, com autonomia)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Sociavel" control={<BpRadio />} onChange={onNext} label="Sociável" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que prefere estar entre amigos, que age com cortesia)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Solitaria" control={<BpRadio />} onChange={onNext} label="Solitária" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que normalmente está só, sem companhia)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Apoiadora" control={<BpRadio />} onChange={onNext} label="Apoiadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que dá apoio, dá suporte aos outros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step35 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 35 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step active><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Resiliente" control={<BpRadio />} onChange={onNext} label="Resiliente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que se adapta às alterações, supera as dificuldades)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Otimista" control={<BpRadio />} onChange={onNext} label="Otimista" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que enxerga as coisas pelo lado bom, positiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Relutante" control={<BpRadio />} onChange={onNext} label="Relutante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que resiste, que não aceita, teimosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Adaptavel" control={<BpRadio />} onChange={onNext} label="Adaptável" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que se adapta com facilidade, flexível)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step36 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 36 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step active><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Obstinada" control={<BpRadio />} onChange={onNext} label="Obstinada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Persistente, que não desiste)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Motivadora" control={<BpRadio />} onChange={onNext} label="Motivadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Animadora, que incentiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Cética" control={<BpRadio />} onChange={onNext} label="Cética" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que não acredita em nada, que tende a duvidar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Leal" control={<BpRadio />} onChange={onNext} label="Leal" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que cumpre com as promessas que faz, responsável)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step37 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 37 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step active><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Assertiva" control={<BpRadio />} onChange={onNext} label="Assertiva" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que fala o que pensa sem ser agressiva ou passiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Democratica" control={<BpRadio />} onChange={onNext} label="Democrática" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que preza pela opinião do todo, que decide baseada na maioria)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Hesitante" control={<BpRadio />} onChange={onNext} label="Hesitante" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Indecisa, dividida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Respeitosa" control={<BpRadio />} onChange={onNext} label="Respeitosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que trata os outros com respeito, com consideração)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step38 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 38 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step active><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Dinamica" control={<BpRadio />} onChange={onNext} label="Dinâmica" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que está sempre em movimento, em evolução)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Empolgada" control={<BpRadio />} onChange={onNext} label="Empolgada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Animada, entusiasmada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Preciosa" control={<BpRadio />} onChange={onNext} label="Preciosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que dificilmente erra, vai direto ao ponto certo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Cuidadosa" control={<BpRadio />} onChange={onNext} label="Cuidadosa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que tem cuidado com o que faz, minuciosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step39 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 39 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step active><p>39</p></Step>
      <Step><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Direcionadora" control={<BpRadio />} onChange={onNext} label="Direcionadora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que direciona, aponta, conduz)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Participativa" control={<BpRadio />} onChange={onNext} label="Participativa" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que gosta de interagir, participar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Reservada" control={<BpRadio />} onChange={onNext} label="Reservada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Discreta, calada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Controladora" control={<BpRadio />} onChange={onNext} label="Controladora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que exerce controle, que gosta das coisas do seu jeito)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step40 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 40 de 41</SubLabel>
    <Title>Sou uma pessoa mais...</Title>
    <DescriptionGroup>
      <Step ><p>30</p></Step>
      <Step ><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step ><p>33</p></Step>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step active><p>40</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Impaciente" control={<BpRadio />} onChange={onNext} label="Impaciente" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que não demonstra paciência, apressada, irritável)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Consultora" control={<BpRadio />} onChange={onNext} label="Consultora" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que orienta, dá pareceres)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Ponderada" control={<BpRadio />} onChange={onNext} label="Ponderada" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Equilibrada, serena, sensata)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
          <Card>
            <FormControlLabel value="Autoridade" control={<BpRadio />} onChange={onNext} label="Autoridade" />
            <MuiThemeProvider theme={theme}>
              <Tooltip title="(Que possui poder para ordenar, que possui conhecimento)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip></MuiThemeProvider>
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < ContainerButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
      <Button
        type="button"
        width="140px"
        fontSize="13px"
        text="Concluir teste"
        hoverColor={colors.lightBlue}
        color={colors.white}
        bgColor={colors.lightBlue}
        onClick={sendTest}
      />
    </ ContainerButton>
  </Group>

  const step41 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de perfil comportamental - Passo 41 de 41</SubLabel>
    <SubTitle>De 0 a 10, qual sua avaliação sobre a facilidade para realizar esse teste?</SubTitle>
    <DescriptionGroup>
      <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
      <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
      <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
      <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
      <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
      <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
      <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
      <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
      <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
      <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl sx={{ backgroundColor: colors.white, m: 1, width: 500, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
        <TextField autoWidth size="small" color="info" disabled={false} placeholder="Comente sua nota"
          multiline
          rows={8}
          maxRows={8}
          InputProps={{
            sx: {
              'input': {
                padding: 0.7,
                '&::placeholder': {
                  fontSize: '12px',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal'
                }
              },
            },
          }}
        />
      </FormControl>
    </DescriptionGroup>
    <Line />
    < ContainerButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
      <Button
        type="button"
        width="140px"
        fontSize="13px"
        text="Concluir teste"
        hoverColor={colors.lightBlue}
        color={colors.white}
        bgColor={colors.lightBlue}
        onClick={onNext}
      />
    </ ContainerButton>
  </Group>




  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Container>
        <ResponsiveAppBar />
        {/* <HeaderUser width={width} /> */}
        {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
        <ErrorModal isOpen={error} onRequestClose={onCloseError} />
        <TestsModal isOpen={modal} onRequestClose={onCloseModal} minutes={time} test="cultura" startReport={startReport} startTest={startTest} />
        {step == 1 ?
          step1
          : step == 2 ?
            step2
            : step == 3 ?
              step3
              : step == 4 ?
                step4
                : step == 5 ?
                  step5
                  : step == 6 ?
                    step6
                    : step == 7 ?
                      step7
                      : step == 8 ?
                        step8
                        : step == 9 ?
                          step9
                          : step == 10 ?
                            step10
                            : step == 11 ?
                              step11
                              : step == 12 ?
                                step12
                                : step == 13 ?
                                  step13
                                  : step == 14 ?
                                    step14
                                    : step == 15 ?
                                      step15
                                      : step == 16 ?
                                        step16
                                        : step == 17 ?
                                          step17
                                          : step == 18 ?
                                            step18
                                            : step == 19 ?
                                              step19
                                              : step == 20 ?
                                                step20
                                                : step == 21 ?
                                                  step21
                                                  : step == 22 ?
                                                    step22
                                                    : step == 23 ?
                                                      step23
                                                      : step == 24 ?
                                                        step24
                                                        : step == 25 ?
                                                          step25
                                                          : step == 26 ?
                                                            step26
                                                            : step == 27 ?
                                                              step27
                                                              : step == 28 ?
                                                                step28
                                                                : step == 29 ?
                                                                  step29
                                                                  : step == 30 ?
                                                                    step30
                                                                    : step == 31 ?
                                                                      step30
                                                                      : step == 32 ?
                                                                        step32
                                                                        : step == 33 ?
                                                                          step33
                                                                          : step == 34 ?
                                                                            step34
                                                                            : step == 35 ?
                                                                              step35
                                                                              : step == 36 ?
                                                                                step36
                                                                                : step == 37 ?
                                                                                  step37
                                                                                  : step == 38 ?
                                                                                    step38
                                                                                    : step == 39 ?
                                                                                      step39
                                                                                      : step == 40 ?
                                                                                        step40
                                                                                        :
                                                                                        step41
        }
      </Container>
    </MuiThemeProvider>
  )
}

export default StartBehavioral;
