import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { lightTheme } from './../../styles/theme.js';
import colors from './../../styles/colors';
import Button from "./../../components/Button";
import { ThemeProvider } from "styled-components";
import logo from './../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
import { TextField, FormControl } from '@mui/material';
import { validEmail } from '../../utils/validEmail.js';
import { phoneMask } from '../../utils/phoneMask.js';
import { auth } from './../../firebaseConfig';
import { createUserWithEmailAndPassword } from "firebase/auth";
import api from '../../api';

//UTILS
import ScrollToTop from './../../utils/ScrollToTop';

//COMPONENTS
import StoreContext from '../../components/Store/Context';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Container,
  Card,
  Header,
  Logo,
  Group,
  ErrorMessage
} from './styles';



const LegalPerson = () => {
  const { token, setToken, setLoginHash, loginHash, uuidSession, setUuidSession } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  const history = useHistory();


  useEffect(() => {
    setWidth(window.screen.width)
  }, [width])

  const onNext = async (e) => {
    e.preventDefault();
    const inputName = name.trim();
    const word = inputName.split(" ");
    if (name.length > 0 && phone.length > 0 && email.length > 0 && message.length > 0) {
      if (!validEmail(email)) {
        setMsg("E-mail inválido! Digite um e-mail válido!");
        setError(true);
      } else {
        if (word.length === 1) {
          setMsg("É necessário inserir pelo menos um sobrenome!");
          setError(true);
        } else {
          createUserWithEmailAndPassword(auth, email, '123456')
            // here we get user id if creating is successful.
            .then(async function (user) {

              const json = {
                'email': email,
                'name': name,
                'password': '123456',
                'password_confirmation': '123456',
                'uid_firebase': user.user.uid
              }
              await api.post('/register', json, {
                headers: {
                  'Content-Type': 'aplication/json',
                }
              }).then(async (response) => {
                const dataUser = response.data
                const credentials = {
                  'email': email,
                  'password': '123456',
                }
                await api.post('/login', credentials, {
                  headers: {
                    'Content-Type': 'aplication/json',
                  }
                }).then(async (response) => {
                  const data = response.data

                  const complementary = {
                    "user_id": dataUser.user.id,
                    "nome": word[0],
                    "sobrenome": word[1],
                    "mensagem": message,
                  }
                  await api.post('/dados-candidatos/save', complementary, {
                    headers: {
                      'authorization': 'Bearer '+ data.refreshToken
                    }
                  }).then(async (response) => {
                    const messageEmail = {
                      "emails": [
                        email
                      ],
                      "mensagem": "Email de teste"
                    }
                    await api.post('/email/send', messageEmail, {
                      headers: {
                        'authorization': 'Bearer '+ data.refreshToken
                      }
                    }).then(async (response) => {
                      history.push({
                        pathname: '/enviarEmail',
                        state: {
                          email: email
                        }
                      })
                    }).catch((error) => {
                      console.log('error', error);
                      setMsg("Erro ao enviar os seus dados!");
                      setError(true);
                    });
                  }).catch((error) => {
                    console.log('error', error);
                    setMsg("Erro ao enviar os seus dados!");
                    setError(true);
                  });

                }).catch((error) => {
                  console.log('error', error);
                  setMsg("Erro ao enviar os seus dados!");
                  setError(true);
                });
              }).catch((error) => {
                console.log('error', error);
                setMsg("Erro ao enviar os seus dados!");
                setError(true);
              });
            }).catch((error) => {
              console.log('error', error);
              setMsg("E-mail já cadastrado! Entre em contato conosco");
              setError(true);
            });
        }
      }
    } else {
      setMsg("É necessário preeencher todos os campos!");
      setError(true);
    }
  }

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    setMsg("");
    setError(false);
  }

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phoneMask(phone));
    setMsg("");
    setError(false);
  }

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setMsg("");
    setError(false);
  }

  const onChangeMessage = (e) => {
    const msg = e.target.value;
    setMessage(msg);
    setMsg("");
    setError(false);
  }


  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <Container>
              <Header>
              <a href="/login"><Logo src={logo} alt="iGrown" /></a>
              </Header>
              <Label>Continue o seu cadastro</Label>
              <Card>
                <Group top="30px">
                  <FormControl sx={{ m: 1.2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField label="Nome completo" variant="outlined" size="small" value={name} onChange={onChangeName} color="info" disabled={false} />
                  </FormControl>
                  <FormControl sx={{ m: 1.2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField label="Celular" variant="outlined" size="small" color="info" onChange={onChangePhone} value={phone} disabled={false} />
                  </FormControl>
                  <FormControl sx={{ m: 1.2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField label="E-mail corporativo" variant="outlined" value={email} onChange={onChangeEmail} size="small" color="info" disabled={false} />
                  </FormControl>
                  <FormControl sx={{ m: 1.2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField label="Mensagem" variant="outlined" size="small" onChange={onChangeMessage} value={message} color="info" disabled={false}
                      multiline
                      rows={4}
                      maxRows={4} />
                  </FormControl>
                </Group>
                <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>{msg}</ErrorMessage>
                <Group>
                  <Button
                    type="button"
                    width={width > 530 ? "600px" : "320px"}
                    hoverColor={colors.lightBlue}
                    text="Avançar"
                    onClick={onNext}
                  />
                </Group>
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  )
}

export default LegalPerson;
