import React, { useState } from 'react';
import Modal from "react-modal";

//ASSETS
import Finish from './../../assets/img/Finish.svg';
import Activity from './../../assets/img/ic_Activity.svg';

//COMPONENTS
import Button from '../Button';

//STYLES
import colors from '../../styles/colors'
import { ModalContainer, ModalContent, Image, Title, SubLabel, Row, Icon, DescriptionData, Group } from './styles';

Modal.setAppElement("#root");

function TermsModal({
  isOpen,
  onRequestClose,
  width,
  onCheck,
}) {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 570 ? '800px' : '380px',
      minWidth: '450px',
      height: '450px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 9999
    },
  };


  return (
    <ModalContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Title>Termos de Uso</Title>
          <Group>
            <DescriptionData weight="400" color={colors.darkBlue}>É importante que você leia com atenção os presentes Termos e Condições de Uso antes de utilizar o serviço.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Esses Termos e Condições são as regras para a utilização da plataforma iGROWN.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>1 - O QUE É A IGROWN?</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN é uma plataforma de aceleração de carreiras, desenvolvida para ajudar pessoas físicas ou jurídicas (USUÁRIOS CONTRATANTES), mais especificamente por meio de assessment (avaliação) com diagnóstico de perfil, cultura e âncora de carreira, viabilizando identificação de talentos e acelerando seu desenvolvimento profissional.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A nossa plataforma é disponibilizada mediante o pagamento de um valor mensal (mensalidade), de acordo com os planos e condições disponíveis na apresentação comercial.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Vale mencionar que o valor dos planos mensais poderá ser reajustado anualmente. Antes destes ajustes anuais, será enviado um e-mail comunicando as mudanças dos valores aos USUÁRIOS.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Os Termos de Uso nada mais são do que um contrato entre as partes envolvidas nos serviços disponibilizados através da plataforma iGROWN. Assim, neste documento são apresentados todos aqueles envolvidos com a prestação dos serviços; bem como são apresentadas as responsabilidades, obrigações e os deveres de cada uma das partes.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Os Termos de Uso são um tipo de contrato de adesão, desse modo, ao acessar ou utilizar a plataforma iGROWN os contratantes e os usuários COLABORADORs estarão de acordo com as regras apresentadas.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A concordância em relação às normas deverá ser ratificada antes de concluída uma operação de cadastro. De qualquer maneira, aconselhamos que esse documento seja lido com atenção antes de utilizar a plataforma, bem como seja revisitado de tempos em tempos.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Informamos também que este documento pode ser eventualmente alterado. A data da última atualização ficará sempre disponibilizada e os contratantes e usuários/colaboradores receberão uma comunicação por e-mail acerca das alterações realizadas.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>2 - QUAIS SÃO OS DIFERENTES TIPOS DE USUÁRIOS?</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	USUÁRIOS: se refere tanto ao USUÁRIO CONTRATANTE, quanto ao USUÁRIO COLABORADOR.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	USUÁRIO CONTRATANTE: o usuário, pessoa física ou jurídica, que realiza a contratação de um dos planos da iGROWN e que terá acesso à plataforma e todas as suas funcionalidades;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	USUÁRIO COLABORADOR: são os colaboradores do USUÁRIO CONTRATANTE (quando pessoa jurídica), e que também terá acesso à nossa plataforma.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>3 - COMO FUNCIONA A PLATAFORMA iGROWN?</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Os USUÁRIOS ficam cientes de que o fluxo completo do serviço disponibilizado pela iGROWN consiste nas fases relacionadas nos itens abaixo e que a conclusão do cadastro dos USUÁRIOS (sejam eles USUÁRIOS CONTRATANTES, sejam USUÁRIOS COLABORADORS) dependerá da ratificação de concordância quanto às condições dos Termos de Uso e da Política de Privacidade.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Vale lembrar, desde já, que os dados pessoais solicitados para a realização do cadastro, bem como a finalidade de coleta desses dados está explicada de maneira detalhada na Política de Privacidade, que poderá ser acessada por meio deste link.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Após escolha do plano, o USUÁRIO CONTRATANTE entrará em contato com um especialista da iGROWN, que o conduzirá pelo processo de contratação dos serviços, incluindo a assinatura de Contrato específico, escolha do método de pagamento e demais informações necessárias.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>O USUÁRIO CONTRATANTE poderá realizar um teste da plataforma, limitado às condições expostas no site.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Ressalta-se que, caso o meio de pagamento escolhido seja o cartão de crédito, os dados informados do cartão NÃO são armazenados pelo iGROWN, que se utiliza de provedor de serviços de pagamento denominado xxxxxxx. O iGROWN não será responsabilizado, nem solidário nem subsidiariamente, por qualquer prejuízo ou dano causado ao USUÁRIO CONTRATANTE, caso haja falha no serviço prestado pelo provedor de serviços de pagamento, ou mesmo por qualquer ato intrínseco/inerente a atividade desse agente que, embora lícito, resulte em prejuízo à USUÁRIO CONTRATANTE. Aconselhamos a leitura dos Termos de Uso e da Política de Privacidade desse provedor de serviços de pagamento:- XXXXXXXXXXXXXXXXXXXX .</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Sendo assim, em resumo, o fluxo para contratação da iGROWN e consequente acesso aos serviços e as ferramentas disponibilizadas pela plataforma é o seguinte:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Escolha do plano desejado, de acordo com as opções previstas no site;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Contato com um de nossos especialistas;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Negociação e assinatura de Contrato; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Cadastro e disponibilização da plataforma.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Importante ressaltar que a disponibilização de acesso à plataforma iGROWN para os USUÁRIO COLABORADORES do USUÁRIO CONTRATANTE é de opção, escolha e responsabilidade do mesmo, estando a iGROWN completamente isenta de qualquer responsabilidade nesse sentido.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>4 - DAS CONSIDERAÇÕES GERAIS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN atua no processo como ferramenta de comodidade ao USUÁRIO CONTRATANTE, mas sem nenhuma participação na execução das ferramentas e dispositivos disponibilizados na plataforma.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Nesse sentido, a iGROWN e seus funcionários, representantes e procuradores se eximem de qualquer responsabilidade em relação ao manejo das ferramentas e dos dispositivos disponibilizados aos USUÁRIOS, seja a que título e a que tempo for. A iGROWN, portanto, atua somente como intermediadora no acesso a essas  ferramentas e dispositivos.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Portanto, tanto a iGROWN quanto os seus funcionários, representantes e procuradores, não respondem em nenhuma hipótese por pagamento total ou parcial dos eventuais prejuízos resultantes do manejo das ferramentos e dos dispositivos disponibilizados aos USUÁRIOS.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN desempenha atividades-meio com relação aos USUÁRIOS CONTRATANTE. Portanto, não existe qualquer responsabilidade da iGROWN, de ordem objetiva ou subjetiva, pelo uso que o USUÁRIO CONTRATANTE e/ou o USUÁRIO COLABORADOR fará das ferramentas e dos dispositivos disponibilizados para desenvolvimento de treinamentos gamificados.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Ficando desde já estipulado que a iGROWN não garante de forma alguma que as ferramentos e os dispositivos disponibilizados funcionarão livres de erros, interrupções, mal funcionamentos, atrasos ou outras imperfeições.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>É importante mencionar que os serviços da iGROWN estão disponíveis apenas para as pessoas que tenham capacidade legal para contratá-los. Não podem utilizá-los, assim, pessoas que não gozem dessa capacidade, inclusive menores de idade (18 anos), ou pessoas que tenham sido inabilitadas temporária ou definitivamente.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Por estes Termos de Uso, o USUÁRIO CONTRATANTE autoriza receber contato por parte da iGROWN, para eventuais esclarecimentos acerca exclusivamente das ferramentas e dos dispositivos disponibilizados, seja por e-mail ou telefone; todavia, fica desde já convencionado que a iGROWN nunca solicitará, via e-mail, confirmação ou complementação de dados cadastrais e/ou financeiros do USUÁRIO CONTRATANTE ou do USUÁRIO COLABORADOR. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Sendo assim, qualquer e-mail nesse sentido deverá ser entendido como fora dos padrões da iGROWN e, consequentemente, denunciado a iGROWN pelos USUÁRIOS.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN não é conivente com a prática de envio de spams, e só enviará e-mails aos USUÁRIOS com o seu consentimento ou que sejam indispensáveis e relacionados à Plataforma.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Pertencem a iGROWN os direitos de uso e propriedade de seu material, não sendo permitida sua reprodução, transmissão e distribuição, por qualquer que seja a finalidade, sem o expresso consentimento do seu titular.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>O uso indevido de material pertencente a iGROWN, protegido por propriedade intelectual (direito autoral, marcas comerciais, etc.), sujeita o infrator às medidas judiciais cabíveis à espécie, sem prejuízo de eventual indenização por perdas e danos e honorários advocatícios.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>5 - OS DIREITOS E DAS OBRIGAÇÕES DOS USUÁRIOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Não utilizar a iGROWN com qualquer propósito ilegal ou que não atenda a critérios de veracidade;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Não cadastrar ou transmitir quaisquer tipos de informações que não sejam verdadeiras ou não pertençam ao USUÁRIO ou que induzam a erro tanto a iGROWN quanto à parte a qual as informações se destinam;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Não violar a privacidade do site e do aplicativo iGROWN ou de outros USUÁRIOS;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Não enviar ou transmitir arquivos que contenham qualquer vírus, worms, malware ou outros programas de computador que possam causar danos ao site ou ao destinatário;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Não utilizar falsos endereços de computadores, de rede ou de correio eletrônico;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Não utilizar os serviços disponíveis na plataforma iGROWN para fins diversos daqueles a que se destinam.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Não utilizar nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades e operações do site, bem como nas hospedagens de ofertas, descrições ou seus bancos de dados. Qualquer intromissão ou atividade que viole ou contrarie as leis e/ou os presentes Termos de Uso tornará o responsável passível as ações legais pertinentes, bem como às sanções previstas, sendo ainda o mesmo responsável pelas indenizações em razão dos danos causados.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>6 - AS RESPONSABILIDADES </DescriptionData>
            <DescriptionData color={colors.darkBlue}>DO USUÁRIO CONTRATANTE:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Cuidar das informações de sua identificação individual, informando-as em operações na internet apenas onde constar o identificador de proteção de dados;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Fornecer informações verídicas e de sua titularidade; </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Responder por quaisquer tipos de informação ou de afirmação originadas com seus dados pessoais de acesso ao site;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Manter em sigilo a sua identificação de USUÁRIO (nome e senha);</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Configurar seu equipamento de forma a protegê-lo de eventuais invasões que resultem em propagação de vírus;</DescriptionData>
            <DescriptionData color={colors.darkBlue}>DO USUÁRIO COLABORADOR:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	O USUÁRIO COLABORADOR declara assumir toda a responsabilidade pela veracidade, exatidão e atualidade dos dados inseridos, reconhecendo que qualquer dado ou informação falsa, inverídica, incompleta, desatualizada ou incorreta poderá induzir PLATAFORMA, os USUÁRIOS CONTRATANTES bem como as ferramentos e os dispositivos disponibilizados, a erro;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	O USUÁRIO COLABORADOR será responsável por proteger os dados de acesso de sua Conta, incluindo, mas não se limitando, ao seu login e senha de acesso, sendo o único responsável pela divulgação dos dados de acesso aos seus empregados e/ou a terceiros e, consequentemente, pela visualização do Conteúdo por terceiros não autorizados pela Plataforma iGROWN; e</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	O USUÁRIO COLABORADOR terá a opção de anexar e disponibilizar certificados, diplomas, certidões verdadeiros, bem como fotos verdadeiras para o perfil profissional.</DescriptionData>
            <DescriptionData color={colors.darkBlue}></DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>DA iGROWN:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Verificar e solucionar eventuais reclamações referentes as ferramentos e aos dispositivos disponibilizados na plataforma;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Fornecer aos USUÁRIOS instruções acerca dos usos das ferramentos e dos dispositivos disponibilizados;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	O iGROWN empreenderá esforços comercialmente razoáveis para tornar a plataforma iGROWN disponíveis, no mínimo, 99,7% (noventa e nove vírgula sete por cento) durante cada Ano de Serviço (conforme definido abaixo) (“Compromisso de Nível de Serviço”).</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Vale ressaltar que o compromisso de nível de serviço não se aplica caso as circunstâncias de indisponibilidade resultem:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>(i) de uma interrupção do fornecimento de energia elétrica ou paradas emergenciais não superiores a 2 (duas) horas ou que ocorram no período das 24h às 06h00min (horário de Brasília); </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>(ii) forem causadas por fatores que fujam ao razoável controle da iGROWN, inclusive casos de força maior ou de acesso à Internet e problemas correlatos; </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>(iii) resultem de quaisquer atos ou omissões dos USUÁRIOS ou de terceiros; </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>(iv) resultem do equipamento, software ou outras tecnologias que a iGROWN usar que impeçam o acesso regular da PLATAFORMA iGROWN; </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>(v) resultem de falhas de instâncias individuais não atribuíveis à indisponibilidade do USUÁRIO; e </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>(vi) resultem de práticas de gerenciamento da rede que possam afetar sua qualidade.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>7 - DO CANCELAMENTO DOS PLANOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Ao realizar a contratação da plataforma iGROWN, o USUÁRIO CONTRATANTE entende que seu plano se renovará automaticamente, seja ele trimestral ou anual.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Portanto, caso deseje realizar o cancelamento de seu plano, o USUÁRIO CONTRATANTE deverá entrar em contato com a iGROWN, por meio do e-mail ouvidoria@igrown.com.br, informando que deseja cancelar o seu plano.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Consequentemente, a renovação automática será cancelada e o plano continuará vigente até o fim do período contratado, por exemplo:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Se você tiver contratado um plano trimestral, o sistema ficará liberado até o final do terceiro mês e depois será desativado automaticamente conforme sua solicitação.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	Se você tiver contratado um plano anual, o sistema ficará liberado pelos doze meses já contratados e depois será desativado automaticamente conforme sua solicitação.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Ou seja, o valor do plano independe da quantidade ou intensidade de acessos e uso por parte do USUÁRIO CONTRATANTE.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Caso você seja um novo contratante, ou seja, um USUÁRIO CONTRATANTE realizando a contratação da iGROWN pela primeira vez, você terá o prazo de 7 (sete) dias, desde a contratação, para solicitar o cancelamento com reembolso total dos valores já pagos e, eventualmente, das parcelas futuras.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Caso durante a vigência do plano contratado o USUÁRIO CONTRATANTE deseje cancelar a contratação, será aplicada uma multa no valor de xx%, calculado sobre o valor residual do plano.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>As disposições aqui previstas acerca do cancelamento poderão não se aplicar em determinadas promoções. Sendo assim, caso você realize a contratação da iGROWN por uma promoção, solicitamos que você verifique o regulamento da promoção, que poderá conter disposições específicas quanto ao cancelamento de seu plano.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Por fim, nós, da iGROWN, faremos de tudo para mantê-lo como cliente. Assim, sugerimos entrar em contato conosco para entendermos melhor o seu caso e orientá-lo ou ajudá-lo caso seja necessário.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>8 - DA LICENÇA DE USO CONCEDIDA AOS USUÁRIOS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A partir do primeiro acesso e cadastro na Plataforma, a iGROWN outorga ao USUÁRIO uma licença de uso, em caráter não exclusivo, temporário, intransferível e revogável para utilização da Plataforma.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Os USUÁRIOS, desde já, reconhecem que qualquer cópia ou uso não autorizado da Plataforma ou do conteúdo lá disponibilizado constitui uma violação destes Termos é um ato terminantemente proibido, podendo ser responsabilizado por eventuais perdas e danos, além de outras indenizações e medidas legais cabíveis.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Os requisitos para acesso e utilização da PLATAFORMA poderão ser alterados a qualquer momento e por qualquer motivo, incluindo, mas não se limitando à atualizações tecnológicas. Os USUÁRIOS concordam e reconhecem que, caso não tenha todos os requisitos de sistema e/ou não atualize o seu dispositivo utilizado para acessar a Plataforma, o acesso e/ou utilização da Plataforma poderão ser afetados e/ou cancelados.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>9 - DA RELAÇÃO DE CONFIDENCIALIDADE ENTRE OS USUÁRIOS E A iGROWN</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN e os USUÁRIOS obrigam-se a garantir o sigilo sobre qualquer informação técnica, comercial, profissional, pessoal e estratégica da outra Parte, oriundas de cadastros e da utilização das ferramentos e dos dispositivos disponibilizados na plataforma.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Todas e quaisquer informações, dados, referências, condições comerciais, questões de ordem interna e/ou externa de cada parte, aspectos societários, econômicos, de execução de trabalhos, de posição mercadológica, de estrutura organizacional e/ou demais informações que forem reveladas de uma parte à outra concernentes às suas próprias estruturas ou da outra, serão tratadas por estas em caráter de mais absoluto sigilo e confidencialidade, abstendo-se qualquer parte de realizar a terceiros qualquer divulgação e/ou uso dessas informações, por menores que sejam, obrigando-se a não ceder, transferir, divulgar, fornecer, emprestar e/ou veicular, por quaisquer meios, todo o conteúdo que lhe seja disponibilizado, salvo em relação às previsões de uso descritas na Política de Privacidade.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>“Informações confidenciais” significam os dados confidenciais e/ou as informações desenvolvidas ou adquiridas pela iGROWN ou pelos USUÁRIOS, e cuja divulgação, por qualquer das partes é vedada taxativamente, a menos que expressamente autorizada pela outra parte. Vale pontuar nesse ponto que a Política de Privacidade prevê a possibilidade de divulgação de dados adquiridos através da plataforma, com a finalidade de produção de marketing, desde que de maneira anônima e desvinculada da identidade dos USUÁRIOS. </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A iGROWN e os USUÁRIOS serão individualmente responsáveis pelo descumprimento, por seus agentes, empregados ou contratados, da obrigação de confidencialidade, sujeitando-se à indenização de todos os danos decorrentes para a outra parte, com a ressalva do disposto abaixo.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>A divulgação de informações confidenciais só não gerará a indenização prevista, quando:</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	a parte comprovar que a informação já era do seu conhecimento, antes de ser recebida da outra parte, não tendo por isso a obrigatoriedade de mantê-la confidencial;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	a informação tiver sido legalmente obtida de terceiros, sem infringência de qualquer das disposições deste contrato;</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	a informação está ou se tornou publicamente disponível de outra forma, que não em decorrência de qualquer ato ou omissão das partes ou seus prepostos; </DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>⦁	ocorrer na forma prevista na Política de Privacidade.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>10 - DAS DISPOSIÇÕES FINAIS</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>O USUÁRIO que violar os presentes Termos e Condições de Uso, será notificado a cessar a prática irregular, sem prejuízo das cominações legais que tais irregularidades venham a dar causa.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>O descumprimento das condições e observações destes Termos e Condições de Uso dá à iGROWN o direito de cancelar, suspender, excluir e/ou desativar o cadastro do USUÁRIO, temporária ou definitivamente, ao seu único e exclusivo critério, sem prejuízo das cominações legais pertinentes e sem a necessidade de comunicar ao USUÁRIO a ação tomada dentro do seu sistema.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>O uso comercial da expressão “iGROWN” como marca, nome empresarial ou nome de domínio, bem como os conteúdos das telas relativas aos serviços, programas, bancos de dados, redes e arquivos, que permitem que o USUÁRIO acesse e use sua conta, são propriedade da iGROWN SERVIÇOS DE INFORMÁTICA LTDA e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo a autorização expressa.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Considerando que a concretização do cadastro está vinculada à anuência às condições do Termo e Condições de Uso para se efetivar, fica entendido que o visitante ou USUÁRIO leu e concordou com as condições previstas nestes Termos, em nada prejudicando que o mesmo seja alterado a qualquer momento pela iGROWN, caso haja necessidade de adaptá-lo a novos fatos.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Por isso, solicitamos a leitura periódica deste Termo, como meio de se cientificar das responsabilidades, deveres e obrigações que o visitante ou USUÁRIO assume ao utilizar a iGROWN.</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>As partes convencionam o recebimento de mensagens e arquivos eletrônicos como prova documental para todos os efeitos, desde que com aviso de confirmação de recebimento ou outro tipo de protocolo que certifique o recebimento da comunicação.</DescriptionData>
            <DescriptionData color={colors.darkBlue}>11 - DO FORO</DescriptionData>
            <DescriptionData weight="400" color={colors.darkBlue}>Fica eleito o foro de Curitiba, Paraná, como competente para dirimir quaisquer controvérsias decorrentes destes Termos de Uso, independentemente de qualquer outro, por mais privilegiado que seja ou venha a ser.</DescriptionData>
          </Group>
          {width > 570 ?
            <Row top="0%">
              <Button
                type="button"
                width="300px"
                fontSize="13px"
                text="Ler depois"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="400px"
                fontSize="13px"
                text={"Aceito os Termos de Uso"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </Row>
            :
            <>
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text="Ler depois"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text={"Aceito os Termos de Uso"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </>
          }
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default TermsModal;


