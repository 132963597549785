import React from 'react';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import './../../../styles/SidebarConfig.css';
import { cnpjMask } from './../../../utils/cnpjMask.js';

const MenuCompany = (prop) => {

    let icon = prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;

    return (
        <Slide right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <GroupLine>
                <Title>{prop.title}</Title>
            </GroupLine>
            <Container>
                <Group>
                    <TextField label="Nome fantasia" variant="outlined" size="small" value={prop.data.nome_fantasia} color="info" disabled={true} />
                </Group>
                <Group>
                    <TextField label="Razão social" variant="outlined" size="small" value={prop.data.razao_social} color="info" disabled={true} />
                </Group>
                <Group>
                    <TextField label="CNPJ" variant="outlined" size="small" value={cnpjMask(prop.data.cnpj)} color="info" disabled={true} />
                </Group>
                <Group>
                    <TextField label="Status" variant="outlined" size="small" value={prop.data.status == 'S' ? 'Ativo' : "Inativo"} color="info" disabled={true} />
                </Group>
                <Group>
                    <TextField label="Plano contratado" variant="outlined" size="small" value={prop.data.nome_plano} color="info" disabled={true} />
                </Group>
                {/* <Card
                    width="230px"
                    bgColor={colors.yellow}
                    title='NPS'
                    description='teste comportamental'
                    value='68%'
                    right
                    left
                    paddingLabel={"0%"}
                />
                <Card
                    width="230px"
                    bgColor={colors.lightGreen}
                    title='NPS'
                    description='teste cultura'
                    value='20%'
                    paddingLabel={"0%"}
                />
                <Card
                    width="230px"
                    bgColor={colors.alertRed}
                    title='NPS'
                    description='teste âncora de carreira'
                    value='88%'
                    paddingLabel={"0%"}
                /> */}
                <Button
                    type="button"
                    width="230px"
                    hoverColor={colors.lightBlue}
                    text="Ok, entendi"
                    fontSize="12px"
                    onClick={prop.onClose}
                />
            </Container>
        </Slide>
    )
}

export default MenuCompany;
