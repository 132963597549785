import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

import colors from '../../styles/colors';
import { Btn, BtnRow } from './styles';

const Button = ({
  bgColor,
  color,
  width,
  hoverColor,
  margin,
  hoverBorder,
  onClick,
  border,
  className,
  disabled,
  isLoading,
  text,
  loadingText,
  fontSize,
  height,
  icon,
  id,
  key
}) => {
  return (
    <Btn
      type="submit"
      bgColor={bgColor}
      id={id}
      key={key}
      color={color}
      height={height}
      width={width}
      hoverColor={hoverColor}
      margin={margin}
      fontSize={fontSize}
      hoverBorder={hoverBorder}
      onClick={onClick}
      border={border}
      className={className}
      disabled={disabled}
      text={text}
      loadingText={loadingText}
      isLoading={isLoading}
    >
      {icon ? icon : ""}
      {!isLoading && (
        <span>{text}</span>
      )}
      {isLoading && (
        <BtnRow>
          {/* <span>{loadingText}</span> */}
          <ThreeDots
            height="30"
            width="30"
            radius="9"
            color={colors.gray5}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </BtnRow>
      )}
    </Btn>
  )
}

export default Button;
