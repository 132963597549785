import React from 'react';
import Context from './Context';
import useStorage from '../../utils/useStorage';

const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  const [loginHash, setLoginHash] = useStorage('loginHash');
  const [userGlobal, setUserGlobal] = useStorage('userGlobal');
  const [uuidSession, setUuidSession] = useStorage('uuidSession')

  return (
    <Context.Provider
      value={{
        token,
        setToken,
        userGlobal,
        setUserGlobal,
        loginHash,
        setLoginHash,
        uuidSession,
        setUuidSession,
      }}
    >
      {children}
    </Context.Provider>
  )
}


export default StoreProvider;
