import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import logo from './../../assets/img/logo.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import TestsModal from './../../components/TestsModal';
import { validEmail } from '../../utils/validEmail';
import { useLocation } from 'react-router';
import ErrorModal from './../../components/ErrorModal';
import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import StoreContext from '../../components/Store/Context';
import api from '../../api';

import {
  Container,
  Header,
  Logo,
  Card,
  SubTitle,
  Title,
  GpButton,
  Group,
  DescriptionGroup,
  GpForm,
  SubLabel,
  Description,
  Line,
  Step,
  ContainerButton,
  InputSmaller,
  ErrorMessage
} from './styles';

const StartCulture = () => {

  const defaultTheme = createMuiTheme();

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: colors.lightBlue,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: colors.lightBlue,
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const { token, setToken } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [value, setValue] = useState('');
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [msg, setMsg] = useState("");
  const [step, setStep] = useState(1);
  const [time, setTime] = useState(0);
  const [resp, setResp] = useState([]);
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(async () => {
    setWidth(window.screen.width);
    getTests();

  }, [width]);

  const onBack = () => {
    step == 1 ?
      history.push({
        pathname: '/',
      })
      :
      resp.length = resp.length - 1;
    setStep(step - 1);
  }


  const onNext = async (event) => {
    const value = event.target.value;
    setMsg("");
    resp.push(value)
    // if (step == 1) {
    //   if (value == "A mim mesmo (autoteste)") {
    //     await setStep(step + 2);
    //   } else if (value == "Amigo") {
    //     await setType("Amigo");
    //     await setStep(step + 1);
    //   } else if (value == "Empresa") {
    //     await setType("Empresa");
    //     await setStep(step + 1);
    //   }
    // } else if (step == 2) {
    //   if (type == "Amigo") {
    //     if (email.length > 0) {
    //       if (!validEmail(email)) {
    //         setError(true);
    //         setMsg("E-mail inválido! Digite um e-mail válido!");
    //       } else {
    //         await setStep(step + 1);
    //       }
    //     } else {
    //       setError(true);
    //       setMsg("É necessário preencher todos os campos!");
    //     }
    //   } else {
    //     if (company.length > 0) {
    //       await setStep(step + 1);
    //     } else {
    //       setError(true);
    //       setMsg("É necessário preencher todos os campos!");
    //     }
    //   }
    // } else {
      if(step != 10){
        await setStep(step + 1);
      }

    // }
  }

  const handleEmail = async (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setError(false);
    setMsg("");
  }

  const onCloseError = () => {
    setError(false);
  }

  const handleCompany = async (e) => {
    e.preventDefault();
    setCompany(e.target.value);
    setError(false);
    setMsg("");
  }

  const onFinish = async () => {
    await setTime(4);
    await setModal(true);
  }

  const sendTest = async () => {
    const body = {
      "lang": "pt-br",
      "email": token.email,
      "data": new Date(),
      "perfil": resp[0],
      "email_amigo": email,
      "empresa": company,
      "Cultura1": resp[1],
      "Cultura2": resp[2],
      "Cultura3": resp[3],
      "Cultura4": resp[4],
      "Cultura5": resp[5],
      "Cultura6": resp[6],
      "Cultura7": resp[7],
      "Cultura8": resp[8],
      "Cultura9": resp[9],
      "Cultura10": resp[10]
    }

    await api.post('/cultural-test', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      onFinish();
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const startTest = async () => {
    const route = !data.comportamental && !data.cultural && !data.valores ? '/testeComportamental'
      : !data.comportamental && !data.cultural && data.valores ? '/testeComportamental'
        : data.comportamental && data.cultural && !data.valores ? '/testeCarreira'
          : !data.comportamental && data.cultural && data.valores ? '/testeComportamental'
            : data.comportamental && !data.cultural && data.valores ? '/testeCultura'
              : !data.comportamental && data.cultural && !data.valores ? '/testeComportamental' : '/testeCarreira'

    history.push({
      pathname: route,
      state: {
        vacancy: location.state.vacancy
      }
    });
  }


  const getTests = async () => {
    const body = {
      "email": token.email
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      await setData(data)
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const startReport = async () => {
    history.push({
      pathname: '/',
    });
  }

  const onCloseModal = async () => {
    await setModal(false);
  }

  // const step1 = <Group>
  //   <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 1 de 11</SubLabel>
  //   <Title>Quem você irá avaliar?</Title>
  //   <DescriptionGroup>
  //     <Step active><p>1</p></Step>
  //     <Step><p>2</p></Step>
  //     <Step><p>3</p></Step>
  //     <Step><p>4</p></Step>
  //     <Step><p>5</p></Step>
  //     <Step><p>6</p></Step>
  //     <Step><p>7</p></Step>
  //     <Step><p>8</p></Step>
  //     <Step><p>9</p></Step>
  //     <Step><p>10</p></Step>
  //     <Step><p>11</p></Step>
  //   </DescriptionGroup>
  //   <DescriptionGroup>
  //     <FormControl>
  //       <RadioGroup
  //         defaultValue=""
  //         aria-labelledby="demo-customized-radios"
  //         name="customized-radios"
  //       >
  //         <Card>
  //           <FormControlLabel value="A mim mesmo (autoteste)" control={<BpRadio />} onChange={onNext} label="A mim mesmo (autoteste)" />
  //         </Card>
  //         <Card>
  //           <FormControlLabel value="A um amigo ou colega" control={<BpRadio />} onChange={onNext} label="A um amigo ou colega" />
  //         </Card>
  //         <Card>
  //           <FormControlLabel value="A minha empresa ou instituição" control={<BpRadio />} onChange={onNext} label="A minha empresa ou instituição" />
  //         </Card>
  //       </RadioGroup>
  //     </FormControl>
  //   </DescriptionGroup>
  //   <Line />
  //   < GpButton>
  //     <Button
  //       type="button"
  //       width="80px"
  //       fontSize="13px"
  //       text="Voltar"
  //       color={colors.lightBlue}
  //       bgColor={colors.transparent}
  //       onClick={onBack}
  //     />

  //   </ GpButton>
  // </Group>


  // const step2 = type == "Amigo" ?
  //   <Group>
  //     <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 1 de 11</SubLabel>
  //     <DescriptionGroup top="1.5%">
  //       <Step active><p>1</p></Step>
  //       <Step><p>2</p></Step>
  //       <Step><p>3</p></Step>
  //       <Step><p>4</p></Step>
  //       <Step><p>5</p></Step>
  //       <Step><p>6</p></Step>
  //       <Step><p>7</p></Step>
  //       <Step><p>8</p></Step>
  //       <Step><p>9</p></Step>
  //       <Step><p>10</p></Step>
  //       <Step><p>11</p></Step>
  //     </DescriptionGroup>
  //     <SubTitle>Informe o e-mail do seu amigo ou colega:</SubTitle>
  //     <GpForm>
  //       <InputSmaller autoFocus>
  //         <input type="text" value={email} name="email" placeholder="E-mail do seu amigo ou colega" onChange={handleEmail} />
  //       </InputSmaller>
  //       <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>{msg}</ErrorMessage>
  //       <Description color={colors.darkBlue}>Confirme com o seu amigo ou colega o e-mail utilizado por ele para {"\n"}acesso ao iGrown para que ele receba o relatório corretamente.</Description>
  //     </GpForm>
  //     <Line />
  //     < ContainerButton>
  //       <Button
  //         type="button"
  //         width="80px"
  //         fontSize="13px"
  //         text="Voltar"
  //         color={colors.lightBlue}
  //         bgColor={colors.transparent}
  //         onClick={onBack}
  //       />
  //       <Button
  //         type="button"
  //         width="140px"
  //         fontSize="13px"
  //         text="Enviar"
  //         hoverColor={colors.lightBlue}
  //         color={colors.white}
  //         bgColor={colors.lightBlue}
  //         onClick={onNext}
  //       />
  //     </ ContainerButton>
  //   </Group>
  //   :
  //   <Group>
  //     <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 1 de 11</SubLabel>
  //     <DescriptionGroup top="1.5%">
  //       <Step active><p>1</p></Step>
  //       <Step><p>2</p></Step>
  //       <Step><p>3</p></Step>
  //       <Step><p>4</p></Step>
  //       <Step><p>5</p></Step>
  //       <Step><p>6</p></Step>
  //       <Step><p>7</p></Step>
  //       <Step><p>8</p></Step>
  //       <Step><p>9</p></Step>
  //       <Step><p>10</p></Step>
  //       <Step><p>11</p></Step>
  //     </DescriptionGroup>
  //     <SubTitle>Informe o nome da empresa:</SubTitle>
  //     <GpForm>
  //       <InputSmaller autoFocus>
  //         <input type="text" value={company} name="company" placeholder="Nome da empresa" onChange={handleCompany} />
  //       </InputSmaller>
  //       <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>{msg}</ErrorMessage>
  //       <Description color={colors.darkBlue}>Informe a empresa a qual irá avaliar a cultura corporativa.</Description>
  //     </GpForm>
  //     <Line />
  //     < ContainerButton>
  //       <Button
  //         type="button"
  //         width="80px"
  //         fontSize="13px"
  //         text="Voltar"
  //         color={colors.lightBlue}
  //         bgColor={colors.transparent}
  //         onClick={onBack}
  //       />
  //       <Button
  //         type="button"
  //         width="140px"
  //         fontSize="13px"
  //         text="Enviar"
  //         hoverColor={colors.lightBlue}
  //         color={colors.white}
  //         bgColor={colors.lightBlue}
  //         onClick={onNext}
  //       />
  //     </ ContainerButton>
  //   </Group >


  const step1 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 1 de 10</SubLabel>
    <Title>Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
      <Step active><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Abertura" control={<BpRadio />} onChange={onNext} label="Abertura" />
          </Card>
          <Card>
            <FormControlLabel value="Colaboração" control={<BpRadio />} onChange={onNext} label="Colaboração" />
          </Card>
          <Card>
            <FormControlLabel value="Decisão" control={<BpRadio />} onChange={onNext} label="Decisão" />
          </Card>
          <Card>
            <FormControlLabel value="Comunicação" control={<BpRadio />} onChange={onNext} label="Comunicação" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step2 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 2 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
      <Step ><p>1</p></Step>
      <Step active><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Dominancia" control={<BpRadio />} onChange={onNext} label="Dominância" />
          </Card>
          <Card>
            <FormControlLabel value="Agilidade" control={<BpRadio />} onChange={onNext} label="Agilidade" />
          </Card>
          <Card>
            <FormControlLabel value="Confianca" control={<BpRadio />} onChange={onNext} label="Confiança" />
          </Card>
          <Card>
            <FormControlLabel value="Obediência" control={<BpRadio />} onChange={onNext} label="Obediência" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step3 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 3 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step active><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Ousadia" control={<BpRadio />} onChange={onNext} label="Ousadia" />
          </Card>
          <Card>
            <FormControlLabel value="Pertencimento" control={<BpRadio />} onChange={onNext} label="Pertencimento" />
          </Card>
          <Card>
            <FormControlLabel value="Regras" control={<BpRadio />} onChange={onNext} label="Regras" />
          </Card>
          <Card>
            <FormControlLabel value="Aprendizado" control={<BpRadio />} onChange={onNext} label="Aprendizado" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step4 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 4 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step ><p>3</p></Step>
      <Step active><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Política" control={<BpRadio />} onChange={onNext} label="Política" />
          </Card>
          <Card>
            <FormControlLabel value="Exploração" control={<BpRadio />} onChange={onNext} label="Exploração" />
          </Card>
          <Card>
            <FormControlLabel value="Respeito" control={<BpRadio />} onChange={onNext} label="Respeito" />
          </Card>
          <Card>
            <FormControlLabel value="Receptividade" control={<BpRadio />} onChange={onNext} label="Receptividade" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step5 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 5 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step ><p>3</p></Step>
      <Step ><p>4</p></Step>
      <Step active><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Trabalho em equipe" control={<BpRadio />} onChange={onNext} label="Trabalho em equipe" />
          </Card>
          <Card>
            <FormControlLabel value="Inovação" control={<BpRadio />} onChange={onNext} label="Inovação" />
          </Card>
          <Card>
            <FormControlLabel value="Tradição" control={<BpRadio />} onChange={onNext} label="Tradição" />
          </Card>
          <Card>
            <FormControlLabel value="Rapidez de reação" control={<BpRadio />} onChange={onNext} label="Rapidez de reação" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step6 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 6 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step ><p>3</p></Step>
      <Step ><p>4</p></Step>
      <Step ><p>5</p></Step>
      <Step active><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Continuidade" control={<BpRadio />} onChange={onNext} label="Continuidade" />
          </Card>
          <Card>
            <FormControlLabel value="Diversidade" control={<BpRadio />} onChange={onNext} label="Diversidade" />
          </Card>
          <Card>
            <FormControlLabel value="Atingimento de metas" control={<BpRadio />} onChange={onNext} label="Atingimento de metas" />
          </Card>
          <Card>
            <FormControlLabel value="Informalidade" control={<BpRadio />} onChange={onNext} label="Informalidade" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step7 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 7 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step ><p>3</p></Step>
      <Step ><p>4</p></Step>
      <Step ><p>5</p></Step>
      <Step ><p>6</p></Step>
      <Step active><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Idealismo" control={<BpRadio />} onChange={onNext} label="Idealismo" />
          </Card>
          <Card>
            <FormControlLabel value="Construção de capacidade" control={<BpRadio />} onChange={onNext} label="Construção de capacidade" />
          </Card>
          <Card>
            <FormControlLabel value="Criatividade" control={<BpRadio />} onChange={onNext} label="Criatividade" />
          </Card>
          <Card>
            <FormControlLabel value="Cuidado" control={<BpRadio />} onChange={onNext} label="Cuidado" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step8 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 8 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step ><p>3</p></Step>
      <Step ><p>4</p></Step>
      <Step ><p>5</p></Step>
      <Step ><p>6</p></Step>
      <Step ><p>7</p></Step>
      <Step active><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="esponsabilidade social" control={<BpRadio />} onChange={onNext} label="Responsabilidade social" />
          </Card>
          <Card>
            <FormControlLabel value="Estabilidade" control={<BpRadio />} onChange={onNext} label="Estabilidade" />
          </Card>
          <Card>
            <FormControlLabel value="Diversão" control={<BpRadio />} onChange={onNext} label="Diversão" />
          </Card>
          <Card>
            <FormControlLabel value="Execução" control={<BpRadio />} onChange={onNext} label="Execução" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>


  const step9 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 9 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step ><p>3</p></Step>
      <Step ><p>4</p></Step>
      <Step ><p>5</p></Step>
      <Step ><p>6</p></Step>
      <Step ><p>7</p></Step>
      <Step ><p>8</p></Step>
      <Step active><p>9</p></Step>
      <Step><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Foco" control={<BpRadio />} onChange={onNext} label="Foco" />
          </Card>
          <Card>
            <FormControlLabel value="Engajamento" control={<BpRadio />} onChange={onNext} label="Engajamento" />
          </Card>
          <Card>
            <FormControlLabel value="Sustentabilidade" control={<BpRadio />} onChange={onNext} label="Sustentabilidade" />
          </Card>
          <Card>
            <FormControlLabel value="Gestão" control={<BpRadio />} onChange={onNext} label="Gestão" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />

    </ GpButton>
  </Group>

  const step10 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de cultura - Passo 10 de 10</SubLabel>
    <Title> Valorizo mais ambientes onde haja...</Title>
    <DescriptionGroup>
    <Step ><p>1</p></Step>
      <Step ><p>2</p></Step>
      <Step ><p>3</p></Step>
      <Step ><p>4</p></Step>
      <Step ><p>5</p></Step>
      <Step ><p>6</p></Step>
      <Step ><p>7</p></Step>
      <Step ><p>8</p></Step>
      <Step ><p>9</p></Step>
      <Step active><p>10</p></Step>
    </DescriptionGroup>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Sucesso" control={<BpRadio />} onChange={onNext} label="Sucesso" />
          </Card>
          <Card>
            <FormControlLabel value="Realismo" control={<BpRadio />} onChange={onNext} label="Realismo" />
          </Card>
          <Card>
            <FormControlLabel value="Instinto" control={<BpRadio />} onChange={onNext} label="Instinto" />
          </Card>
          <Card>
            <FormControlLabel value="Tolerância" control={<BpRadio />} onChange={onNext} label="Tolerância" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < ContainerButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
      <Button
        type="button"
        width="140px"
        fontSize="13px"
        text="Concluir teste"
        hoverColor={colors.lightBlue}
        color={colors.white}
        bgColor={colors.lightBlue}
        onClick={sendTest}
      />
    </ ContainerButton>
  </Group>

  const step11 = <></>
  const step12 = <></>

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Container>
        <ResponsiveAppBar />
        <ErrorModal isOpen={error} onRequestClose={onCloseError} />
        {/* <HeaderUser width={width} /> */}
        {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
        <TestsModal isOpen={modal} onRequestClose={onCloseModal} minutes={time} test="carreira" startReport={startReport} startTest={startTest} />
        {step == 1 ?
          step1
          : step == 2 ?
            step2
            : step == 3 ?
              step3
              : step == 4 ?
                step4
                : step == 5 ?
                  step5
                  : step == 6 ?
                    step6
                    : step == 7 ?
                      step7
                      : step == 8 ?
                        step8
                        : step == 9 ?
                          step9
                          : step == 10 ?
                            step10
                            : step == 11 ?
                              step11
                              :
                              step12
        }
      </Container>
    </MuiThemeProvider>
  )
}

export default StartCulture;
