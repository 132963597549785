import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Correct from './../../assets/img/Correct.svg';
import X from './../../assets/img/X.svg';
import Carbon from './../../assets/img/Carbon.svg';
import Result from './../../assets/img/Result.svg';
import Like from './../../assets/img/Like.svg';
import NotLike from './../../assets/img/NotLike.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import CardColor from './../../components/Card';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import AlertModal from './../../components/AlertModal';
import ErrorModal from './../../components/ErrorModal';
import moment from 'moment';

import {
  Container,
  Header,
  Logo,
  Image,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  Note,
  DescriptionGroup,
  ContainerButton,
  Group,
  GpImage,
  GpItems
} from './styles';

const CareerReport = () => {

  const { token, setToken } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [report, setReport] = useState([]);
  const [note1, setNote1] = useState(false);
  const [note2, setNote2] = useState(false);
  const [note3, setNote3] = useState(false);
  const [note4, setNote4] = useState(false);
  const [note5, setNote5] = useState(false);
  const [note6, setNote6] = useState(false);
  const [note7, setNote7] = useState(false);
  const [note8, setNote8] = useState(false);
  const [note9, setNote9] = useState(false);
  const [note10, setNote10] = useState(false);
  const [valueNote, setValueNote] = useState(0)
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [comentary, setComentary] = useState("");

  useEffect(async () => {
    getTest();
    setWidth(window.screen.width)
  }, [width]);


  const onSend = async () => {
    const body = {
      "teste_id": report.ID,
      "nps": valueNote,
      "comentario": comentary
    }

    await api.post('/nps', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      setSend(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const handleComentary = async (e) => {
    e.preventDefault();
    setComentary(e.target.value);
  }

  const getTest = async () => {
    const body = {
      "email": token.email
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.valores;
      await setReport(data)
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const handleChangeNote = (value) => {
    setValueNote(value);
    if (value == 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 2) {
      setNote1(false);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 3) {
      setNote1(false);
      setNote2(false);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 4) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 5) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 6) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 7) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 8) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value == 9) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(true);
      setNote10(false);
    } else if (value == 10) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(true);
    }
  };

  const onCloseError = () => {
    setError(false);
  }

  const onCloseAlert = () => {
    setSend(false);
  }

  return (
    <Container>
      <ResponsiveAppBar />
      {/* <HeaderUser width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <ErrorModal isOpen={error}  onRequestClose={onCloseError} />
      <AlertModal isOpen={send} msg="Avaliação enviada com sucesso!" onRequestClose={onCloseAlert} />
      <Title>Relatório de Âncora de Carreira iGrown</Title>
      <ContainerCard top="20%">
        <Row>
          <DescriptionData weight="400">Última atualização em</DescriptionData>
          <DescriptionData>&nbsp;{report ? moment(report.DATA_DA_ENTRADA).format("DD/MM/YYYY") : ""}</DescriptionData>
        </Row>
      </ContainerCard>
      <ContainerCard top="20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Hey, iGrowner!</SubTitle>
            <LabelData font="14px" weight="400">Aqui está o seu </LabelData>
            <LabelData font="14px">
              Relatório de Âncora de Carreira iGrown
            </LabelData>
            <LabelData font="14px" weight="400">
              . O valor de carreira é a diferença entre o conjunto de benefícios (ganhos percebidos) e os custos envolvidos em sua jornada profissional (perdas nesse processo).
            </LabelData>
            <Row>
              <GpImage width="auto">
                <Image width="30px" src={Like} alt="Item" />
                <DescriptionData align="center">Benefícios</DescriptionData>
              </GpImage>
              <LabelData bottom="5%" font="14px">+</LabelData>
              <GpImage width="auto">
                <Image width="30px" src={NotLike} alt="Item" />
                <DescriptionData align="center">Custos</DescriptionData>
              </GpImage>
              <LabelData bottom="5%" font="14px">=</LabelData>
              <GpImage width="auto" top="12px">
                <Image width="30px" src={Result} alt="Item" />
                <DescriptionData align="center">Valores de <br /> carreira</DescriptionData>
              </GpImage>
            </Row>
            <SubTitle font="16px">Nesse relatório, você irá conferir: </SubTitle>
            <DescriptionData font="14px">• Quais são os pontos mais e menos relevantes para você quando falamos de carreira;</DescriptionData>
            <DescriptionData font="14px">• Se estiver trabalhando,  você também verá um balanço de como esses valores são percebidos na sua empresa;</DescriptionData>
            <DescriptionData font="14px">• Por fim, será mostrada a comparação entre o que você espera e o que a sua empresa está entregando;</DescriptionData>
            <DescriptionData bottom="1%" font="14px">Aproveite e #boradecolar!</DescriptionData>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Sobre você</SubTitle>
            <LabelData font="14px" weight="400">
              De acordo com suas respostas, analisamos o grau de importância de cada valor para a sua carreira profissional. Os números variam de 0% a 100% e,
            </LabelData>
            <LabelData font="14px">
              &nbsp;quanto maior, mais importante aquele quesito para você.
            </LabelData>
            <DescriptionData font="14px" weight="400">Conforme mostrado abaixo, os atributos estão divididos em três grandes grupos: estrutura e benefícios, cultura e valores e clima e confiança.</DescriptionData>
            <SubCard top="2%" width="100%" heigth="auto" border={colors.white}>
              <GroupItems padding="2%">
                {width > 530 ?
                  <Row top="0%" align="start">
                    <Group width="35%">
                      <SubTitle>Estrutura e benefícios</SubTitle>
                      <SubTitle font="40px">{report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_RELEVANCIA_COLABORADOR ? report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_RELEVANCIA_COLABORADOR + "%" : ""}</SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Horário de trabalho'
                          value={report.HORARIO_DE_TRABALHO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Local de trabalho'
                          value={report.LOCAL_DE_TRABALHO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Pacote de benefícios'
                          value={report.PACOTE_DE_BENEFICIOS + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Salário'
                          value={report.SALARIO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                    </Group>
                    <Group width="35%">
                      <SubTitle>Cultura e valores</SubTitle>
                      <SubTitle font="40px">{report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR ? report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR + "%" : ""}</SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Abertura para mudanças'
                          value={report.ABERTURA_PARA_MUDANCAS_E_INOVACAO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Celebração de conquista'
                          value={report.CELEBRACAO_DE_CONQUISTAS + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Cultura e valores da empresa'
                          value={report.CULTURA_E_VALORES_DA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Desafios constantes'
                          value={report.DESAFIOS_CONSTANTES + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Gestão clara e acompanhamento dos objetivos'
                          value={report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Oportunidade de desenvolvimento'
                          value={report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Plano de carreira estruturado'
                          value={report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Possibilidade de crescimento'
                          value={report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Processos simples e desburocratizados'
                          value={report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS + "%"}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                    </Group>
                    <Group width="35%">
                      <SubTitle>Clima e confiança</SubTitle>
                      <SubTitle font="40px">{report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR ? report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR + "%" : ""}</SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Autonomia e abertura para se expressar'
                          value={report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR + "%"}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Clima interno'
                          value={report.CLIMA_INTERNO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Confiança na lideranças'
                          value={report.CONFIANCA_NA_LIDERANCA_IMEDIATA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Confiança no time'
                          value={report.CONFIANCA_NO_TIME_DE_TRABALHO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Reconhecimento e valorização'
                          value={report.RECONHECIMENTO_E_VALORIZACAO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Tratamento justo e igualitário'
                          value={report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Visão do futuro da sua empresa'
                          value={report.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Tratamento humanizado'
                          value={report.TRATAMENTO_HUMANIZADO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                    </Group>
                  </Row>
                  :
                  <>
                    <SubTitle>Estrutura e benefícios</SubTitle>
                    <SubTitle font="40px">{report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_RELEVANCIA_COLABORADOR ? report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_RELEVANCIA_COLABORADOR + "%" : ""}</SubTitle>
                    <Grid xs="3">
                      <CardColor
                        width="300px"
                        bgColor={colors.yellow}
                        title='Horário de trabalho'
                        value={report.HORARIO_DE_TRABALHO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.yellow}
                        title='Local de trabalho'
                        value={report.LOCAL_DE_TRABALHO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.yellow}
                        title='Pacote de beneficios'
                        value={report.PACOTE_DE_BENEFICIOS + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.yellow}
                        title='Salário'
                        value={report.SALARIO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                    </Grid>
                    <SubTitle>Cultura e valores</SubTitle>
                    <SubTitle font="40px">{report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR ? report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR + "%" : ""}</SubTitle>
                    <Grid xs="3">
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Autonomia e abertura para se expressar'
                        value={report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR + "%"}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Celebração de conquista'
                        value={report.CELEBRACAO_DE_CONQUISTAS + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Cultura e valores da empresa'
                        value={report.CULTURA_E_VALORES_DA_EMPRESA + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Desafios constantes'
                        value={report.DESAFIOS_CONSTANTES + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Gestão clara e acomp- nhamento dos objetivos'
                        value={report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Oportunidade de desenvolvimento'
                        value={report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Plano de carreira estruturado'
                        value={report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Possibilidade de crescimento'
                        value={report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.lightBlue}
                        title='Processos simples e desburocratizados'
                        value={report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS + "%"}
                        font="12px"
                        progress
                        height="90px"
                      />
                    </Grid>
                    <SubTitle>Clima e confiança</SubTitle>
                    <SubTitle font="40px">{report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR ? report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR + "%" : ""}</SubTitle>
                    <Grid xs="3">
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Autonomia e abertura para mudanças e inovação'
                        value={report.ABERTURA_PARA_MUDANCAS_E_INOVACAO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Clima interno'
                        value={report.CLIMA_INTERNO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Confiança na liderança'
                        value={report.CONFIANCA_NA_LIDERANCA_IMEDIATA + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Confiança no time'
                        value={report.CONFIANCA_NO_TIME_DE_TRABALHO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Reconhecimento e valorização'
                        value={report.RECONHECIMENTO_E_VALORIZACAO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Tratamento justo e igualitário'
                        value={report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Visão do futuro da sua empresa'
                        value={report.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                      <CardColor
                        width="300px"
                        bgColor={colors.alertRed}
                        title='Tratamento humanizado'
                        value={report.TRATAMENTO_HUMANIZADO + '%'}
                        font="12px"
                        progress
                        height="90px"
                      />
                    </Grid>
                  </>
                }
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
      </ContainerCard>

      {String(report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA).length > 0 && report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA != undefined ?
        <ContainerCard top="0%">
          <Card width="100%" heigth="650px">
            <GroupItems>
              <SubTitle>Sobre a empresa que você trabalha</SubTitle>
              <DescriptionData font="14px" weight="400">Nessa segunda etapa do relatório, analisamos como você percebe esse mesmos âncora de carreira na sua empresa. Assim como foi feito anteriormente, cada atributo recebeu uma nota (variando entre 0% e 100%) e, quanto maior esse número, mais você percebe aquele valor presente dentro da organização.</DescriptionData>
              <SubCard top="2%" width="100%" heigth="auto" border={colors.white}>
                <GroupItems padding="2%">
                  {width > 530 ?
                    <Row top="0%" align="start">
                      <Group width="35%">
                        <SubTitle>Estrutura e benefícios</SubTitle>
                        <SubTitle font="40px">{report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_ATENDIMENTO_EMPRESA ? report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_ATENDIMENTO_EMPRESA + "%" : ""}</SubTitle>
                        <Grid xs="3">
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Horário de trabalho'
                            value={report.HORARIO_DE_TRABALHO_DA_SUA_EMPRESA_OU_AREA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Local de trabalho'
                            value={report.SEU_LOCAL_DE_TRABALHO + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Pacote de benefícios'
                            value={report.PACOTE_DE_BENEFICIOS_DA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Salário'
                            value={report.SEU_SALARIO + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <SubTitle>Cultura e valores</SubTitle>
                        <SubTitle font="40px">{report.CULTURA_E_VALORES_PORCENTAGEM_ATENDIMENTO_EMPRESA ? report.CULTURA_E_VALORES_PORCENTAGEM_ATENDIMENTO_EMPRESA + "%" : ""}</SubTitle>
                        <Grid xs="3">
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Abertura para mudanças'
                            value={report.ABERTURA_DA_SUA_EMPRESA_PARA_MUDANCAS_E_INOVACAO + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Celebração de conquista'
                            value={report.CELEBRACAO_DE_CONQUISTAS_PELA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Cultura e valores da empresa'
                            value={report.CULTURA_E_VALORES_DA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Desafios constantes'
                            value={report.DESAFIOS_CONSTANTES_DADOS_PELA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Gestão clara e acompanhamento dos objetivos'
                            value={report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_PELA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Oportunidade de desenvolvimento'
                            value={report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_NA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Plano de carreira estruturado'
                            value={report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_NA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Possibilidade de crescimento'
                            value={report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_NA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Processos simples e desburocratizados'
                            value={report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_DA_SUA_EMPRESA + "%"}
                            font="12px"
                            progress
                            height="90px"
                          />
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <SubTitle>Clima e confiança</SubTitle>
                        <SubTitle font="40px">{report.CLIMA_E_CONFIANCA_PORCENTAGEM_ATENDIMENTO_EMPRESA ? report.CLIMA_E_CONFIANCA_PORCENTAGEM_ATENDIMENTO_EMPRESA + "%" : ""}</SubTitle>
                        <Grid xs="3">
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Autonomia e abertura para se expressar'
                            value={report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Clima interno'
                            value={report.CLIMA_INTERNO_NA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Confiança na lideranças'
                            value={report.CONFIANCA_NA_SUA_LIDERANCA_IMEDIATA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Confiança no time'
                            value={report.CONFIANCA_NO_SEU_TIME_DE_TRABALHO + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Reconhecimento e valorização'
                            value={report.RECONHECIMENTO_E_VALORIZACAO_DO_SEU_TRABALHO + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Tratamento justo e igualitário'
                            value={report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_EM_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Visão do futuro da sua empresa'
                            value={report.VISAO_DO_FUTURO_DA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Tratamento humanizado'
                            value={report.TRATAMENTO_HUMANIZADO_DOS_COLABORADORES_DA_SUA_EMPRESA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                        </Grid>
                      </Group>
                    </Row>
                    :
                    <>
                      <SubTitle>Estrutura e benefícios</SubTitle>
                      <SubTitle font="40px">{report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_ATENDIMENTO_EMPRESA ? report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_ATENDIMENTO_EMPRESA + "%" : ""}</SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Horário de trabalho'
                          value={report.HORARIO_DE_TRABALHO_DA_SUA_EMPRESA_OU_AREA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Local de trabalho'
                          value={report.SEU_LOCAL_DE_TRABALHO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Pacote de beneficios'
                          value={report.PACOTE_DE_BENEFICIOS_DA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Salário'
                          value={report.SEU_SALARIO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                      <SubTitle>Cultura e valores</SubTitle>
                      <SubTitle font="40px">{report.CULTURA_E_VALORES_PORCENTAGEM_ATENDIMENTO_EMPRESA ? report.CULTURA_E_VALORES_PORCENTAGEM_ATENDIMENTO_EMPRESA + "%" : ""}</SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Abertura para mudanças e inovação'
                          value={report.ABERTURA_DA_SUA_EMPRESA_PARA_MUDANCAS_E_INOVACAO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Celebração de conquista'
                          value={report.CELEBRACAO_DE_CONQUISTAS_PELA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Cultura e valores da empresa'
                          value={report.CULTURA_E_VALORES_DA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Desafios constantes'
                          value={report.DESAFIOS_CONSTANTES_DADOS_PELA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Gestão clara e acomp- nhamento dos objetivos'
                          value={report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_PELA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Oportunidade de desenvolvimento'
                          value={report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_NA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Plano de carreira estruturado'
                          value={report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_NA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Possibilidade de crescimento'
                          value={report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_NA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Processos simples e desburocratizados'
                          value={report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_DA_SUA_EMPRESA + "%"}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                      <SubTitle>Clima e confiança</SubTitle>
                      <SubTitle font="40px">{report.CLIMA_E_CONFIANCA_PORCENTAGEM_ATENDIMENTO_EMPRESA ? report.CLIMA_E_CONFIANCA_PORCENTAGEM_ATENDIMENTO_EMPRESA + "%" : ""}</SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Autonomia e abertura para se expressar'
                          value={report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Clima interno'
                          value={report.CLIMA_INTERNO_NA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Confiança na liderança'
                          value={report.CONFIANCA_NA_SUA_LIDERANCA_IMEDIATA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Confiança no time'
                          value={report.CONFIANCA_NO_SEU_TIME_DE_TRABALHO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Reconhecimento e valorização'
                          value={report.RECONHECIMENTO_E_VALORIZACAO_DO_SEU_TRABALHO + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Tratamento justo e igualitário'
                          value={report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_EM_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Visão do futuro da sua empresa'
                          value={report.VISAO_DO_FUTURO_DA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Tratamento humanizado'
                          value={report.TRATAMENTO_HUMANIZADO_DOS_COLABORADORES_DA_SUA_EMPRESA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                    </>
                  }
                </GroupItems>
              </SubCard>
            </GroupItems>
          </Card>
        </ContainerCard>
        :
        <></>}

      {String(report.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA).length > 0 && report.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA != undefined ?

        <ContainerCard top="-10%">
          <Card width="100%" heigth="650px">
            <GroupItems>
              <LabelData font="20px">Valores importantes para você</LabelData>
              <LabelData font="20px" color={colors.lightBlue}>&nbsp;x</LabelData>
              <LabelData font="20px">&nbsp;valores percebidos na sua empresa</LabelData>
              <DescriptionData font="16px">Abaixo, está o índice de valor percebido em sua carreira na empresa em que você trabalha atualmente: </DescriptionData>
              <br />
              <SubCard top="0%" width="100%" heigth="auto" mt="0" pb="0" bottom="0" border={colors.white}>
                <GroupItems padding="0%">
                  {width > 530 ?
                    <Row top="5%" align="initial">
                      <GpItems width="15%" radius="12px 0px 0px 12px" background={colors.lightBlue}>
                        <Icon float="center" width="50px" src={Carbon} alt="Item" />
                        <SubTitle align="center" color={colors.white} font="40px">{report.INDICE_DE_PERCEPCAO_DE_VALOR_DE_CARREIRA_NA_EMPRESA + '%'}</SubTitle>
                        <DescriptionData align="center" color={colors.white} font="14px" weight="400">de valor percebido em sua carreira</DescriptionData>
                      </GpItems>
                      <GpItems width="85%" radius="0px 12px 12px 0px" background={colors.gray5}>
                        <DescriptionData font="14px" weight="400">Para chegarmos até esse número, foi feita uma comparação entre os atributos que são importantes para você e como você enxerga a sua empresa atual. </DescriptionData>
                        <DescriptionData font="14px">Portanto, esse resultado representa o quanto a organização atende os atributos de carreira pesquisados. </DescriptionData>
                        <DescriptionData font="14px" weight="400">Assim como aconteceu anteriormente, a variação é de 0% a 100% e, quanto maior a porcentagem, mais você percebe valor na empresa atual.</DescriptionData>
                      </GpItems>
                    </Row>
                    :
                    <>
                      <GpItems width="100%" radius="12px 12px 0px 0px" background={colors.lightBlue}>
                        < ContainerButton>
                          <Icon float="center" width="50px" src={Carbon} alt="Item" />
                        </ContainerButton>
                        <SubTitle align="center" color={colors.white} font="40px">{report.INDICE_DE_PERCEPCAO_DE_VALOR_DE_CARREIRA_NA_EMPRESA + '%'}</SubTitle>
                        <DescriptionData align="center" color={colors.white} font="14px" weight="400">de valor percebido em sua carreira</DescriptionData>
                      </GpItems>
                      <GpItems width="100%" radius="0px 0px 12px 12px" background={colors.gray5}>
                        <DescriptionData font="14px" weight="400">Para chegarmos até esse número, foi feita uma comparação entre os atributos que são importantes para você e como você enxerga a sua empresa atual. </DescriptionData>
                        <DescriptionData font="14px">Portanto, esse resultado representa o quanto a organização atende os atributos de carreira pesquisados. </DescriptionData>
                        <DescriptionData font="14px" weight="400">Assim como aconteceu anteriormente, a variação é de 0% a 100% e, quanto maior a porcentagem, mais você percebe valor na empresa atual.</DescriptionData>
                      </GpItems>
                    </>
                  }
                </GroupItems>
              </SubCard>
              <DescriptionData font="14px">O que é importante para mim também é relevante para minha empresa?</DescriptionData>
              <br />
              <LabelData font="14px" weight="400">
                Por último, analisamos a aderência dos atributos da empresa às suas necessidades de carreira, ou seja,
              </LabelData>
              <LabelData font="14px">
                mostramos o quanto a sua empresa atende aos atributos que são importante para você.
              </LabelData>
              <Row top="0%" align="start">
                <Icon top="15px" left="0px" right="10px" width="30px" src={X} alt="Item" />
                <DescriptionData font="14px">Resultados abaixo de 100% demonstram que a empresa não está atendendo as suas expectativas. </DescriptionData>
              </Row>
              <Row top="0%" align="start">
                <Icon top="15px" left="0px" right="10px" width="30px" src={Correct} alt="Item" />
                <DescriptionData font="14px">Resultados próximos ou iguais a 100% demonstram que a expectativa está sendo atendida</DescriptionData>
              </Row>
              <Row top="0%" align="start">
                <Icon top="15px" left="0px" right="10px" width="30px" src={Correct} alt="Item" />
                <DescriptionData font="14px">Resultados superiores a 100% demonstram que sua expectativa está sendo superada.</DescriptionData>
              </Row>
              <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue}>
                <GroupItems padding="2%">
                  <LabelData color={colors.white} weight="400" font="14px">Resultados</LabelData>
                  <LabelData color={colors.white} font="14px">&nbsp;superiores a 100%</LabelData>
                  <LabelData color={colors.white} weight="400" font="14px">&nbsp;nem sempre são bons, uma vez que a empresa pode estar investindo muito para atender pontos que </LabelData>
                  <LabelData color={colors.white} font="14px">&nbsp;são pouco relevantes para você </LabelData>
                  <LabelData color={colors.white} weight="400" font="14px">&nbsp;e deixando de atender pontos de maior importância.</LabelData>
                </GroupItems>
              </SubCard>
              <SubCard top="2%" width="100%" heigth="auto" border={colors.white}>
                <GroupItems padding="2%">
                  {width > 530 ?
                    <Row top="0%" align="start">
                      <Group width="35%">
                        <SubTitle>Estrutura e benefícios</SubTitle>
                        {/* <SubTitle font="40px">69%</SubTitle> */}
                        <Grid xs="3">
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Horário de trabalho'
                            value={report.HORARIO_DE_TRABALHO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Local de trabalho'
                            value={report.LOCAL_DE_TRABALHO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Pacote de benefícios'
                            value={report.PACOTE_DE_BENEFICIOS_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.yellow}
                            title='Salário'
                            value={report.SALARIO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <SubTitle>Cultura e valores</SubTitle>
                        {/* <SubTitle font="40px">80%</SubTitle> */}
                        <Grid xs="3">
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Abertura para mudanças'
                            value={report.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Celebração de conquista'
                            value={report.CELEBRACAO_DE_CONQUISTAS_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Cultura e valores da empresa'
                            value={report.CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Desafios constantes'
                            value={report.DESAFIOS_CONSTANTES_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Gestão clara e acompanhamento dos objetivos'
                            value={report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Oportunidade de desenvolvimento'
                            value={report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Plano de carreira estruturado'
                            value={report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Possibilidade de crescimento'
                            value={report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.lightBlue}
                            title='Processos simples e desburocratizados'
                            value={report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA + "%"}
                            font="12px"
                            progress
                            height="90px"
                          />
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <SubTitle>Clima e confiança</SubTitle>
                        {/* <SubTitle font="40px">53%</SubTitle> */}
                        <Grid xs="3">
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Autonomia e abertura para se expressar'
                            value={report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Clima interno'
                            value={report.CLIMA_INTERNO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Confiança na lideranças'
                            value={report.CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Confiança no time'
                            value={report.CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Reconhecimento e valorização'
                            value={report.RECONHECIMENTO_E_VALORIZACAO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Tratamento justo e igualitário'
                            value={report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Visão do futuro da sua empresa'
                            value={report.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                          <CardColor
                            width="300px"
                            bgColor={colors.alertRed}
                            title='Tratamento humanizado'
                            value={report.TRATAMENTO_HUMANIZADO_ADERENCIA + '%'}
                            font="12px"
                            progress
                            height="90px"
                          />
                        </Grid>
                      </Group>
                    </Row>
                    :
                    <>
                      <SubTitle>Estrutura e benefícios</SubTitle>
                      {/* <SubTitle font="40px">69%</SubTitle> */}
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Horário de trabalho'
                          value={report.HORARIO_DE_TRABALHO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Local de trabalho'
                          value={report.LOCAL_DE_TRABALHO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Pacote de beneficios'
                          value={report.PACOTE_DE_BENEFICIOS_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.yellow}
                          title='Salário'
                          value={report.SALARIO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                      <SubTitle>Cultura e valores</SubTitle>
                      {/* <SubTitle font="40px">80%</SubTitle> */}
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Abertura para mudanças e inovação'
                          value={report.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Celebração de conquista'
                          value={report.CELEBRACAO_DE_CONQUISTAS_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Cultura e valores da empresa'
                          value={report.CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Desafios constantes'
                          value={report.DESAFIOS_CONSTANTES_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Gestão clara e acomp- nhamento dos objetivos'
                          value={report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Oportunidade de desenvolvimento'
                          value={report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Plano de carreira estruturado'
                          value={report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Possibilidade de crescimento'
                          value={report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.lightBlue}
                          title='Processos simples e desburocratizados'
                          value={report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA + "%"}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                      <SubTitle>Clima e confiança</SubTitle>
                      {/* <SubTitle font="40px">53%</SubTitle> */}
                      <Grid xs="3">
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Autonomia e abertura para se expressar'
                          value={report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Clima interno'
                          value={report.CLIMA_INTERNO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Confiança na liderança'
                          value={report.CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Confiança no time'
                          value={report.CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Reconhecimento e valorização'
                          value={report.RECONHECIMENTO_E_VALORIZACAO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Tratamento justo e igualitário'
                          value={report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Visão do futuro da sua empresa'
                          value={report.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                        <CardColor
                          width="300px"
                          bgColor={colors.alertRed}
                          title='Tratamento humanizado'
                          value={report.TRATAMENTO_HUMANIZADO_ADERENCIA + '%'}
                          font="12px"
                          progress
                          height="90px"
                        />
                      </Grid>
                    </>
                  }
                </GroupItems>
              </SubCard>
            </GroupItems>
          </Card>
        </ContainerCard>

        : <></>}

      <ContainerCard top="-20%" bottom="0.5%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>De 0 a 10, qual sua avaliação sobre a facilidade para realizar esse teste?</SubTitle>
            <DescriptionGroup>
              <Note onClick={() => handleChangeNote(1)} active={note1}><p>1</p></Note>
              <Note onClick={() => handleChangeNote(2)} active={note2}><p>2</p></Note>
              <Note onClick={() => handleChangeNote(3)} active={note3}><p>3</p></Note>
              <Note onClick={() => handleChangeNote(4)} active={note4}><p>4</p></Note>
              <Note onClick={() => handleChangeNote(5)} active={note5}><p>5</p></Note>
              <Note onClick={() => handleChangeNote(6)} active={note6}><p>6</p></Note>
              <Note onClick={() => handleChangeNote(7)} active={note7}><p>7</p></Note>
              <Note onClick={() => handleChangeNote(8)} active={note8}><p>8</p></Note>
              <Note onClick={() => handleChangeNote(9)} active={note9}><p>9</p></Note>
              <Note onClick={() => handleChangeNote(10)} active={note10}><p>10</p></Note>
            </DescriptionGroup>
            <DescriptionGroup>
              <FormControl sx={{ backgroundColor: colors.white, m: 1, width: "100%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                <TextField autoWidth size="small" color="info" disabled={false} placeholder="Comente sua nota" value={comentary} onChange={handleComentary}
                  multiline
                  rows={8}
                  maxRows={8}
                  InputProps={{
                    sx: {
                      'input': {
                        padding: 0.7,
                        '&::placeholder': {
                          fontSize: '12px',
                          fontFamily: 'Poppins',
                          fontStyle: 'normal'
                        }
                      },
                    },
                  }}
                />
              </FormControl>
            </DescriptionGroup>
            < ContainerButton>
              <Button
                type="button"
                width="140px"
                fontSize="13px"
                text="Enviar"
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onSend}
              />
            </ ContainerButton>
          </GroupItems>
        </Card>
      </ContainerCard>
    </Container>
  )
}

export default CareerReport;
