import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { lightTheme } from './../../styles/theme.js';
import colors from './../../styles/colors';
import Button from "./../../components/Button";
import { ThemeProvider } from "styled-components";
import logo from './../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
import { validEmail } from '../../utils/validEmail.js';
import { phoneMask } from '../../utils/phoneMask.js';
import { validDate } from '../../utils/validDate.js';
import { dateMask } from '../../utils/dateMask.js';
import { Select, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import { auth } from './../../firebaseConfig';
import { createUserWithEmailAndPassword } from "firebase/auth";
import api from '../../api';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Companies from './../../assets/data/companies.json';

//UTILS
import ScrollToTop from './../../utils/ScrollToTop';

//COMPONENTS
import StoreContext from '../../components/Store/Context';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Container,
  Card,
  Header,
  Logo,
  Group,
  ErrorMessage,
  Row
} from './styles';

const NaturalPerson = () => {
  const { token, setToken, setLoginHash, loginHash, uuidSession, setUuidSession, userGlobal } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [open, setOpen] = useState(false);
  const [openCareer, setOpenCareer] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [genre, setGenre] = useState("");
  const [occupation, setOccupation] = useState("");
  const [error, setError] = useState(false);
  const [statusOccupation, setStatusOccupation] = useState(false);
  const [statusCompanie, setStatusCompanie] = useState(false);
  const [statusCareer, setStatusCareer] = useState(false);
  const [msg, setMsg] = useState("");
  const [companies, setCompanies] = useState([]);
  const [career, setCareer] = useState([]);
  const [companie, setCompanie] = useState("");
  const [companieId, setCompanieId] = useState("");
  const [careerId, setCareerId] = useState("");
  const [nameCompanie, setNameCompanie] = useState("");

  const history = useHistory();

  useEffect(() => {
    getCompanies();
    setWidth(window.screen.width);
  }, [width])


  const onNext = async (e) => {
    e.preventDefault();
    const inputName = name.trim();
    const word = inputName.split(" ");
    if (name.length > 0 && phone.length > 0 && password.length > 0 && email.length > 0 && date.length > 0 && genre.toString().length > 0 && occupation.toString().length > 0) {
      if (!validEmail(email)) {
        setMsg("E-mail inválido! Digite um e-mail válido!");
        setError(true);
      } else {
        if (word.length === 1) {
          setMsg("É necessário inserir pelo menos um sobrenome!");
          setError(true);
        } else {
          if (validDate(date)) {
            createUserWithEmailAndPassword(auth, email, '123456')
              // here we get user id if creating is successful.
              .then(async function (user) {

                const json = {
                  'email': email,
                  'name': name,
                  'password': '123456',
                  'password_confirmation': '123456',
                  'uid_firebase': user.user.uid
                }
                await api.post('/register', json, {
                  headers: {
                    'Content-Type': 'aplication/json',
                  }
                }).then(async (response) => {
                  const dataUser = response.data
                  const credentials = {
                    'email': email,
                    'password': password,
                  }
                  await api.post('/login', credentials, {
                    headers: {
                      'Content-Type': 'aplication/json',
                    }
                  }).then(async (response) => {
                    const data = response.data;
                    await setToken(data);

                    const perfil = {
                      "user_id": dataUser.user.id,
                      "perfil_id": 11
                    }

                    await api.post('/users/perfil', perfil, {
                      headers: {
                        'authorization': 'Bearer ' + data.refreshToken
                      }
                    }).then(async (response) => {

                      const complementary = {
                        "user_id": dataUser.user.id,
                        "nome": word[0],
                        "sobrenome": word[1],
                        "tipo_pessoa": "PF",
                        "data_nascimento": date,
                        "genero": genre,
                        "ocupacao_principal": occupation
                      }
                      await api.post('/dados-candidatos/save', complementary, {
                        headers: {
                          'authorization': 'Bearer ' + data.refreshToken
                        }
                      }).then(async (response) => {


                        if (nameCompanie.length > 0 && companie.toString().length > 0) {
                          console.log('teste if', nameCompanie, companie)
                          const dataCompanie = {
                            "cnpj": "",
                            "razao_social": nameCompanie,
                            "nome_fantasia": nameCompanie,
                            "id_igrown": ""
                          }
                          await api.post('/companies/create', dataCompanie, {
                            headers: {
                              'authorization': 'Bearer ' + data.refreshToken
                            }
                          }).then(async (response) => {
                            const resp = response.data;
                            const companieId = resp.created_id ? resp.created_id : resp.exists_id;
                            const dataUserCompanie = {
                              "user_id": dataUser.user.id,
                              "company_id": companieId
                            }
                            await api.post('/users/empresa', dataUserCompanie, {
                              headers: {
                                'authorization': 'Bearer ' + data.refreshToken
                              }
                            }).then(async (response) => {
                              const messageEmail = {
                                "emails": [
                                  email
                                ],
                                "mensagem": "É com imenso prazer que lhe damos as boas-vindas à iGrown! Nossa plataforma é dedicada ao seu desenvolvimento pessoal e profissional, oferecendo recursos inovadores e oportunidades para você expandir seus conhecimentos, aprimorar habilidades e alcançar seus objetivos.\n\nNa iGrown, acreditamos no poder do crescimento contínuo e estamos empenhados em fornecer a melhor experiência possível. Você terá acesso a uma ampla variedade de cursos online, treinamentos exclusivos e conteúdos especializados, tudo projetado para impulsionar seu crescimento e ajudá-lo(a) a se destacar em sua área de atuação.\n\nNossa equipe está pronta para acompanhá-lo(a) nessa jornada de sucesso. Se surgir alguma dúvida ou se precisar de assistência, conte conosco. Aproveite ao máximo tudo o que a iGrown tem a oferecer e prepare-se para se tornar a melhor versão de si mesmo(a)!"
                              }
                              await api.post('/email/send', messageEmail, {
                                headers: {
                                  'authorization': 'Bearer ' + data.refreshToken
                                }
                              }).then(async (response) => {
                                history.push({
                                  pathname: '/enviarEmail',
                                  state: {
                                    email: email
                                  }
                                })

                              }).catch((error) => {
                                console.log('error', error);
                                setMsg("Erro ao enviar os seus dados!");
                                setError(true);
                              });
                            }).catch((error) => {
                              console.log('error', error);
                              setMsg("Erro ao enviar os seus dados!");
                              setError(true);
                            });
                          }).catch((error) => {
                            console.log('error', error);
                            setMsg("Erro ao enviar os seus dados!");
                            setError(true);
                          });
                        } else if (nameCompanie.length == 0 && companie.toString().length > 0) {
                          console.log('teste else if', nameCompanie, companie)
                          const dataUserCompanie = {
                            "user_id": dataUser.user.id,
                            "company_id": companieId
                          }
                          await api.post('/users/empresa', dataUserCompanie, {
                            headers: {
                              'authorization': 'Bearer ' + data.refreshToken
                            }
                          }).then(async (response) => {

                            if (careerId == 0) {
                              const messageEmail = {
                                "emails": [
                                  email
                                ],
                                "mensagem": "É com imenso prazer que lhe damos as boas-vindas à iGrown! Nossa plataforma é dedicada ao seu desenvolvimento pessoal e profissional, oferecendo recursos inovadores e oportunidades para você expandir seus conhecimentos, aprimorar habilidades e alcançar seus objetivos.\n\nNa iGrown, acreditamos no poder do crescimento contínuo e estamos empenhados em fornecer a melhor experiência possível. Você terá acesso a uma ampla variedade de cursos online, treinamentos exclusivos e conteúdos especializados, tudo projetado para impulsionar seu crescimento e ajudá-lo(a) a se destacar em sua área de atuação.\n\nNossa equipe está pronta para acompanhá-lo(a) nessa jornada de sucesso. Se surgir alguma dúvida ou se precisar de assistência, conte conosco. Aproveite ao máximo tudo o que a iGrown tem a oferecer e prepare-se para se tornar a melhor versão de si mesmo(a)!"
                              }
                              await api.post('/email/send', messageEmail, {
                                headers: {
                                  'authorization': 'Bearer ' + data.refreshToken
                                }
                              }).then(async (response) => {
                                history.push({
                                  pathname: '/enviarEmail',
                                  state: {
                                    email: email
                                  }
                                })

                              }).catch((error) => {
                                console.log('error', error);
                                setMsg("Erro ao enviar os seus dados!");
                                setError(true);
                              });
                            } else {
                              const json = {
                                "carreira_id": careerId
                              }

                              await api.put('/users/' + dataUser.user.id, json, {
                                headers: {
                                  'authorization': 'Bearer ' + data.refreshToken
                                }
                              }).then(async (response) => {

                                const messageEmail = {
                                  "emails": [
                                    email
                                  ],
                                  "mensagem": "É com imenso prazer que lhe damos as boas-vindas à iGrown! Nossa plataforma é dedicada ao seu desenvolvimento pessoal e profissional, oferecendo recursos inovadores e oportunidades para você expandir seus conhecimentos, aprimorar habilidades e alcançar seus objetivos.\n\nNa iGrown, acreditamos no poder do crescimento contínuo e estamos empenhados em fornecer a melhor experiência possível. Você terá acesso a uma ampla variedade de cursos online, treinamentos exclusivos e conteúdos especializados, tudo projetado para impulsionar seu crescimento e ajudá-lo(a) a se destacar em sua área de atuação.\n\nNossa equipe está pronta para acompanhá-lo(a) nessa jornada de sucesso. Se surgir alguma dúvida ou se precisar de assistência, conte conosco. Aproveite ao máximo tudo o que a iGrown tem a oferecer e prepare-se para se tornar a melhor versão de si mesmo(a)!"
                                }
                                await api.post('/email/send', messageEmail, {
                                  headers: {
                                    'authorization': 'Bearer ' + data.refreshToken
                                  }
                                }).then(async (response) => {
                                  history.push({
                                    pathname: '/enviarEmail',
                                    state: {
                                      email: email
                                    }
                                  })

                                }).catch((error) => {
                                  console.log('error', error);
                                  setMsg("Erro ao enviar os seus dados!");
                                  setError(true);
                                });

                              }).catch((error) => {
                                console.log('error', error);
                                setMsg("Erro ao enviar os seus dados!");
                                setError(true);
                              });
                            }

                          }).catch((error) => {
                            console.log('error', error);
                            setMsg("Erro ao enviar os seus dados!");
                            setError(true);
                          });
                        } else {
                          console.log('teste else', nameCompanie, companie)
                          const messageEmail = {
                            "emails": [
                              email
                            ],
                            "mensagem": "É com imenso prazer que lhe damos as boas-vindas à iGrown! Nossa plataforma é dedicada ao seu desenvolvimento pessoal e profissional, oferecendo recursos inovadores e oportunidades para você expandir seus conhecimentos, aprimorar habilidades e alcançar seus objetivos.\n\nNa iGrown, acreditamos no poder do crescimento contínuo e estamos empenhados em fornecer a melhor experiência possível. Você terá acesso a uma ampla variedade de cursos online, treinamentos exclusivos e conteúdos especializados, tudo projetado para impulsionar seu crescimento e ajudá-lo(a) a se destacar em sua área de atuação.\n\nNossa equipe está pronta para acompanhá-lo(a) nessa jornada de sucesso. Se surgir alguma dúvida ou se precisar de assistência, conte conosco. Aproveite ao máximo tudo o que a iGrown tem a oferecer e prepare-se para se tornar a melhor versão de si mesmo(a)!"
                          }
                          await api.post('/email/send', messageEmail, {
                            headers: {
                              'authorization': 'Bearer ' + data.refreshToken
                            }
                          }).then(async (response) => {
                            history.push({
                              pathname: '/enviarEmail',
                              state: {
                                email: email
                              }
                            })

                          }).catch((error) => {
                            console.log('error', error);
                            setMsg("Erro ao enviar os seus dados!");
                            setError(true);
                          });

                        }

                      }).catch((error) => {
                        console.log('error', error);
                        setMsg("Erro ao enviar os seus dados!");
                        setError(true);
                      });


                    }).catch((error) => {
                      console.log('error', error);
                      setMsg("Erro ao enviar os seus dados!");
                      setError(true);
                    });

                  }).catch((error) => {
                    console.log('error', error);
                    setMsg("Erro ao enviar os seus dados!");
                    setError(true);
                  });
                }).catch((error) => {
                  console.log('error', error);
                  setMsg("Erro ao enviar os seus dados!");
                  setError(true);
                });
              }).catch((error) => {
                console.log('error', error);
                setMsg("E-mail já cadastrado! Entre em contato conosco");
                setError(true);
              });
          } else {
            setMsg("Data inválida! Insira uma data válida!");
            setError(true);
          }
        }
      }
    } else {
      setMsg("É necessário preeencher todos os campos!");
      setError(true);
    }
  }

  const getCompanies = async () => {
    //Admin
    await api.get('/companies', {
    }).then(async (response) => {
      const data = response.data.response;
      let json = [];
      await data.map((item) => {
        json.push({ id: item.id, label: item.nome_fantasia })
      })
      setCompanies(json);
    }).catch((error) => {
      setCompanies(Companies);
      console.log('erro', error);
      setError(true);
    });

    //     const data = userGlobal;
    // setCompanies(data);
  }

  const getCareer = async (id) => {
    await api.get('/companies/carreiras/' + id, {
    }).then(async (response) => {
      let json = [];
      const data = response.data;
      await data.map((item) => {
        json.push({ id: item.dados_carreira.id, label: item.dados_carreira.nome })
      })
      await json.push({ id: 0, label: "Não encontrei minha carreira" })
      await setCareer(json);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    setMsg("");
    setError(false);
  }

  const onChangePassword = (e) => {
    const name = e.target.value;
    setPassword(name);
    setMsg("");
    setError(false);
  }

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phoneMask(phone));
    setMsg("");
    setError(false);
  }

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setMsg("");
    setError(false);
  }

  const onChangeDate = (e) => {
    const date = dateMask(e.target.value)
    setDate(date);
    setMsg("");
    setError(false);
  }

  const onChangeGenre = (e) => {
    const genre = e.target.value;
    setGenre(genre);
    setMsg("");
    setError(false);
  }

  const onChangeOccupation = (e) => {
    const occupation = e.target.value;
    if (occupation == 1) {
      setStatusOccupation(true);
      setOccupation(occupation);
    } else {
      setStatusOccupation(false);
      setOccupation(occupation);
      setCompanie("");
    }

    setMsg("");
    setError(false);
  }

  const onChangeCompanie = (event, value) => {
    const companie = value.id;
    companie == 61 && statusOccupation ? setStatusCompanie(true) : setStatusCompanie(false);
    companie != 61 && statusOccupation ? setStatusCareer(true) : setStatusCareer(false);
    setCompanie(value);
    setCompanieId(companie);
    setMsg("");
    setError(false);
    getCareer(companie);
  }

  const onChangeCarrer = (event, value) => {
    const career = value.id;
    console.log(value)
    setCareerId(career);
  }

  const onChangeNameCompanie = (e) => {
    const companie = e.target.value;
    setNameCompanie(companie);
    setMsg("");
    setError(false);
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <Container>
              <Header>
                <a href="/login"><Logo src={logo} alt="iGrown" /></a>
              </Header>
              <Label>Continue o seu cadastro</Label>
              <Card>
                <Group top="30px">
                  <FormControl sx={{ m: 1.2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField label="Nome completo" variant="outlined" size="small" value={name} onChange={onChangeName} color="info" disabled={false} />
                  </FormControl>
                  <Row>
                    <FormControl sx={{ m: 1.2, width: "39%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                      <TextField label="Senha" variant="outlined" size="small" color="info" onChange={onChangePassword} value={password} type="password" disabled={false} />
                    </FormControl>
                    <FormControl sx={{ m: 1.2, width: "39%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                      <TextField label="Celular" variant="outlined" size="small" color="info" onChange={onChangePhone} value={phone} disabled={false} />
                    </FormControl>
                  </Row>

                  <FormControl sx={{ m: 1.2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField label="E-mail" variant="outlined" value={email} onChange={onChangeEmail} size="small" color="info" disabled={false} />
                  </FormControl>
                  <Row>
                    <FormControl sx={{ m: 1.2, width: "39%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                      <TextField label="Data de nascimento" variant="outlined" size="small" onChange={onChangeDate} value={date} color="info" disabled={false} />
                    </FormControl>
                    <FormControl sx={{ m: 1.2, width: "39%" }}>
                      <InputLabel id="genre">Gênero</InputLabel>
                      <Select
                        labelId="genre"
                        id="genre"
                        variant="outlined"
                        value={genre}
                        size="small"
                        color="info"
                        onChange={onChangeGenre}
                        disabled={false}
                        input={<OutlinedInput label="Gênero" />}
                      >
                        <MenuItem value={1}>Feminino</MenuItem>
                        <MenuItem value={2}>Masculino</MenuItem>
                        <MenuItem value={3}>Prefiro não informar</MenuItem>
                      </Select>
                    </FormControl>
                  </Row>
                  <FormControl sx={{ m: 1.2, width: "80%" }}>
                    <InputLabel id="occupation">Ocupação principal</InputLabel>
                    <Select
                      labelId="occupation"
                      id="occupation"
                      variant="outlined"
                      value={occupation}
                      size="small"
                      color="info"
                      onChange={onChangeOccupation}
                      disabled={false}
                      input={<OutlinedInput label="Ocupação principal" />}
                    >
                      <MenuItem value={1}>Trabalho</MenuItem>
                      <MenuItem value={2}>Estudo</MenuItem>
                      <MenuItem value={3}>Em busca de recolocação</MenuItem>
                      <MenuItem value={4}>Aposentado</MenuItem>
                      <MenuItem value={5}>Outros</MenuItem>
                    </Select>
                  </FormControl>
                </Group>
                {statusOccupation ?
                  <FormControl sx={{ m: 1.2, width: "80%" }}>
                    <Stack spacing={2}>
                      <Autocomplete
                        onChange={(event, value) => onChangeCompanie(event, value)}
                        id="searchCompanie"
                        open={open}
                        onInputChange={(_, value) => {
                          if (value.length === 0) {
                            if (open) setOpen(false);
                          } else {
                            if (!open) setOpen(true);
                          }
                        }}
                        onClose={() => setOpen(false)}
                        options={companies}
                        renderInput={(params) => (
                          <TextField
                            value={params}
                            {...params}
                            label="Procure a sua empresa"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </FormControl>
                  :
                  <></>}
                {statusOccupation && statusCareer ?
                  <FormControl sx={{ m: 1.2, width: "80%" }}>
                    <Stack spacing={2}>
                      <Autocomplete
                        onChange={(event, value) => onChangeCarrer(event, value)}
                        id="searchCareer"
                        onClose={() => setOpenCareer(false)}
                        options={career}
                        renderInput={(params) => (
                          <TextField
                            value={params}
                            {...params}
                            label="Procure a sua carreira"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </FormControl>
                  :
                  <></>}
                {statusCompanie && statusOccupation ?
                  <FormControl sx={{ m: 1.2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField label="Nome da sua empresa" variant="outlined" value={nameCompanie} onChange={onChangeNameCompanie} size="small" color="info" disabled={false} />
                  </FormControl>
                  : <></>
                }
                <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>{msg}</ErrorMessage>
                <Group>
                  <Button
                    type="button"
                    width={width > 530 ? "600px" : "320px"}
                    hoverColor={colors.lightBlue}
                    text="Avançar"
                    onClick={onNext}
                  />
                </Group>
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  )
}

export default NaturalPerson;
