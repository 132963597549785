import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import logo from './../../assets/img/logo.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import CardColor from './../../components/Card';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material'; import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreContext from '../../components/Store/Context';
import Graph from './../../assets/img/Graph.svg';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import api from '../../api';
import AlertModal from './../../components/AlertModal';
import ErrorModal from './../../components/ErrorModal';
import moment from 'moment';
import { useLocation } from 'react-router';

import {
  Container,
  Header,
  Logo,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  GroupAccordion,
  GroupText,
  Note,
  DescriptionGroup,
  ContainerButton,
  Group
} from './styles';

const BehavioralReport = () => {

  const { token, setToken } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [report, setReport] = useState([]);
  const [note1, setNote1] = useState(false);
  const [note2, setNote2] = useState(false);
  const [note3, setNote3] = useState(false);
  const [note4, setNote4] = useState(false);
  const [note5, setNote5] = useState(false);
  const [note6, setNote6] = useState(false);
  const [note7, setNote7] = useState(false);
  const [note8, setNote8] = useState(false);
  const [note9, setNote9] = useState(false);
  const [note10, setNote10] = useState(false);
  const [valueNote, setValueNote] = useState(0)
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [comentary, setComentary] = useState("");
  const location = useLocation();

  useEffect(async () => {
    getTest();
    setWidth(window.screen.width)
  }, [width, location]);

  const onSend = async () => {
    const body = {
      "teste_id": report.ID,
      "nps": valueNote,
      "comentario": comentary
    }

      await api.post('/nps', body, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
       setSend(true);
      }).catch((error) => {
        console.log('erro', error);
        setError(true);
      });
  }

  const getTest = async () => {
    const body = {
      "email":  location.state ? location.state.activeUser : location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : token.email
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.comportamental;
      await setReport(data)
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const handleComentary = async (e) => {
    e.preventDefault();
    setComentary(e.target.value);
  }

  const handleChangeNote = (value) => {
    setValueNote(value);
    if (value == 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 2) {
      setNote1(false);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 3) {
      setNote1(false);
      setNote2(false);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 4) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 5) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 6) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 7) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 8) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value == 9) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(true);
      setNote10(false);
    } else if (value == 10) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(true);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onCloseError = () => {
    setError(false);
  }

  const onCloseAlert = () => {
    setSend(false);
  }

  return (
    <Container>
      <ResponsiveAppBar />
      {/* <HeaderUser width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <ErrorModal isOpen={error}  onRequestClose={onCloseError} />
      <AlertModal isOpen={send} msg="Avaliação enviada com sucesso!" onRequestClose={onCloseAlert} />
      <Title>Relatório comportamental iGrown</Title>
      <ContainerCard top="20%">
        <Row>
          <DescriptionData weight="400">Última atualização em</DescriptionData>
          <DescriptionData>&nbsp;{report ? moment(report.DATA_DA_ENTRADA).format("DD/MM/YYYY") : ""}</DescriptionData>
        </Row>
      </ContainerCard>
      <ContainerCard top="20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Hey, iGrowner!</SubTitle>
            <DescriptionData font="14px">Aqui está o seu relatório comportamental iGrown.</DescriptionData>
            <LabelData font="14px" weight="400">
              Lembramos que as informações a seguir retratam suas tendências comportamentais, podendo variar conforme o contexto, pessoa ou momento de vida. Assim, não há certo ou errado, melhor ou pior.
              Apenas informações orientadoras para aumentar o seu autoconhecimento e acelerar a sua carreira, ok?
            </LabelData>
            <LabelData font="14px">
              &nbsp;Aproveite e #boradecolar
            </LabelData>
            <SubTitle font="16px">Nesse relatório, foram analisadas:</SubTitle>
            <DescriptionData font="14px">• As suas tendências comportamentais;</DescriptionData>
            <DescriptionData font="14px">• As suas características de aprendizagem;</DescriptionData>
            <DescriptionData bottom="2.5%" font="14px">• As suas soft skills, ou seja, as habilidades comportamentais de destaque para desenvolvimento;</DescriptionData>
            <LabelData font="14px">
              O grande objetivo
            </LabelData>
            <LabelData font="14px" >
              &nbsp; é aumentar o seu autoconhecimento e ajudá-lo a conhecer suas forças e fraquezas para que você possa decolar em sua carreira! =D
            </LabelData>
            <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue}>
              <GroupItems padding="2%">
                <LabelData color={colors.white} font="14px">Pessoas com autoconhecimento elevado têm uma boa dose de autocrítica saudável, passam por uma busca contínua por feedback e auto feedback,
                  resultando em um alto nível de autoconfiança. Têm consciência do seu potencial e conseguem traduzi-lo nas demais dimensões por meio de ações práticas e efetivas.</LabelData>
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Tendências comportamentais</SubTitle>
            <DescriptionData font="14px" weight="400">O seu relatório comportamental pode ser composto a partir de quatro perfis básicos: Desbravador, Mantenedor, Investigador e Embaixador. Clicando no ícone        ao lado dos nomes abaixo, você poderá conhecer as características principais de cada um deles.
            </DescriptionData>
            <DescriptionData font="14px">
              Abaixo, de acordo com suas respostas, veja como ficou a composição do seu perfil
              nesse momento.
            </DescriptionData>
            <SubCard top="2%" width="100%" heigth="auto" border={colors.gray5}>
              <GroupItems padding="2%">
                {width > 530 ?
                  <Row top="0%">
                    <Group width="50%">
                      <Grid xs="6">
                        <CardColor
                          width="400px"
                          bgColor={colors.yellow}
                          title='Desbravador'
                          value={report.DESBRAVADOR + '%'}
                          font="14px"
                        />
                        <CardColor
                          width="400px"
                          bgColor={colors.lightBlue}
                          title='Mantenedor'
                          value={report.MANTENEDOR + '%'}
                          font="14px"
                        />
                        <CardColor
                          width="400px"
                          bgColor={colors.lightGreen}
                          title='Investigador'
                          value={report.INVESTIGADOR + '%'}
                          font="14px"
                        />
                        <CardColor
                          width="400px"
                          bgColor={colors.alertRed}
                          title='Embaixador'
                          value={report.EMBAIXADOR + '%'}
                          font="14px"
                        />
                      </Grid>
                    </Group>
                    <Group width="50%">
                      <Grid xs="6">
                        <SubTitle>Seu perfil predominante é</SubTitle>
                        <SubTitle top="0%" color={colors.lightBlue}>{report.PERFIL_PREDOMINANTE}</SubTitle>
                        <DescriptionData font="14px" weight="400">
                          {report.MEU_PERFIL_COMPORTAMENTAL}
                        </DescriptionData>
                      </Grid>
                    </Group>
                  </Row>
                  :
                  <>
                    <Grid xs="6">
                      <CardColor
                        width="400px"
                        bgColor={colors.yellow}
                        title='Desbravador'
                        value={report.DESBRAVADOR + '%'}
                        font="14px"
                      />
                      <CardColor
                        width="400px"
                        bgColor={colors.lightBlue}
                        title='Mantenedor'
                        value={report.MANTENEDOR + '%'}
                        font="14px"
                      />
                      <CardColor
                        width="400px"
                        bgColor={colors.lightGreen}
                        title='Investigador'
                        value={report.INVESTIGADOR + '%'}
                        font="14px"
                      />
                      <CardColor
                        width="400px"
                        bgColor={colors.alertRed}
                        title='Embaixador'
                        value={report.EMBAIXADOR + '%'}
                        font="14px"
                      />
                    </Grid>
                    <Grid xs="6">
                      <SubTitle>Seu perfil predominante é</SubTitle>
                      <SubTitle top="0%" color={colors.lightBlue}>{report.PERFIL_PREDOMINANTE}</SubTitle>
                      <DescriptionData font="14px" weight="400">
                        {report.MEU_PERFIL_COMPORTAMENTAL}
                      </DescriptionData>
                    </Grid>
                  </>
                }
              </GroupItems>
            </SubCard>
            <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue}>
              <GroupItems padding="2%">
                <LabelData color={colors.white} font="14px">Todas essas tendências descritas aqui são situacionais, ou seja, elas podem variar de acordo com o contexto e seu momento de vida.</LabelData>
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="0%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Quais outras características estão relacionadas a esse perfil? </SubTitle>
            <LabelData font="14px" weight="400">
              Veja, abaixo, algumas características gerais que estão diretamente ligadas ao seu perfil dominante.
            </LabelData>
          </GroupItems>
          <GroupAccordion top="2px">

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>Palavras-chave</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.PALAVRAS_CHAVE}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Objetivos frequentes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.SEUS_OBJETIVOS_FREQUENTES}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Estilos de liderança
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.SEUS_ESTILOS_DE_LIDERANCA}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Onde colocar ênfase
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.ONDE_VOCE_PODE_COLOCAR_ENFASE}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Onde perde energia
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.ONDE_VOCE_PODE_PERDER_ENERGIA}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Comportamentos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.COMPORTAMENTOS_CARACTERISTICOS}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Como se comunicar com você
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.COMO_SE_COMUNICAR_COM_VOCE}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Como reconhecer pessoas com o seu perfil
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.COMO_RECONHECER_PESSOAS_COM_O_SEU_PERFIL}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupAccordion top="5%" bottom="5%" left="0%" right="0%">
              <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Cultura do time
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report.CULTURA_DO_TIME}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupAccordion>

          </GroupAccordion>
        </Card>
      </ContainerCard>

      <ContainerCard top="-10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Características de aprendizagem</SubTitle>
            <DescriptionData font="14px" weight="400">
              A partir das palavras que você selecionou anteriormente, identificamos quais são as suas características de aprendizagem (Learning Agilities). Veja seu resultado abaixo.
            </DescriptionData>
            <DescriptionData font="14px">Com base nos resultados, seu perfil de aprendizagem é:</DescriptionData>
            {width > 530 ?
              <GroupAccordion top="5%" bottom="1%" left="0%" right="0%">
                <Row>
                  <Group width="50%">
                    <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                      <CardColor
                        width="100%"
                        tooltip={report.AGILIDADE_COM_MUDANCAS_TEXT}
                        left
                        bgColor={colors.white}
                        title='Agilidade com mudanças'
                        value={report.AGILIDADE_COM_MUDANCAS}
                        valueColor={colors.lightBlue}
                        font="14px"
                        step={report.AGILIDADE_COM_MUDANCAS == "Alta" ? 5 : report.AGILIDADE_COM_MUDANCAS == "Moderada" ? 3 : 1}
                        height="100%"
                      />
                    </SubCard>
                  </Group>
                  <Group width="50%">
                    <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                      <CardColor
                        width="100%"
                        left
                        bgColor={colors.white}
                        tooltip={report.AGILIDADE_COM_RESULTADOS_TEXT}
                        title='Agilidade com resultados'
                        value={report.AGILIDADE_COM_RESULTADOS}
                        valueColor={colors.lightBlue}
                        font="14px"
                        step={report.AGILIDADE_COM_RESULTADOS == "Alta" ? 5 : report.AGILIDADE_COM_RESULTADOS == "Moderada" ? 3 : 1}
                        height="100%"
                      />
                    </SubCard>
                  </Group>
                </Row>
                <Row>
                  <Group width="50%">
                    <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                      <CardColor
                        width="100%"
                        left
                        bgColor={colors.white}
                        title='Agilidade mental'
                        tooltip={report.AGILIDADE_MENTAL_TEXT}
                        value={report.AGILIDADE_MENTAL}
                        valueColor={colors.lightBlue}
                        font="14px"
                        step={report.AGILIDADE_MENTAL == "Alta" ? 5 : report.AGILIDADE_MENTAL == "Moderada" ? 3 : 1}
                        height="100%"
                      />
                    </SubCard>
                  </Group>
                  <Group width="50%">
                    <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                      <CardColor
                        width="100%"
                        left
                        bgColor={colors.white}
                        title='Agilidade com pessoas'
                        value={report.AGILIDADE_COM_PESSOAS}
                        tooltip={report.AGILIDADE_COM_PESSOAS_TEXT}
                        valueColor={colors.lightBlue}
                        font="14px"
                        step={report.AGILIDADE_COM_PESSOAS == "Alta" ? 5 : report.AGILIDADE_COM_PESSOAS == "Moderada" ? 3 : 1}
                        height="100%"
                      />
                    </SubCard>
                  </Group>
                </Row>
              </GroupAccordion>
              :
              <GroupAccordion top="5%" bottom="1%" left="0px" right="0px">
                <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                  <CardColor
                    width="100%"
                    left="0px"
                    bgColor={colors.white}
                    value={report.AGILIDADE_COM_MUDANCAS}
                    tooltip={report.AGILIDADE_COM_MUDANCAS_TEXT}
                    title='Agilidade com mudanças'
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={report.AGILIDADE_COM_MUDANCAS == "Alta" ? 5 : report.AGILIDADE_COM_MUDANCAS == "Moderada" ? 3 : 1}
                    height="100%"
                    right="0px"
                  />
                </SubCard>
                <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                  <CardColor
                    width="100%"
                    left="0px"
                    bgColor={colors.white}
                    value={report.AGILIDADE_COM_RESULTADOS}
                    tooltip={report.AGILIDADE_COM_RESULTADOS_TEXT}
                    title='Agilidade com resultados'
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={report.AGILIDADE_COM_RESULTADOS == "Alta" ? 5 : report.AGILIDADE_COM_RESULTADOS == "Moderada" ? 3 : 1}
                    height="100%"
                    right="0px"
                  />
                </SubCard>
                <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                  <CardColor
                    width="100%"
                    left="0px"
                    bgColor={colors.white}
                    value={report.AGILIDADE_MENTAL}
                    tooltip={report.AGILIDADE_MENTAL_TEXT}
                    title='Agilidade mental'
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={report.AGILIDADE_MENTAL == "Alta" ? 5 : report.AGILIDADE_MENTAL == "Moderada" ? 3 : 1}
                    height="100%"
                    right="0px"
                  />
                </SubCard>
                <SubCard top="2%" width="100%" heigth="auto" background={colors.white} border={colors.gray5}>
                  <CardColor
                    width="100%"
                    left="0px"
                    bgColor={colors.white}
                    title='Agilidade com pessoas'
                    tooltip={report.AGILIDADE_COM_PESSOAS_TEXT}
                    value={report.AGILIDADE_COM_PESSOAS}
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={report.AGILIDADE_COM_PESSOAS == "Alta" ? 5 : report.AGILIDADE_COM_PESSOAS == "Moderada" ? 3 : 1}
                    height="100%"
                    right="0px"
                  />
                </SubCard>
              </GroupAccordion>
            }

            <SubCard top="2%" width="100%" heigth="auto" background={colors.darkBlue}>
              <GroupItems padding="2%">
                <LabelData color={colors.white} font="14px">Pessoas que buscam desenvolver suas agilidades de aprendizado, normalmente, são consideradas para posições estratégicas e como sucessoras de líderes, dado sua capacidade de adaptação e entregas acima da média, tendo condições de levar o negócio a novos patamares e assegurar o crescimento e a perpetuidade da organização.</LabelData>
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="-20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Soft Skills: Habilidades comportamentais </SubTitle>
            <DescriptionData font="14px" weight="400">
              Como você pode ver abaixo, assim como foi feito nas learning agilities, as soft skills foram organizadas em diferentes grupos. No entanto, o foco aqui são habilidades comportamentais.
            </DescriptionData>
            <DescriptionData font="14px">
              Para você entender melhor seu resultado, os parâmetros a serem considerados são:
            </DescriptionData>
            <SubCard top="2%" width="100%" heigth="auto" border={colors.white}>
              <GroupItems padding="2%">
                {width > 530 ?
                  <Row top="0%">
                    <Group width="25%">
                      <SubTitle>Mudanças </SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Engajamento contínuo'
                          value={report.ENGAJAMENTO_CONTINUO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Flexibilidade'
                          value={report.FLEXIBILIDADE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Gestão da ansiedade'
                          value={report.GESTAO_DA_ANSIEDADE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Gestão de mudanças'
                          value={report.GESTAO_DA_MUDANCA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Mentalidade de startup'
                          value='68%'
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Resiliência'
                          value={report.RESILIENCIA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Negociação'
                          value={report.NEGOCIACAO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Orientação ao negócio'
                          value={report.ORIENTACAO_AO_NEGOCIO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.yellow}
                          title='Planejamento'
                          value={report.PLANEJAMENTO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                      </Grid>
                    </Group>
                    <Group width="25%">
                      <SubTitle>Resultados </SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Alinhamento corporativo'
                          value={report.ALINHAMENTO_CORPORATIVO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Visão de longo prazo'
                          value={report.VISAO_DE_LONGO_PRAZO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Visão sistemica'
                          value={report.VISAO_SISTEMICA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Velocidade na tomada de decisão'
                          value={report.VELOCIDADE_NA_TOMADA_DE_DECISAO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Gestão da qualidade'
                          value={report.GESTAO_DA_QUALIDADE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Senso de dono'
                          value={report.SENSO_DE_DONO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Proatividade'
                          value={report.PROATIVIDADE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Venda consultiva'
                          value={report.VENDA_CONSULTIVA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Gestão de projetos'
                          value={report.GESTAO_DE_PROJETOS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Orientação ao resultado'
                          value={report.ORIENTACAO_AO_RESULTADO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Mentalidade de startup'
                          value={report.MENTALIDADE_DE_STARTUP + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Gestão do tempo'
                          value={report.GESTAO_DO_TEMPO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Melhoria de desempenho'
                          value={report.MELHORIA_DE_DESEMPENHO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Identificação de propósito'
                          value={report.IDENTIFICACAO_DE_PROPOSITO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Intraempre- endedorismo'
                          value={report.INTRAEMPREENDEDORISMO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Solução de problemas'
                          value={report.SOLUCAO_DE_PROBLEMAS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightBlue}
                          title='Inovação'
                          value={report.INOVACAO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                      </Grid>
                    </Group>
                    <Group width="25%">
                      <SubTitle>Mental </SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Análise de dados'
                          value={report.ANALISE_DE_DADOS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Aprendizado constante'
                          value={report.APRENDIZADO_CONSTANTE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Capacidade analítica'
                          value={report.CAPACIDADE_ANALITICA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Liderança estratégica'
                          value={report.LIDERANCA_ESTRATEGICA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Capacidade de desaprender'
                          value={report.CAPACIDADE_DE_DESAPRENDER + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Raciocínio lógico'
                          value={report.RACIOCINIO_LOGICO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Pensamento estratégico'
                          value={report.PENSAMENTO_ESTRATEGICO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Inteligência emocional'
                          value={report.INTELIGENCIA_EMOCIONAL + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Liderança inspiradora'
                          value={report.LIDERANCA_INSPIRADORA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Escuta ativa'
                          value={report.ESCUTA_ATIVA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Pensamento ágil'
                          value={report.PENSAMENTO_AGIL + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Inteligência digital'
                          value={report.INTELIGENCIA_DIGITAL + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Gestão do conhecimento'
                          value={report.GESTAO_DO_CONHECIMENTO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.lightGreen}
                          title='Influência'
                          value={report.INFLUENCIA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                      </Grid>
                    </Group>
                    <Group width="25%">
                      <SubTitle>Pessoas </SubTitle>
                      <Grid xs="3">
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Acabativa'
                          value={report.ACABATIVA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Soluções de conflitos'
                          value={report.SOLUCOES_DE_CONFLITOS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Relacionamento interpessoal'
                          value={report.RELACIONAMENTO_INTERPESSOAL + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Trabalho em equipe'
                          value={report.TRABALHO_EM_EQUIPE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Colaboração'
                          value={report.COLABORACAO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Orientação ao cliente'
                          value={report.ORIENTACAO_AO_CLIENTE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Concessão de feedbacks'
                          value={report.CONCESSAO_DE_FEEDBACK + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Mentalidade inclusiva'
                          value={report.MENTALIDADE_INCLUSIVA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Construção de alianças'
                          value={report.CONSTRUCAO_DE_ALIANCAS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Comunicação'
                          value={report.COMUNICACAO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Excelencia no atendimento'
                          value={report.EXCELENCIA_NO_ATENDIMENTO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Gestão de pessoas'
                          value={report.GESTAO_DE_PESSOAS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Desenvolvimento de talentos'
                          value={report.DESENVOLVIMENTO_DE_TALENTOS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                      </Grid>
                    </Group>
                  </Row>
                  :
                  <>
                    <SubTitle>Mudanças </SubTitle>
                    <Grid xs="3">
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Engajamento contínuo'
                        value={report.ENGAJAMENTO_CONTINUO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Flexibilidade'
                        value={report.FLEXIBILIDADE + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Gestão da ansiedade'
                        value={report.GESTAO_DA_ANSIEDADE + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Gestão de mudanças'
                        value={report.GESTAO_DA_MUDANCA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Mentalidade de startup'
                        value='68%'
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Resiliência'
                        value={report.RESILIENCIA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Negociação'
                        value={report.NEGOCIACAO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Orientação ao negócio'
                        value={report.ORIENTACAO_AO_NEGOCIO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.yellow}
                        title='Planejamento'
                        value={report.PLANEJAMENTO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                    </Grid>
                    <SubTitle>Resultados </SubTitle>
                    <Grid xs="3">
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Alinhamento corporativo'
                        value={report.ALINHAMENTO_CORPORATIVO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Visão de longo prazo'
                        value={report.VISAO_DE_LONGO_PRAZO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Visão sistemica'
                        value={report.VISAO_SISTEMICA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Velocidade na tomada de decisão'
                        value={report.VELOCIDADE_NA_TOMADA_DE_DECISAO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Gestão da qualidade'
                        value={report.GESTAO_DA_QUALIDADE + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Senso de dono'
                        value={report.SENSO_DE_DONO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Proatividade'
                        value={report.PROATIVIDADE + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Venda consultiva'
                        value={report.VENDA_CONSULTIVA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Gestão de projetos'
                        value={report.GESTAO_DE_PROJETOS + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Orientação ao resultado'
                        value={report.ORIENTACAO_AO_RESULTADO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Mentalidade de startup'
                        value={report.MENTALIDADE_DE_STARTUP + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Gestão do tempo'
                        value={report.GESTAO_DO_TEMPO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Melhoria de desempenho'
                        value={report.MELHORIA_DE_DESEMPENHO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Identificação de propósito'
                        value={report.IDENTIFICACAO_DE_PROPOSITO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Intraempre- endedorismo'
                        value={report.INTRAEMPREENDEDORISMO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Solução de problemas'
                        value={report.SOLUCAO_DE_PROBLEMAS + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightBlue}
                        title='Inovação'
                        value={report.INOVACAO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                    </Grid>
                    <SubTitle>Mental </SubTitle>
                    <Grid xs="3">
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Análise de dados'
                        value={report.ANALISE_DE_DADOS + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Aprendizado constante'
                        value={report.APRENDIZADO_CONSTANTE + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Capacidade analítica'
                        value={report.CAPACIDADE_ANALITICA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Liderança estratégica'
                        value={report.LIDERANCA_ESTRATEGICA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Capacidade de desaprender'
                        value={report.CAPACIDADE_DE_DESAPRENDER + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Raciocínio lógico'
                        value={report.RACIOCINIO_LOGICO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Pensamento estratégico'
                        value={report.PENSAMENTO_ESTRATEGICO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Inteligência emocional'
                        value={report.INTELIGENCIA_EMOCIONAL + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Liderança inspiradora'
                        value={report.LIDERANCA_INSPIRADORA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Escuta ativa'
                        value={report.ESCUTA_ATIVA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Pensamento ágil'
                        value={report.PENSAMENTO_AGIL + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Inteligência digital'
                        value={report.INTELIGENCIA_DIGITAL + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Gestão do conhecimento'
                        value={report.GESTAO_DO_CONHECIMENTO + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                      <CardColor
                        width="200px"
                        bgColor={colors.lightGreen}
                        title='Influência'
                        value={report.INFLUENCIA + '%'}
                        font="12px"
                        progress
                        height="70px"
                      />
                    </Grid>
                    <SubTitle>Pessoas </SubTitle>
                    <Grid xs="3">
                    <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Acabativa'
                          value={report.ACABATIVA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Soluções de conflitos'
                          value={report.SOLUCOES_DE_CONFLITOS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Relacionamento interpessoal'
                          value={report.RELACIONAMENTO_INTERPESSOAL + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Trabalho em equipe'
                          value={report.TRABALHO_EM_EQUIPE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Colaboração'
                          value={report.COLABORACAO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Orientação ao cliente'
                          value={report.ORIENTACAO_AO_CLIENTE + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Concessão de feedbacks'
                          value={report.CONCESSAO_DE_FEEDBACK + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Mentalidade inclusiva'
                          value={report.MENTALIDADE_INCLUSIVA + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Construção de alianças'
                          value={report.CONSTRUCAO_DE_ALIANCAS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Comunicação'
                          value={report.COMUNICACAO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Excelencia no atendimento'
                          value={report.EXCELENCIA_NO_ATENDIMENTO + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Gestão de pessoas'
                          value={report.GESTAO_DE_PESSOAS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                        <CardColor
                          width="200px"
                          bgColor={colors.alertRed}
                          title='Desenvolvimento de talentos'
                          value={report.DESENVOLVIMENTO_DE_TALENTOS + '%'}
                          font="12px"
                          progress
                          height="70px"
                        />
                    </Grid>
                  </>
                }
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
          <ContainerCard top="-40px" bottom="0.1%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>De 0 a 10, qual sua avaliação sobre a facilidade para realizar esse teste?</SubTitle>
                <DescriptionGroup>
                  <Note onClick={() => handleChangeNote(1)} active={note1}><p>1</p></Note>
                  <Note onClick={() => handleChangeNote(2)} active={note2}><p>2</p></Note>
                  <Note onClick={() => handleChangeNote(3)} active={note3}><p>3</p></Note>
                  <Note onClick={() => handleChangeNote(4)} active={note4}><p>4</p></Note>
                  <Note onClick={() => handleChangeNote(5)} active={note5}><p>5</p></Note>
                  <Note onClick={() => handleChangeNote(6)} active={note6}><p>6</p></Note>
                  <Note onClick={() => handleChangeNote(7)} active={note7}><p>7</p></Note>
                  <Note onClick={() => handleChangeNote(8)} active={note8}><p>8</p></Note>
                  <Note onClick={() => handleChangeNote(9)} active={note9}><p>9</p></Note>
                  <Note onClick={() => handleChangeNote(10)} active={note10}><p>10</p></Note>
                </DescriptionGroup>
                <DescriptionGroup>
                  <FormControl sx={{ backgroundColor: colors.white, m: 1, width: "100%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
                    <TextField autoWidth size="small" color="info" disabled={false} placeholder="Comente sua nota" value={comentary} onChange={handleComentary}
                      multiline
                      rows={8}
                      maxRows={8}
                      InputProps={{
                        sx: {
                          'input': {
                            padding: 0.7,
                            '&::placeholder': {
                              fontSize: '12px',
                              fontFamily: 'Poppins',
                              fontStyle: 'normal'
                            }
                          },
                        },
                      }}
                    />
                  </FormControl>
                </DescriptionGroup>
                < ContainerButton>
                  <Button
                    type="button"
                    width="140px"
                    fontSize="13px"
                    text="Enviar"
                    hoverColor={colors.lightBlue}
                    color={colors.white}
                    bgColor={colors.lightBlue}
                    onClick={onSend}
                  />
                </ ContainerButton>
              </GroupItems>
            </Card>
          </ContainerCard>
      </ContainerCard>
    </Container>
  )
}

export default BehavioralReport;
