import React, { useState } from 'react';
import Modal from "react-modal";

//ASSETS
import Finish from './../../assets/img/Finish.svg';
import Activity from './../../assets/img/ic_Activity.svg';
import { TextField, FormControl } from '@mui/material';
import { validEmail } from './../../utils/validEmail.js';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from './../../firebaseConfig';

//COMPONENTS
import Button from '../Button';

//STYLES
import colors from '../../styles/colors'
import { ModalContainer, ModalContent, Image, Title, SubLabel, Row, Icon, DescriptionData, ErrorMessage } from './styles';

Modal.setAppElement("#root");

function PasswordResetModal({
  isOpen,
  onRequestClose,
  width
}) {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: `${width}`,
      minWidth: '450px',
      height: '300px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 9999
    },
  };

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setMsg("");
    setError(false);
  }

  const onNext = async (e) => {
    e.preventDefault();
    if (email.length > 0) {
      if (!validEmail(email)) {
        setMsg("E-mail inválido! Digite um e-mail válido!");
        setError(true);
      } else {
        //Reset de senha
        await sendPasswordResetEmail(auth, email)
          .then(() => {
            setMsg("E-mail enviado com sucesso!");
            setError(false);
            setEmail("");
          }).catch((error) => {
            setMsg("E-mail não cadastrado!");
            setError(true);
            setEmail("");
          })
      }
    } else {
      setMsg("É necessário preeencher todos os campos!");
      setError(true);
    }
  }

  const onClose = () => {
    onRequestClose();
    setMsg("");
    setError(false);
  }

  return (
    <ModalContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          {/* <Image src={Finish} alt="Finish" /> */}
          <Title>Para recuperar a senha,</Title>
          <Title>informe o seu e-mail cadastrado:</Title>
          <FormControl sx={{ m: 2, width: "80%", fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal' }}>
            <TextField label="E-mail" variant="outlined" size="small" value={email} onChange={onChangeEmail} color="info" disabled={false} />
          </FormControl>
          <ErrorMessage color={error ? colors.alertRed : colors.lowGreen}>{msg}</ErrorMessage>
          <Row top="0%">
            <Button
              type="button"
              width="150px"
              fontSize="13px"
              text="Voltar"
              color={colors.lightBlue}
              bgColor={colors.transparent}
              onClick={onClose}
            />
            <Button
              type="button"
              width="150px"
              fontSize="13px"
              text="Enviar"
              hoverColor={colors.lightBlue}
              color={colors.white}
              bgColor={colors.lightBlue}
              onClick={onNext}
            />
          </Row>
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default PasswordResetModal;


