import React, { useState, useContext, useEffect } from 'react';
import Modal from "react-modal";

import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import StoreContext from '../Store/Context';

//COMPONENTS
import Button from '../Button';
import ErrorModal from '../ErrorModal';
import AlertModal from '../AlertModal';

//STYLES
import colors from '../../styles/colors'
import { ModalContainer, ModalContent, Image, Title, SubLabel, Row, Icon, DescriptionData, Group } from './styles';
import api from '../../api';



const NewCareerModal = (prop) => {
  const isOpen = prop.isOpen;
  const onRequestClose = prop.onRequestClose;
  const width = prop.width;
  const getData = prop.getData;
  const selected = [prop.selected];

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState('');
  const [code, setCode] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState(selected.length > 0 ? selected[0].nome : '');
  const [description, setDescription] = useState(selected.length > 0 ? selected[0].descricao : '');


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



  useEffect(async () => {
    setName(selected.length > 0 ? selected[0].nome : '');
    setDescription(selected.length > 0 ? selected[0].descricao : '');
    setCode(selected.length > 0 ? selected[0].codigo : '');
    getCompanies();
  }, [prop, alert]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 570 ? '800px' : '380px',
      minWidth: '450px',
      height: '420px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1
    },
  };

  let fieldRow1 = width <= 570 ? '80%' : 350;
  let fieldRow2 = width <= 570 ? '80%' : 718;


  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setCompanies(data);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setCompanies(data);
  }


  const linkCompanie = async (career) => {

    const body = {
      "company_id": userGlobal[0].id,
      "carreira_id": career
    }

    await api.post('/companies/carreiras', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      await onRequestClose();
      await setMsg("Carreira cadastrada com sucesso!");
      await setAlert(true);
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const onCheck = async () => {

    const data = {
      "codigo": code,
      "nome": name,
      "descricao": description
    }

    selected[0].id ?
      await api.put('/carreiras/' + selected[0].id, data, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
        await onRequestClose();
        await setMsg("Carreira atualizada com sucesso!");
        await setAlert(true);
      }).catch(async (error) => {
        console.log('erro', error);
        await onRequestClose();
        await setError(true);
      })
      :
      await api.post('/carreiras', data, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
        linkCompanie(response.data.id);
      }).catch(async (error) => {
        console.log('erro', error);
        await onRequestClose();
        await setError(true);
      });
  }

  const onCloseError = () => {
    setError(false);
  }

  const onCloseAlert = () => {
    setAlert(false);
  }


  const handleCode = (e) => {
    const value = e.target.value;
    setCode(value);
  }


  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
  }


  const handleDescription = (e) => {
    const value = e.target.value;
    setDescription(value);
  }


  return (
    <ModalContainer>
      {error ? <ErrorModal isOpen={error} onRequestClose={onCloseError} /> : false}
      {alert ? <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} /> : false}
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Title>Nova carreira</Title>
          <Group>
            {/* <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
              <InputLabel id="job">Empresa</InputLabel>
              <Select
                value={company}
                onChange={handleCompany}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Empresa" />}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.white, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                {companies.map((item) => {
                  return <MenuItem value={item.id}>{item.nome_fantasia}</MenuItem>
                })}
              </Select>
            </FormControl> */}
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow2 }}>
              <TextField
                value={name}
                onChange={handleName}
                label="Nome da carreira"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow2 }}>
              <TextField
                value={code}
                onChange={handleCode}
                label="Código"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow2 }}>
              <TextField
                value={description}
                onChange={handleDescription}
                label="Descrição"
                id="outlined-size-small"
                size="small"
                multiline
                rows={4}
                maxRows={4}
              />
            </FormControl>
          </Group>

          {width > 570 ?
            <Row top="0%">
              <Button
                type="button"
                width="300px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="400px"
                fontSize="13px"
                text={"Salvar"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </Row>
            :
            <>
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text={"Salvar"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </>
          }
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default NewCareerModal;

