import React, { useState, useEffect, useContext } from 'react';
import HeaderAdmin from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import Table from './../../components/Table';
import MenuData from '../../components/Menu/MenuUsers';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import ErrorModal from './../../components/ErrorModal';
import { ThreeDots } from 'react-loader-spinner'

//DATA
import api from '../../api';

import {
  Container,
  Header,
  Logo,
  Title,
  Card
} from './styles';


const ActiveUsers = () => {
  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [jsonDetails, setJsonDetails] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [object, setObject] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);


  useEffect(async () => {
    setWidth(window.screen.width);
    getData();
  }, [width]);

  const changeDetails = async (item) => {
    await setJsonDetails(item);
    setDetails(!details);
  }

  const onCloseDetails = async () => {
    await setDetails(!details);
  }

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.plan}</th>
      <th class={width <= 570 ? 'hidden' : ''} key={index}>{item.status}</th>
      <th key={index}>{item.button}</th>
    </>
  )

  const getData = async () => {
    // await api.get('/users?status=S', {
    await api.get('/companies/users/' + userGlobal[0].id, {

      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      // const data = response.data.users;
      const data = response.data.data;
      setObject(data);
      let json = [];
      data.map((item) => {
        json.push({ "id": item.id, "name": item.name, "plan": "Sem Plano", "status": item.status == 'S' ? 'Ativo' : 'Inativo', details: item })
      });
      let num = data.filter((item) => {
        return item.status == 'S';
      });
      setData(json);
      setIsVisible(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.plan}</td>
      <td class={width <= 570 ? 'hidden' : ''}>{item.status}</td>
      <td>
        <Button
          type="button"
          width="80px"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          fontSize={'12px'}
          height={'30px'}
          margin={'0px'}
          text="Detalhes"
          onClick={() => changeDetails(item.details)}
        /></td>
    </tr>
  )

  const users =
  {
    head: [
      { name: 'Nome do usuário', plan: 'Plano', status: 'Status', button: ' ' }
    ],
    body: data
  }

  const onCloseError = () => {
    setError(false);
  }

  return (
    <Container>
      {details ?
        <MenuData isOpen={details} onClose={onCloseDetails} title="Detalhes do Usuário" width={width} data={jsonDetails} />
        : <></>}
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <ResponsiveAppBar />
      {/* <HeaderAdmin width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <Title>Usuários ativos</Title>
      <Card>
        {isVisible ?
          <Table
            limit='10'
            headData={users.head}
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={users.body}
            renderBody={(item, index) => renderBody(item, index)}
          />
          :
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={colors.gray3}
            ariaLabel="three-dots-loading"
            wrapperStyle={{ width: "80px", textAlign: "center", margin: "auto" }}
            wrapperClassName=""
            visible={true}
          />}
      </Card>
    </Container>
  )
}

export default ActiveUsers;
