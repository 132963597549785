import { color } from '@mui/system';
import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
    /* Shape */
    position: relative;
    width: 100%;
    height: 100vh;

    background: ${colors.darkBlue};
    `;



export const Header = styled.div`
    position: relative;
    background-color: ${colors.white};
    left: 0%;
    heigth: 100px;
    right: 0%;
    top: 0%;
    bottom: 0%;
    box-shadow: inset 0px -1px 0px #E2E2EA;
  `;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 140px;
  }

  @media (max-width: 570px) {  
    width: 100px;
  }
`;

export const Icon = styled.img`
  position: relative;
  text-align: center;
  padding-right : ${(props) => props.right || "0px"};
  width: ${(props) => props.width || "40px"};
  @media (max-width: 870px) {
    width: ${(props) => props.width || "40px"};
  }

  @media (max-width: 570px) {  
    width: ${(props) => props.width || "40px"};
  }
`;


export const Group = styled.div`
    text-align: center;
    padding-top: 80px;
`;


export const Title = styled.h1`
      position: relative;
      height: 52px;
      font-size: 40px;
      padding-bottom: 4%;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 37px;
      }

      @media (max-width: 570px) {  
        font-size: 27px;
      }

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${colors.white};
 `;
 
 export const DescriptionGroup = styled.div`
      padding-bottom: 2%;
      @media (max-width: 1500px) {
        padding-bottom: 6%;
        padding-top: 3%;
      }
      @media (max-width: 870px) {
        padding-bottom: 20%;
        padding-top: 5%;
      }

      @media (max-width: 570px) {  
        padding-bottom: 35%;
        padding-top: 5%;
      }

      @media (max-width: 425px) {  
        padding-bottom: 55%;
        padding-top: 5%;
      }

      @media (max-width: 320px) {  
        padding-bottom: 70%;
        padding-top: 5%;
      }
 `;


 export const SubLabel = styled.label`
      position: relative;
      text-align: center !important;
      height: 52px;
      font-size: 16px;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 16px;
      }

      @media (max-width: 570px) {  
        font-size: 15px;
      }

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${(props) => props.color || colors.white};
`;

export const Description = styled.h3`
      position: relative;
      text-align: center !important;
      height: 52px;
      top: 2px;
      font-size: 16px;
      padding-right: 30%;
      padding-left: 30%;

      /* H1 - Web */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;

      line-height: 130%;
      /* identical to box height, or 52px */

      @media (max-width: 870px) {
        font-size: 16px;
      }

      @media (max-width: 570px) {  
        font-size: 15px;
      }

      text-align: center;
      letter-spacing: 0.116667px;

      color: ${(props) => props.color || colors.white};
`;

export const ContainerTitle = styled.div`
      position: relative;
      justify-content: space-between;
      display: flex;
      padding-right: 30%;
      padding-left: 30%;

      @media (max-width: 425px) {  
        padding-right: 20%;
        padding-left: 20%;
      }
`;

export const Line = styled.div`
      opacity: 0.2;
      border-bottom: 1px solid ${colors.lightBlue};
      padding-top: ${(props) => props.top || "8%"};
    }
    @media (max-width: 870px) {
      padding-top: ${(props) => props.top || "10%"};
    }

    @media (max-width: 570px) {  
      padding-top: ${(props) => props.top || "12%"};
    }

    @media (max-width: 425px) {  
      padding-top: ${(props) => props.top || "15%"};
    }

    @media (max-width: 320px) {  
      padding-top: ${(props) => props.top || "35%"};
    }
`;

export const Row = styled.div`
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      @media (max-width: 1500px) {
        padding-top: ${(props) => props.top || "0%"};
      }
      @media (max-width: 870px) {
        padding-top: ${(props) => props.top || "3%"};
      }

      @media (max-width: 570px) {  
        padding-top: ${(props) => props.top || "10%"};
      }

      @media (max-width: 425px) {  
        padding-top: ${(props) => props.top || "10%"};
      }

      @media (max-width: 320px) {  
        padding-top: ${(props) => props.top || "35%"};
      }
`;

export const DescriptionData = styled.p`
          font-size: 16px;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: ${(props) => props.weight || "400"};;

          line-height: 130%;
          /* identical to box height, or 52px */

          @media (max-width: 870px) {
            font-size: 16px;
            padding-top: 0.5%;
          }

          @media (max-width: 570px) {  
            font-size: 16px;
            padding-top: 1%;
          }

          text-align: center;
          letter-spacing: 0.116667px;

          color: ${colors.white};
`;

