import React, { useState, useContext, useEffect } from 'react';
import Modal from "react-modal";

import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import StoreContext from '../Store/Context';

//COMPONENTS
import Button from '../Button';
import ErrorModal from '../ErrorModal';
import AlertModal from '../AlertModal';

//STYLES
import colors from '../../styles/colors'
import { ModalContainer, ModalContent, Image, Title, SubLabel, Row, Icon, DescriptionData, Group, Card, SubTitle } from './styles';
import api from '../../api';
import * as Io from "react-icons/io5";


const HardskillCalibration = (prop) => {
  const isOpen = prop.isOpen;
  const onRequestClose = prop.onRequestClose;
  const width = prop.width;
  const career = prop.career;

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [HSCareer, setHSCareer] = useState([]);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  useEffect(async () => {

    getCareer(career)
  }, [prop, alert]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 570 ? '800px' : '380px',
      minWidth: '450px',
      height: '600px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1
    },
  };

  let fieldRow1 = width <= 570 ? '80%' : 340;
  let fieldRow2 = width <= 570 ? '80%' : 718;
  let fieldRow3 = width <= 570 ? '80%' : 280;


  // const postHardskill = async () => {
  //   await setVisibleHards(false);
  //   const json = {
  //     "name": hardskill.nome,
  //     "hard_skill_id": hardskill.id,
  //     "valor": value
  //   }
  //   await hards.push(json);
  //   await setVisibleHards(true);
  // }

  const getCareer = async (id) => {
    await api.get('/carreiras/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      setHSCareer(data[0].persona.persona.hard_skills);
      console.log('careerHS', data[0].persona.persona.hard_skills)
      getHardskill(data[0].persona.persona.hard_skills);
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const onCloseError = () => {
    setError(false);
  }

  const onCloseAlert = () => {
    setAlert(false);
  }

  const getHardskill = async (hards) => {
    await api.get('/hardskill', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      const list = hards.map((item) => {
        let arr = data.filter((i) => {
          return i.nome == item.nome
        })[0];
        return arr
      })
      console.log('list', list)
      setList(list);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const getInputs = async () => {
    return
  }

  return (
    <ModalContainer>
      {error ? <ErrorModal isOpen={error} onRequestClose={onCloseError} /> : false}
      {alert ? <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} /> : false}
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Title>Calibrar Hardskill da carreira</Title>

          <Group>

            {list.map((i) => {
              return i.tipo == "valor" ?
                <Group>
                  <FormControl sx={{ m: 0, width: 220 }}>
                    <InputLabel id="value">{i.nome}</InputLabel>
                    <Select
                      labelId="value"
                      id="value"
                      InputLabelProps={{ shrink: true, required: true }}
                      // value={value}
                      size="small"
                      color="info"
                      // onChange={handleValue}
                      disabled={false}
                      input={<OutlinedInput label="Nível de experiência" />}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </Group>
                : i.tipo == "select" ?
                  <Group>
                    <FormControl sx={{ m: 0, width: 220 }}>
                      <InputLabel id="value">{i.nome}</InputLabel>
                      <Select
                        labelId="value"
                        id="value"
                        InputLabelProps={{ shrink: true, required: true }}
                        // value={value}
                        size="small"
                        color="info"
                        // onChange={handleValue}
                        disabled={false}
                        input={<OutlinedInput label="Resposta" />}
                      >
                        <MenuItem value={"S"}>Sim</MenuItem>
                        <MenuItem value={"N"}>Não</MenuItem>
                      </Select>
                    </FormControl>
                  </Group>
                  : i.tipo == "Idioma" ?
                    <Group>
                      <FormControl sx={{ m: 0, width: 220 }}>
                        <InputLabel id="job">{i.nome}</InputLabel>
                        <Select
                          labelId="job"
                          id="job"
                          InputLabelProps={{ shrink: true, required: true }}
                          // value={value}
                          size="small"
                          color="info"
                          // onChange={handleValue}
                          disabled={false}
                          input={<OutlinedInput label="Nível" />}
                        >
                          <MenuItem value={"Básico"}>Básico</MenuItem>
                          <MenuItem value={"Intermediário"}>Intermediário</MenuItem>
                          <MenuItem value={"Avançado"}>Avançado</MenuItem>
                          <MenuItem value={"Fluente"}>Fluente</MenuItem>
                        </Select>
                      </FormControl>
                    </Group>
                    : <></>
            })
            }
          </Group>



          {width > 570 ?
            <Row top="0%">
              <Button
                type="button"
                width="300px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="400px"
                fontSize="13px"
                text={"Salvar"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
              // onClick={onCheck}
              />
            </Row>
            :
            <>
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text={"Salvar"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
              // onClick={onCheck}
              />
            </>
          }
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default HardskillCalibration;

