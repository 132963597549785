import React, { useContext, useState, useEffect } from 'react';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import './../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from './../../ErrorModal';

const MenuAbout = (prop) => {

    const { token, setToken } = useContext(StoreContext);
    const [list, setList] = useState([]);
    const [hardskill, setHardskill] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);

    useEffect(async () => {
        getHardskill();
    }, []);

    const handleHardskill = (e) => {
        const value = e.target.value;
        setHardskill(value);
    }

    const handleValue = (e) => {
        const value = e.target.value;
        setValue(value);
    }


    const postHardskill = async () => {
        const json = {
            "user_id": prop.user[0].id,
                "hard_skill_id": hardskill.id,
                "valor": value
        }


        await api.post('/user-hardskill', json, {
            headers: {
                'authorization': 'Bearer ' + token.refreshToken
            }
        }).then(async (response) => {
            prop.onClose();
        }).catch((error) => {
            console.log('erro', error);
            setError(true);
        });
    }

    const getHardskill = async () => {
        await api.get('/hardskill', {
            headers: {
                'authorization': 'Bearer ' + token.refreshToken
            }
        }).then(async (response) => {
            const data = response.data.data;
            setList(data);
        }).catch((error) => {
            console.log('erro', error);
            setError(true);
        });
    }

    const onCloseError = () => {
        setError(false);
    }

    let icon = prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;


    return (
        <Slide right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <ErrorModal isOpen={error} onRequestClose={onCloseError} />
            <GroupLine>
                <Title>Hardskill</Title>
            </GroupLine>
            <Container>
                <Group>
                    <FormControl sx={{ m: 0, width: 220 }}>
                        <InputLabel id="value">Hardskill</InputLabel>
                        <Select
                            labelId="value"
                            id="value"
                            InputLabelProps={{ shrink: true, required: true }}
                            value={hardskill}
                            size="small"
                            color="info"
                            disabled={false}
                            onChange={handleHardskill}
                            input={<OutlinedInput label="Hardskill" />}
                        >
                            {list.map((v, i) => {
                                return <MenuItem value={v}>{v.nome}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Group>
                {hardskill.tipo == "valor" ?
                    <Group>
                        <FormControl sx={{ m: 0, width: 220 }}>
                            <InputLabel id="value">Nível de experiência</InputLabel>
                            <Select
                                labelId="value"
                                id="value"
                                InputLabelProps={{ shrink: true, required: true }}
                                value={value}
                                size="small"
                                color="info"
                                onChange={handleValue}
                                disabled={false}
                                input={<OutlinedInput label="Nível de experiência" />}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                            </Select>
                        </FormControl>
                    </Group>
                    :
                    hardskill.tipo == "select" ?
                        <Group>
                            <FormControl sx={{ m: 0, width: 220 }}>
                                <InputLabel id="value">Resposta</InputLabel>
                                <Select
                                    labelId="value"
                                    id="value"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    value={value}
                                    size="small"
                                    color="info"
                                    onChange={handleValue}
                                    disabled={false}
                                    input={<OutlinedInput label="Resposta" />}
                                >
                                    <MenuItem value={"S"}>Sim</MenuItem>
                                    <MenuItem value={"N"}>Não</MenuItem>
                                </Select>
                            </FormControl>
                        </Group>
                        :
                        hardskill.tipo == "Idioma" ?
                            <Group>
                                <FormControl sx={{ m: 0, width: 220 }}>
                                    <InputLabel id="job">Nível</InputLabel>
                                    <Select
                                        labelId="job"
                                        id="job"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        value={value}
                                        size="small"
                                        color="info"
                                        onChange={handleValue}
                                        disabled={false}
                                        input={<OutlinedInput label="Nível" />}
                                    >
                                        <MenuItem value={"Básico"}>Básico</MenuItem>
                                        <MenuItem value={"Intermediário"}>Intermediário</MenuItem>
                                        <MenuItem value={"Avançado"}>Avançado</MenuItem>
                                        <MenuItem value={"Fluente"}>Fluente</MenuItem>
                                    </Select>
                                </FormControl>
                            </Group>
                            :
                            <></>
                }

                <Button
                    type="button"
                    width="230px"
                    hoverColor={colors.lightBlue}
                    text="Salvar alterações"
                    fontSize="12px"
                    onClick={postHardskill}
                />
            </Container>
        </Slide>
    )
}

export default MenuAbout;
