import styled from 'styled-components';

import colors from '../../styles/colors';

export const Btn = styled.button`
  width: ${(props) => props.width || '180px'};
  background-color: ${(props) => props.bgColor || colors.lightBlue};
  height: ${(props) => props.height || '42px'};
  border-radius: 50px;
  border: ${(props) => props.border ? '2px solid' + colors.white : '2px solid' + colors.lightBlue};
  outline: none;
  color: ${(props) => props.color || colors.white};
  font-weight: 600;
  margin: ${(props) => props.margin || '10px 0'};
  transition: 0.5s;
  font-family: 'Poppins';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: ${(props) => props.fontSize || '16px'} !important;
  line-height: 24px;
  margin-right: 10px;

  &:hover {
    color: ${(props) => props.color || colors.white};
    background-color: ${(props) => props.hoverColor || 'transparent'};
    border: ${(props) => props.hoverBorder || '1px solid' + colors.lightBlue}
  }
  &:disabled {
    cursor: initial;
    background-color: ${(props) => props.bgColor || colors.lightBlue};
    border: 1px solid ${colors.lightBlue};
    opacity: 0.3;
  }
`;

export const BtnRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
