import React from 'react';
import { Shape, Item } from './styles';
import { slide as Menu } from 'react-burger-menu'
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import './../../../styles/Sidebar.css';

const HeaderLogin = (prop) => {

    let icon = prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;

    return (
        <Menu right customBurgerIcon={icon} noOverlay disableCloseOnEsc >
            <a id="login" className="menu-item" href="/login">Login</a>
            <a id="admin" className="menu-item" href="/loginAdmin">Acesso administrativo</a>
        </Menu>
    )
}

export default HeaderLogin;
