import React, { useState, useContext, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import colors from './../../styles/colors';
import logo from './../../assets/img/logo.svg';
import logoPNG from './../../assets/img/logo.png';
import User from "./../../assets/img/User.png";
import { useHistory } from 'react-router-dom';
import { Logo } from './styles';
import LogoutModal from '../LogoutModal';
import StoreContext from '../Store/Context';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import api from '../../api';


function ResponsiveAppBar() {
  const history = useHistory();
  const { token, setToken, setLoginHash, loginHash, uuidSession, setUuidSession, userGlobal, setUserGlobal } = useContext(StoreContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElItem, setAnchorElItem] = useState(null);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const [logout, setLogout] = useState(false);
  const name = token.displayName || token.email;
  const nameUser = name.length > 0 ? name.trim() : '';
  const nameUpper = name.length > 0 ? nameUser.toUpperCase() : '';
  const avatar = nameUpper.split(' ');
  const nameAvatar = avatar[0].substr(0, 1);

  const pagesMobile = [
    {
      name: 'Painel iGrown',
      url: '/'
    },
    {
      name: 'Painel Admin',
      url: '/painelAdmin'
    },
    {
      name: 'Painel de Carreiras',
      url: '/painelDeCarreiras'
    },
    {
      name: 'Painel iGrown-X',
      url: '/painelDaEmpresa'
    },
    {
      name: 'Detalhes do Time',
      url: '/painelDoTime'
    },
    // {
    //   name: 'Painel de Vagas',
    //   url: '/painelDeVagas'
    // },
    // {
    //   name: 'Comparativo Fit Persona',
    //   url: '/fitPersona'
    // },
    {
      name: 'Comparativo Fit Colaboradores',
      url: '/fitColaboradores'
    },
    {
      name: 'Empresas ativas',
      url: '/empresasAtivas'
    },
    {
      name: 'Usuário ativos',
      url: '/usuariosAtivos'
    },
    {
      name: 'Planos contratados',
      url: '/planosContratados'
    },
    {
      name: 'NPS',
      url: '/nps'
    }
  ]
  const settings = [
    {
      name: 'Perfil',
      url: '/perfil'
    },
    data.comportamental ? {
      name: 'Relatório comportamental',
      url: '/relatorioComportamental'
    } : false,
    data.valores ? {
      name: 'Relatório de âncora de carreira',
      url: '/relatorioDeValoresDeCarreira'
    } : false,
    data.cultural ? {
      name: 'Relatório de cultura',
      url: '/relatorioDeCultura'
    } : false,
    {
      name: 'Sair',
      url: 'Logout'
    }
  ]
  const pagesWeb = [
    {
      name: 'Painel da Empresa',
      pages: [
        {
          name: 'Painel Admin',
          url: '/painelAdmin'
        },
        {
          name: 'Painel de Carreiras',
          url: '/painelDeCarreiras'
        },
        {
          name: 'Painel iGrown-X',
          url: '/painelDaEmpresa'
        },
        {
          name: 'Detalhes do Time',
          url: '/painelDoTime'
        },
        // {
        //   name: 'Painel de Vagas',
        //   url: '/painelDeVagas'
        // },
        // {
        //   name: 'Comparativo Fit Persona',
        //   url: '/fitPersona'
        // },
        {
          name: 'Comparativo Fit Colaboradores',
          url: '/fitColaboradores'
        },
        {
          name: 'Empresas ativas',
          url: '/empresasAtivas'
        },
        {
          name: 'Usuário ativos',
          url: '/usuariosAtivos'
        },
        {
          name: 'Planos contratados',
          url: '/planosContratados'
        },
        {
          name: 'NPS',
          url: '/nps'
        }
      ]
    },
    {
      name: 'Painel iGrown',
      url: '/',
    }
  ];

  const handleSignOut = () => {
    setToken(null);
    setLoginHash(null);
    setUuidSession(null);
    onRedirect('/login');
  }

  useEffect(async () => {
    console.log('token', token)
    getTests();
    getUser();
  }, []);

  const getTests = async () => {
    const body = {
      "email": token.email
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      await setData(data)
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const getUser = async () => {
    const setGlobal = new Set();
    await api.get('/users?status=S', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.users;
      let user = data.filter((item) => {
        return item.email == token.email
      })
      console.log('user menu', user)
      await api.get('/users/empresa/'+user[0].id, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
        const filter = response.data.data.filter((item) => {
          const duplicated = setGlobal.has(item.id);
          setGlobal.add(item.id);
          return !duplicated;
        })
      setUserGlobal(filter);
    }).catch((error) => {
      console.log('erro', error);
    });

    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenItemMenu = (event) => {
    setAnchorElItem(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseItemMenu = () => {
    setAnchorElItem(null);
  };

  const onRedirect = (url) => {
    if (url == "Logout") {
      setLogout(true);
    } else {
      history.push({
        pathname: url
      });
    }
  }


  return (
    <AppBar position="static"
      sx={{
        backgroundColor: colors.white
      }}>
      <LogoutModal isOpen={logout} width={width} onRequestClose={() => setLogout(false)} handleSignOut={handleSignOut} />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo web="block" mobile="none" src={logoPNG} alt="iGrown" onClick={() => onRedirect('/')} />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: colors.lightBlue }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pagesMobile.map((page) => (
                <MenuItem key={page} onClick={() => onRedirect(page.url)}>
                  <Typography textAlign="center" sx={{ my: 0, color: colors.darkBlue, display: 'block', backgroundColor: colors.white, fontFamily: 'Poppins', fontSize: "12px", textTransform: 'initial', fontWeight: 400, paddingTop: '5px', paddingRight: '30px' }}>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Logo web="none" mobile="block" src={logo} alt="iGrown" onClick={() => onRedirect('/')} />
          </Box>


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pagesWeb.map((page) => (
              page.pages ?
                <>
                  <Button
                    key={page}
                    onClick={handleOpenItemMenu}
                    sx={{ my: 0, color: colors.darkBlue, display: 'block', backgroundColor: colors.white, fontFamily: 'Poppins', textTransform: 'initial', fontWeight: 600, paddingTop: '15px', paddingRight: '30px' }}
                  >
                    {page.name}
                    <ExpandMoreIcon style={{ verticalAlign: 'top', marginLeft: '3px' }} />
                  </Button>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar3"
                    anchorEl={anchorElItem}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElItem)}
                    onClose={handleCloseItemMenu}
                  >
                    {page.pages.map((page) => (
                      <MenuItem key={page} onClick={() => onRedirect(page.url)}>
                        <Typography textAlign="center" sx={{ my: 0, color: colors.darkBlue, display: 'block', backgroundColor: colors.white, fontFamily: 'Poppins', fontSize: "12px", textTransform: 'initial', fontWeight: 400, paddingTop: '5px', paddingRight: '30px' }}>{page.name}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
                :
                <Button
                  key={page}
                  onClick={() => onRedirect(page.url)}
                  sx={{ my: 0, color: colors.darkBlue, display: 'block', backgroundColor: colors.white, fontFamily: 'Poppins', textTransform: 'initial', fontWeight: 600, paddingTop: '15px', paddingRight: '30px' }}
                >
                  {page.name}
                </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, my: 0 }}>
                {/* <Avatar alt="Avatar" src={User} variant="rounded" /> */}
                <Avatar sx={{ bgcolor: colors.darkBlue }} alt="Avatar" variant="rounded">{nameAvatar}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                setting.url && (
                  <MenuItem key={setting} onClick={() => onRedirect(setting.url)}>
                    <Typography textAlign="center" sx={{ my: 0, color: colors.darkBlue, display: 'block', backgroundColor: colors.white, fontFamily: 'Poppins', fontSize: "12px", textTransform: 'initial', fontWeight: 400, paddingTop: '5px', paddingRight: '30px' }}>{setting.name}</Typography>
                  </MenuItem>
                )
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
