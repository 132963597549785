export const removeAccentuation = (str) => {
    let newstr = '';
    const with_accent =
      'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
    const without_accent =
      'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
  
    for (let i = 0; i < str.length; i++) {
      let swap = false;
      for (let a = 0; a < with_accent.length; a++) {
        if (str.substr(i, 1) == with_accent.substr(a, 1)) {
          newstr += without_accent.substr(a, 1);
          swap = true;
          break;
        }
      }
      if (swap == false) {
        newstr += str.substr(i, 1);
      }
    }
    return newstr;
  };