import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import logo from './../../assets/img/logo.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import Info from './../../assets/img/Info.svg';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import TestsModal from './../../components/TestsModal';
import StoreContext from '../../components/Store/Context';
import ErrorModal from './../../components/ErrorModal';
import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation } from 'react-router';
import api from '../../api';

import {
  Container,
  Header,
  Logo,
  Card,
  Icon,
  SubTitle,
  Title,
  GpButton,
  Group,
  DescriptionGroup,
  SubLabel,
  Line,
  Step,
  Note,
  ContainerButton,
  GpNote,
  Description,
  GpDescription
} from './styles';

const StartCareer = () => {

  const defaultTheme = createMuiTheme();
  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
          backgroundColor: colors.darkBlue
        }
      }
    }
  });


  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: colors.lightBlue,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: colors.lightBlue,
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const { token, setToken } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [value, setValue] = useState('');
  const [note1, setNote1] = useState(false)
  const [note2, setNote2] = useState(false)
  const [note3, setNote3] = useState(false)
  const [note4, setNote4] = useState(false)
  const [note5, setNote5] = useState(false)
  const [note6, setNote6] = useState(false)
  const [note7, setNote7] = useState(false)
  const [note8, setNote8] = useState(false)
  const [note9, setNote9] = useState(false)
  const [note10, setNote10] = useState(false)
  const [modal, setModal] = useState(false)
  const [valueNote, setValueNote] = useState(0)
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const [company, setCompany] = useState(false);
  const [time, setTime] = useState(0);
  const [resp, setResp] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(async () => {

    setWidth(window.screen.width)
  }, [width]);

  const onBack = () => {

    if (step == 1) {
      history.push({
        pathname: '/',
      })
    } else {
      if (step != 23) {
        resp.length = resp.length - 1;
        setStep(step - 1)
      } else {
        setStep(step - 1)
      }
    }
  }


  const handleChange = async (value) => {
    setValueNote(value);
    if (resp.length < 42) {
      resp.push(value)
    }else{
      resp.length = resp.length - 1;
      resp.push(value);
    }
    if (value == 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 2) {
      setNote1(true);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 3) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 4) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 5) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 6) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 7) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value == 8) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value == 9) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(true);
      setNote10(false);
    } else if (value == 10) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(true);
      setNote10(true);
    }
    onNext();

  };

  const cleanAll = async () => {
    if (step != 43) {
      await setStep(step + 1);
      await setNote1(false);
      await setNote2(false);
      await setNote3(false);
      await setNote4(false);
      await setNote5(false);
      await setNote6(false);
      await setNote7(false);
      await setNote8(false);
      await setNote9(false);
      await setNote10(false);
    }
  }

  const onNextYes = async (event) => {
    setCompany(true);
    if (step != 43) {
      await setStep(step + 1);
    }

  }

  const onNextNo = async (event) => {
    setCompany(false);

  }


  const onNext = async (event) => {
    await setTimeout(cleanAll, 500);
  }

  const onCloseError = () => {
    setError(false);
  }

  const onFinish = async () => {
    if (!company) {
      await setTime(4);
      await setModal(true);
    } else {
      setCompany(true);
      if (step != 43) {
        await setStep(step + 1);
      }else{
        await setModal(true);
      }
    }
  }

  const startTest = async () => {
    history.push({
      pathname: '/testeCultura',
      state: {
        vacancy: location.state.vacancy
      }
    });
  }

  const startReport = async () => {
    const vacancy = location.state.vacancy;
    if (vacancy) {
      history.push({
        pathname: '/hardskill',
      });
    } else {
      history.push({
        pathname: '/',
      });
    }
  }

  const sendTest = async () => {
    const body = {
      "custom_questions": {
          "pergunta01": "",
          "pergunta02": ""
      },
      "lang": "pt-br",
      "ID_DO_USUARIO": token.displayName || "",
      "EMAIL": token.email,
      "DATA_DA_ENTRADA": new Date(),
      "AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR": resp[0],
      "CLIMA_INTERNO": resp[1],
      "CONFIANCA_NA_LIDERANCA_IMEDIATA": resp[2],
      "CONFIANCA_NO_TIME_DE_TRABALHO": resp[3],
      "RECONHECIMENTO_E_VALORIZACAO": resp[4],
      "TRATAMENTO_HUMANIZADO": resp[5],
      "TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS": resp[6],
      "VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO": resp[7],
      "HORARIO_DE_TRABALHO": resp[8],
      "LOCAL_DE_TRABALHO": resp[9],
      "PACOTE_DE_BENEFICIOS": resp[10],
      "SALARIO": resp[11],
      "ABERTURA_PARA_MUDANCAS_E_INOVACAO": resp[12],
      "CELEBRACAO_DE_CONQUISTAS": resp[13],
      "CULTURA_E_VALORES_DA_EMPRESA": resp[14],
      "DESAFIOS_CONSTANTES": resp[15],
      "GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS": resp[16],
      "OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO": resp[17],
      "PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO": resp[18],
      "POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO": resp[19],
      "PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS": resp[20],
      "VOCE_ESTA_TRABALHANDO_ATUALMENTE": company ? "Sim" : "Não",
      "AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA": resp[21] || "",
      "CLIMA_INTERNO_NA_SUA_EMPRESA": resp[22] || "",
      "CONFIANCA_NA_SUA_LIDERANCA_IMEDIATA": resp[23] || "",
      "CONFIANCA_NO_SEU_TIME_DE_TRABALHO": resp[24] || "",
      "RECONHECIMENTO_E_VALORIZACAO_DO_SEU_TRABALHO": resp[25] || "",
      "TRATAMENTO_HUMANIZADO_DOS_COLABORADORES_DA_SUA_EMPRESA": resp[26] || "",
      "TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_EM_SUA_EMPRESA": resp[27] || "",
      "VISAO_DO_FUTURO_DA_SUA_EMPRESA": resp[28] || "",
     "HORARIO_DE_TRABALHO_DA_SUA_EMPRESA_OU_AREA": resp[29] || "",
      "SEU_LOCAL_DE_TRABALHO": resp[30] || "",
      "PACOTE_DE_BENEFICIOS_DA_SUA_EMPRESA": resp[31] || "",
      "SEU_SALARIO": resp[32] || "",
      "ABERTURA_DA_SUA_EMPRESA_PARA_MUDANCAS_E_INOVACAO": resp[33] || "",
      "CELEBRACAO_DE_CONQUISTAS_PELA_SUA_EMPRESA": resp[34] || "",
      "CULTURA_E_VALORES_DA_SUA_EMPRESA": resp[35] || "",
      "DESAFIOS_CONSTANTES_DADOS_PELA_SUA_EMPRESA": resp[36] || "",
      "GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_PELA_SUA_EMPRESA": resp[37] || "",
      "OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_NA_SUA_EMPRESA": resp[38] || "",
      "PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_NA_SUA_EMPRESA": resp[39] || "",
      "POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_NA_SUA_EMPRESA": resp[40] || "",
      "PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_DA_SUA_EMPRESA": resp[41] || "",
  }

    await api.post('/values-test', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      startReport();
    }).catch((error) => {
      console.log('erro', error);
      // setError(true);
    });
  }

  const onCloseModal = async () => {
    await setModal(false);
  }

  const step1 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 1 de 22</SubLabel>
    <DescriptionGroup>
      <Step active><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Autonomia e abertura para se expressar</Title>
      <Description color={colors.darkBlue}>O quanto você ter autonomia no trabalho e abertura da liderança ou pares para expressar o que pensa são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step2 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 2 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step active><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Clima interno</Title>
      <Description color={colors.darkBlue}>O quanto o clima interno (bom ambiente e relacionamento, satisfação, motivação etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step3 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 3 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step active><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Confiança na liderança imediata</Title>
      <Description color={colors.darkBlue}>O quanto sentir que você pode confiar em seu líder imediato (gestor) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step4 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 4 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step active><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Confiança no time de trabalho</Title>
      <Description color={colors.darkBlue}>O quanto sentir que você pode confiar em seu líder imediato (gestor) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step5 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 5 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step active><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Reconhecimento e valorização</Title>
      <Description color={colors.darkBlue}>O quanto você sentir que recebe reconhecimento e valorização em uma empresa são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step6 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 6 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step active><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Tratamento humanizado</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa tratar de forma humanizada seus colaboradores (a pessoa por trás do profissional) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step7 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 7 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step active><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Tratamento justo e igualitário com todos</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa tratar de forma justa seus colaboradores, sem preferências e de forma igualitária, é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step8 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 8 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step active><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Visão de futuro da empresa ou negócio</Title>
      <Description color={colors.darkBlue}>O quanto ter clareza sobre o futuro da empresa e o que ela busca é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step9 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 9 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step active><p>9</p></Step>
      <Step><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Horário de trabalho</Title>
      <Description color={colors.darkBlue}>O quanto o horário de trabalho (turno, flexibilidade de horário, previsibilidade de horário, dias de folga, escalas etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step10 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 10 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step active><p>10</p></Step>
      <Step><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Local de trabalho</Title>
      <Description color={colors.darkBlue}>O quanto o local físico de trabalho (distância de sua residência, instalações físicas, conforto, possibilidade de trabalhar home office etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step11 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 11 de 22</SubLabel>
    <DescriptionGroup>
      <Step><p>1</p></Step>
      <Step><p>2</p></Step>
      <Step><p>3</p></Step>
      <Step><p>4</p></Step>
      <Step><p>5</p></Step>
      <Step><p>6</p></Step>
      <Step><p>7</p></Step>
      <Step><p>8</p></Step>
      <Step><p>9</p></Step>
      <Step ><p>10</p></Step>
      <Step active><p>11</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Pacote de benefícios</Title>
      <Description color={colors.darkBlue}>O quanto o pacote de benefícios oferecidos por uma empresa (vale alimentação, academia, plano de saúde etc) é um fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step12 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 12 de 22</SubLabel>
    <DescriptionGroup>
      <Step active><p>12</p></Step>
      <Step><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Salário</Title>
      <Description color={colors.darkBlue}>O quanto o salário pago é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step13 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 13 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step active><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Abertura para mudanças e inovação</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa demonstrar abertura real a mudanças e inovação é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step14 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 14 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step active><p>14</p></Step>
      <Step><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Celebração de conquistas</Title>
      <Description color={colors.darkBlue}>O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a grandes comemorações) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step15 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 15 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step active><p>15</p></Step>
      <Step><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Cultura e valores da empresa</Title>
      <Description color={colors.darkBlue}>O quanto a cultura ("jeitão" da empresa) e seus valores (o que se prega, o que é valorizado naquele ambiente etc) são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step16 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 16 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step active><p>16</p></Step>
      <Step><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Desafios constantes </Title>
      <Description color={colors.darkBlue}>O quanto receber desafios constantes (metas desafiadoras, projetos difíceis etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step17 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 17 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step active><p>17</p></Step>
      <Step><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title height="200px">Gestão clara e acompanhamento dos objetivos</Title>
      <Description color={colors.darkBlue}>O quanto ter clareza dos objetivos e perceber que a empresa faz gestão e acompanhamento constante de seus objetivos são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step18 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 18 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step ><p>17</p></Step>
      <Step active><p>18</p></Step>
      <Step><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title height="200px">Oportunidades de desenvolvimento e aprendizado</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa oferecer oportunidades reais de desenvolvimento profissional e aprendizado constante são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step19 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 19 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step ><p>17</p></Step>
      <Step ><p>18</p></Step>
      <Step active><p>19</p></Step>
      <Step><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Plano de carreira claro e estruturado</Title>
      <Description color={colors.darkBlue}>O quanto ter um plano de carreira claro e estruturado pela empresa é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step20 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 20 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step ><p>17</p></Step>
      <Step ><p>18</p></Step>
      <Step ><p>19</p></Step>
      <Step active><p>20</p></Step>
      <Step><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title height="200px">Possibilidades de crescimento e promoção</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa oferecer possibilidades reais de crescimento e promoção é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step21 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 21 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step ><p>17</p></Step>
      <Step ><p>18</p></Step>
      <Step ><p>19</p></Step>
      <Step ><p>20</p></Step>
      <Step active><p>21</p></Step>
      <Step><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Processos simples e desburocratizados</Title>
      <Description color={colors.darkBlue}>O quanto a simplificação e organização dos processos da empresa são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step22 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 22 de 22</SubLabel>
    <DescriptionGroup>
      <Step ><p>12</p></Step>
      <Step ><p>13</p></Step>
      <Step ><p>14</p></Step>
      <Step ><p>15</p></Step>
      <Step ><p>16</p></Step>
      <Step ><p>17</p></Step>
      <Step ><p>18</p></Step>
      <Step ><p>19</p></Step>
      <Step ><p>20</p></Step>
      <Step ><p>21</p></Step>
      <Step active><p>22</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Você está trabalhando atualmente?</Title>
    </GpDescription>
    <DescriptionGroup>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
        >
          <Card>
            <FormControlLabel value="Sim" control={<BpRadio />} onChange={onNextYes} label="Sim" />
          </Card>
          <Card>
            <FormControlLabel value="Nao" control={<BpRadio />} onChange={onNextNo} label="Não" />
          </Card>
        </RadioGroup>
      </FormControl>
    </DescriptionGroup>
    <Line />
    < ContainerButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
      <Button
        type="button"
        width="140px"
        fontSize="13px"
        text="Concluir teste"
        hoverColor={colors.lightBlue}
        color={colors.white}
        bgColor={colors.lightBlue}
        onClick={onFinish}
      />
    </ ContainerButton>
  </Group>


  const step23 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 23 de 43</SubLabel>
    <DescriptionGroup>
      <Step active><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Autonomia e abertura para se expressar na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto você ter autonomia no trabalho e abertura da liderança ou pares para expressar o que pensa são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step24 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 24 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step active><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Clima interno na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto o clima interno (bom ambiente e relacionamento, satisfação, motivação etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step25 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 25 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step active><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Confiança na liderança imediata na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto sentir que você pode confiar em seu líder imediato (gestor) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step26 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 26 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step active><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Confiança no time de trabalho</Title>
      <Description color={colors.darkBlue}>O quanto sentir que você pode confiar em seu líder imediato (gestor) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step27 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 27 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step active><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Reconhecimento e valorização do seu trabalho</Title>
      <Description color={colors.darkBlue}>O quanto você sentir que recebe reconhecimento e valorização em uma empresa são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step28 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 28 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step active><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="50%">Tratamento humanizado dos colaboradores da sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa tratar de forma humanizada seus colaboradores (a pessoa por trás do profissional) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step29 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de 6ancora de carreira - Passo 29 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step active><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Tratamento justo e igualitário com todos em sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa tratar de forma justa seus colaboradores, sem preferências e de forma igualitária, é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step30 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 30 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step active><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="50%">Visão de futuro da empresa ou negócio na sua empresao futuro da sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto ter clareza sobre o futuro da empresa e o que ela busca é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step31 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 31 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step active><p>31</p></Step>
      <Step><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Horário de trabalho da sua empresa ou área</Title>
      <Description color={colors.darkBlue}>O quanto o horário de trabalho (turno, flexibilidade de horário, previsibilidade de horário, dias de folga, escalas etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step32 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 32 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step active><p>32</p></Step>
      <Step><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Seu local de trabalho</Title>
      <Description color={colors.darkBlue}>O quanto o local físico de trabalho (distância de sua residência, instalações físicas, conforto, possibilidade de trabalhar home office etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step33 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 33 de 43</SubLabel>
    <DescriptionGroup>
      <Step><p>23</p></Step>
      <Step><p>24</p></Step>
      <Step><p>25</p></Step>
      <Step><p>26</p></Step>
      <Step><p>27</p></Step>
      <Step><p>28</p></Step>
      <Step><p>29</p></Step>
      <Step><p>30</p></Step>
      <Step><p>31</p></Step>
      <Step ><p>32</p></Step>
      <Step active><p>33</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Pacote de benefícios na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto o pacote de benefícios oferecidos por uma empresa (vale alimentação, academia, plano de saúde etc) é um fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step34 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 34 de 43</SubLabel>
    <DescriptionGroup>
      <Step active><p>34</p></Step>
      <Step><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step><p>37</p></Step>
      <Step><p>38</p></Step>
      <Step><p>39</p></Step>
      <Step><p>40</p></Step>
      <Step><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title>Seu salário</Title>
      <Description color={colors.darkBlue}>O quanto o salário pago é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step35 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 35 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step active><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step><p>37</p></Step>
      <Step><p>38</p></Step>
      <Step><p>39</p></Step>
      <Step><p>40</p></Step>
      <Step><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Abertura da sua empresa para mudanças e inovação</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa demonstrar abertura real a mudanças e inovação é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step36 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 36 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step active><p>36</p></Step>
      <Step><p>37</p></Step>
      <Step><p>38</p></Step>
      <Step><p>39</p></Step>
      <Step><p>40</p></Step>
      <Step><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Celebração de conquistas na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a grandes comemorações) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step37 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 37 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step active><p>37</p></Step>
      <Step><p>38</p></Step>
      <Step><p>39</p></Step>
      <Step><p>40</p></Step>
      <Step><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="30%">Cultura e valores da sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto a cultura ("jeitão" da empresa) e seus valores (o que se prega, o que é valorizado naquele ambiente etc) são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>


  const step38 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 38 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step active><p>38</p></Step>
      <Step><p>39</p></Step>
      <Step><p>40</p></Step>
      <Step><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%" >Desafios constantes dados pela sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto receber desafios constantes (metas desafiadoras, projetos difíceis etc) é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step39 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 39 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step active><p>39</p></Step>
      <Step><p>40</p></Step>
      <Step><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="50%">Gestão clara e acompanhamento dos objetivos na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto ter clareza dos objetivos e perceber que a empresa faz gestão e acompanhamento constante de seus objetivos são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step40 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 40 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step active><p>40</p></Step>
      <Step><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="50%" >Oportunidades de desenvolvimento e aprendizado na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa oferecer oportunidades reais de desenvolvimento profissional e aprendizado constante são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step41 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 41 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step ><p>40</p></Step>
      <Step active><p>41</p></Step>
      <Step><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Plano de carreira claro e estruturado na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto ter um plano de carreira claro e estruturado pela empresa é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step42 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 42 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step ><p>40</p></Step>
      <Step ><p>41</p></Step>
      <Step active><p>42</p></Step>
      <Step><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="50%" >Possibilidades de crescimento e promoção na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto uma empresa oferecer possibilidades reais de crescimento e promoção é fator decisivo para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < GpButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
    </ GpButton>
  </Group>

  const step43 = <Group>
    <SubLabel color={colors.darkBlue}>Teste de âncora de carreira - Passo 43 de 43</SubLabel>
    <DescriptionGroup>
      <Step ><p>34</p></Step>
      <Step ><p>35</p></Step>
      <Step ><p>36</p></Step>
      <Step ><p>37</p></Step>
      <Step ><p>38</p></Step>
      <Step ><p>39</p></Step>
      <Step ><p>40</p></Step>
      <Step ><p>41</p></Step>
      <Step ><p>42</p></Step>
      <Step active><p>43</p></Step>
    </DescriptionGroup>
    <GpDescription>
      <Title bottom="40%">Processos simples e desburocratizados na sua empresa</Title>
      <Description color={colors.darkBlue}>O quanto a simplificação e organização dos processos da empresa são fatores decisivos para manter você em um trabalho?</Description>
    </GpDescription>
    {width <= 570 ? (
      <DescriptionGroup>
        <GpNote>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
        </GpNote>
        <GpNote>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </GpNote>
      </DescriptionGroup>
    )
      :
      (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}><p>1</p></Note>
          <Note onClick={() => handleChange(2)} active={note2}><p>2</p></Note>
          <Note onClick={() => handleChange(3)} active={note3}><p>3</p></Note>
          <Note onClick={() => handleChange(4)} active={note4}><p>4</p></Note>
          <Note onClick={() => handleChange(5)} active={note5}><p>5</p></Note>
          <Note onClick={() => handleChange(6)} active={note6}><p>6</p></Note>
          <Note onClick={() => handleChange(7)} active={note7}><p>7</p></Note>
          <Note onClick={() => handleChange(8)} active={note8}><p>8</p></Note>
          <Note onClick={() => handleChange(9)} active={note9}><p>9</p></Note>
          <Note onClick={() => handleChange(10)} active={note10}><p>10</p></Note>
        </DescriptionGroup>
      )}
    < Line />
    < ContainerButton>
      <Button
        type="button"
        width="80px"
        fontSize="13px"
        text="Voltar"
        color={colors.lightBlue}
        bgColor={colors.transparent}
        onClick={onBack}
      />
      <Button
        type="button"
        width="140px"
        fontSize="13px"
        text="Concluir teste"
        hoverColor={colors.lightBlue}
        color={colors.white}
        bgColor={colors.lightBlue}
        onClick={onFinish}
      />
    </ ContainerButton>
  </Group>

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Container>
        <ResponsiveAppBar />
        <ErrorModal isOpen={error} onRequestClose={onCloseError} />
        {/* <HeaderUser width={width} /> */}
        {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
        <TestsModal isOpen={modal} isHome={location.state.vacancy} onRequestClose={onCloseModal} test="" startReport={sendTest} startTest={startTest} />
        {step == 1 ?
          step1
          : step == 2 ?
            step2
            : step == 3 ?
              step3
              : step == 4 ?
                step4
                : step == 5 ?
                  step5
                  : step == 6 ?
                    step6
                    : step == 7 ?
                      step7
                      : step == 8 ?
                        step8
                        : step == 9 ?
                          step9
                          : step == 10 ?
                            step10
                            : step == 11 ?
                              step11
                              : step == 12 ?
                                step12
                                : step == 13 ?
                                  step13
                                  : step == 14 ?
                                    step14
                                    : step == 15 ?
                                      step15
                                      : step == 16 ?
                                        step16
                                        : step == 17 ?
                                          step17
                                          : step == 18 ?
                                            step18
                                            : step == 19 ?
                                              step19
                                              : step == 20 ?
                                                step20
                                                : step == 21 ?
                                                  step21
                                                  : step == 22 ?
                                                    step22
                                                    : step == 23 ?
                                                      step23
                                                      : step == 24 ?
                                                        step24
                                                        : step == 25 ?
                                                          step25
                                                          : step == 26 ?
                                                            step26
                                                            : step == 27 ?
                                                              step27
                                                              : step == 28 ?
                                                                step28
                                                                : step == 29 ?
                                                                  step29
                                                                  : step == 30 ?
                                                                    step30
                                                                    : step == 31 ?
                                                                      step31
                                                                      : step == 32 ?
                                                                        step32
                                                                        : step == 33 ?
                                                                          step33
                                                                          : step == 34 ?
                                                                            step34
                                                                            : step == 35 ?
                                                                              step35
                                                                              : step == 36 ?
                                                                                step36
                                                                                : step == 37 ?
                                                                                  step37
                                                                                  : step == 38 ?
                                                                                    step38
                                                                                    : step == 39 ?
                                                                                      step39
                                                                                      : step == 40 ?
                                                                                        step40
                                                                                        : step == 41 ?
                                                                                          step41
                                                                                          : step == 42 ?
                                                                                            step42
                                                                                            :
                                                                                            step43
        }
      </Container>
    </MuiThemeProvider>
  )
}

export default StartCareer;
