import React, { useContext, useState, useEffect } from 'react';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import StoreContext from '../../Store/Context';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import './../../../styles/SidebarConfig.css';
import api from '../../../api';
import ErrorModal from './../../ErrorModal';

const MenuSummary = (prop) => {

    const { token, setToken } = useContext(StoreContext);
    const [data, setData] = useState(prop.user[0].dados_adicionais.resumo);
    const [error, setError] = useState(false);

    const handleValue = async (e) => {
        const value = e.target.value;
        setData(value)
    }

    let icon =  token ? <></> : prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;

    const putData = async () => {
        const json = { "user_id": prop.user[0].id,
        "resumo" : data,
    }
        await api.put('/dados-candidatos/update', json, {
            headers: {
                'authorization': 'Bearer ' + token.refreshToken
            }
        }).then(async (response) => {
            prop.onClose();
        }).catch((error) => {
            console.log('erro', error);
            setError(true);
        });
    }

    const onCloseError = () => {
        setError(false);
    }

    return (
        <Slide right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <ErrorModal isOpen={error} onRequestClose={onCloseError} />
            <GroupLine>
                <Title>Resumo</Title>
            </GroupLine>
            <Container>
                <Group>
                    <TextField label="Resumo sobre você"
                       InputLabelProps={{ shrink: true, required: true }}
                        size="small"
                        multiline
                        rows={8}
                        onChange={handleValue}
                        maxRows={10}
                        value={data}
                        color="info"
                        disabled={false} />
                </Group>
                <Button
                    type="button"
                    width="230px"
                    hoverColor={colors.lightBlue}
                    text="Salvar alterações"
                    fontSize="12px"
                    onClick={putData}
                />
            </Container>
        </Slide>
    )
}

export default MenuSummary;
