import React, { useState, useContext, useEffect } from 'react';
import Modal from "react-modal";

import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import StoreContext from '../Store/Context';

//COMPONENTS
import Button from '../Button';
import ErrorModal from '../ErrorModal';
import AlertModal from '../AlertModal';

//STYLES
import colors from '../../styles/colors'
import { ModalContainer, ModalContent, Image, Title, SubLabel, Row, Icon, DescriptionData, Group, Card, SubTitle } from './styles';
import api from '../../api';
import * as Io from "react-icons/io5";


const NewPersona = (prop) => {
  const isOpen = prop.isOpen;
  const onRequestClose = prop.onRequestClose;
  const width = prop.width;
  const getData = prop.getData;
  const selected = [prop.selected];

  const { token, setToken, userGlobal } = useContext(StoreContext);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [list, setList] = useState([]);
  const [hardskill, setHardskill] = useState([]);
  const [value, setValue] = useState("");
  const [minBehavioral, setMinBehavioral] = useState(100);
  const [minCulture, setMinCulture] = useState(100);
  const [msg, setMsg] = useState('');
  const [learn1, setLearn1] = useState('');
  const [learn2, setLearn2] = useState('');
  const [learn3, setLearn3] = useState('');
  const [learn4, setLearn4] = useState('');
  const [learn5, setLearn5] = useState('');
  const [visibleHards, setVisibleHards] = useState(true);
  const [visibleBehavioral, setVisibleBehavioral] = useState(true);
  const [statusBehavioral, setStatusBehavioral] = useState(true);
  const [statusCulture, setStatusCulture] = useState(true);
  const [behavioral1, setBehavioral1] = useState(0);
  const [behavioral2, setBehavioral2] = useState(0);
  const [behavioral3, setBehavioral3] = useState(0);
  const [behavioral4, setBehavioral4] = useState(0);
  const [culture1, setCulture1] = useState(0);
  const [culture2, setCulture2] = useState(0);
  const [culture3, setCulture3] = useState(0);
  const [culture4, setCulture4] = useState(0);
  const [culture5, setCulture5] = useState(0);
  const [culture6, setCulture6] = useState(0);
  const [culture7, setCulture7] = useState(0);
  const [culture8, setCulture8] = useState(0);
  const [career1, setCareer1] = useState('');
  const [career2, setCareer2] = useState('');
  const [career3, setCareer3] = useState('');
  const [career4, setCareer4] = useState('');
  const [career5, setCareer5] = useState('');
  const [career6, setCareer6] = useState('');
  const [career7, setCareer7] = useState('');
  const [career8, setCareer8] = useState('');
  const [career9, setCareer9] = useState('');
  const [career11, setCareer11] = useState('');
  const [career12, setCareer12] = useState('');
  const [career13, setCareer13] = useState('');
  const [career14, setCareer14] = useState('');
  const [career15, setCareer15] = useState('');
  const [career16, setCareer16] = useState('');
  const [career17, setCareer17] = useState('');
  const [career18, setCareer18] = useState('');
  const [career10, setCareer10] = useState('');
  const [career19, setCareer19] = useState('');
  const [career20, setCareer20] = useState('');
  const [career21, setCareer21] = useState('');
  const [soft1, setSoft1] = useState('');
  const [soft2, setSoft2] = useState('');
  const [soft3, setSoft3] = useState('');
  const [soft4, setSoft4] = useState('');
  const [soft5, setSoft5] = useState('');
  const [soft6, setSoft6] = useState('');
  const [soft7, setSoft7] = useState('');
  const [soft8, setSoft8] = useState('');
  const [soft9, setSoft9] = useState('');
  const [soft10, setSoft10] = useState('');
  const [soft11, setSoft11] = useState('');
  const [soft12, setSoft12] = useState('');
  const [soft13, setSoft13] = useState('');
  const [soft14, setSoft14] = useState('');
  const [soft15, setSoft15] = useState('');
  const [soft16, setSoft16] = useState('');
  const [soft17, setSoft17] = useState('');
  const [soft18, setSoft18] = useState('');
  const [soft19, setSoft19] = useState('');
  const [soft20, setSoft20] = useState('');
  const [soft21, setSoft21] = useState('');
  const [soft22, setSoft22] = useState('');
  const [soft23, setSoft23] = useState('');
  const [soft24, setSoft24] = useState('');
  const [soft25, setSoft25] = useState('');
  const [soft26, setSoft26] = useState('');
  const [soft27, setSoft27] = useState('');
  const [soft28, setSoft28] = useState('');
  const [soft29, setSoft29] = useState('');
  const [soft30, setSoft30] = useState('');
  const [soft31, setSoft31] = useState('');
  const [soft32, setSoft32] = useState('');
  const [soft33, setSoft33] = useState('');
  const [soft34, setSoft34] = useState('');
  const [soft35, setSoft35] = useState('');
  const [soft36, setSoft36] = useState('');
  const [soft37, setSoft37] = useState('');
  const [soft38, setSoft38] = useState('');
  const [soft39, setSoft39] = useState('');
  const [soft40, setSoft40] = useState('');
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');
  const [hards, setHards] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  useEffect(async () => {
    getHardskill();
    getCompanies();
  }, [prop, alert, hards, minBehavioral]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 570 ? '800px' : '380px',
      minWidth: '450px',
      height: '600px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1
    },
  };

  let fieldRow1 = width <= 570 ? '80%' : 340;
  let fieldRow2 = width <= 570 ? '80%' : 718;
  let fieldRow3 = width <= 570 ? '80%' : 280;

  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setCompanies(data);
    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setCompanies(data);

  }

  const getHardskill = async () => {
    await api.get('/hardskill', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      setList(data);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }


  const clean = async () => {
    setCompany('');
    setLearn1('');
    setLearn2('');
    setLearn3('');
    setLearn4('');
    setLearn5('');
    setBehavioral1(0);
    setBehavioral2(0);
    setBehavioral3(0);
    setBehavioral4(0);
    setCulture1(0);
    setCulture2(0);
    setCulture3(0);
    setCulture4(0);
    setCulture5(0);
    setCulture6(0);
    setCulture7(0);
    setCulture8(0);
    setCareer1('');
    setCareer2('');
    setCareer3('');
    setCareer4('');
    setCareer5('');
    setCareer6('');
    setCareer7('');
    setCareer8('');
    setCareer9('');
    setCareer11('');
    setCareer12('');
    setCareer13('');
    setCareer14('');
    setCareer15('');
    setCareer16('');
    setCareer17('');
    setCareer18('');
    setCareer10('');
    setCareer19('');
    setCareer20('');
    setCareer21('');
    setSoft1('');
    setSoft2('');
    setSoft3('');
    setSoft4('');
    setSoft5('');
    setSoft6('');
    setSoft7('');
    setSoft8('');
    setSoft9('');
    setSoft10('');
    setSoft11('');
    setSoft12('');
    setSoft13('');
    setSoft14('');
    setSoft15('');
    setSoft16('');
    setSoft17('');
    setSoft18('');
    setSoft19('');
    setSoft20('');
    setSoft21('');
    setSoft22('');
    setSoft23('');
    setSoft24('');
    setSoft25('');
    setSoft26('');
    setSoft27('');
    setSoft28('');
    setSoft29('');
    setSoft30('');
    setSoft31('');
    setSoft32('');
    setSoft33('');
    setSoft34('');
    setSoft35('');
    setSoft36('');
    setSoft37('');
    setSoft38('');
    setSoft39('');
    setSoft40('');
    setHards([]);
    setMinBehavioral(100);
    setMinCulture(100)
  }


  const removeHards = async (id) => {
    let json = await hards.filter((item) => {
      return item.hard_skill_id != id
    });
    setHards(json);
  }

  const onCheck = async () => {
    let valueHard = [];
    await hards.map((item) => {
      valueHard.push({
        nome: item.name,
        valor: item.valor
      })
    })



    const data =
    {
      "nome": selected[0].nome,
      "persona": {
        "perfil_comportamental": {
          "DESBRAVADOR": behavioral1,
          "EMBAIXADOR": behavioral2,
          "MANTENEDOR": behavioral3,
          "INVESTIGADOR": behavioral4
        },
        "learn_agilities": {
          "AGILIDADE_MENTAL": learn1,
          "AGILIDADE_COM_PESSOAS": learn2,
          "AGILIDADE_COM_MUDANCAS": learn3,
          "AGILIDADE_COM_RESULTADOS": learn4,
          "POTENCIAL": learn5
        },
        "cultural": {
          "ACOLHIMENTO": culture1,
          "PROPOSITO": culture2,
          "APRENDIZADO": culture3,
          "PRAZER": culture4,
          "RESULTADOS": culture5,
          "AUTORIDADE": culture6,
          "SEGURANÇA": culture7,
          "ORDEM": culture8
        },
        "hard_skills": valueHard,
        "valores_de_carreira": {
          "ABERTURA_PARA_MUDANCAS_E_INOVACAO": parseInt(career1),
          "AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR": parseInt(career2),
          "CELEBRACAO_DE_CONQUISTAS": parseInt(career3),
          "CLIMA_INTERNO": parseInt(career4),
          "CONFIANCA_NA_LIDERANCA_IMEDIATA": parseInt(career5),
          "CONFIANCA_NO_TIME_DE_TRABALHO": parseInt(career6),
          "CULTURA_E_VALORES_DA_EMPRESA": parseInt(career7),
          "DESAFIOS_CONSTANTES": parseInt(career8),
          "GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS": parseInt(career9),
          "HORARIO_DE_TRABALHO": parseInt(career10),
          "LOCAL_DE_TRABALHO": parseInt(career11),
          "OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO": parseInt(career12),
          "PACOTE_DE_BENEFICIOS": parseInt(career13),
          "PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO": parseInt(career14),
          "POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO": parseInt(career15),
          "PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS": parseInt(career16),
          "RECONHECIMENTO_E_VALORIZACAO": parseInt(career17),
          "SALARIO": parseInt(career18),
          "TRATAMENTO_HUMANIZADO": parseInt(career19),
          "TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS": parseInt(career20),
          "VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO": parseInt(career21)
        },
        "soft_skills": {
          "APRENDIZADO_CONSTANTE": parseInt(soft1),
          "FLEXIBILIDADE": parseInt(soft2),
          "GESTAO_DA_MUDANCA": parseInt(soft3),
          "INOVACAO": parseInt(soft4),
          "INTELIGENCIA_DIGITAL": parseInt(soft5),
          "INTRAEMPREENDEDORISMO": parseInt(soft6),
          "MENTALIDADE_DE_STARTUP": parseInt(soft7),
          "PENSAMENTO_AGIL": parseInt(soft8),
          "RESILIENCIA": parseInt(soft9),
          "ACABATIVA": parseInt(soft10),
          "ENGAJAMENTO_CONTINUO": parseInt(soft11),
          "GESTAO_DA_QUALIDADE": parseInt(soft12),
          "GESTAO_DE_PROJETOS": parseInt(soft13),
          "GESTAO_DO_TEMPO": parseInt(soft14),
          "IDENTIFICACAO_DE_PROPOSITO": parseInt(soft15),
          "MELHORIA_DE_DESEMPENHO": parseInt(soft16),
          "ORIENTACAO_AO_RESULTADO": parseInt(soft17),
          "SENSO_DE_DONO": parseInt(soft18),
          "VENDA_CONSULTIVA": parseInt(soft19),
          "CAPACIDADE_ANALITICA": parseInt(soft20),
          "ESCUTA_ATIVA": parseInt(soft21),
          "GESTAO_DA_ANSIEDADE": parseInt(soft22),
          "GESTAO_DO_CONHECIMENTO": parseInt(soft23),
          "INTELIGENCIA_EMOCIONAL": parseInt(soft24),
          "LIDERANCA_ESTRATEGICA": parseInt(soft25),
          "ORIENTACAO_AO_NEGOCIO": parseInt(soft26),
          "PENSAMENTO_ESTRATEGICO": parseInt(soft27),
          "PLANEJAMENTO": parseInt(soft28),
          "VELOCIDADE_NA_TOMADA_DE_DECISAO": parseInt(soft29),
          "COMUNICACAO": parseInt(soft30),
          "CONSTRUCAO_DE_ALIANCAS": parseInt(soft31),
          "DESENVOLVIMENTO_DE_TALENTOS": parseInt(soft32),
          "GESTAO_DE_PESSOAS": parseInt(soft33),
          "MENTALIDADE_INCLUSIVA": parseInt(soft34),
          "NEGOCIACAO": parseInt(soft35),
          "ORIENTACAO_AO_CLIENTE": parseInt(soft36),
          "CONCESSAO_DE_FEEDBACK": parseInt(soft37),
          "RELACIONAMENTO_INTERPESSOAL": parseInt(soft38),
          "SOLUCOES_DE_CONFLITOS": parseInt(soft39),
          "TRABALHO_EM_EQUIPE": parseInt(soft40)
        }
      },
      "company_id": company
    }

    await api.post('/persona/create', data, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const idPersona = response.data.created_id;

      const career = {
        persona_ideal_id: idPersona
      }

      await api.put('/carreiras/' + selected[0].id, career, {
        headers: {
          'authorization': 'Bearer ' + token.refreshToken
        }
      }).then(async (response) => {
        await onRequestClose();
        await setMsg("Persona calibrada com sucesso!");
        await setAlert(true);
        await clean();
      }).catch(async (error) => {
        console.log('erro', error);
        await onRequestClose();
        await setError(true);
      });
    }).catch(async (error) => {
      console.log('erro', error);
      await onRequestClose();
      await setError(true);
    });
  }

  const onCloseError = () => {
    setError(false);
  }

  const onCloseAlert = () => {
    setAlert(false);
  }

  const handleHardskill = (e) => {
    const value = e.target.value;
    setHardskill(value);
  }

  const handleValue = (e) => {
    const value = e.target.value;
    setValue(value);
  }

  const handleCompany = (e) => {
    const value = e.target.value;
    setCompany(value);
  }

  const handleLearn1 = (e) => {
    const value = e.target.value;
    setLearn1(value);
  }

  const handleLearn2 = (e) => {
    const value = e.target.value;
    setLearn2(value);
  }

  const handleLearn3 = (e) => {
    const value = e.target.value;
    setLearn3(value);
  }

  const handleLearn4 = (e) => {
    const value = e.target.value;
    setLearn4(value);
  }

  const handleLearn5 = (e) => {
    const value = e.target.value;
    setLearn5(value);
  }

  const handleBehavioral1 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setBehavioral1(parseInt(value));
    await setMinBehavioral((100 - (parseInt(value) + behavioral2 + behavioral3 + behavioral4)));
  }

  const handleBehavioral2 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setBehavioral2(parseInt(value));
    await setMinBehavioral((100 - (parseInt(behavioral1) + parseInt(value) + behavioral3 + behavioral4)));
  }


  const handleBehavioral3 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setBehavioral3(parseInt(value));
    await setMinBehavioral((100 - (behavioral1 + behavioral2 + parseInt(value) + behavioral4)));
  }


  const handleBehavioral4 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setBehavioral4(parseInt(value));
    await setMinBehavioral((100 - (behavioral1 + behavioral2 + behavioral3 + parseInt(value))));
  }

  const handleCulture1 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setCulture1(parseInt(value));
    await setMinCulture((100 - (parseInt(value) + culture2 + culture3 + culture4 + culture5 + culture6 + culture7 + culture8)));
  }

  const handleCulture2 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setCulture2(parseInt(value));
    await setMinCulture((100 - (parseInt(value) + culture1 + culture3 + culture4 + culture5 + culture6 + culture7 + culture8)));

  }

  const handleCulture3 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setMinCulture((100 - (parseInt(value) + culture2 + culture1 + culture4 + culture5 + culture6 + culture7 + culture8)));
    await setCulture3(parseInt(value));
  }

  const handleCulture4 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setMinCulture((100 - (parseInt(value) + culture2 + culture3 + culture1 + culture5 + culture6 + culture7 + culture8)));
    await setCulture4(parseInt(value));
  }

  const handleCulture5 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setMinCulture((100 - (parseInt(value) + culture2 + culture3 + culture4 + culture1 + culture6 + culture7 + culture8)));
    await setCulture5(parseInt(value));
  }

  const handleCulture6 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setMinCulture((100 - (parseInt(value) + culture2 + culture3 + culture4 + culture5 + culture1 + culture7 + culture8)));
    await setCulture6(parseInt(value));
  }

  const handleCulture7 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setMinCulture((100 - (parseInt(value) + culture2 + culture3 + culture4 + culture5 + culture6 + culture1 + culture8)));
    await setCulture7(parseInt(value));
  }

  const handleCulture8 = async (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    await setMinCulture((100 - (parseInt(value) + culture2 + culture3 + culture4 + culture5 + culture6 + culture7 + culture1)));
    await setCulture8(parseInt(value));
  }

  const handleCareer1 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer1(value);
  }

  const handleCareer2 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer2(value);
  }


  const handleCareer3 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer3(value);
  }


  const handleCareer4 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer4(value);
  }


  const handleCareer5 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer5(value);
  }


  const handleCareer6 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer6(value);
  }


  const handleCareer7 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer7(value);
  }


  const handleCareer8 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer8(value);
  }


  const handleCareer9 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer9(value);
  }


  const handleCareer10 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer10(value);
  }


  const handleCareer11 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer11(value);
  }


  const handleCareer12 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer12(value);
  }


  const handleCareer13 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer13(value);
  }



  const handleCareer14 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer14(value);
  }


  const handleCareer15 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer15(value);
  }


  const handleCareer16 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer16(value);
  }


  const handleCareer17 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer17(value);
  }


  const handleCareer18 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer18(value);
  }


  const handleCareer19 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer19(value);
  }


  const handleCareer20 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer20(value);
  }


  const handleCareer21 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setCareer21(value);
  }

  const handleSoft1 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft1(value);
  }

  const handleSoft2 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft2(value);
  }

  const handleSoft3 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft3(value);
  }

  const handleSoft4 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft4(value);
  }

  const handleSoft5 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft5(value);
  }

  const handleSoft6 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft6(value);
  }

  const handleSoft7 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft7(value);
  }

  const handleSoft8 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft8(value);
  }

  const handleSoft9 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft9(value);
  }

  const handleSoft10 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft10(value);
  }

  const handleSoft11 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft11(value);
  }

  const handleSoft12 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft12(value);
  }

  const handleSoft13 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft13(value);
  }

  const handleSoft14 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft14(value);
  }

  const handleSoft15 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft15(value);
  }

  const handleSoft16 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft16(value);
  }

  const handleSoft17 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft17(value);
  }

  const handleSoft18 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft18(value);
  }

  const handleSoft19 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft19(value);
  }

  const handleSoft20 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft20(value);
  }

  const handleSoft21 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft21(value);
  }

  const handleSoft22 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft22(value);
  }

  const handleSoft23 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft23(value);
  }

  const handleSoft24 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft24(value);
  }

  const handleSoft25 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft25(value);
  }

  const handleSoft26 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft26(value);
  }

  const handleSoft27 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft27(value);
  }

  const handleSoft28 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft28(value);
  }

  const handleSoft29 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft29(value);
  }

  const handleSoft30 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft30(value);
  }

  const handleSoft31 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft31(value);
  }

  const handleSoft32 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft32(value);
  }

  const handleSoft33 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft33(value);
  }

  const handleSoft34 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft34(value);
  }

  const handleSoft35 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft35(value);
  }

  const handleSoft36 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft36(value);
  }

  const handleSoft37 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft37(value);
  }

  const handleSoft38 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft38(value);
  }

  const handleSoft39 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft39(value);
  }

  const handleSoft40 = (e) => {
    const value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    setSoft40(value);
  }

  const postHardskill = async () => {
    await setVisibleHards(false);
    const json = {
      "name": hardskill.nome,
      "hard_skill_id": hardskill.id,
      "valor": value
    }
    await hards.push(json);
    await setVisibleHards(true);
  }


  return (
    <ModalContainer>
      {error ? <ErrorModal isOpen={error} onRequestClose={onCloseError} /> : false}
      {alert ? <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} /> : false}
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Title>Calibrar Persona</Title>
          <Group>
            <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow2 }}>
              <InputLabel id="job">Empresa</InputLabel>
              <Select
                value={company}
                onChange={handleCompany}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Empresa" />}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.white, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                {companies.map((item) => {
                  return <MenuItem value={item.id}>{item.nome_fantasia}</MenuItem>
                })}
              </Select>
            </FormControl>

            <Card>
              <DescriptionData>Learn Agilities</DescriptionData>
            </Card>
            <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
              <InputLabel id="job">Agilidade mental</InputLabel>
              <Select
                value={learn1}
                onChange={handleLearn1}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Agilidade mental" />}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.white, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                <MenuItem value={'Baixa'}>Baixa</MenuItem>
                <MenuItem value={'Moderada'}>Moderada</MenuItem>
                <MenuItem value={'Alta'}>Alta</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
              <InputLabel id="job">Agilidade com pessoas</InputLabel>
              <Select
                value={learn2}
                onChange={handleLearn2}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Agilidade com pessoas" />}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.white, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                <MenuItem value={'Baixa'}>Baixa</MenuItem>
                <MenuItem value={'Moderada'}>Moderada</MenuItem>
                <MenuItem value={'Alta'}>Alta</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
              <InputLabel id="job">Agilidade com mudanças</InputLabel>
              <Select
                value={learn3}
                onChange={handleLearn3}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Agilidade com mudanças" />}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.white, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                <MenuItem value={'Baixa'}>Baixa</MenuItem>
                <MenuItem value={'Moderada'}>Moderada</MenuItem>
                <MenuItem value={'Alta'}>Alta</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
              <InputLabel id="job">Agilidade com resultados</InputLabel>
              <Select
                value={learn4}
                onChange={handleLearn4}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Agilidade com resultados" />}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.white, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                <MenuItem value={'Baixa'}>Baixa</MenuItem>
                <MenuItem value={'Moderada'}>Moderada</MenuItem>
                <MenuItem value={'Alta'}>Alta</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.gray6, m: 1, width: fieldRow1 }}>
              <InputLabel id="job">Potencial</InputLabel>
              <Select
                value={learn5}
                onChange={handleLearn5}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Potencial" />}
                autoWidth
                size="small"
                color="info"
                sx={{ backgroundColor: colors.white, fontSize: '12px', fontFamily: 'Poppins', fontStyle: 'normal', textAlign: 'start' }}
                InputProps={{
                  sx: {
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  },
                }}
              >
                <MenuItem value={'Baixa'}>Baixa</MenuItem>
                <MenuItem value={'Moderada'}>Moderada</MenuItem>
                <MenuItem value={'Alta'}>Alta</MenuItem>
              </Select>
            </FormControl>




            <Card>
              <DescriptionData>Perfil Comportamental</DescriptionData>
            </Card>
            {visibleBehavioral ?
              <>
                <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
                  <TextField
                    value={behavioral1}
                    onChange={handleBehavioral1}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: minBehavioral } }}
                    label="Desbravador"
                    id="outlined-size-small"
                    size="small"
                  />
                </FormControl>
                <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
                  <TextField
                    value={behavioral2}
                    onChange={handleBehavioral2}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: minBehavioral } }}
                    label="Embaixador"
                    id="outlined-size-small"
                    size="small"
                  />
                </FormControl>
                <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
                  <TextField
                    value={behavioral3}
                    onChange={handleBehavioral3}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: minBehavioral } }}
                    label="Mantenedor"
                    id="outlined-size-small"
                    size="small"
                  />
                </FormControl>
                <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
                  <TextField
                    value={behavioral4}
                    onChange={handleBehavioral4}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: minBehavioral } }}
                    label="Investigador"
                    id="outlined-size-small"
                    size="small"
                  />
                </FormControl>
                <DescriptionData align="center" color={minBehavioral == 0 ? colors.cleanGreen : colors.alertRed}>{statusBehavioral ? minBehavioral == 0 ? "Parabéns! Valores preenchidos corretamente!" : "A soma dos valores deve ser igual a 100. Valor atual: " + minBehavioral : ""}</DescriptionData>
              </>
              : <></>}
            <Card>
              <DescriptionData>Perfil Cultural</DescriptionData>
            </Card>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture1}
                onChange={handleCulture1}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Acolhimento"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture2}
                onChange={handleCulture2}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Propósito"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture3}
                onChange={handleCulture3}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Aprendizado"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture4}
                onChange={handleCulture4}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Prazer"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture5}
                onChange={handleCulture5}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Resultados"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture6}
                onChange={handleCulture6}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Autoridade"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture7}
                onChange={handleCulture7}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Segurança"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={culture8}
                onChange={handleCulture8}
                type="number"
                InputProps={{ inputProps: { min: 0, max: minCulture } }}
                label="Ordem"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <DescriptionData align="center" color={minCulture == 0 ? colors.cleanGreen : colors.alertRed}>{statusCulture ? minCulture == 0 ? "Parabéns! Valores preenchidos corretamente!" : "A soma dos valores deve ser igual a 100. Valor atual: " + minCulture : ""}</DescriptionData>

            <Card>
              <DescriptionData>Valores de Carreira</DescriptionData>
            </Card>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career1}
                onChange={handleCareer1}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Abertura para mudanças e inovação"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career2}
                onChange={handleCareer2}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Autonomia e abertura para se expressar"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career3}
                onChange={handleCareer3}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Celebração de conquistas"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career4}
                onChange={handleCareer4}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Clima interno"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career5}
                onChange={handleCareer5}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Confiança na liderança imediata"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career6}
                onChange={handleCareer6}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Confiança no time de trabalho"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career7}
                onChange={handleCareer7}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Cultura e valores da empresa"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career8}
                onChange={handleCareer8}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Desafios constantes"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career9}
                onChange={handleCareer9}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão clara e acompanhamento dos objetivos"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career10}
                onChange={handleCareer10}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Horário de trabalho"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career11}
                onChange={handleCareer11}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Local de trabalho"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career12}
                onChange={handleCareer12}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Oportunidades de desenvolvimento e aprendizado"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career13}
                onChange={handleCareer13}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Pacote de beneficios"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career14}
                onChange={handleCareer14}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Plano de carreira claro e estruturado"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career15}
                onChange={handleCareer15}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Possibilidade de crescimento e promoção"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career16}
                onChange={handleCareer16}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Processos simples e desburocratizados"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career17}
                onChange={handleCareer17}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Reconhecimento e valorização"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career18}
                onChange={handleCareer18}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Salário"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career19}
                onChange={handleCareer19}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Tratamento humanizado"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career20}
                onChange={handleCareer20}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Tratamento justo e igualitário com todos"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={career21}
                onChange={handleCareer21}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Visão de futuro da empresa ou negócio"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <Card>
              <DescriptionData>Softskills</DescriptionData>
            </Card>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft1}
                onChange={handleSoft1}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Aprendizado constante"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft2}
                onChange={handleSoft2}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Flexibilidade"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft3}
                onChange={handleSoft3}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão da Mudança"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft4}
                onChange={handleSoft4}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Inovação"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft5}
                onChange={handleSoft5}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Inteligência digital"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft6}
                onChange={handleSoft6}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Intraempreendedorismo"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft7}
                onChange={handleSoft7}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Mentalidade de startup"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft8}
                onChange={handleSoft8}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Pensamento ágil"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft9}
                onChange={handleSoft9}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Resiliência"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft10}
                onChange={handleSoft10}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Acabativa"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft11}
                onChange={handleSoft11}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Engajamento contínuo"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft12}
                onChange={handleSoft12}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão da qualidade"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft13}
                onChange={handleSoft13}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão de projetos"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft14}
                onChange={handleSoft14}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão de tempo"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft15}
                onChange={handleSoft15}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Identificação de propósito"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft16}
                onChange={handleSoft16}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Melhoria de desempenho"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft17}
                onChange={handleSoft17}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Orientação ao resultado"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft18}
                onChange={handleSoft18}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Senso de dono"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft19}
                onChange={handleSoft19}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Venda consultiva"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft20}
                onChange={handleSoft20}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Capacidade analítica"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft21}
                onChange={handleSoft21}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Escuta ativa"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft22}
                onChange={handleSoft22}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão da ansiedade"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft23}
                onChange={handleSoft23}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão do conhecimento"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft24}
                onChange={handleSoft24}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Inteligência emocional"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft25}
                onChange={handleSoft25}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Liderança estratégicas"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft26}
                onChange={handleSoft26}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Orientação ao negócio"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft27}
                onChange={handleSoft27}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Pensamento estratégico"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft28}
                onChange={handleSoft28}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Planejamento"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft29}
                onChange={handleSoft29}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Velocidade na tomada de decisão"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft30}
                onChange={handleSoft30}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Comunicação"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft31}
                onChange={handleSoft31}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Construção de alianças"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft32}
                onChange={handleSoft32}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Desenvolvimento de talentos"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft33}
                onChange={handleSoft33}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Gestão de pessoas"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft34}
                onChange={handleSoft34}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Mentalidade inclusiva"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft35}
                onChange={handleSoft35}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Negociação"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>


            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft36}
                onChange={handleSoft36}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Orientação ao cliente"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft37}
                onChange={handleSoft37}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Concessão de feedback"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft38}
                onChange={handleSoft38}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Relacionamento interpessoal"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft39}
                onChange={handleSoft39}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Soluções de conflitos"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow1 }}>
              <TextField
                value={soft40}
                onChange={handleSoft40}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                label="Trabalho em equipe"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>

            <Card>
              <DescriptionData>Hardskills</DescriptionData>
            </Card>

            <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow3 }}>
              <InputLabel id="value">Hardskill</InputLabel>
              <Select
                labelId="value"
                id="value"
                InputLabelProps={{ shrink: true, required: true }}
                value={hardskill}
                size="small"
                color="info"
                disabled={false}
                onChange={handleHardskill}
                input={<OutlinedInput label="Hardskill" />}
              >
                {list.map((v, i) => {
                  return <MenuItem value={v}>{v.nome}</MenuItem>
                })}
              </Select>
            </FormControl>
            {hardskill.tipo == "valor" ?
              <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow3 }}>
                <InputLabel id="value">Nível de experiência</InputLabel>
                <Select
                  labelId="value"
                  id="value"
                  InputLabelProps={{ shrink: true, required: true }}
                  value={value}
                  size="small"
                  color="info"
                  onChange={handleValue}
                  disabled={false}
                  input={<OutlinedInput label="Nível de experiência" />}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
              :
              hardskill.tipo == "select" ?
                <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow3 }}>
                  <InputLabel id="value">Resposta</InputLabel>
                  <Select
                    labelId="value"
                    id="value"
                    InputLabelProps={{ shrink: true, required: true }}
                    value={value}
                    size="small"
                    color="info"
                    onChange={handleValue}
                    disabled={false}
                    input={<OutlinedInput label="Resposta" />}
                  >
                    <MenuItem value={"S"}>Sim</MenuItem>
                    <MenuItem value={"N"}>Não</MenuItem>
                  </Select>
                </FormControl>
                :
                hardskill.tipo == "Idioma" ?
                  <FormControl sx={{ backgroundColor: colors.white, m: 1, width: fieldRow3 }}>
                    <InputLabel id="job">Nível</InputLabel>
                    <Select
                      labelId="job"
                      id="job"
                      InputLabelProps={{ shrink: true, required: true }}
                      value={value}
                      size="small"
                      color="info"
                      onChange={handleValue}
                      disabled={false}
                      input={<OutlinedInput label="Nível" />}
                    >
                      <MenuItem value={"Básico"}>Básico</MenuItem>
                      <MenuItem value={"Intermediário"}>Intermediário</MenuItem>
                      <MenuItem value={"Avançado"}>Avançado</MenuItem>
                      <MenuItem value={"Fluente"}>Fluente</MenuItem>
                    </Select>
                  </FormControl>
                  :
                  <></>
            }

            <Button
              type="button"
              width="40px"
              hoverColor={colors.lightBlue}
              text="+"
              fontSize="12px"
              onClick={postHardskill}
            />

            {visibleHards ? hards.map((item) => {
              return <Row align="flex-start" top="5px" cursor={"pointer"}>
                <SubTitle font="14px">&nbsp;{item.name}</SubTitle>
                <SubTitle font="14px" weight="400">&nbsp;{'(' + item.valor + ')'}</SubTitle>
                <Io.IoRemoveCircle style={{
                  cursor: 'pointer',
                  position: 'relative',
                  top: '12px',
                }} color={colors.alertRed} onClick={() => removeHards(item.hard_skill_id)} />
              </Row>
            }) : <></>
            }

          </Group>

          {width > 570 ?
            <Row top="0%">
              <Button
                type="button"
                width="300px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="400px"
                fontSize="13px"
                text={"Salvar"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </Row>
            :
            <>
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text={"Salvar"}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </>
          }
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default NewPersona;

