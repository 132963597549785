import styled from 'styled-components';

import colors from '../../styles/colors';

export const Logo = styled.img`
  position: relative;
  cursor: pointer;
  width: 80px;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  display: ${(props) => props.web || "none"};

  @media (max-width: 870px) {
    width: 80px;
  }

  @media (max-width: 570px) { 
    width: 100px; 
    align-items: center;
    margin: auto;
    display: ${(props) => props.mobile || "none"};
  }
`;
