import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import logo from './../../assets/img/logo.svg';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import BtnExport from './../../assets/img/BtnExport.svg';
import User from './../../assets/img/User.png';
import BtnEdit from './../../assets/img/BtnEdit.svg';
import Site from './../../assets/img/Site.svg';
import Facebook from './../../assets/img/Facebook.svg';
import PlusBlue from './../../assets/img/PlusBlue.svg';
import Twitter from './../../assets/img/Twitter.svg';
import LinkedIn from './../../assets/img/LinkedIn.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import CardColor from './../../components/Card';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts';
import MenuSocial from './../../components/Menu/MenuSocial';
import { ThemeProvider } from "styled-components";
import { lightTheme } from './../../styles/theme.js';
import ScrollToTop from './../../utils/ScrollToTop';
import MenuSummary from './../../components/Menu/MenuSummary';
import MenuExperience from './../../components/Menu/MenuExperience';
import MenuFormation from '../../components/Menu/MenuFormation';
import MenuHardskill from '../../components/Menu/MenuHardskill';
import MenuAbout from '../../components/Menu/MenuAbout';
import * as Io from "react-icons/io5";
import StoreContext from '../../components/Store/Context';
import ErrorModal from './../../components/ErrorModal';
import DeleteModal from './../../components/DeleteModal'
import api from '../../api';
import { useLocation } from 'react-router';
import moment from 'moment';
import CopyModal from '../../components/CopyModal';

import {
  Container,
  Header,
  Logo,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  GroupAccordion,
  GroupText,
  Note,
  DescriptionGroup,
  ContainerButton,
  Group,
  ImageHorizontal,
  ImageVertical,
  ContainerCenter,
  GroupGraph,
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  SubTitleLink,
  ContainerBlue,
  GroupFilter,
  Circle,
  InputCircle,
  Line,
  Avatar,
  Wrap
} from './styles';
import { color } from '@mui/system';


const data = {
  series: [{
    name: 'Cultura',
    data: [80, 50, 30, 40, 100, 20, 100, 80],
  }],
  options: {
    colors: ['#95D45A'],
    chart: {
      height: 350,
      type: 'radar',
      fontFamily: 'Poppins',
    },
    title: {
      text: ''
    },
    xaxis: {
      color: [colors.darkBlue],
      categories: ['Acolhimento', 'Propósito', 'Aprendizado', 'Prazer', 'Resultados', 'Autoridade', 'Segurança', 'Ordem']
    }
  },
}


const Profile = () => {
  const { token, setToken } = useContext(StoreContext);
  if (token == null) {
    history.push({
      pathname: '/login',
    });
  }
  const location = useLocation();
  const [width, setWidth] = useState(window.screen.width);
  const history = useHistory();
  const [card1, setCard1] = useState(false);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  const [card4, setCard4] = useState(false);
  const [card5, setCard5] = useState(false);
  const [card6, setCard6] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const name = location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : (token.displayName || token.email);
  const nameUser = name.length > 0 ? name.trim() : '';
  const nameUpper = name.length > 0 ? nameUser.toUpperCase() : '';
  const [link, setLink] = useState(false);
  const avatar = nameUpper.split(' ');
  const nameAvatar = avatar[0].substr(0, 1);
  const [reportCulture, setReportCulture] = useState([]);
  const [reportBehavioral, setReportBehavioral] = useState([]);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [hardskill, setHardskill] = useState([]);
  const [radar, setRadar] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState(false);
  const [del, setDel] = useState(false);
  const [selectedHS, setSelectedHS] = useState("");
  const [active, setActive] = useState({});

  useEffect(async () => {
    setWidth(window.screen.width);
    getTest();
    getUsers();
    getHardskill();
  }, [width, location]);

  const getUsers = async () => {
    await api.get('/users?status=S', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.users;
      let user = data.filter((item) => {
        return item.email == (location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : token.email)
      })
      setUser(user);

      getData(user[0].id);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const getData = async (id) => {
    await api.get('/dados-candidatos/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      setData(data);

    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const onSaveHS = async (id) => {
    setSelectedHS(id);
    setDel(true);
  }

  const onCard1 = () => {
    setCard1(true);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(false);
  }

  const onCard2 = () => {
    setCard1(false);
    setCard2(true);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(false);
  }

  const onCard3 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(true);
    setCard4(false);
    setCard5(false);
    setCard6(false);
  }

  const onCard4 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(true);
    setCard5(false);
    setCard6(false);
  }

  const onCard5 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(true);
    setCard6(false);
  }

  const onCard6 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(true);
  }

  const onClose = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(false);
    getUsers();
  }

  const editExperience = async (arr) => {
    await setActive(arr);
    await onCard4();
  }

  const newExperience = async (arr) => {
    await setActive([]);
    await onCard4();
  }

  const editCollege = async (arr) => {
    await setActive(arr);
    await onCard5();
  }

  const newCollege = async (arr) => {
    await setActive([]);
    await onCard5();
  }


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onCulture = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/relatoriodeCultura',
      state: {
        activeUser: location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : token.email
      }
    });
  }

  const onBehavioral = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/relatorioComportamental',
      state: {
        activeUser: location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : token.email
      }
    });
  }

  const onChange = async (e) => {
    e.preventDefault();
    const fs = require('fs').promises;
    const files = e.target.files;

    const filesArr = Array.prototype.slice.call(files);

    const formData = new FormData();
    await formData.append("file", filesArr[0].name);

    // await api.post('/upload', formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then((response) => {
    //   console.log("Success: " + response.status);
    // }).catch((error) => {
    //   setError(true);
    //   console.log("Error: ", error.response);
    // });
  }

  const onCloseError = () => {
    setError(false);
  }


  const getTest = async () => {
    const body = {
      "email": (location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : token.email)
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const dataCulture = response.data.cultural;
      const dataBehavioral = response.data.comportamental;
      if (dataCulture != null) {
        await setReportCulture(dataCulture);
        await setRadar({
          series: [{
            name: 'Cultura',
            data: [dataCulture.ACOLHIMENTO, dataCulture.PROPOSITO, dataCulture.APRENDIZADO, dataCulture.PRAZER, dataCulture.RESULTADOS, dataCulture.AUTORIDADE, dataCulture.SEGURANÇA, dataCulture.ORDEM],
          }],
          options: {
            colors: ['#95D45A'],
            chart: {
              height: 350,
              type: 'radar',
              fontFamily: 'Poppins',
            },
            title: {
              text: ''
            },
            xaxis: {
              color: [colors.darkBlue],
              categories: ['Acolhimento', 'Propósito', 'Aprendizado', 'Prazer', 'Resultados', 'Autoridade', 'Segurança', 'Ordem']
            }
          },
        });
        await setIsVisible(true);
      }
      if(dataBehavioral != null){
        await setReportBehavioral(dataBehavioral)
      }
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const getHardskill = async () => {
    await api.get('/hardskill', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      setHardskill(data);

    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  const deleteHS = async (id) => {
    await api.delete('user-hardskill/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      setDel(false);
      getUsers();
    }).catch((error) => {
      setDel(false);
      console.log('erro', error);
      setError(true);
    });
  }

  const copyLink = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_URL + '/perfil?activeUser=' + (location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : token.email));
    setLink(true);
    setTimeout(() => {
      setLink(false);
    }, 800);
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <ContainerBlue>
              <ResponsiveAppBar />
              <ErrorModal isOpen={error} onRequestClose={onCloseError} />
              <CopyModal isOpen={link} width={width} onRequestClose={() => setLink(false)} />
              <DeleteModal isOpen={del} onRequestClose={() => setDel(false)} title={"Tem certeza que deseja desvincular a hardskill?"} onNext={() => deleteHS(selectedHS)} />
              {/* <HeaderUser width={width} /> */}
              {/* <Header>
                          <Logo src={logo} alt="iGrown" />
                        </Header> */}
              {card1 ?
                <MenuSummary user={user} isOpen={card1} onClose={onClose} />
                :
                card2 ?
                  <MenuAbout user={user} isOpen={card2} onClose={onClose} />
                  :
                  card3 ?
                    <MenuSocial user={user} isOpen={card3} onClose={onClose} />
                    :
                    card4 ?
                      <MenuExperience active={active} user={user} isOpen={card4} onClose={onClose} />
                      :
                      card5 ?
                        <MenuFormation active={active} user={user} isOpen={card5} onClose={onClose} />
                        :
                        card6 ?
                          <MenuHardskill user={user} isOpen={card6} onClose={onClose} />
                          :
                          <></>}
              < ScrollToTop />
              <Container>


                <ContainerCard top="20%">
                  <Circle bottom="0%" style={{
                    background: colors.darkBlue,
                    // backgroundImage: 'url(' + User + ')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}>
                    <Avatar>{nameAvatar}</Avatar>
                  </Circle>
                  {/* <Circle type="file" bottom="0%" border={colors.lightBlue} background={colors.lightBlue} top="-35px" width="45px" height="45px">
                    <label for="file-input">
                      <Icon width="30px" float="none" left="0px" right="0px" src={BtnEdit} alt="Item" />
                    </label>
                    <InputCircle id="file-input" type="file" onChange={onChange} />
                  </Circle> */}
                  <GroupFilter top="0%" bottom="70px">
                    <SubTitle bottom="0%" top="1.2px" align="center">{user.length > 0 ? String(user[0].dados_adicionais.nome) + " " + String(user[0].dados_adicionais.sobrenome) : "--"}</SubTitle>
                    <SubTitle bottom="0%" top="1.2px" font="14px" color={colors.lightBlue} align="center">{data.length > 0 ? data[0].posicao_atual : "--"}</SubTitle>
                    <SubTitle weight="400" top="1.2px" font="12px" color={colors.gray4} align="center">{user.length > 0 ? String(user[0].dados_adicionais.cidade) + ", " + String(user[0].dados_adicionais.estado) : "--"}</SubTitle>
                    <Button
                      type="button"
                      width="200px"
                      hoverColor={colors.lightBlue}
                      text="Compartilhar perfil"
                      fontSize="12px"
                      onClick={copyLink}
                      icon={<Icon width="40px" float="left" left="20px" right="-20px" src={BtnExport} alt="Item" />}
                    />
                  </GroupFilter>
                </ContainerCard>

                <ContainerCard top="5%" bottom="0%">
                  <Card width="100%" heigth="300px">
                    <GroupItems>
                      <Row top="0%" content="space-between">
                        <SubTitle>Resumo</SubTitle>
                        {location.search.length > 0 ? <></> : <Button
                          type="button"
                          width="100px"
                          hoverColor={colors.lightBlue}
                          text="Editar"
                          fontSize="12px"
                          onClick={onCard1}
                          icon={<Icon width="30px" float="left" left="10px" right="-20px" src={BtnEdit} alt="Item" />}
                        />}
                      </Row>

                      <DescriptionData font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.resumo) : "--"}</DescriptionData>
                    </GroupItems>
                  </Card>
                </ContainerCard>

                <ContainerCard top="-5%" bottom="0%">
                  <Card width="100%" heigth="300px">
                    <GroupItems>
                      <Row top="0%" content="space-between">
                        <SubTitle>Sobre</SubTitle>
                        {location.search.length > 0 ? <></> : <Button
                          type="button"
                          width="100px"
                          hoverColor={colors.lightBlue}
                          text="Editar"
                          fontSize="12px"
                          onClick={onCard2}
                          icon={<Icon width="30px" float="left" left="10px" right="-20px" src={BtnEdit} alt="Item" />}
                        />}
                      </Row>
                      <Row top="0%">
                        <Group width="25%">
                          <SubTitle font="14px">Cidade onde mora</SubTitle>
                          <SubTitle font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.cidade) : "--"}</SubTitle>
                        </Group>
                        <Group width="25%">
                          <SubTitle font="14px">Experiência na área</SubTitle>
                          <SubTitle font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.experiencia_area) : "--"}</SubTitle>
                        </Group>
                        <Group width="25%">
                          <SubTitle font="14px">Pretensão salarial</SubTitle>
                          <SubTitle font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.pretensao_salarial) : "--"}</SubTitle>
                        </Group>
                        <Group width="25%">
                          <SubTitle font="14px">Modelo de trabalho</SubTitle>
                          <SubTitle font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.modelo_trabalho) : "--"}</SubTitle>
                        </Group>
                      </Row>

                      <Row top="0%">
                        <Group width="25%">
                          <SubTitle font="14px">Aceita propostas</SubTitle>
                          <SubTitle font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.aceita_propostas) : "--"}</SubTitle>
                        </Group>
                        <Group width="25%">
                          <SubTitle font="14px">Nível de inglês</SubTitle>
                          <SubTitle font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.nivel_ingles) : "--"}</SubTitle>
                        </Group>
                        <Group width="25%">
                          <SubTitle font="14px">É PCD?</SubTitle>
                          <SubTitle font="14px" weight="400">{user.length > 0 ? String(user[0].dados_adicionais.pcd) : "--"}</SubTitle>
                        </Group>
                        <Group width="25%">
                        </Group>
                      </Row>
                    </GroupItems>
                  </Card>
                </ContainerCard>

                <ContainerCard top="-15%" bottom="0%">
                  <Card width="100%" heigth="300px">
                    <GroupItems>
                      <Row top="0%" content="space-between">
                        <SubTitle>Redes Sociais</SubTitle>
                        {location.search.length > 0 ? <></> : <Button
                          type="button"
                          width="100px"
                          hoverColor={colors.lightBlue}
                          text="Editar"
                          fontSize="12px"
                          onClick={onCard3}
                          icon={<Icon width="30px" float="left" left="10px" right="-20px" src={BtnEdit} alt="Item" />}
                        />}
                      </Row>
                      <Row top="0%">
                        {user.length > 0 && user[0].dados_adicionais.site_pessoal ?
                          <Group width="25%">
                            <Row top="0%">
                              <SubTitleLink href={user.length > 0 ? String(user[0].dados_adicionais.site_pessoal) : "#"} target="_blank" font="14px" >
                                <Icon width="30px" float="left" left="0px" right="10px" src={Site} alt="Item" />
                                Site pessoal
                              </SubTitleLink>
                            </Row>
                          </Group>
                          :
                          <Group width="25%">

                          </Group>
                        }

                        {user.length > 0 && user[0].dados_adicionais.linkedin ?
                          <Group width="25%">
                            <Row top="0%">
                              <SubTitleLink font="14px" href={user.length > 0 ? String(user[0].dados_adicionais.linkedin) : "#"} target="_blank">
                                <Icon width="30px" float="left" left="0px" right="10px" src={LinkedIn} alt="Item" />
                                LinkedIn
                              </SubTitleLink>
                            </Row>
                          </Group>
                          :
                          <Group width="25%">

                          </Group>
                        }

                        {user.length > 0 && user[0].dados_adicionais.facebook ?
                          <Group width="25%">
                            <Row top="0%">
                              <SubTitleLink font="14px" href={user.length > 0 ? String(user[0].dados_adicionais.facebook) : "#"} target="_blank">
                                <Icon width="30px" float="left" left="0px" right="10px" src={Facebook} alt="Item" />
                                Facebook
                              </SubTitleLink>
                            </Row>
                          </Group>
                          :
                          <Group width="25%">

                          </Group>
                        }

                        {user.length > 0 && user[0].dados_adicionais.twitter ?
                          <Group width="25%">
                            <Row top="0%">
                              <SubTitleLink font="14px" href={user.length > 0 ? String(user[0].dados_adicionais.twitter) : "#"} target="_blank">
                                <Icon width="30px" float="left" left="0px" right="10px" src={Twitter} alt="Item" />
                                Twitter
                              </SubTitleLink>
                            </Row>
                          </Group>
                          :
                          <Group width="25%">

                          </Group>
                        }
                      </Row>
                    </GroupItems>
                  </Card>
                </ContainerCard>

                <ContainerCard top="-25%" bottom="0%">
                  <Card width="100%" heigth="300px">
                    <GroupItems>
                      <Row top="0%" content="space-between">
                        <SubTitle>Experiência profissional</SubTitle>
                        {/* <Button
                          type="button"
                          width="100px"
                          hoverColor={colors.lightBlue}
                          text="Editar"
                          fontSize="12px"
                          onClick={onCard4}
                          icon={<Icon width="30px" float="left" left="10px" right="-20px" src={BtnEdit} alt="Item" />}
                        /> */}
                      </Row>
                      {location.search.length > 0 ? <></> : <Button
                        type="button"
                        width="230px"
                        fontSize="13px"
                        text="Adicionar experiência"
                        color={colors.lightBlue}
                        bgColor={colors.transparent}
                        onClick={newExperience}
                        icon={<Icon width="40px" float="left" left="20px" right="-20px" src={PlusBlue} alt="Item" />}
                      />}

                      {data.map((item, index) => {
                        if (item.experiencias.length > 0) {
                          return <>
                            <Row top="0%" bottom="0%" content="space-between">
                              <Group bottom="-20px" width="30%">
                                <SubTitle bottom="0%" top="3px" font="16px">{item.experiencias[0].empresa}</SubTitle>
                              </Group>
                              <Group bottom="-20px" width="70%" right="0px">
                                {location.search.length > 0 ? <></> : <Button
                                  type="button"
                                  width="100px"
                                  hoverColor={colors.lightBlue}
                                  text="Editar"
                                  fontSize="12px"
                                  onClick={() => editExperience(item.experiencias)}
                                  icon={<Icon width="30px" float="left" left="10px" right="-20px" src={BtnEdit} alt="Item" />}
                                />}
                              </Group>
                            </Row>

                            <SubTitle bottom="0%" top="3px" font="14px" weight="400">{item.experiencias[0].cargo}</SubTitle>
                            <SubTitle bottom="0%" top="3px" font="12px" weight="400" color={colors.gray4}>{moment(item.experiencias[0].periodo_de).format("DD/MM/YYYY") + " - " + (item.experiencias[0].periodo_ate ? moment(item.experiencias[0].periodo_ate).format("DD/MM/YYYY") : "até o momento")}</SubTitle>
                            <SubTitle bottom="0%" font="12px" weight="400">
                              {item.experiencias[0].descricao}
                            </SubTitle>
                            <Line />
                          </>
                        }
                      })}

                    </GroupItems>
                  </Card>
                </ContainerCard>


                <ContainerCard top="-35%" bottom="0%">
                  <Card width="100%" heigth="300px">
                    <GroupItems>
                      <Row top="0%" content="space-between">
                        <SubTitle>Formação acadêmica</SubTitle>
                        {/* <Button
                          type="button"
                          width="100px"
                          hoverColor={colors.lightBlue}
                          text="Editar"
                          fontSize="12px"
                          onClick={onCard5}
                          icon={<Icon width="30px" float="left" left="10px" right="-20px" src={BtnEdit} alt="Item" />}
                        /> */}
                      </Row>
                      {location.search.length > 0 ? <></> : <Button
                        type="button"
                        width="230px"
                        fontSize="13px"
                        text="Adicionar formação"
                        color={colors.lightBlue}
                        bgColor={colors.transparent}
                        onClick={newCollege}
                        icon={<Icon width="40px" float="left" left="20px" right="-20px" src={PlusBlue} alt="Item" />}
                      />}

                      {data.map((item, index) => {
                        if (item.formacoes.length > 0) {
                          return <>
                            <Row top="0%" bottom="0%" content="space-between">
                              <Group bottom="-20px" width="30%">
                                <SubTitle bottom="0%" top="3px" font="16px">{item.formacoes[0].instituicao}</SubTitle>
                              </Group>
                              <Group bottom="-20px" width="70%" right="0px">
                                {location.search.length > 0 ? <></> : <Button
                                  type="button"
                                  width="100px"
                                  hoverColor={colors.lightBlue}
                                  text="Editar"
                                  fontSize="12px"
                                  onClick={() => editCollege(item.formacoes)}
                                  icon={<Icon width="30px" float="left" left="10px" right="-20px" src={BtnEdit} alt="Item" />}
                                />}
                              </Group>
                            </Row>
                            <Row top="0%" bottom="0%" content="start">
                              <SubTitle bottom="0%" top="3px" font="14px" weight="400">{item.formacoes[0].nome_formacao}</SubTitle>
                            </Row>
                            <SubTitle bottom="0%" top="3px" font="12px" weight="400" color={colors.gray4}>{moment(item.formacoes[0].periodo_de).format("DD/MM/YYYY") + " - " + (item.formacoes[0].periodo_ate ? moment(item.formacoes[0].periodo_ate).format("DD/MM/YYYY") : "até o momento")}</SubTitle>
                            {/* <SubTitle bottom="0%" font="12px" weight="400">
                              {item.formacoes[0].empresa}
                            </SubTitle> */}
                            <Line />
                          </>
                        }
                      })}
                    </GroupItems>
                  </Card>
                </ContainerCard>

                {!location.search.length > 0 && user.length > 0 && user[0].hard_skills.length > 0 || location.search.length > 0 && user.length > 0 && user[0].hard_skills.length > 0 ?
                  <ContainerCard top="-45%" bottom="1%">
                    <Card width="100%" heigth="300px">
                      <GroupItems>
                        <Row top="0%" content="space-between">
                          <SubTitle>Hardskills</SubTitle>
                          {location.search.length > 0 ? <></> : <Button
                            type="button"
                            width="230px"
                            fontSize="13px"
                            text="Adicionar hardskill"
                            color={colors.lightBlue}
                            bgColor={colors.transparent}
                            onClick={onCard6}
                            icon={<Icon width="40px" float="left" left="20px" right="-20px" src={PlusBlue} alt="Item" />}
                          />}
                        </Row>

                        {user.length > 0 && user[0].hard_skills.length > 0 ?
                          <Wrap top="0%">
                            {user[0].hard_skills.map((i) => {
                              let json = hardskill.filter((item) => {
                                return i.hard_skill_id == item.id
                              })


                              return <Group width="35%" bottom="1.5%">
                                <Row cursor={"pointer"}>
                                  <Io.IoRemoveCircle color={colors.alertRed} onClick={() => onSaveHS(i.hard_skill_id)} />
                                  <SubTitle font="14px">&nbsp;{json.length > 0 ? json[0].nome : "--"}</SubTitle>
                                  <SubTitle font="14px" weight="400">&nbsp;{'(' + i.valor + ')'}</SubTitle>
                                </Row>
                              </Group>
                            })}
                          </Wrap>
                          : <></>
                        }

                      </GroupItems>
                    </Card>
                  </ContainerCard>
                  :
                  <></>}

                {String(reportBehavioral).length > 0 && reportBehavioral != undefined ?
                  <ContainerCard top="-61%">
                    <Card width="100%" heigth="650px">
                      <GroupItems>
                        <SubTitle>Relatório comportamental</SubTitle>
                        <DescriptionData font="14px" weight="400">O seu relatório comportamental pode ser composto a partir de quatro perfis básicos: Desbravador, Mantenedor, Investigador e Embaixador. Clicando no ícone        ao lado dos nomes abaixo, você poderá conhecer as características principais de cada um deles.
                        </DescriptionData>
                        <DescriptionData font="14px">
                          Abaixo, de acordo com suas respostas, veja como ficou a composição do seu perfil
                          nesse momento.
                        </DescriptionData>
                        <SubCard top="2%" width="100%" heigth="auto" border={colors.gray5}>
                          <GroupItems padding="2%">
                            {width > 530 ?
                              <Row top="0%">
                                <Group width="50%">
                                  <Grid xs="6">
                                    <CardColor
                                      width="400px"
                                      bgColor={colors.yellow}
                                      title='Desbravador'
                                      value={reportBehavioral.DESBRAVADOR + '%'}
                                      font="14px"
                                    />
                                    <CardColor
                                      width="400px"
                                      bgColor={colors.lightBlue}
                                      title='Mantenedor'
                                      value={reportBehavioral.MANTENEDOR + '%'}
                                      font="14px"
                                    />
                                    <CardColor
                                      width="400px"
                                      bgColor={colors.lightGreen}
                                      title='Investigador'
                                      value={reportBehavioral.INVESTIGADOR + '%'}
                                      font="14px"
                                    />
                                    <CardColor
                                      width="400px"
                                      bgColor={colors.alertRed}
                                      title='Embaixador'
                                      value={reportBehavioral.EMBAIXADOR + '%'}
                                      font="14px"
                                    />
                                  </Grid>
                                </Group>
                                <Group width="50%">
                                  <Grid xs="6">
                                    <SubTitle>Seu perfil predominante é</SubTitle>
                                    <SubTitle top="0%" color={colors.lightBlue}>{reportBehavioral.PERFIL_PREDOMINANTE}</SubTitle>
                                    <DescriptionData font="14px" weight="400">
                                      {reportBehavioral.MEU_PERFIL_COMPORTAMENTAL}
                                    </DescriptionData>
                                  </Grid>
                                </Group>
                              </Row>
                              :
                              <>
                                <Grid xs="6">
                                  <CardColor
                                    width="400px"
                                    bgColor={colors.yellow}
                                    title='Desbravador'
                                    value={reportBehavioral.DESBRAVADOR + '%'}
                                    font="14px"
                                  />
                                  <CardColor
                                    width="400px"
                                    bgColor={colors.lightBlue}
                                    title='Mantenedor'
                                    value={reportBehavioral.MANTENEDOR + '%'}
                                    font="14px"
                                  />
                                  <CardColor
                                    width="400px"
                                    bgColor={colors.lightGreen}
                                    title='Investigador'
                                    value={reportBehavioral.INVESTIGADOR + '%'}
                                    font="14px"
                                  />
                                  <CardColor
                                    width="400px"
                                    bgColor={colors.alertRed}
                                    title='Embaixador'
                                    value={reportBehavioral.EMBAIXADOR + '%'}
                                    font="14px"
                                  />
                                </Grid>
                                <Grid xs="6">
                                  <SubTitle>Seu perfil predominante é</SubTitle>
                                  <SubTitle top="0%" color={colors.lightBlue}>{reportBehavioral.PERFIL_PREDOMINANTE}</SubTitle>
                                  <DescriptionData font="14px" weight="400">
                                    {reportBehavioral.MEU_PERFIL_COMPORTAMENTAL}
                                  </DescriptionData>
                                </Grid>
                              </>
                            }
                          </GroupItems>
                        </SubCard>
                      </GroupItems>

                      <GroupFilter>
                        <Button
                          type="button"
                          width="250px"
                          hoverColor={colors.lightBlue}
                          text="Ver relatório completo"
                          fontSize="12px"
                          onClick={onBehavioral}
                        />
                      </GroupFilter>
                    </Card>
                  </ContainerCard>
                  :
                  <></>
                }

                {String(reportCulture).length > 0 && reportCulture != undefined ?
                  <ContainerCard top="-70%" bottom="1%">
                    <Card width="100%" heigth="650px">
                      <GroupItems>
                        <SubTitle>Relatório cultural</SubTitle>
                        <DescriptionData font="14px">
                          De acordo com suas respostas, seus estilos dominantes são:
                        </DescriptionData>
                        <GroupItems padding="2%">
                          {width > 530 ?
                            <Row top="0%">
                              <Group width="100%">
                                <SubCard top="2%" width="100%" heigth="auto" background={colors.lightBlue} border={colors.lightBlue}>
                                  <GroupItems padding="5%">
                                    <SubTitle font="16px" color={colors.white}>{reportCulture.CULTURA_PREDOMINANTE}</SubTitle>
                                    <LabelData color={colors.white} font="14px" weight="400">{reportCulture.A_CULTURA}</LabelData>
                                  </GroupItems>
                                </SubCard>
                              </Group>
                            </Row>
                            :
                            <>
                              <Group width="100%">
                                <SubCard top="2%" width="100%" heigth="auto" background={colors.lightBlue} border={colors.lightBlue}>
                                  <GroupItems padding="5%">
                                    <GroupItems padding="5%">
                                      <SubTitle font="16px" color={colors.white}>{reportCulture.CULTURA_PREDOMINANTE}</SubTitle>
                                      <LabelData color={colors.white} font="14px" weight="400">{reportCulture.A_CULTURA}</LabelData>
                                    </GroupItems>
                                  </GroupItems>
                                </SubCard>
                              </Group>
                            </>
                          }
                        </GroupItems>
                        <SubTitle>A composição de suas culturas pode ser representada da seguinte forma:</SubTitle>
                        <DescriptionData font="16px" weight="400">No gráfico abaixo, você pode ver, além de seus estilos predominantes, outros elementos que, de alguma forma, estiveram presentes em suas respostas.</DescriptionData>
                        {isVisible ? <Chart options={radar.options} series={radar.series} type="radar" height={800} /> : false}
                      </GroupItems>

                      <GroupFilter>
                        <Button
                          type="button"
                          width="250px"
                          hoverColor={colors.lightBlue}
                          text="Ver relatório completo"
                          fontSize="12px"
                          onClick={onCulture}
                        />
                      </GroupFilter>
                    </Card>
                  </ContainerCard>
                  :
                  <></>
                }

              </Container>
            </ContainerBlue>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider >

  )
}

export default Profile;
