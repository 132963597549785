export const validDate = (date) => {
    let regex = /^(\d{2})\/(\d{2})\/(\d{4})$/.exec(date);
    if (regex) {
      let day = parseInt(regex[1]);
      let month = parseInt(regex[2]);
      let year = parseInt(regex[3]);
  
      let d = new Date(year, month - 1, day);
      let valid = d.getFullYear() == year
        && d.getMonth() + 1 == month
        && d.getDate() == day;
      return valid;
    } else {
      console.log("Error -> function: validDate")
    }
  }