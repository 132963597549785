import React from 'react';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import './../../../styles/SidebarConfig.css';

const MenuData = (prop) => {

    let icon = prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;

    return (
        <Slide right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <GroupLine>
                <Title>{prop.title}</Title>
            </GroupLine>
            <Container>
                <Group>
                    <TextField label="Nome" variant="outlined" size="small" value={prop.data.name} color="info" disabled={true} />
                </Group>
                <Group>
                    <TextField label="E-mail" variant="outlined" size="small" value={prop.data.email} color="info" disabled={true} />
                </Group>
                {/* <Group>
                    <FormControl sx={{ m: 0, width: 218 }}>
                        <InputLabel id="genre">Gênero</InputLabel>
                        <Select
                            labelId="genre"
                            id="genre"
                            variant="outlined"
                            value={2}
                            size="small"
                            color="info"
                            disabled={true}
                            input={<OutlinedInput label="Gênero" />}
                        >
                            <MenuItem value={1}>Feminino</MenuItem>
                            <MenuItem value={2}>Masculino</MenuItem>
                            <MenuItem value={3}>Prefiro não informar</MenuItem>
                        </Select>
                    </FormControl>
                </Group>
                <Group>
                    <FormControl sx={{ m: 0, width: 218 }}>
                        <InputLabel id="occupation">Ocupação principal</InputLabel>
                        <Select
                            labelId="occupation"
                            id="occupation"
                            variant="outlined"
                            value={1}
                            size="small"
                            color="info"
                            disabled={true}
                            input={<OutlinedInput label="Ocupação principal" />}
                        >
                            <MenuItem value={1}>Trabalho</MenuItem>
                            <MenuItem value={2}>Autônomo</MenuItem>
                        </Select>
                    </FormControl>
                </Group>
                <Group>
                    <TextField label="Nome da empresa" variant="outlined" size="small" value="CodeNuts" color="info" disabled={true} />
                </Group>
                <Group>
                    <TextField label="Data de nascimento" variant="outlined" size="small" value="00/00/0000" color="info" disabled={true} />
                </Group> */}
                {prop.data.dados_adicionais ?
                    <>
                        <Group>
                            <TextField label="Ocupação principal" variant="outlined" size="small" value={prop.data.dados_adicionais.ocupacao_principal || "Não informada"} color="info" disabled={true} />
                        </Group>
                        <Group>
                            <TextField label="Gênero" variant="outlined" size="small" value={prop.data.dados_adicionais.genre || "Não informado"} color="info" disabled={true} />
                        </Group>
                    </>
                    : false}
                <Group>
                    <TextField label="Plano contratado" variant="outlined" size="small" value="Sem Plano" color="info" disabled={true} />
                </Group>
                {/* <Card
                    width="230px"
                    bgColor={colors.yellow}
                    title='NPS'
                    description='teste comportamental'
                    value='68%'
                    right
                    left
                    paddingLabel={"0%"}
                />
                <Card
                    width="230px"
                    bgColor={colors.lightGreen}
                    title='NPS'
                    description='teste cultura'
                    value='20%'
                    paddingLabel={"0%"}
                />
                <Card
                    width="230px"
                    bgColor={colors.alertRed}
                    title='NPS'
                    description='teste âncora de carreira'
                    value='88%'
                    paddingLabel={"0%"}
                /> */}
                <Button
                    type="button"
                    width="230px"
                    hoverColor={colors.lightBlue}
                    text="Ok, entendi"
                    fontSize="12px"
                    onClick={prop.onClose}
                />
            </Container>
        </Slide>
    )
}

export default MenuData;
