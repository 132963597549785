import styled from 'styled-components';
import colors from './../../styles/colors'

export const LayoutContainer = styled.div `
  background-color: ${({ theme }) => theme.background};
  /* transition: 0.8s ease-in-out */
`; 

export const LayoutContent = styled.div `
  min-height: 100vh;
  @media (max-width: 870px) {
    padding-left: 0;
  }
`;

export const LayoutContentMain = styled.div `
  margin: 0 auto;
`; 

export const Title = styled.h1`
position: relative;
height: 52px;
top: 110px;

/* H1 - Web */

font-family: 'Poppins';
font-style: normal;
font-weight: 700;

line-height: 130%;
/* identical to box height, or 52px */

@media (max-width: 870px) {
  font-size: 37px;
}

@media (max-width: 570px) {  
  font-size: 27px;
}

text-align: center;
letter-spacing: 0.116667px;

color: ${colors.white};
`;

export const Container = styled.div`
/* Shape */
position: relative;
width: 100%;
height: 400px;
border-radius: 0% 100% 0% 100% / 82% 0% 100% 18%;
border-bottom-right-radius: 50px;
z-index: 1;
background: ${colors.darkBlue};
`;

export const Card = styled.div`
position: relative;
text-align: center;
width: 400px;
height: 264px;
margin-left: auto;
margin-right: auto;
top: 35%;
background: ${colors.white};
box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
border-radius: 12px;
align-items: center;

@media (max-width: 870px) {
  width: 400px;
}

@media (max-width: 570px) {  
  width: 340px;
}
`;


export const Label = styled.p`
width: 336px;
height: 72px;
left: 552px;
top: 262px;

margin-left: auto;
margin-right: auto;
padding-top: 30px;

/* 16px - Regular */

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: ${(props) => props.size || "16px"};
line-height: 150%;
/* or 24px */

text-align: center;
letter-spacing: 0.1px;

/* 062847 */

padding-bottom: 100px;

p{
  font-weight: bold;
}

@media (max-width: 570px) {  
  padding-left: 10px;
  padding-right: 10px;
}

color: ${(props) => props.color || colors.darkBlue};
`;
