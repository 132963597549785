import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #E2E2EA;
`;

export const Container = styled.div`
    padding-top: 10%;
`;

export const Item = styled.img`
  position: relative;
  width: 120px !important;
  padding-left: 2%;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  margin-top: -15px;
  margin-bottom: 15px;

  @media (max-width: 870px) {
    width: 110px !important;
    padding-left: 1%;
  }

  @media (max-width: 570px) {  
    width: 100px !important;
    padding-left: 1%;
  }
`;

export const Group = styled.div`
    padding-bottom: 5%;
`;

export const Title = styled.h2`
    /* H2 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.116667px;
    color: ${colors.darkBlue};
`;

export const SubTitle = styled.p`
 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => props.font || "18px"};
    line-height: 150%;
    letter-spacing: 0.116667px;
    color: ${colors.darkBlue};
`;

export const GroupLine = styled.div`
    padding-bottom: 5%;
    border-bottom: 1px solid ${colors.gray5};
`;

export const Shape = styled.img`
  position: relative;
  width: 30px !important;
  padding-left: 2%;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  margin-top: -15px;
  margin-bottom: 15px;
  margin-left: 70%;

  @media (max-width: 870px) {
    width: 25px !important;
    padding-left: 1%;
  }

  @media (max-width: 570px) {  
    width: 20px !important;
    padding-left: 1%;
  }
`;

export const Row = styled.div`
      position: relative;
      width: 100% important;
      text-align: ${(props) => props.align || "center"};
      align-item: center;
      display: flex;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${(props) => props.bottom || "20px"};
      justify-content: start;
      border-radius: 12px;
      top: ${(props) => props.top || "0%"};
      align-items: ${(props) => props.items || "center"};;
`;

export const DescriptionData = styled.p`
          font-size: ${(props) => props.font || "12px"};
          padding-bottom: ${(props) => props.bottom || "0%"};
          padding-top: ${(props) => props.top || "1.5%"};

          font-family: 'Poppins';
          font-style: ${(props) => props.styles || "normal"};
          font-weight: ${(props) => props.weight || "600"};

          /* identical to box height, or 52px */

          @media (max-width: 870px) {
            font-size: 12px;
            padding-top: 0.5%;
          }

          @media (max-width: 570px) {  
            font-size: 12px;
            padding-top: 1%;
          }

          text-align: start;
          letter-spacing: 0.116667px;

          color: ${(props) => props.color || colors.darkBlue};
`;

export const GpItems = styled.div`
  position: relative;
  margin-top: 0;
  padding: ${(props) => props.padding || "20px"};
  border: 1px solid ${(props) => props.borderColor || colors.transparent};;
  border-radius: ${(props) => props.radius ? props.radius : "12px 12px 12px 12px"};
  width: ${(props) => props.width || "500px"};
  background: ${(props) => props.background ? props.background : colors.transparent};
`;

export const LabelData = styled.label`
          font-size: ${(props) => props.font || "12px"};
          padding-top: 25px;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: ${(props) => props.weight || "600"};

          line-height: 130%;
          /* identical to box height, or 52px */

          @media (max-width: 870px) {
            font-size: 12px;
          }

          @media (max-width: 570px) {  
            font-size: 12px;
          }

          text-align: start;
          letter-spacing: 0.116667px;
          text-align: ${(props) => props.align || "start"};
          color: ${(props) => props.color || colors.darkBlue};
`;

export const Icon = styled.img`
  position: relative;
  width: ${(props) => props.width || "50px"};
  float: ${(props) => props.float || "right"};
  margin-top: ${(props) => props.top || "0px"};;
  padding-right: 10px;
  padding-left: 15px;

  @media (max-width: 870px) {
    width: ${(props) => props.width || "50px"};
  }

  @media (max-width: 570px) {  
    width: ${(props) => props.width || "50px"};
  }
`;