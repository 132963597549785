import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
/* Shape */
position: relative;
width: 100%;
height: 400px;
border-radius: 0% 100% 0% 100% / 82% 0% 100% 18%;
border-bottom-right-radius: 50px;
z-index: 1;
background: ${colors.darkBlue};
`;

export const Group = styled.div`
  position: relative;
  flex-direction: column;
  padding-bottom: ${(props) => props.bottom || "0px"};
  margin-right:  ${(props) => props.right || "25px"};
  text-align: start !important;
  width: ${(props) => props.width || "500px"};
`;

export const GroupFilter = styled.div`
    padding-bottom: ${(props) => props.bottom || "1%"};
    padding-top: ${(props) => props.top || "0%"};
    padding-left: 5.6%;
    padding-right: 5.6%;
    text-align: ${(props) => props.align || "center"};
`;


export const Card = styled.div`
    position: relative;
    text-align: center;
    align-item: center;
    width: ${(props) => props.width || "1200px"};
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${(props) => props.bottom || "0.5%"};
    margin-bottom: 1%;
    top: 40%;
    background: ${colors.white};
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius: 12px;
    align-items: center;

    @media (max-width: 870px) {
      width: 700px;
      margin-bottom: 5%;
    }

    @media (max-width: 570px) {  
      width: 340px;
      margin-bottom: 5%;
    }
    `;

export const GroupItems = styled.div`
      padding: ${(props) => props.padding || "5%"};
`;


export const Icon = styled.img`
  position: relative;
  cursor: pointer;
  width: ${(props) => props.width || "50px"};
  float: ${(props) => props.float || "right"};
  padding-right: ${(props) => props.right || "15px"};
  padding-left: ${(props) => props.left || "10px"};

  @media (max-width: 870px) {
    width: ${(props) => props.width || "50px"};
  }

  @media (max-width: 570px) {  
    width: ${(props) => props.width || "50px"};
  }
`;


export const Row = styled.div`
      cursor: ${(props) => props.cursor || "auto"};
      position: relative;
      width: 100% important;
      text-align: center;
      align-item: center;
      display: flex;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${(props) => props.bottom || "20px"};
      justify-content: ${(props) => props.content || "start"};
      border-radius: 12px;
      align-items: center;
`;

export const Wrap = styled.div`
      position: relative;
      width: 100% important;
      text-align: center;
      align-item: center;
      display: flex;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${(props) => props.bottom || "20px"};
      justify-content: ${(props) => props.content || "start"};
      border-radius: 12px;
      align-items: center;
      flex-wrap: wrap;
`;

export const SubTitle = styled.h2`
    /* H2 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: ${(props) => props.weight || "700"};;
    font-size: ${(props) => props.font || "20px"};
    line-height: 150%;
    text-align:  ${(props) => props.align || "start"};;
    padding-top:  ${(props) => props.top || "1.5%"};
    padding-bottom: ${(props) => props.bottom || "1.8%"};
    letter-spacing: 0.116667px;
    color:  ${(props) => props.color || colors.darkBlue};
    @media (max-width: 870px) {
    padding-top:  ${(props) => props.top || "5%"};
    padding-bottom: ${(props) => props.bottom || "6%"};
    }
  
    @media (max-width: 570px) {  
    padding-top:  ${(props) => props.top || "5%"};
    padding-bottom: ${(props) => props.bottom || "6%"};
    }
`;



export const ContainerCard = styled.div`
      position: relative;
      text-align: start;
      align-item: start;
      width: ${(props) => props.width || "1200px"};
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${(props) => props.bottom || "0.5%"};
      padding-bottom: ${(props) => props.bottom || "0%"};
      justify-content: start;
      top: ${(props) => props.top || '30%'};
      border-radius: 12px;
      align-items: start;

      @media (max-width: 870px) {
        width: 700px;
        display: block;
      }

      @media (max-width: 570px) {  
        width: 340px;
        display: block;
      }
`;


export const Label = styled.h2`
    /* H2 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: start;
    padding-top: 1.5%;
    padding-bottom: 1.8%;
    padding-left: 2.5%;
    letter-spacing: 0.116667px;
    color: ${colors.darkBlue};
    @media (max-width: 870px) {
      padding-top: 5%;
      padding-bottom: 6%;
    }
  
    @media (max-width: 570px) {  
      padding-top: 5%;
      padding-bottom: 6%;
    }
`;


export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #E2E2EA;
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 120px;
  }

  @media (max-width: 570px) {  
    width: 100px;
  }
`;

export const Title = styled.h1`
position: relative;
height: 52px;
top: 110px;

/* H1 - Web */

font-family: 'Poppins';
font-style: normal;
font-weight: 700;

line-height: 130%;
/* identical to box height, or 52px */

@media (max-width: 870px) {
  font-size: 37px;
}

@media (max-width: 570px) {  
  font-size: 27px;
}

text-align: center;
letter-spacing: 0.116667px;

color: ${colors.white};
`;

