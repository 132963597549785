import React, { useState, useEffect, useContext } from 'react';
import BtnExport from './../../assets/img/BtnExport.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import { lightTheme } from './../../styles/theme.js';
import ScrollToTop from './../../utils/ScrollToTop';
import logo from './../../assets/img/logo.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import ErrorModal from './../../components/ErrorModal';
import AlertModal from './../../components/AlertModal';
import { useLocation } from 'react-router';

import {
  Container,
  Header,
  Logo,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  GroupAccordion,
  GroupText,
  Note,
  DescriptionGroup,
  ContainerButton,
  Group,
  ImageHorizontal,
  ImageVertical,
  ContainerCenter,
  GroupGraph,
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  SubTitleLink,
  ContainerBlue,
  ContainerTitle,
  GroupFilter,
  CardRadio,
  Circle,
  InputCircle,
  Line,
  Column,
  Tag,
} from './styles';
import { color } from '@mui/system';


// const data = {
//   series: [{
//     name: 'Cultura',
//     data: [80, 50, 30, 40, 100, 20, 100, 80],
//   }],
//   options: {
//     colors: ['#95D45A'],
//     chart: {
//       height: 350,
//       type: 'radar',
//       fontFamily: 'Poppins',
//     },
//     title: {
//       text: ''
//     },
//     xaxis: {
//       color: [colors.darkBlue],
//       categories: ['Acolhimento', 'Propósito', 'Aprendizado', 'Prazer', 'Resultados', 'Autoridade', 'Segurança', 'Ordem']
//     }
//   },
// }


const Vacancy = () => {
  const [width, setWidth] = useState(window.screen.width);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [vacancy, setVacancy] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const { token, setToken, userGlobal } = useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();

  // const defaultTheme = createMuiTheme();
  // const theme = createMuiTheme({
  //   overrides: {
  //     MuiTooltip: {
  //       tooltip: {
  //         fontSize: "1em",
  //         backgroundColor: colors.darkBlue
  //       }
  //     }
  //   }
  // });


  // const BpIcon = styled('span')(({ theme }) => ({
  //   borderRadius: '50%',
  //   width: 16,
  //   height: 16,
  //   boxShadow:
  //     theme.palette.mode === 'dark'
  //       ? '0 0 0 1px rgb(16 22 26 / 40%)'
  //       : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  //   backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  //   backgroundImage:
  //     theme.palette.mode === 'dark'
  //       ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //       : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  //   '.Mui-focusVisible &': {
  //     outline: '2px auto rgba(19,124,189,.6)',
  //     outlineOffset: 2,
  //   },
  //   'input:hover ~ &': {
  //     backgroundColor: theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
  //   },
  //   'input:disabled ~ &': {
  //     boxShadow: 'none',
  //     background:
  //       theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
  //   },
  // }));

  // const BpCheckedIcon = styled(BpIcon)({
  //   backgroundColor: colors.lightBlue,
  //   backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  //   '&:before': {
  //     display: 'block',
  //     width: 16,
  //     height: 16,
  //     backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
  //     content: '""',
  //   },
  //   'input:hover ~ &': {
  //     backgroundColor: colors.lightBlue,
  //   },
  // });

  // // Inspired by blueprintjs
  // function BpRadio(props) {
  //   return (
  //     <Radio
  //       disableRipple
  //       color="default"
  //       checkedIcon={<BpCheckedIcon />}
  //       icon={<BpIcon />}
  //       {...props}
  //     />
  //   );
  // }

  const onHome = () => {
    history.push({
      pathname: '/',
    });
  }

  const onNext = () => {
    if (data.comportamental && data.cultural && data.valores) {
      bondVacancy();
    } else {
      const route = !data.comportamental && !data.cultural && !data.valores ? '/testeComportamental'
        : !data.comportamental && !data.cultural && data.valores ? '/testeComportamental'
          : data.comportamental && data.cultural && !data.valores ? '/testeCarreira'
            : !data.comportamental && data.cultural && data.valores ? '/testeComportamental'
              : data.comportamental && !data.cultural && data.valores ? '/testeCultural'
                : '/testeComportamental'

      history.push({
        pathname: route,
        state: {
          vacancy: true
        },
      });
    }

  }


  const getTests = async () => {
    const body = {
      "email": token.email
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      await setData(data)
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const getVacancy = async (id) => {
    await api.get('/vagas/' + id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;

      setVacancy(data[0]);
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  const getCompanies = async () => {
    //Admin
    // await api.get('/companies', {
    //   headers: {
    //     'authorization': 'Bearer ' + token.refreshToken
    //   }
    // }).then(async (response) => {
    //   const data = response.data.response;
    //   setCompanies(data);

    // }).catch((error) => {
    //   console.log('erro', error);
    //   setError(true);
    // });

    const data = userGlobal;
    setCompanies(data);
  }

  const getUsers = async () => {
    await api.get('/users?status=S', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.users;
      let user = data.filter((item) => {
        return item.email == token.email
      })
      setUser(user);

    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }


  useEffect(async () => {
    let id = location.search.length > 0 ? location.search.substring(location.search.indexOf("=") + 1, +  location.search.length) : "";
    getUsers();
    getCompanies();
    getVacancy(id);
    getTests();
    setWidth(window.screen.width);
  }, [width]);

  const onCloseError = () => {
    setError(false);
  }

  let json = companies.filter((i) => {
    return vacancy.company_id == i.id
  })

  const onCloseAlert = () => {
    setAlert(false);
  }

  const bondVacancy = async () => {

    await api.get('/dados/users/' + user[0].id + "/" + vacancy.id, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data.data;
      if (data.length == 0) {
        linkVacancy();
      }
      //CONTINUA AQUI DEPOIS INSERINDO A TELA PRA RESPONDER DADOS COMPLEMENTARES
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });

  }


  const linkVacancy = async () => {
    const json = {
      "user_id": user[0].id,
      "vaga_id": vacancy.id
    }
    await api.post('/user-vagas', json, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      setAlert(true);
    }).catch((error) => {
      console.log('erro', error);
      setError(true);
    });
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            {token ?
              <ResponsiveAppBar />
              : <Header>
                <a href="/login"><Logo src={logo} alt="iGrown" /></a>
              </Header>}
            <ErrorModal isOpen={error} onRequestClose={onCloseError} />
            <AlertModal isOpen={alert} msg={"Identificamos que você realizou todos os testes necessários. \nSua candidatura foi concluída com sucesso!"} onRequestClose={onCloseAlert} />
            <ScrollToTop />
            <Container>

              <ContainerCard top="10%">
                <GroupFilter top="5%" bottom="70px">
                  {width <= 570 ?
                    <>
                      <SubTitle color={colors.white} font="30px" bottom="0%" top="1.2px" align="center">Aplicação para</SubTitle>
                      <Row top="0%" content="center">
                        <SubTitle color={colors.white} font="30px" bottom="0%" top="1.2px" align="center"> vaga:</SubTitle>
                        <SubTitle color={colors.lightBlue} font="30px" bottom="0%" top="1.2px" align="center">&nbsp;{vacancy.nome}</SubTitle>
                      </Row>
                    </>
                    :
                    <Row top="0%" content="center">
                      <SubTitle color={colors.white} font="30px" bottom="0%" top="1.2px" align="center">Aplicação para vaga:</SubTitle>
                      <SubTitle color={colors.lightBlue} font="30px" bottom="0%" top="1.2px" align="center">&nbsp;{vacancy.nome}</SubTitle>
                    </Row>
                  }

                  <Row top="0%" content="center">
                    <SubTitle color={colors.white} font="30px" bottom="0%" top="1.2px" align="center">Empresa:</SubTitle>
                    <SubTitle color={colors.lightBlue} font="30px" bottom="0%" top="1.2px" align="center">&nbsp;{json.length > 0 ? json[0].nome_fantasia : "--"}</SubTitle>
                  </Row>
                  <SubTitle color={colors.white}>Hey, iGrowner!</SubTitle>
                  <DescriptionData font="14px" weight="400" color={colors.white}>
                    Agora que você já entrou na plataforma o momento é de conhecer melhor você e ver o quanto você está aderente a vaga aberta da carreira que estamos te apresentando.
                  </DescriptionData>
                  <DescriptionData font="14px" weight="400" color={colors.white}>
                    Clique no botão abaixo e faça os nossos testes. Ao final, você retornará para essa página, finalizando com sua autoavaliação das competências e requisitos que pedimos para esta carreira.
                  </DescriptionData>
                  <ContainerCard top="5%" bottom="0%">
                    <SubTitle bottom="0.5%" top="4%" color={colors.lightBlue}>Descrição da vaga</SubTitle>
                    <DescriptionData font="14px" weight="400" color={colors.white}>
                      {vacancy.descricao}
                    </DescriptionData>
                  </ContainerCard>
                  <Row top="20px" content="center">
                    <Button
                      type="button"
                      width="400px"
                      hoverColor={colors.lightBlue}
                      text="Responder aos testes de conhecimento"
                      fontSize="12px"
                      onClick={onNext}
                      icon={<Icon width="40px" float="left" left="20px" right="-20px" src={BtnExport} alt="Item" />}
                    />
                  </Row>
                </GroupFilter>
              </ContainerCard>

              <Line />
              <ContainerTitle>
                <Button
                  type="button"
                  width="80px"
                  fontSize="13px"
                  text="Sair"
                  color={colors.lightBlue}
                  bgColor={colors.transparent}
                  onClick={onHome}
                />
                <Button
                  type="button"
                  width="100px"
                  fontSize="13px"
                  hoverColor={colors.white}
                  bgColor={colors.white}
                  color={colors.darkBlue}
                  border
                  text="Iniciar"
                  onClick={onNext}
                />
              </ContainerTitle>

              {/* <ContainerCard top="0%" bottom="0%">
                  <Card width="100%" background={colors.transparent} top="0%" bottom="0%">
                    <GroupItems>
                      <SubTitle>Auto-avaliação de competências</SubTitle>
                      <DescriptionData font="14px" weight="400">{'Utilize o formulário abaixo para registrar a sua auto avaliação sobre as suas competências técnicas.'}</DescriptionData>
                      <DescriptionData font="14px" weight="400">{'Responda conforme o seu grau de conhecimento sobre cada assunto, considerando a seguinte escala de referência:'}</DescriptionData>
                      <DescriptionData font="14px" weight="400">{'0: Nunca atuei na área e não possuo conhecimento teórico.'}</DescriptionData>
                      <DescriptionData font="14px" weight="400">{'3: Nunca atuei na área, mas possuo conhecimento teórico.'}</DescriptionData>
                      <DescriptionData font="14px" weight="400">{'5: Tenho alguma experiência, mas estou aprendendo.'}</DescriptionData>
                      <DescriptionData font="14px" weight="400">{'7: Tenho experiência e consigo auxiliar outras pessoas.'}</DescriptionData>
                      <DescriptionData font="14px" weight="400">{'10: Tenho muita experiência e sou visto(a) como referência.'}</DescriptionData>
                    </GroupItems>
                  </Card>
                </ContainerCard> */}

              {/* <ContainerCard top="0%" bottom="1%">
                  <Card width="100%" background={colors.snow} top="0%" bottom="0%">
                    <Column>
                      <Tag>
                        <DescriptionData color={colors.white} align="center">
                          Desejável
                        </DescriptionData>
                      </Tag>
                    </Column>
                    <GroupItems>
                      <SubTitle>Inglês</SubTitle>
                      <FormControl>
                        <RadioGroup
                          defaultValue=""
                          aria-labelledby="demo-customized-radios"
                          name="customized-radios"
                        >
                          <CardRadio>
                            <FormControlLabel value="Básico" control={<BpRadio />} label="Básico" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="Intermediário" control={<BpRadio />} label="Intermediário" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="Avançado" control={<BpRadio />} label="Avançado" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="Fluente" control={<BpRadio />} label="Fluente" />
                          </CardRadio>
                        </RadioGroup>
                      </FormControl>
                    </GroupItems>
                  </Card>
                </ContainerCard> */}


              {/* <ContainerCard top="0%" bottom="1%">
                  <Card width="100%" background={colors.snow} top="0%" bottom="0%">
                    <Column>
                      <Tag>
                        <DescriptionData color={colors.white} align="center">
                          Desejável
                        </DescriptionData>
                      </Tag>
                    </Column>
                    <GroupItems>
                      <SubTitle>Conhecimento em Python</SubTitle>
                      <FormControl>
                        <RadioGroup
                          defaultValue=""
                          aria-labelledby="demo-customized-radios"
                          name="customized-radios"
                        >
                          <CardRadio>
                            <FormControlLabel value="1" control={<BpRadio />} label="1" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="2" control={<BpRadio />} label="2" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="3" control={<BpRadio />} label="3" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="4" control={<BpRadio />} label="4" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="5" control={<BpRadio />} label="5" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="6" control={<BpRadio />} label="6" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="7" control={<BpRadio />} label="7" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="8" control={<BpRadio />} label="8" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="9" control={<BpRadio />} label="9" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="10" control={<BpRadio />} label="10" />
                          </CardRadio>
                        </RadioGroup>
                      </FormControl>
                    </GroupItems>
                  </Card>
                </ContainerCard> */}

              {/* <ContainerCard top="0%" bottom="5%">
                  <Card width="100%" background={colors.snow} top="0%" bottom="0%">
                    <Column>
                      <Tag>
                        <DescriptionData color={colors.white} align="center">
                          Desejável
                        </DescriptionData>
                      </Tag>
                    </Column>
                    <GroupItems>
                      <SubTitle>CRECI</SubTitle>
                      <FormControl>
                        <RadioGroup
                          defaultValue=""
                          aria-labelledby="demo-customized-radios"
                          name="customized-radios"
                        >
                          <CardRadio>
                            <FormControlLabel value="Sim" control={<BpRadio />} label="Sim" />
                          </CardRadio>
                          <CardRadio>
                            <FormControlLabel value="Não" control={<BpRadio />} label="Não" />
                          </CardRadio>
                        </RadioGroup>
                      </FormControl>
                    </GroupItems>
                  </Card>
                </ContainerCard> */}


            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider >

  )
}

export default Vacancy;
