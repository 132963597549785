import React, { useState, useEffect, useContext } from 'react';
import HeaderUser from '../../components/Headers/HeaderAdmin';
import Activity from './../../assets/img/Activity.svg';
import Solution from './../../assets/img/Solution.svg';
import Deployment from './../../assets/img/Deployment.svg';
import Cluster from './../../assets/img/Cluster.svg';
import Finish from './../../assets/img/Finish.svg';
import Pending from './../../assets/img/Pending.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import moment from 'moment';
import { Link } from "react-router-dom";
import api from '../../api';

import {
  Container,
  Header,
  Logo,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  Description,
  ContainerTitle,
  DescriptionData,
  DescriptionCard,
  Row,
  Tag,
  Column,
  GroupProgress,
  Progress
} from './styles';

const Home = () => {

  const { token, setToken } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const history = useHistory();

  useEffect(async () => {
    setWidth(window.screen.width);
    getTests1();
    getTests();
  }, [width]);

  

 const changeDetails = (id) => {
    setDetails(!details);
  }

  const onBehavioral = () => {
    history.push({
      pathname: '/testeComportamental',
      state: {
        vacancy: false
      }
    });
  }

  const onCulture = () => {
    history.push({
      pathname: '/testeCultura',
      state: {
        vacancy: false
      },
    });
  }

  const onCarrer = () => {
    history.push({
      pathname: '/testeCarreira',
      state: {
        vacancy: false
      },
    });
  }

  const onVacancy = () => {
    history.push({
      pathname: '/minhasCarreiras',
      state: {
        vacancy: false
      },
    });
  }

  const getTests1 = async () => {
    await api.post('/perfis', {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      console.log('data', data)
    }).catch((error) => {
      console.log('erro', error);
    });
  }


  const getTests = async () => {
    const body = {
      "email": token.email
    }
    await api.post('/tests', body, {
      headers: {
        'authorization': 'Bearer ' + token.refreshToken
      }
    }).then(async (response) => {
      const data = response.data;
      await setData(data)
    }).catch((error) => {
      console.log('erro', error);
    });
  }

  return (
    <Container>
      <ResponsiveAppBar />
      {/* <HeaderUser width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <Title>Inicie sua jornada de carreira</Title>
      <Description>Apenas 3 passos para aumentar o seu autoconhecimento e ter um perfil campeão</Description>
      <ContainerCard width="1400px">
        <Card width="23%">
          <GroupItems>
            <ContainerTitle>
              <Icon src={Solution} alt="Next" />
              <div>
                <DescriptionData>{data.comportamental ? "Útltima atualização:" : ""}</DescriptionData>
                <DescriptionData weight="700">{data.comportamental ? moment(data.comportamental.DATA_DA_ENTRADA).format("DD/MM/YYYY") : ""}</DescriptionData>
              </div>
            </ContainerTitle>
            {data.comportamental ?
              <Column>
                <Tag>
                  <DescriptionData color={colors.white}>
                    Teste 1 - Concluído
                  </DescriptionData>
                </Tag>
              </Column>
              :
              <Column>
                <Tag background={colors.gray4}>
                  <DescriptionData color={colors.white}>
                    Teste 1 - Pendente
                  </DescriptionData>
                </Tag>
              </Column>
            }
            <SubTitle bottom="12%">Teste de perfil comportamental</SubTitle>
            <DescriptionCard>
              Conheça seu perfil predominante, suas habilidades comportamentais, agilidades de aprendizagem e potencial.
            </DescriptionCard>
            <Row>
              <Icon width='35px' src={Activity} alt="Next" />
              <DescriptionData> Tempo estimado 8 minutos</DescriptionData>
            </Row>
            {data.comportamental ?
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard color={colors.darkBlue}>
                    Concluído
                  </DescriptionCard>
                  <DescriptionCard color={colors.darkBlue}>
                    100%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress colors={colors.pureGreen} />
              </GroupProgress>
              :
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard>
                    Não iniciado
                  </DescriptionCard>
                  <DescriptionCard>
                    0%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress />
              </GroupProgress>
            }

            {data.comportamental ?
              <Column top="-10px">
                <Button
                  type="button"
                  width="120px"
                  fontSize="13px"
                  hoverColor={colors.lightBlue}
                  text="Refazer teste"
                  onClick={onBehavioral}
                />
              </Column>
              :
              <Column top="0%">
                <Button
                  type="button"
                  width="120px"
                  fontSize="13px"
                  hoverColor={colors.lightBlue}
                  text="Iniciar teste"
                  onClick={onBehavioral}
                />
              </Column>
            }

            {data.comportamental ?
              <Link
                target="_blank"
                to={{
                  pathname: "/relatorioComportamental?activeUser=" + token.email,
                  search: "",
                  hash: "",
                  state: {
                    activeUser: token.email
                  }
                }}
              >
                <Column top="0%">
                  <Button
                    type="button"
                    color={colors.lightBlue}
                    width="100px"
                    hoverColor={colors.white}
                    bgColor={colors.white}
                    border={colors.white}
                    hoverBorder={colors.white}
                    fontSize={'12px'}
                    height={'30px'}
                    margin={'0px'}
                    text="Ver relatório"
                  />
                </Column>
              </Link>
              :
              <></>
            }
          </GroupItems>
        </Card>
        <Card width="23%">
          <GroupItems>
            <ContainerTitle>
              <Icon src={Deployment} alt="Next" />
              <div>
                <DescriptionData>{data.cultural ? "Útltima atualização:" : ""}</DescriptionData>
                <DescriptionData weight="700">{data.cultural ? moment(data.cultural.DATA_DA_ENTRADA).format("DD/MM/YYYY") : ""}</DescriptionData>
              </div>
            </ContainerTitle>
            {data.cultural ?
              <Column>
                <Tag>
                  <DescriptionData color={colors.white}>
                    Teste 2 - Concluído
                  </DescriptionData>
                </Tag>
              </Column>
              :
              <Column>
                <Tag background={colors.gray4}>
                  <DescriptionData color={colors.white}>
                    Teste 2 - Pendente
                  </DescriptionData>
                </Tag>
              </Column>
            }
            <SubTitle bottom="15%">Teste de Cultura</SubTitle>
            <DescriptionCard>
              Descubra o seu estilo cultural, o que é aceito ou não por você no ambiente profissional e com qual cultura organizacional você mais se identifica.
            </DescriptionCard>
            <Row>
              <Icon width='35px' src={Activity} alt="Next" />
              <DescriptionData> Tempo estimado 4 minutos</DescriptionData>
            </Row>
            {data.cultural ?
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard color={colors.darkBlue}>
                    Concluído
                  </DescriptionCard>
                  <DescriptionCard color={colors.darkBlue}>
                    100%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress colors={colors.pureGreen} />
              </GroupProgress>
              :
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard>
                  Não iniciado
                  </DescriptionCard>
                  <DescriptionCard>
                    0%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress />
              </GroupProgress>
            }

            {data.comportamental ?
              <Column top="0%">
                <Button
                  type="button"
                  width="120px"
                  fontSize="13px"
                  hoverColor={colors.lightBlue}
                  text="Refazer teste"
                  onClick={onCulture}
                />
              </Column>
              :
              <Column top="0%">
                <Button
                  type="button"
                  width="120px"
                  fontSize="13px"
                  hoverColor={colors.lightBlue}
                  text="Iniciar teste"
                  onClick={onCulture}
                />
              </Column>
            }

            {data.comportamental ?
              <Link
                target="_blank"
                to={{
                  pathname: "/relatoriodeCultura?activeUser=" + token.email,
                  search: "",
                  hash: "",
                  state: {
                    activeUser: token.email
                  }
                }}
              >
                <Column top="0%">
                  <Button
                    type="button"
                    color={colors.lightBlue}
                    width="100px"
                    hoverColor={colors.white}
                    bgColor={colors.white}
                    border={colors.white}
                    hoverBorder={colors.white}
                    fontSize={'12px'}
                    height={'30px'}
                    margin={'0px'}
                    text="Ver relatório"
                  />
                </Column>
              </Link>
              :
              <></>
            }
          </GroupItems>
        </Card>
        <Card width="23%">
          <GroupItems>
            <ContainerTitle>
              <Icon src={Cluster} alt="Next" />
              <div>
                <DescriptionData>{data.valores ? "Útltima atualização:" : ""}</DescriptionData>
                <DescriptionData weight="700">{data.valores ? moment(data.valores.DATA_DA_ENTRADA).format("DD/MM/YYYY") : ""}</DescriptionData>
              </div>
            </ContainerTitle>
            {data.valores ?
              <Column>
                <Tag>
                  <DescriptionData color={colors.white}>
                    Teste 3 - Concluído
                  </DescriptionData>
                </Tag>
              </Column>
              :
              <Column>
                <Tag background={colors.gray4}>
                  <DescriptionData color={colors.white}>
                    Teste 3 - Pendente
                  </DescriptionData>
                </Tag>
              </Column>
            }
            <SubTitle>Teste de âncora de carreira</SubTitle>
            <DescriptionCard>
              Saiba o que você mais valoriza em sua jornada profissional, além da comparação entre o que você espera e o que a sua empresa está entregando
            </DescriptionCard>
            <Row top={'13%'}>
              <Icon width='35px' src={Activity} alt="Next" />
              <DescriptionData> Tempo estimado 7 minutos</DescriptionData>
            </Row>
            {data.valores ?
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard color={colors.darkBlue}>
                    Concluído
                  </DescriptionCard>
                  <DescriptionCard color={colors.darkBlue}>
                    100%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress colors={colors.pureGreen} />
              </GroupProgress>
              :
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard>
                  Não iniciado
                  </DescriptionCard>
                  <DescriptionCard>
                    0%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress />
              </GroupProgress>
            }

            {data.comportamental ?
              <Column top="0%">
                <Button
                  type="button"
                  width="120px"
                  fontSize="13px"
                  hoverColor={colors.lightBlue}
                  text="Refazer teste"
                  onClick={onCarrer}
                />
              </Column>
              :
              <Column top="0%">
                <Button
                  type="button"
                  width="120px"
                  fontSize="13px"
                  hoverColor={colors.lightBlue}
                  text="Iniciar teste"
                  onClick={onCarrer}
                />
              </Column>
            }

            {data.comportamental ?
              <Link
                target="_blank"
                to={{
                  pathname: "/relatorioDeValoresDeCarreira?activeUser=" + token.email,
                  search: "",
                  hash: "",
                  state: {
                    activeUser: token.email
                  }
                }}
              >
                <Column top="0%">
                  <Button
                    type="button"
                    color={colors.lightBlue}
                    width="100px"
                    hoverColor={colors.white}
                    bgColor={colors.white}
                    border={colors.white}
                    hoverBorder={colors.white}
                    fontSize={'12px'}
                    height={'30px'}
                    margin={'0px'}
                    text="Ver relatório"
                  />
                </Column>
              </Link>
              :
              <></>
            }
          </GroupItems>
        </Card>
        <Card width="23%">
          <GroupItems>
            <ContainerTitle bottom="2%">
              < AppRegistrationIcon sx={{ color: colors.lightBlue, fontSize: 40 }} />
              {/* <Icon src={Finish} alt="Next" /> */}
            </ContainerTitle>
            <Column>
            <Tag>
                <DescriptionData color={colors.white}>
                  Hardskills
                </DescriptionData>
              </Tag>
            </Column>
            <SubTitle bottom="8%">Meu Painel de carreiras</SubTitle>
            <DescriptionCard bottom="14%">
              Acompanhe de uma maneira fácil e eficiente o gerenciamento de suas carreiras, faça atualizações no seu perfil e encontre possíveis empregadores.
            </DescriptionCard>
            {/* <Row top={'5%'}>
              <Icon width='35px' src={Activity} alt="Next" />
              <DescriptionData> Tempo estimado 7 minutos</DescriptionData>
            </Row> */}
            {/* <GroupProgress>
              <ContainerTitle>
                <DescriptionCard>
                  Concluído
                </DescriptionCard>
                <DescriptionCard>
                  0%
                </DescriptionCard>
              </ContainerTitle>
              <Progress />
            </GroupProgress> */}
            <Column top="22%">
              <Button
                type="button"
                width="120px"
                fontSize="13px"
                hoverColor={colors.lightBlue}
                text="Iniciar"
                onClick={onVacancy}
              />
            </Column>
          </GroupItems>
        </Card>
      </ContainerCard>
    </Container >
  )
}

export default Home;
