import React, { useContext, useState, useEffect } from 'react';
import { Item, Group, Title, GroupLine, Container, Shape, SubTitle, Row, GpItems, DescriptionData, LabelData, Icon } from './styles';
import { slide as Slide } from 'react-burger-menu';
import { Select, TextField, MenuItem, InputLabel, OutlinedInput, FormControl } from '@mui/material';
import menu from './../../../assets/img/Menu.svg';
import shape from './../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import './../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from './../../ErrorModal';
import StatesBrazil from './../../../assets/data/states';
import Chart from 'react-apexcharts';
import vector from './../../../assets/img/Vector.svg';
import { Link } from "react-router-dom";
import CardColor from './../../Card';


const MenuAbout = (prop) => {
    const { token, setToken } = useContext(StoreContext);
    const data = prop.data;
    // const culture = data.length > 0 ? data[0].test_cultural : [];
    // const radar = data.length > 0 ? {
    //     series: [{
    //         name: 'Cultura',
    //         data: [culture.ACOLHIMENTO, culture.PROPOSITO, culture.APRENDIZADO, culture.PRAZER, culture.RESULTADOS, culture.AUTORIDADE, culture.SEGURANÇA, culture.ORDEM],
    //     }],
    //     options: {
    //         colors: ['#95D45A'],
    //         chart: {
    //             height: 350,
    //             type: 'radar',
    //             fontFamily: 'Poppins',
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xaxis: {
    //             color: [colors.darkBlue],
    //             categories: ['Acolhimento', 'Propósito', 'Aprendizado', 'Prazer', 'Resultados', 'Autoridade', 'Segurança', 'Ordem']
    //         }
    //     },
    // } : {}
    // const behavioral = data.length > 0 ? data[0].test_comportamental : [];

    // useEffect(async () => {
    // }, [prop]);

    let icon = token ? false : prop.width <= 570 ? <Shape src={shape} alt="Menu" /> : <Item src={menu} alt="Menu" />;
    let result = data.length > 0 ? data[0] : [];
    let width = prop.width;
    let user = prop.user;
    let vacancy = prop.vacancy;
    let slide = width <= 570 ? "420px" : "700px";
    let seriesBar =  data.length > 0 ? [{
        name: user.users != undefined ? user.users[0].name : '--',
        data: [parseInt(result.FITS.COMPORTAMENTAL), parseInt(result.FITS.CULTURAL), parseInt(result.FITS.LEARN_AGILITIES), parseInt(result.FITS.HARD_SKILLS)]
    }, {
        name: 'Persona',
        data: [100, 100, 100, 100]
    }] : [];
    let optionsBar = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Fit Comportamental', 'Fit Cultural', 'Fit Learning Agilities', 'Fit HardSkill'],
        },
        yaxis: {
            max: 100,
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
    };

    let seriesRadio =  data.length > 0 ? [parseInt(result.FITS.FIT_PERSONA_IDEAL)] : [];
    let optionsRadio =  data.length > 0 ? {
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: parseInt(result.FITS.FIT_PERSONA_IDEAL) + "%",
                }
            },
        },
        labels: ['Fit com a pessoa ideal'],
    } : {}

    let seriesPie =  data.length > 0 ? [parseInt(result.DESBRAVADOR), parseInt(result.EMBAIXADOR), parseInt(result.MANTENEDOR), parseInt(result.INVESTIGADOR)] : [];
    let optionsPie = {
        chart: {
            type: 'donut',
        },
        labels: ["DESBRAVADOR", "EMBAIXADOR", "MANTENEDOR", "INVESTIGADOR"],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    let seriesRadar =  data.length > 0 ? [{
        data: [result.ACOLHIMENTO, result.PROPOSITO, result.APRENDIZADO, result.PRAZER, result.RESULTADOS, result.AUTORIDADE, result.SEGURANÇA, result.ORDEM],
    }] : [];

    let optionsRadar = {
        chart: {
            height: 350,
            type: 'radar',
        },
        xaxis: {
            categories: ['Acolhimento', 'Propósito', 'Aprendizado', 'Prazer', 'Resultados', 'Autoridade', 'Segurança', 'Ordem']
        }
    };



    return (
        data.length > 0 ?
        <Slide width={slide} right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <GroupLine>
                <Title>Persona</Title>
            </GroupLine>
            <Container>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                    <SubTitle>Avaliado: <b>{ user.users != undefined ? user.users[0].name : '--'}</b></SubTitle>
                    </GpItems>
                    <GpItems padding="15px" width="20%" radius="0px 12px 12px 0px" background={colors.white} borderColor={colors.white}>
                        {data.length > 0 && user.users != undefined ?
                            <Link
                                target="_blank"
                                to={{
                                    pathname: "/perfil?activeUser=" +   user.users != undefined ? user.users[0].email : '--',
                                    search: "",
                                    hash: "",
                                    state: {
                                        activeUser:  user.users != undefined ? user.users[0].email : '--',
                                    }
                                }}
                            >
                                <Button
                                    type="button"
                                    width="80px"
                                    hoverColor={colors.lightBlue}
                                    fontSize={'12px'}
                                    height={'30px'}
                                    margin={'0px'}
                                    text="Ver perfil"
                                />
                            </Link>
                            : false}
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Cargo: <b>{vacancy.nome}</b></SubTitle>
                    </GpItems>
                </Row>
                <GroupLine>
                    <Title>Fit Persona ideal</Title>
                </GroupLine>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <Chart options={optionsBar} series={seriesBar} type="bar" height={350} />
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <Chart options={optionsRadio} series={seriesRadio} type="radialBar" height={350} />
                    </GpItems>
                </Row>
                <GroupLine>
                    <Title>Fit Comportamental iGrown</Title>
                </GroupLine>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Perfil Comportamental: <b>{parseInt(result.DESBRAVADOR) > parseInt(result.EMBAIXADOR) && parseInt(result.DESBRAVADOR) > parseInt(result.MANTENEDOR) && parseInt(result.DESBRAVADOR) > parseInt(result.INVESTIGADOR) ? "Desbravador" :
                            parseInt(result.EMBAIXADOR) > parseInt(result.DESBRAVADOR) && parseInt(result.EMBAIXADOR) > parseInt(result.MANTENEDOR) && parseInt(result.EMBAIXADOR) > parseInt(result.INVESTIGADOR) ? "Embaixador" :
                                parseInt(result.MANTENEDOR) > parseInt(result.DESBRAVADOR) && parseInt(result.MANTENEDOR) > parseInt(result.EMBAIXADOR) && parseInt(result.MANTENEDOR) > parseInt(result.INVESTIGADOR) ? "Mantenedor" : "Investigador"}</b></SubTitle>
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <Chart options={optionsPie} series={seriesPie} type="donut" height={350} />
                    </GpItems>
                </Row>
                <GroupLine>
                    <Title>Learning Agilities</Title>
                </GroupLine>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade Mental'
                            value={result.AGILIDADE_MENTAL + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade com Pessoas'
                            value={result.AGILIDADE_COM_PESSOAS + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade com Mudanças'
                            value={result.AGILIDADE_COM_MUDANCAS + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Agilidade com Resultados'
                            value={result.AGILIDADE_COM_RESULTADOS + '%'}
                            font="14px"
                        />
                        <CardColor
                            width="400px"
                            bgColor={colors.lightBlue}
                            title='Potencial Fit'
                            value={result.POTENCIAL + '%'}
                            font="14px"
                        />
                    </GpItems>
                </Row>
                <GroupLine>
                    <Title>Cultura</Title>
                </GroupLine>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <Chart options={optionsRadar} series={seriesRadar} type="radar" height={350} />
                    </GpItems>
                </Row>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Container>
        </Slide >
        :
        <Slide width={slide} right customBurgerIcon={icon} isOpen={prop.isOpen} noOverlay disableCloseOnEsc onClose={prop.onClose}   >
            <GroupLine>
                <Title>Persona</Title>
            </GroupLine>
            <Container>
                <Row top="0%" align="start" bottom="0px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                    <SubTitle>Avaliado: <b>{ user.users != undefined ? user.users[0].name : '--'}</b></SubTitle>
                    </GpItems>
                    <GpItems padding="15px" width="20%" radius="0px 12px 12px 0px" background={colors.white} borderColor={colors.white}>
                        {data.length > 0 && user.users != undefined ?
                            <Link
                                target="_blank"
                                to={{
                                    pathname: "/perfil?activeUser=" +   user.users != undefined ? user.users[0].email : '--',
                                    search: "",
                                    hash: "",
                                    state: {
                                        activeUser:  user.users != undefined ? user.users[0].email : '--',
                                    }
                                }}
                            >
                                <Button
                                    type="button"
                                    width="80px"
                                    hoverColor={colors.lightBlue}
                                    fontSize={'12px'}
                                    height={'30px'}
                                    margin={'0px'}
                                    text="Ver perfil"
                                />
                            </Link>
                            : false}
                    </GpItems>
                </Row>
                <Row top="0%" align="start" bottom="30px">
                    <GpItems padding="10px" width="80%" radius="12px 0px 0px 12px" background={colors.white} borderColor={colors.white}>
                        <SubTitle>Cargo: <b>{vacancy.nome}</b></SubTitle>
                    </GpItems>
                </Row>
                <SubTitle align="center" font="16px" color={colors.alertRed}><b>Não há dados para esse candidado!</b></SubTitle>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Container>
        </Slide >
    )
}

export default MenuAbout;
